import { AppAction } from 'common/configStore';
import { firebaseAnalytics } from 'common/firebase';
import { AnyAction } from 'redux';
import {
    CREATE_USER_BEGIN,
    CREATE_USER_DISMISS_ERROR,
    CREATE_USER_FAILURE,
    CREATE_USER_SUCCESS,
} from 'redux/users/constants';
import { logError } from 'common/analytics/firebaseEvents';

export const createUser = (username: string, email: string, password: string): AppAction => async (dispatch) => {
    dispatch({
        type: CREATE_USER_BEGIN,
    });

    const query = `
            mutation signup{
              signup(
                username: "${username}"
                email: "${email}"
                password: "${password}"
              )
            }
        `;

    const opts: RequestInit = {
        credentials: 'include',
        method: 'POST',
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify({ query }),
    };

    return fetch(`${process.env.REACT_APP_GRAPHQL}`, opts)
        .then((response) => response.json())
        .then((createdJson) => {
            if (createdJson?.errors || !createdJson?.data?.signup) {
                dispatch({
                    type: CREATE_USER_FAILURE,
                    errors: createdJson?.errors || ['Failed to join. Please try again later'],
                });
            } else {
                firebaseAnalytics?.logEvent('sign_up', {
                    user_email: email,
                    user_name: username,
                });
                dispatch({
                    type: CREATE_USER_SUCCESS,
                });
            }
        })
        .catch((errors) => {
            logError(errors, 'user_join');
            dispatch({
                type: CREATE_USER_FAILURE,
                errors: [errors],
            });
        });
};

// Async action saves request error by default, this method is used to dismiss the error info.
// If you don't want errors to be saved in Redux store, just ignore this method.
export const dismissCreateUserError = () => ({
    type: CREATE_USER_DISMISS_ERROR,
});

export const reducer = (state: any, action: AnyAction) => {
    switch (action.type) {
    case CREATE_USER_BEGIN:
        // Just after a request is sent
        return {
            ...state,
            createUserPending: true,
            createUserError: null,
            userCreated: false,
        };

    case CREATE_USER_SUCCESS:
        // The request is success
        return {
            ...state,
            createUserPending: false,
            createUserError: null,
            userCreated: true,
        };

    case CREATE_USER_FAILURE:
        // The request is failed
        return {
            ...state,
            userCreated: false,
            createUserPending: false,
            createUserError: action.errors,
        };

    case CREATE_USER_DISMISS_ERROR:
        // Dismiss the request failure error
        return {
            ...state,
            createUserError: null,
        };

    default:
        return state;
    }
};
