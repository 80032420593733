import { AppAction } from 'common/configStore';
import { AnyAction } from 'redux';
import {
    CONTACT_SEND_BEGIN,
    CONTACT_SEND_DISMISS_ERROR,
    CONTACT_SEND_FAILURE,
    CONTACT_SEND_SUCCESS,
} from 'redux/common/constants';

export const contactSend = (name: string, email: string, message: string): AppAction => async (dispatch) => {
    dispatch({
        type: CONTACT_SEND_BEGIN,
    });

    const query = `
        mutation {
          contact(
            name: "${name}"
            email: "${email}"
            message: "${message}"
          )
        }
    `;

    const opts: RequestInit = {
        method: 'POST',
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify({ query }),
    };

    return fetch(`${process.env.REACT_APP_GRAPHQL}`, opts)
        .then((response) => response.json())
        .then((createdJson) => {
            if (!createdJson?.errors && createdJson?.data?.contact) {
                dispatch({
                    type: CONTACT_SEND_SUCCESS,
                });
            } else {
                dispatch({
                    type: CONTACT_SEND_FAILURE,
                });
            }
        })
        .catch((errors) => {
            dispatch({
                type: CONTACT_SEND_FAILURE,
                errors,
            });
        });
};

// Async action saves request error by default, this method is used to dismiss the error info.
// If you don't want errors to be saved in Redux store, just ignore this method.
export const dismissContactSendError = () => ({
    type: CONTACT_SEND_DISMISS_ERROR,
});

export const reducer = (state: any, action: AnyAction) => {
    switch (action.type) {
    case CONTACT_SEND_BEGIN:
        // Just after a request is sent
        return {
            ...state,
            contactSendPending: true,
            contactSendError: false,
            contactSent: false,
        };

    case CONTACT_SEND_SUCCESS:
        // The request is success
        return {
            ...state,
            contactSendPending: false,
            contactSendError: false,
            contactSent: true,
        };

    case CONTACT_SEND_FAILURE:
        // The request is failed
        return {
            ...state,
            contactSendError: true,
            contactSendPending: false,
            contactSent: false,
        };

    case CONTACT_SEND_DISMISS_ERROR:
        // Dismiss the request failure error
        return {
            ...state,
            contactSendError: false,
            contactSent: false,
        };

    default:
        return state;
    }
};
