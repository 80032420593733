import { AppAction } from 'common/configStore';
import { AnyAction } from 'redux';
import {
    RESET_PASSWORD_BEGIN,
    RESET_PASSWORD_DISMISS_ERROR,
    RESET_PASSWORD_FAILURE,
    RESET_PASSWORD_SUCCESS,
} from 'redux/users/constants';

export const resetPassword = (
    userID: string = '',
    newPassword: string = '',
    token: string = '',
): AppAction => async (dispatch) => {

    dispatch({
        type: RESET_PASSWORD_BEGIN,
    });

    const query = `
            mutation {
              resetPassword(
                user_id: "${userID}"
                newPassword:  "${newPassword}"
                token: "${token}"
              ) {
                _id
                email
                user_name
                project_ids
                liked_projects
                premium
                user_image
                date_created
                user_image
              }
            }
        `;

    const opts: RequestInit = {
        method: 'POST',
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify({ query }),
    };

    return fetch(process.env.REACT_APP_GRAPHQL || '', opts)
        .then((response) => response.json())
        .then((createdJson) => {
            if (createdJson.errors) {
                dispatch({
                    type: RESET_PASSWORD_FAILURE,
                    errors: createdJson,
                });
            } else {
                dispatch({
                    type: RESET_PASSWORD_SUCCESS,
                    data: createdJson.data.resetPassword,
                });
            }
        })
        .catch((errors) => {
            dispatch({
                type: RESET_PASSWORD_FAILURE,
                errors,
            });
        });
};

// Async action saves request error by default, this method is used to dismiss the error info.
// If you don't want errors to be saved in Redux store, just ignore this method.
export const dismissResetPasswordError = () => ({
    type: RESET_PASSWORD_DISMISS_ERROR,
});

export const reducer = (state:any, action: AnyAction) => {
    switch (action.type) {
    case RESET_PASSWORD_BEGIN:
        // Just after a request is sent
        return {
            ...state,
            resetPasswordPending: true,
            resetPasswordError: null,
            passwordReset: false,
        };

    case RESET_PASSWORD_SUCCESS:
        // The request is success
        return {
            ...state,
            resetPasswordPending: false,
            resetPasswordError: null,
            passwordReset: true,
        };

    case RESET_PASSWORD_FAILURE:
        // The request is failed
        return {
            ...state,
            resetPasswordPending: false,
            resetPasswordError: action.errors,
            passwordReset: false,
        };

    case RESET_PASSWORD_DISMISS_ERROR:
        // Dismiss the request failure error
        return {
            ...state,
            resetPasswordError: null,
            passwordReset: false,
        };

    default:
        return state;
    }
};
