import { AppAction } from 'common/configStore';
import { AnyAction } from 'redux';
import {
    GET_PROJECT_BEGIN,
    GET_PROJECT_DISMISS_ERROR,
    GET_PROJECT_FAILURE,
    GET_PROJECT_SUCCESS,
} from 'redux/projects/constants';

export const getProject = (id: string, viewer: string = ''): AppAction => async (dispatch) => {

    dispatch({
        type: GET_PROJECT_BEGIN,
    });

    const imagePanelQuery = `
            annotations {
                startPoints {
                    x
                    y
                }
                midPoints {
                    x
                    y
                }
                endPoints {
                    x
                    y
                }
                annotationInfo
            }
            description
            image
        `;

    const query = `
            query {
              getProject(
                projectID: "${id}"
                viewer: "${viewer}"
              ) {
                _id
                categories
                liked_by
                owner_id
                private
                is_showcase
                project_name
                description
                date_created
                last_updated
                featured_image
                collaborators
                ownerTier
                owner_data {
                    user_name
                    user_image
                }
                liked_by_users {
                    _id
                    user_name
                    user_image
                }
                sketches {
                    ${imagePanelQuery}
                }
                materials {
                    ${imagePanelQuery}
                }
                outcome {
                    ${imagePanelQuery}
                }
                measurements {
                    headerWidths
                    headers
                    data
                }
              }
            }
        `;

    const opts: RequestInit = {
        method: 'POST',
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify({ query }),
    };

    return fetch(process.env.REACT_APP_GRAPHQL || '', opts)
        .then((response) => response.json())
        .then((createdJson) => {
            if (createdJson.errors) {
                dispatch({
                    type: GET_PROJECT_FAILURE,
                    errors: createdJson.errors,
                });
            } else {
                // Uncomment to see how GraphQL returns data
                // console.log(createdJson);
                dispatch({
                    type: GET_PROJECT_SUCCESS,
                    data: createdJson.data.getProject,
                });
            }
        })
        .catch((errors) => {
            dispatch({
                type: GET_PROJECT_FAILURE,
                errors,
            });
        });
};

// Async action saves request error by default, this method is used to dismiss the error info.
// If you don't want errors to be saved in Redux store, just ignore this method.
export const dismissGetProjectError = () => ({
    type: GET_PROJECT_DISMISS_ERROR,
});

export const reducer = (state: any, action: AnyAction) => {
    switch (action.type) {
    case GET_PROJECT_BEGIN:
        // Just after a request is sent
        return {
            ...state,
            getProjectPending: true,
            getProjectError: null,
            project: null,
        };

    case GET_PROJECT_SUCCESS:
        // The request is success
        return {
            ...state,
            getProjectPending: false,
            getProjectError: null,
            project: action.data,
        };

    case GET_PROJECT_FAILURE:
        // The request is failed
        return {
            ...state,
            project: null,
            getProjectPending: false,
            getProjectError: action.errors,
        };

    case GET_PROJECT_DISMISS_ERROR:
        // Dismiss the request failure error
        return {
            ...state,
            getProjectError: null,
        };

    default:
        return state;
    }
};
