export const { REACT_APP_USER_TIER_1_PRICE_ID: USER_TIER_1_PRICE_ID, REACT_APP_USER_TIER_2_PRICE_ID: USER_TIER_2_PRICE_ID } = process.env;

export const FREE_TABLE_CAP = parseInt(process.env.REACT_APP_FREE_TABLE_CAP || '10', 10);
export const FREE_IMAGES_SECTION_CAP = parseInt(process.env.REACT_APP_FREE_IMAGES_SECTION_CAP || '4', 10);
export const FREE_ANNOTATION_CAP = parseInt(process.env.REACT_APP_FREE_ANNOTATION_CAP || '10', 10);
export const FREE_PROJECT_CAP = parseInt(process.env.REACT_APP_FREE_PROJECT_CAP || '5', 10);

export const TIER_1_TABLE_CAP = parseInt(process.env.REACT_APP_TIER_1_TABLE_CAP || '20', 10);
export const TIER_1_IMAGES_SECTION_CAP = parseInt(process.env.REACT_APP_TIER_1_IMAGES_SECTION_CAP || '6', 10);
export const TIER_1_ANNOTATION_CAP = parseInt(process.env.REACT_APP_TIER_1_ANNOTATION_CAP || '20', 10);
export const TIER_1_PROJECT_CAP = parseInt(process.env.REACT_APP_TIER_1_PROJECT_CAP || '20', 10);

export const TIER_2_TABLE_CAP = parseInt(process.env.REACT_APP_TIER_2_TABLE_CAP || '30', 10);
export const TIER_2_IMAGES_SECTION_CAP = parseInt(process.env.REACT_APP_TIER_2_IMAGES_SECTION_CAP || '10', 10);
export const TIER_2_ANNOTATION_CAP = parseInt(process.env.REACT_APP_TIER_2_ANNOTATION_CAP || '30', 10);
export const TIER_2_PROJECT_CAP = parseInt(process.env.REACT_APP_TIER_2_PROJECT_CAP || '-1', 10);
