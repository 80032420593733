import { logPageView } from 'common/analytics/firebaseEvents';
import React, { useEffect } from 'react';
import withHelmet from 'components/hocs/withHelmet';
import { PAGE_NAMES } from 'constants/metaData';
import styles from 'styles/general.module.css';
import logo from 'logo2_500.png';

const EmailSent = () => {

    useEffect(() => {
        logPageView();
    }, []);

    return (
        <div>
            <div><img width='250vw' src={logo} alt='Chiqpack Logo' /></div>
            <h1 className={`${styles.largeCenterTitle}`}>Verification Email Sent</h1>
            <p className={`${styles.smallCenterDescription}`}>Please check your inbox and verify your email.</p>
        </div>
    );
};

EmailSent.displayName = PAGE_NAMES.EMAIL_SENT;

export default withHelmet(EmailSent);
