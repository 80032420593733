import React, { useEffect, useState } from 'react';
import { logPageView } from 'common/analytics/firebaseEvents';
import { isValidEmail } from 'common/authenUtils/authenUtils';
import { Link, Redirect } from 'react-router-dom';
import { EReCaptchaV2Size, EReCaptchaV2Theme, ReCaptchaV2, ReCaptchaV3, TReCaptchaV2Callback } from 'react-recaptcha-x';
import { useDispatch, useSelector } from 'common/hooks';
import {
    Container,
    Grid,
    TextField,
    Button,
} from '@material-ui/core';
import { Alert } from '@material-ui/lab';
import { createUser, dismissCreateUserError } from 'redux/actions';
import { fontSize } from 'pages/constants/login';
import styles from 'styles/login.module.css';
import logo from 'logo2_500.png';
import { v2Callback, v3Callback } from 'common/recaptcha';
import withHelmet from 'components/hocs/withHelmet';
import { PAGE_NAMES } from 'constants/metaData';

const Join = () => {
    const dispatch = useDispatch();
    const [username, setUsername] = useState('');
    const [password, setPassword] = useState('');
    const [confirmPassword, setConfirmPassword] = useState('');
    const [email, setEmail] = useState('');
    const [emailError, setEmailError] = useState(false);
    const [passwordError, setPasswordError] = useState(false);
    const [recaptchaDone, setRecaptchaDone] = useState(false);

    const {
        userCreated,
        createUserPending,
        createUserError,
    } = useSelector((state) => ({
        userCreated: state.users.userCreated,
        createUserPending: state.users.createUserPending,
        createUserError: state.users.createUserError,
    }));

    useEffect(() => {
        logPageView();
    }, []);

    // Make sure password and confirmation password are the same
    const passwordsMatch = () => confirmPassword === password;

    const join = () => {
        if (!isValidEmail(email)) {
            setEmailError(true);
        } else if (!passwordsMatch()) {
            setPasswordError(true);
        } else {
            setEmailError(false);
            setPasswordError(false);
            dispatch(createUser(username, email, password));
        }
    };

    // Redirect to success page if user is  created succesfully
    const redir = userCreated ? (<Redirect to='/emailVerificationSent' />) : null;

    const emailAlert = !emailError ? null : (
        <Alert
            severity='error'
            onClose={() => { setEmailError(false); }}
            className={styles.login_alert}
        >
            Invalid Email.
        </Alert>
    );

    const loginError = !createUserError ? null : (
        <Alert
            severity='error'
            onClose={() => { dispatch(dismissCreateUserError()); }}
            className={styles.login_alert}
        >
            {createUserError && createUserError.length > 0 ? createUserError[0]?.message : 'Unable to create account. Please try again later.'}
        </Alert>
    );

    const passwordMatchAlert = passwordError ? (
        <Alert
            severity='error'
            onClose={() => setPasswordError(false)}
            className={styles.login_alert}
        >
            Passwords do not match
        </Alert>
    ) : null;

    if (userCreated) {
        return (<Redirect to='/emailVerificationSent' />);
    }

    return (
        <div>
            <div className={styles.outer_container}>
                {redir}
                <div className={styles.logo_wrapper}>
                    <img src={logo} alt='Chiqpack Logo' className={styles.logo} />
                    <h1 className={styles.logo_header}>Sign up for Chiqpack</h1>
                </div>
                {loginError}
                {emailAlert}
                {passwordMatchAlert}
                <Container className={styles.form_container} maxWidth={false}>
                    <form>
                        <Grid container spacing={3}>
                            <Grid item xs={12}>
                                <Grid container spacing={2}>
                                    <Grid item xs={12}>
                                        <TextField
                                            fullWidth
                                            label={<span style={{ fontSize, lineHeight: 0 }}>Username</span>}
                                            name='username'
                                            size='small'
                                            variant='outlined'
                                            value={username}
                                            required
                                            inputProps={{ style: { fontSize } }}
                                            InputLabelProps={{ style: { fontSize } }}
                                            onChange={
                                                (event) => (setUsername(event.target.value))
                                            }
                                        />
                                    </Grid>
                                    <Grid item xs={12}>
                                        <TextField
                                            fullWidth
                                            label={<span style={{ fontSize, lineHeight: 0 }}>Email</span>}
                                            name='email'
                                            size='small'
                                            variant='outlined'
                                            value={email}
                                            required
                                            inputProps={{ style: { fontSize } }}
                                            InputLabelProps={{ style: { fontSize } }}
                                            onChange={
                                                (event) => (setEmail(event.target.value))
                                            }
                                        />
                                    </Grid>
                                    <Grid item xs={12}>
                                        <TextField
                                            fullWidth
                                            label={<span style={{ fontSize, lineHeight: 0 }}>Password</span>}
                                            name='password'
                                            size='small'
                                            type='password'
                                            variant='outlined'
                                            value={password}
                                            required
                                            inputProps={{ style: { fontSize } }}
                                            InputLabelProps={{ style: { fontSize } }}
                                            onChange={
                                                (event) => (setPassword(event.target.value))
                                            }
                                        />
                                    </Grid>
                                    <Grid item xs={12}>
                                        <TextField
                                            fullWidth
                                            label={<span style={{ fontSize, lineHeight: 0 }}>Confirm Password</span>}
                                            name='password'
                                            size='small'
                                            type='password'
                                            variant='outlined'
                                            value={confirmPassword}
                                            required
                                            inputProps={{ style: { fontSize } }}
                                            InputLabelProps={{ style: { fontSize } }}
                                            onChange={
                                                (event) => (setConfirmPassword(event.target.value))
                                            }
                                        />
                                    </Grid>
                                </Grid>
                            </Grid>
                            <Grid item xs={12}>
                                <ReCaptchaV2
                                    callback={(token) => v2Callback(token, setRecaptchaDone)}
                                    theme={EReCaptchaV2Theme.Light}
                                    size={EReCaptchaV2Size.Normal}
                                    id='recaptchaV2'
                                    tabindex={0}
                                />
                                <ReCaptchaV3 action='joinAction' callback={v3Callback} />
                            </Grid>
                            <Grid item xs={12}>
                                <Button
                                    disabled={userCreated || createUserPending || !recaptchaDone}
                                    color='inherit'
                                    fullWidth
                                    variant='contained'
                                    className={styles.login_button}
                                    onClick={join}
                                >
                                Create Account
                                </Button>
                            </Grid>
                        </Grid>
                    </form>
                </Container>

                <Container className={styles.sub_container} maxWidth={false}>
                    <h2 className={styles.sub_header}>
                        Already have an account?
                        {' '}
                        <Link className={styles.sub_link} to='/login'>Sign In</Link>
                    </h2>
                </Container>
            </div>
        </div>
    );
};

Join.displayName = PAGE_NAMES.JOIN;

export default withHelmet(Join);
