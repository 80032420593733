import React from 'react';
import { Helmet } from 'react-helmet';
import { PageName, PAGE_META_DATA, PAGE_NAMES } from 'constants/metaData';

const withHelmet = <P extends object>(
    Component: React.ComponentType<P>,
): React.FC<P> => ({
        ...props
    }: P) => {
    // Use the meta data on the Home page as a fallback
        const { helmetTitle = 'Chiqpack', helmetDescription } = PAGE_META_DATA[Component.displayName as PageName || PAGE_NAMES.HOME];
        return (
            <div>
                <Helmet>
                    <title>{helmetTitle}</title>
                    { helmetDescription && <meta name='description' content={helmetDescription} /> }
                </Helmet>
                <Component {...props as P} />
            </div>
        );
    };

export default withHelmet;
