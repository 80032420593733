import React, { useMemo } from 'react';
import Modal from 'components/Modal';
import { ProjectSection, PROJECT_SECTIONS } from 'pages/Project';
import { DetailedImage } from 'redux/projects/models/DetailedImage';
import { NavigationPanel } from 'components/modals/componentCardModal/NavigationPanel';
import { MainPanel } from 'components/modals/componentCardModal/MainPanel';
import { Table } from 'redux/projects/models/Table';
import styles from 'components/modals/componentCardModal/styles/componentCardModal.module.css';

interface Props {
    section: ProjectSection;
    setSection: (section: ProjectSection) => void;
    components: {
        sketches?: DetailedImage[];
        materials?: DetailedImage[];
        measurements?: Table;
        outcome?: DetailedImage[];
    };
    componentIndex: number;
    setComponentIndex: (idx: number) => void;
    // eslint-disable-next-line no-restricted-globals
    open: boolean;
    onClose: () => void;
}

export const ComponentCardModal = ({
    section,
    setSection,
    components,
    componentIndex,
    setComponentIndex,
    ...props
}: Props) => {
    const availableSections = useMemo(() => (
        PROJECT_SECTIONS.filter((s) => {
            if (s !== 'measurements') {
                return components?.[s]?.length;
            }
            return components?.[s]?.data[0].length;
        })
    ), [components]);

    return (
        <Modal
            fullScreen
            {...props}
        >
            <div className={styles.container}>
                <NavigationPanel
                    section={section}
                    setSection={setSection}
                    availableSections={availableSections}
                />
                <MainPanel
                    section={section}
                    components={components?.[section]}
                    componentIndex={componentIndex}
                    setComponentIndex={setComponentIndex}
                />
            </div>
        </Modal>
    );
};
