import { AppAction } from 'common/configStore';
import { firebaseAnalytics } from 'common/firebase';
import { AnyAction } from 'redux';
import {
    getUpdateProjectAuthentication,
} from 'redux/utils/projectUtils';
import {
    DELETE_PROJECT_BEGIN,
    DELETE_PROJECT_DISMISS_ERROR,
    DELETE_PROJECT_DISMISS_SUCCESS,
    DELETE_PROJECT_FAILURE,
    DELETE_PROJECT_SUCCESS,
} from 'redux/projects/constants';

export const deleteProject = (userID: string = '', projectID: string = ''): AppAction => async (dispatch) => {

    dispatch({
        type: DELETE_PROJECT_BEGIN,
    });

    // Get authentication before proceeding
    const authen = await getUpdateProjectAuthentication(userID, projectID, true);

    if (!authen) {
        dispatch({
            type: DELETE_PROJECT_FAILURE,
            errors: ['You don\'t have permissions to delete this project'],
        });
        return;
    }

    const query = `
            mutation {
              deleteProject(
                id: "${projectID}"
              )
            }
        `;

    const opts: RequestInit = {
        credentials: 'include',
        method: 'POST',
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify({ query }),
    };

    fetch(process.env.REACT_APP_GRAPHQL || '', opts)
        .then((response) => response.json())
        .then((createdJson) => {
            if (createdJson.errors) {
                dispatch({
                    type: DELETE_PROJECT_FAILURE,
                    errors: createdJson.errors,
                });
            } else {
                firebaseAnalytics?.logEvent('project_deleted', {
                    user_id: userID,
                    project_id: projectID,
                });
                dispatch({
                    type: DELETE_PROJECT_SUCCESS,
                    data: createdJson.data.deleteProject,
                });
            }
        })
        .catch((errors) => {
            dispatch({
                type: DELETE_PROJECT_FAILURE,
                errors,
            });
        });
};

// Async action saves request error by default, this method is used to dismiss the error info.
// If you don't want errors to be saved in Redux store, just ignore this method.
export const dismissDeleteProjectError = () => ({
    type: DELETE_PROJECT_DISMISS_ERROR,
});

export const dismissProjectDeleted = () => ({
    type: DELETE_PROJECT_DISMISS_SUCCESS,
});

export const reducer = (state: any, action: AnyAction) => {
    switch (action.type) {
    case DELETE_PROJECT_BEGIN:
        // Just after a request is sent
        return {
            ...state,
            deleteProjectPending: true,
            deleteProjectError: null,
            projectDeleted: false,
        };

    case DELETE_PROJECT_SUCCESS:
        // The request is success
        return {
            ...state,
            deleteProjectPending: false,
            deleteProjectError: null,
            projectDeleted: action.data,
        };

    case DELETE_PROJECT_FAILURE:
        // The request is failed
        return {
            ...state,
            deleteProjectPending: false,
            deleteProjectError: action.errors,
            projectDeleted: false,
        };

    case DELETE_PROJECT_DISMISS_ERROR:
        // Dismiss the request failure error
        return {
            ...state,
            deleteProjectError: null,
            projectDeleted: false,
        };

    case DELETE_PROJECT_DISMISS_SUCCESS:
        // Dismiss the success state
        return {
            ...state,
            projectDeleted: false,
        };

    default:
        return state;
    }
};
