import React, { useCallback, useState } from 'react';
import ReactPlayer, { ReactPlayerProps } from 'react-player';
import Slider, { Settings as ReactSlickSettings } from 'react-slick';
import ExamplesCarouselSlide from 'components/ExamplesCarousel/ExamplesCarouselSlide';
import { CORNER_POSITION, MAX_VIDEO_LENGTH } from 'components/ExamplesCarousel/constants';
import styles from 'components/ExamplesCarousel/styles/examplesCarousel.module.css';
import landingStyles from 'styles/landing.module.css';
import { Link } from 'react-router-dom';
import THEME_COLORS from 'utils/themeColors';

const carouselSettings: ReactSlickSettings = {
    dots: true,
    infinite: true,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
    arrows: false,
    autoplay: true,
    autoplaySpeed: MAX_VIDEO_LENGTH,
    // users may accidentally hover over the carousel and prevent autoplay so disable pauseOnHover
    pauseOnHover: false,
    fade: true,
    appendDots: (_) => (<></>), // removes dots
};

const playerProps: ReactPlayerProps = {
    width: '100%',
    height: '100%',
    type: 'video/webm',
    // needs to be true for iOS to work
    playsinline: true,
    loop: true,
    muted: true,
};

const ExamplesCarousel = () => {
    const [currentIndex, setCurrentIndex] = useState<number>(0);

    const handleSlideChange = useCallback((idx: number) => {
        setCurrentIndex(idx);
    }, [setCurrentIndex]);

    return (
        <>
            <Slider className={styles.container} afterChange={handleSlideChange} {...carouselSettings}>
                <ExamplesCarouselSlide
                    corner={CORNER_POSITION.TOP_LEFT}
                    blurb={( // bit of a hack to keep 'project ➕' together
                        <>
                            Create a
                            <span style={{ whiteSpace: 'nowrap' }}>&nbsp;project&nbsp;➕</span>
                        </>
                    )}
                >
                    <ReactPlayer playing={currentIndex === 0} url='example_CreateProject.mp4' {...playerProps} />
                </ExamplesCarouselSlide>
                <ExamplesCarouselSlide corner={CORNER_POSITION.TOP_RIGHT} blurb='Annotate&nbsp;✍️'>
                    <ReactPlayer playing={currentIndex === 1} url='example_EditProject.mp4' {...playerProps} />
                </ExamplesCarouselSlide>
                <ExamplesCarouselSlide
                    corner={CORNER_POSITION.BOTTOM_RIGHT}
                    blurb={(
                        <>
                            Add
                            <span style={{ whiteSpace: 'nowrap' }}>&nbsp;measurements&nbsp;📏</span>
                        </>
                    )}
                >
                    <ReactPlayer playing={currentIndex === 2} url='example_Measurements.mp4' {...playerProps} />
                </ExamplesCarouselSlide>
                <ExamplesCarouselSlide corner={CORNER_POSITION.BOTTOM_LEFT} blurb='Celebrate&nbsp;🎉'>
                    <ReactPlayer playing={currentIndex === 3} url='example_Project.mp4' {...playerProps} />
                </ExamplesCarouselSlide>
            </Slider>
            <p className={landingStyles.section_blurb}>
                Check out the project demo
                {' '}
                <Link style={{ color: THEME_COLORS.lightPurple, textDecoration: 'none' }} to='/Chiqpack/61947dc039bb6222c66ed990'><b>here!</b></Link>
            </p>
        </>

    );
};

export default ExamplesCarousel;
