import React from 'react';

interface Props {
    width?: string | number;
    height?: string | number;
}

const Spacer = ({ width, height }: Props) => (
    <div style={{ width, height }} />
);

Spacer.defaultProps = {
    width: 0,
    height: 0,
};

export default Spacer;
