export enum CORNER_POSITION {
    TOP_LEFT = 'TOP_LEFT',
    TOP_RIGHT = 'TOP_RIGHT',
    BOTTOM_LEFT = 'BOTTOM_LEFT',
    BOTTOM_RIGHT = 'BOTTOM_RIGHT',
}

export type Corner = keyof typeof CORNER_POSITION;

// in ms
export const MAX_VIDEO_LENGTH = 11500;
