import { AppAction } from 'common/configStore';
import { AnyAction } from 'redux';
import {
    SEARCH_USERS_BEGIN,
    SEARCH_USERS_DISMISS_ERROR,
    SEARCH_USERS_FAILURE,
    SEARCH_USERS_SUCCESS,
    SEARCH_USERS_CLEAR_DATA,
} from 'redux/users/constants';

export const searchUsers = (keyword: string): AppAction => async (dispatch) => {

    dispatch({
        type: SEARCH_USERS_BEGIN,
    });

    const query = `
            query {
              searchUsers(
                keyword: ${JSON.stringify(keyword)}
              ) {
                _id
                email
                user_name
              }
            }
        `;

    const opts: RequestInit = {
        method: 'POST',
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify({ query }),
    };

    return fetch(process.env.REACT_APP_GRAPHQL || '', opts)
        .then((response) => response.json())
        .then((createdJson) => {
            if (createdJson.errors) {
                dispatch({
                    type: SEARCH_USERS_FAILURE,
                    errors: createdJson.errors,
                });
            } else {
                dispatch({
                    type: SEARCH_USERS_SUCCESS,
                    data: createdJson.data.searchUsers,
                });
            }
        })
        .catch((errors) => {
            dispatch({
                type: SEARCH_USERS_FAILURE,
                errors,
            });
        });
};

// Async action saves request error by default, this method is used to dismiss the error info.
// If you don't want errors to be saved in Redux store, just ignore this method.
export const dismissSearchUsersError = () => ({
    type: SEARCH_USERS_DISMISS_ERROR,
});

export const clearSearchUsersData = () => ({
    type: SEARCH_USERS_CLEAR_DATA,
});

export const reducer = (state: any, action: AnyAction) => {
    switch (action.type) {
    case SEARCH_USERS_BEGIN:
        // Just after a request is sent
        return {
            ...state,
            searchUsersPending: true,
            searchUsersError: null,
            searchUsersData: null,
        };

    case SEARCH_USERS_SUCCESS:
        // The request is success
        return {
            ...state,
            searchUsersPending: false,
            searchUsersError: null,
            searchUsersData: action.data,
        };

    case SEARCH_USERS_FAILURE:
        // The request is failed
        return {
            ...state,
            searchUsersData: null,
            searchUsersPending: false,
            searchUsersError: action.errors,
        };

    case SEARCH_USERS_DISMISS_ERROR:
        // Dismiss the request failure error
        return {
            ...state,
            searchUsersError: null,
        };

    case SEARCH_USERS_CLEAR_DATA:
        // Clear searchUsersData in state
        return {
            ...state,
            searchUsersData: null,
        };

    default:
        return state;
    }
};
