// @ts-nocheck
import { createStore, applyMiddleware, compose, AnyAction } from 'redux';
import thunk, { ThunkAction, ThunkDispatch } from 'redux-thunk';
import rootReducer from 'common/rootReducer';

const middlewares = [
    thunk,
];
let devToolsExtension = (f: any) => f;

if (process.env.REACT_APP_ENV === 'dev' || process.env.REACT_APP_ENV === 'development') {
    const { createLogger } = require('redux-logger');
    const logger = createLogger({ collapsed: true });
    middlewares.push(logger);

    // @ts-ignore
    devToolsExtension = window.devToolsExtension ? window.devToolsExtension() : (f) => f;
}

export const Store = createStore(rootReducer, {}, compose(
    applyMiddleware(...middlewares),
    devToolsExtension,
));

export type RootState = ReturnType<typeof Store.getState>;

export type AppDispatch = ThunkDispatch<RootState, void, AnyAction>;

export type AppAction = ThunkAction<Promise<void>, RootState, void, AnyAction>;
