import { AppAction } from 'common/configStore';
import { firebaseAnalytics } from 'common/firebase';
import { AnyAction } from 'redux';
import {
    UPDATE_PROJECT_VISIBILITY_BEGIN,
    UPDATE_PROJECT_VISIBILITY_DISMISS_ERROR,
    UPDATE_PROJECT_VISIBILITY_FAILURE,
    UPDATE_PROJECT_VISIBILITY_SUCCESS,
} from 'redux/projects/constants';
import {
    getUpdateProjectAuthentication,
} from 'redux/utils/projectUtils';

export const updateProjectVisibility = (
    userID: string = '',
    projectID: string = '',
    projectVisbility: boolean = false,
): AppAction => async (dispatch) => {

    dispatch({
        type: UPDATE_PROJECT_VISIBILITY_BEGIN,
    });

    // Get authentication before proceeding
    const authen = await getUpdateProjectAuthentication(userID, projectID);

    if (!authen) {
        dispatch({
            type: UPDATE_PROJECT_VISIBILITY_FAILURE,
            errors: ['You don\'t have permissions to edit this project'],
        });
        return;
    }

    const query = `
            mutation {
              updateProjectVisibility(
                user_id: "${userID}"
                project_id: "${projectID}"
                is_private: ${projectVisbility}
              )
            }
        `;

    const opts: RequestInit = {
        credentials: 'include',
        method: 'POST',
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify({ query }),
    };

    fetch(process.env.REACT_APP_GRAPHQL || '', opts)
        .then((response) => response.json())
        .then((createdJson) => {
            if (createdJson.errors) {
                firebaseAnalytics?.logEvent('update_project_visibility', {
                    user_id: userID,
                    project_id: projectID,
                    error: createdJson?.errors,
                });
                dispatch({
                    type: UPDATE_PROJECT_VISIBILITY_FAILURE,
                    errors: createdJson.errors,
                });
            } else {
                firebaseAnalytics?.logEvent('update_project_visibility', {
                    user_id: userID,
                    project_id: projectID,
                    private: projectVisbility,
                });
                dispatch({
                    type: UPDATE_PROJECT_VISIBILITY_SUCCESS,
                    data: createdJson.data.updateProjectVisibility,
                });
            }
        })
        .catch((errors) => {
            firebaseAnalytics?.logEvent('update_project_visibility', {
                user_id: userID,
                project_id: projectID,
                error: errors,
            });
            dispatch({
                type: UPDATE_PROJECT_VISIBILITY_FAILURE,
                errors,
            });
        });

};

// Async action saves request error by default, this method is used to dismiss the error info.
// If you don't want errors to be saved in Redux store, just ignore this method.
export const dismissUpdateProjectVisibilityError = () => ({
    type: UPDATE_PROJECT_VISIBILITY_DISMISS_ERROR,
});

export const reducer = (state: any, action: AnyAction) => {
    switch (action.type) {
    case UPDATE_PROJECT_VISIBILITY_BEGIN:
        // Just after a request is sent
        return {
            ...state,
            updateProjectVisibilityPending: true,
            updateProjectVisibilityError: null,
            updateProjectError: action.errors,
            projectUpdated: false,
        };

    case UPDATE_PROJECT_VISIBILITY_SUCCESS:
        // The request is success
        // Update only the visibility setting in project
        return {
            ...state,
            updateProjectVisibilityPending: false,
            updateProjectVisibilityError: null,
            updateProjectError: action.errors,
            projectUpdated: true,
            project: {
                ...state.project,
                private: action.data,
            },
        };

    case UPDATE_PROJECT_VISIBILITY_FAILURE:
        // The request is failed
        return {
            ...state,
            updateProjectVisibilityPending: false,
            updateProjectVisibilityError: action.errors,
            updateProjectError: action.errors,
            projectUpdated: false,
        };

    case UPDATE_PROJECT_VISIBILITY_DISMISS_ERROR:
        // Dismiss the request failure error
        return {
            ...state,
            updateProjectVisibilityError: null,
            updateProjectError: null,
            projectUpdated: false,
        };

    default:
        return state;
    }
};
