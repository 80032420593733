// These helper stateless-functional-components allow us to reuse styles, and give each shape a meaningful name.
import React, { MouseEvent } from 'react';
import { Point } from 'redux/projects/models/Point';
import styles from 'components/annotations/annotations.module.css';
import { Tooltip } from '@material-ui/core';

interface ConnectingLineProps {
    from: Point;
    to: Point;
}

export const ConnectingLine = ({ from, to }: ConnectingLineProps) => (
    <line
        x1={from.x}
        y1={from.y}
        x2={to.x}
        y2={to.y}
        stroke='rgb(200, 200, 200)'
        strokeDasharray='5,5'
        strokeWidth={2}
    />
);

interface CurveProps {
    instructions: string;
}

export const Curve = ({ instructions }: CurveProps) => (
    <path
        d={instructions}
        fill='none'
        stroke='black'
        strokeWidth={2}
    />
);

interface LargeHandleProps {
    coordinates: Point;
    onMouseDown: (e: MouseEvent<SVGEllipseElement>) => void;
    editable: boolean | undefined;
    num: number;
}

export const LargeHandle = ({ coordinates, onMouseDown, num, editable }: LargeHandleProps) => (
    <g>
        <ellipse
            cx={coordinates.x}
            cy={coordinates.y}
            rx={12}
            ry={12}
            stroke='green'
            fill='white'
            strokeWidth={1}
            onMouseDown={onMouseDown}
            pointerEvents='fill'
            className={editable ? styles.hoverPointer : undefined}
        />
        <text
            fill='black'
            fontFamily='Verdana'
            x={coordinates.x}
            y={coordinates.y}
            dy='0.3em'
            textAnchor='middle'
            pointerEvents='none'
            fontWeight='bolder'
        >
            {num}
        </text>
    </g>
);

export const SmallHandle = ({ coordinates, onMouseDown, editable }: Omit<LargeHandleProps, 'num'>) => (
    <ellipse
        cx={coordinates.x}
        cy={coordinates.y}
        rx={6}
        ry={6}
        fill='transparent'
        stroke='rgb(244, 0, 137)'
        strokeWidth={2}
        onMouseDown={onMouseDown}
        pointerEvents='all'
        className={editable ? styles.hoverPointer : undefined}
    />
);
