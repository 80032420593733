import { combineReducers } from 'redux';
import { reducer as usersReducer } from 'redux/users/reducer';
import { reducer as projectsReducer } from 'redux/projects/reducer';
import { reducer as commonReducer } from 'redux/common/reducer';

const reducerMap = {
    users: usersReducer,
    projects: projectsReducer,
    common: commonReducer,
};

export default combineReducers(reducerMap);
