import axios from 'axios';
import { firebaseAnalytics } from 'common/firebase';
import { ModifiedDetailedImage } from 'redux/projects/models/DetailedImage';
import { logError } from 'common/analytics/firebaseEvents';

export interface UploadUrlData {
    url: string;
    fileName: string;
}

export enum FileSection {
    FeaturedImage = 'featured_image',
    Sketches = 'sketches',
    Materials = 'materials',
    Outcome = 'outcome',
    UserImage = 'user_image',
}

const getFileNameFromUrl = (imageUrl: string): string => decodeURIComponent(imageUrl).split('/').pop() || '';

async function getUploadURL(userID: string, projectID: string, fileName: string, fileType: string, fileSection: FileSection): Promise<UploadUrlData | null> {

    const query = `
        mutation getSignedURL{
          getSignedURL(
            userID: "${userID}"
            projectID: "${projectID}"
            fileName: "${fileName}"
            fileType: "${fileType}"
            fileSection: "${fileSection}"
          ) {
            url
            fileName
          }
        }
    `;

    const opts: RequestInit = {
        credentials: 'include',
        method: 'POST',
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify({ query }),
    };

    const res = await fetch(`${process.env.REACT_APP_GRAPHQL}`, opts);
    const response = await res.json();
    if (!response?.errors && response?.data?.getSignedURL && response?.data?.getSignedURL?.url) {
        return response?.data?.getSignedURL;
    }
    return null;

}

// Delete an image from GCS
async function deleteImage(userID: string, projectID: string, fileName: string): Promise<boolean> {

    const query = `
        mutation deleteImage{
          deleteImage(
            userID: "${userID}"
            projectID: "${projectID}"
            fileName: "${fileName}"
            projectFile: ${projectID !== ''}
          )
        }
    `;

    const opts: RequestInit = {
        credentials: 'include',
        method: 'POST',
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify({ query }),
    };

    try {
        const res = await fetch(`${process.env.REACT_APP_GRAPHQL}`, opts);
        const response = await res.json();
        return !response?.errors && response?.data?.deleteImage;
    } catch (e) {
        firebaseAnalytics?.logEvent('error', {
            type: 'delete_image',
            error: e,
        });
        console.log(e);
        return false;
    }

}

// This will get an create project token that will allow the user to create a project
export async function getCreateProjectAuthentication(userID: string): Promise<boolean> {

    const query = `
        mutation createProjectAuthen{
          createProjectAuthen(
            userID: "${userID}"
          )
        }
    `;

    const opts: RequestInit = {
        credentials: 'include',
        method: 'POST',
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify({ query }),
    };

    const res = await fetch(`${process.env.REACT_APP_GRAPHQL}`, opts);
    const response = await res.json();
    return !response?.errors && response?.data?.createProjectAuthen;

}

// This will get an update project token that will allow the user to edit the project
// isOwner flag determines if the update action requires an owner and not a collaborator
export async function getUpdateProjectAuthentication(userID: string, projectID: string, isOwner: boolean = false): Promise<boolean> {

    const query = `
        mutation updateProjectAuthen{
          updateProjectAuthen(
            userID: "${userID}"
            projectID: "${projectID}"
            isOwner: ${isOwner}
          )
        }
    `;

    const opts: RequestInit = {
        credentials: 'include',
        method: 'POST',
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify({ query }),
    };

    const res = await fetch(`${process.env.REACT_APP_GRAPHQL}`, opts);
    const response = await res.json();
    return !response?.errors;

}

export async function convertFileObjectToProjectOutput(userID: string, projectID: string, fileObject: any, fileSection: FileSection): Promise<string> {

    const file = fileObject?.image;
    if (!file) {
        // This fileObject does not contain an image. Fail right away
        return '';
    }

    // For this to work file_name needs to be passed as fileName.type
    const regExp = /(?:\.([^.]+))?$/;
    let fileType;
    // If fileType is empty we will not be able to upload. File names have to contain an extension
    const fileTypeList = regExp.exec(file.name);
    if (fileTypeList && fileTypeList?.length > 0) {
        // eslint-disable-next-line prefer-destructuring
        fileType = fileTypeList[1];
    } else {
        return '';
    }

    // First get the file name from the url base and decode the url
    const fileName = getFileNameFromUrl(fileObject?.oldURL || file.name);

    if (fileName === '') {
        return '';
    }

    const response = await getUploadURL(userID, projectID, fileName, fileType, fileSection);

    if (!response) {
        return '';
    }

    const { url, fileName: newFileName } = response;

    try {

        const resp = await axios.put(url, fileObject.image, {
            headers: {
                'Content-Type': 'application/octet-stream',
            },
        });

        // Delete the old imageURL. This is for featured image on projects and user profile images
        // TODO - Find  way to handle errors
        if (fileObject?.oldURL) {
            const deleted = await deleteImage(userID, projectID, fileName);
            if (!deleted) {
                logError({
                    userID,
                    fileName,
                }, 'delete_image_fail');
            }
        }

        // Check if this upload was successful
        if (resp?.status !== 200) {
            return '';
        }

        return `https://storage.googleapis.com/${userID}/${newFileName}`;
    } catch (e) {
        console.log(e);
        return '';
    }

}

// convertFileArrayToProjectOutput receives an array of file objects that have to be uploaded/deleted from GCS
// and returns an array of the uploadedObjects and uploadErrors
export async function convertFileArrayToProjectOutput(userID: string, projectID: string, projectArray: ModifiedDetailedImage[], fileSection: FileSection): Promise<[Partial<ModifiedDetailedImage>[], string[]]> {
    const objectArr: Partial<ModifiedDetailedImage>[] = []; // Array of objects created from data in projectArray
    const uploadIndexArr: number[] = []; // Keeps track of the index the promises in promiseArray belong to
    const uploadPromiseArr: Promise<string>[] = []; // Used to store and then execute image upload Promises
    const deletePromiseArr: Promise<boolean>[] = []; // Store Promise's to delete
    const deleteIndexArr: number[] = [];
    const uploadErrors: string[] = []; // Array of errors if individual files fail to upload

    projectArray.forEach((project: ModifiedDetailedImage, i: number) => {

        const obj = project;
        const newObj: Partial<ModifiedDetailedImage> = {};

        if (obj.delete) {
            // Check if url is valid. If not skip attempting to delete from GCS.
            const imageURL = obj?.image;
            try {
                // eslint-disable-next-line no-unreachable
                const url = new URL(imageURL);
                // TODO - Move host check to a constant
                if (url.host.includes('storage.googleapis')) {
                    deleteIndexArr.push(i);
                    const fileName = getFileNameFromUrl(imageURL);
                    if (fileName === '') {
                        throw new Error(`Invalid image url ${url.origin}`);
                    }
                    deletePromiseArr.push(deleteImage(userID, projectID, fileName));
                } else {
                    throw new Error(`Invalid image url ${url.origin}`);
                }
                // eslint-disable-next-line no-empty
            } catch (e) {
                firebaseAnalytics?.logEvent('error', {
                    type: 'delete_image_promise_arr',
                    error: e,
                });
                console.log(e);
                deleteIndexArr.push(i);
                deletePromiseArr.push(Promise.resolve(false));
            }
        } else {
            // This path is for when the user has uploaded a new image
            if (obj.uploadedImage) {
                // This will contain the index of the object the url belongs to
                uploadIndexArr.push(i);
                uploadPromiseArr.push(convertFileObjectToProjectOutput(userID, projectID, obj, fileSection));
            } else {
                // Keep original image location if no new image
                newObj.image = obj.image;
            }
            // Store other values
            newObj.description = obj.description;
            newObj.annotations = obj.annotations;
            objectArr.push(newObj);
        }
    });

    // Wait until all uploading is complete
    const uploadedURLS = await Promise.all(uploadPromiseArr);
    uploadedURLS.forEach((url, i) => {
        const objectIndex = uploadIndexArr[i];
        // Check if uploading errored out and remove offending object
        if (!url || url === '') {
            uploadErrors.push(`Failed to upload image ${objectIndex}`);
            objectArr.splice(uploadIndexArr[i], 1);
        } else {
            // Set image to url of newly uploaded image
            objectArr[objectIndex].image = url;
        }
    });

    // Wait until all deleting is complete
    const deletedValues = await Promise.all(deletePromiseArr);
    deletedValues.forEach((deleted, i) => {
        // If we failed to delete image from storage
        // Return an error and set to original image value
        if (!deleted) {
            const deleteIndex = deleteIndexArr[i];
            const newObj = {
                image: projectArray[deleteIndex].image,
                description: projectArray[deleteIndex].description,
            };
            objectArr.splice(deleteIndex, 0, newObj);
            uploadErrors.push(`Failed to delete image ${deleteIndexArr[i]}`);
        }
    });

    // We return the new object array and any errors that may have popped up from uploading
    return [objectArr, uploadErrors];

}
