import React, { KeyboardEvent, MouseEvent, useEffect, useState } from 'react';
import { useSnackbar } from 'notistack';
import InstanceHandler from 'components/annotations/InstanceHandler';
import { Point } from 'redux/projects/models/Point';
import 'styles/bezier.module.css';
import { UserTiers } from 'redux/users/models/User';
import { hasHitAnnotationCap } from 'common/tierChecks';

interface BezierProps {
    // viewBoxWidth is the width of the svg viewbox. Should be image width
    viewBoxWidth: number;
    // viewBoxHeight is the height of the svg viewbox. Should be image height
    viewBoxHeight: number;
    // styleWidth is the width on screen
    styleWidth?: number | string;
    // styleHeight is the height on screen
    styleHeight?: number | string;
    background: string;
    onStartPoints: any;
    onMidPoints: any;
    onEndPoints: any;
    originalStart: Point[];
    originalMid: Point[];
    originalEnd: Point[];
    annotations: string[];
    notifyCreate?: (index: number | null) => void,
    notifyDelete?: (index: number | null) => void,
    notifySelect?: (index: number | null) => void,
    editable?: boolean;
    editorTier?: UserTiers;
    toDelete?: { index: number, seed: number } | null;
}

export const Bezier = (props: BezierProps) => {

    const { enqueueSnackbar } = useSnackbar();

    const [drawing, setDrawing] = useState<boolean>(false);
    const [startPoints, setStartPoints] = useState<Point[]>([]);
    const [controlPoints, setControlPoints] = useState<Point[]>([]);
    const [endPoints, setEndPoints] = useState<Point[]>([]);
    const [currentCurve, setCurrentCurve] = useState<number | null>(null);
    // TODO - Reconsider using string or number for draggingPointId
    const [draggingPointId, setDraggingPointId] = useState<string | number | null>(null);
    const [node, setNode] = useState<SVGSVGElement | null>(null);
    const [drawStart, setDrawStart] = useState<Point | null>(null);
    const [drawControl, setDrawControl] = useState<Point | null>(null);
    const [drawEnd, setDrawEnd] = useState<Point | null>(null);
    const {
        viewBoxWidth,
        viewBoxHeight,
        styleWidth,
        styleHeight,
        background,
        onStartPoints,
        onMidPoints,
        onEndPoints,
        originalStart,
        originalMid,
        originalEnd,
        notifyCreate,
        notifyDelete,
        notifySelect,
        editable,
        annotations,
        editorTier,
        toDelete,
    } = props;

    // On load use original points
    useEffect(() => {
        setStartPoints(originalStart);
        setControlPoints(originalMid);
        setEndPoints(originalEnd);
    }, []); // eslint-disable-line

    // If not editable update with new values passed into bezier
    useEffect(() => {
        if (!editable) {
            setStartPoints(originalStart);
            setControlPoints(originalMid);
            setEndPoints(originalEnd);
        }
    }, [editable, originalStart, originalEnd, originalMid]);

    const handleMouseDown = (pointId: string) => {
        if (!editable) return;
        setDraggingPointId(pointId);
    };

    // Unselects dragging point
    const handleMouseUp = () => {
        if (!editable) return;
        if (draggingPointId !== null) {
            onStartPoints(startPoints);
            onMidPoints(controlPoints);
            onEndPoints(endPoints);
        }
        setDraggingPointId(null);
    };

    const handleClick = (e: MouseEvent<SVGElement>, index: number) => {
        if (!editable) return;
        const curve = index > -1 ? index : null;
        setCurrentCurve(curve);
        if (notifySelect) {
            notifySelect(curve);
        }
    };

    const getPositions = (clientX: number, clientY: number) => {
        const svgRect = node?.getBoundingClientRect();
        const svgX = clientX - (svgRect?.left ?? 0);
        const svgY = clientY - (svgRect?.top ?? 0);
        // eslint-disable-next-line no-mixed-operators
        let viewBoxX = svgX * viewBoxWidth / (svgRect?.width ?? 1);
        // eslint-disable-next-line no-mixed-operators
        let viewBoxY = svgY * viewBoxHeight / (svgRect?.height ?? 1);

        if (viewBoxX > viewBoxWidth) viewBoxX = viewBoxWidth;
        else if (viewBoxX < 0) viewBoxX = 0;

        if (viewBoxY > viewBoxHeight) viewBoxY = viewBoxHeight;
        else if (viewBoxY < 0) viewBoxY = 0;

        return [viewBoxX, viewBoxY];
    };

    const handleMouseMove = ({ clientX, clientY }: MouseEvent<SVGSVGElement>) => {
        if (!editable) return;
        const [viewBoxX, viewBoxY] = getPositions(clientX, clientY);
        if (currentCurve !== null) {
            if (draggingPointId === `startPoint ${currentCurve}`) {
                const items = [...startPoints];
                items[currentCurve] = {
                    x: viewBoxX,
                    y: viewBoxY,
                };
                setStartPoints(items);
            } else if (draggingPointId === `endPoint ${currentCurve}`) {
                const items = [...endPoints];
                items[currentCurve] = {
                    x: viewBoxX,
                    y: viewBoxY,
                };
                setEndPoints(items);
            } else if (draggingPointId === `controlPoint ${currentCurve}`) {
                const items = [...controlPoints];
                items[currentCurve] = {
                    x: viewBoxX,
                    y: viewBoxY,
                };
                setControlPoints(items);
            }
        }
    };

    const removeCurve = (index: number | null) => {
        if (currentCurve === index) {
            setCurrentCurve(null);
        }
        const cntlpts = [...controlPoints];
        const endpts = [...endPoints];
        const strtpts = [...startPoints];
        // Remove the annotation and it's information
        if (index !== null) {
            cntlpts.splice(index, 1);
            endpts.splice(index, 1);
            strtpts.splice(index, 1);
            // Set points for Bezier
            setStartPoints(strtpts);
            setControlPoints(cntlpts);
            setEndPoints(endpts);
            // Send new points up
            onStartPoints(strtpts);
            onMidPoints(cntlpts);
            onEndPoints(endpts);
            // Notify of deletion
            if (notifyDelete) {
                notifyDelete(index);
            }
        }
    };

    // Seed ensures that the effect will run every time we set toDelete
    useEffect(() => {
        const { index } = toDelete || {};
        if (index !== null && index !== undefined) {
            removeCurve(index);
        }
    }, [toDelete?.seed]);

    const startDrawing = (ev: MouseEvent<SVGRectElement>) => {
        if (!editable) return;

        if (hasHitAnnotationCap(editorTier || UserTiers.FREE, annotations.length)) {
            enqueueSnackbar('You\'ve reached the maximum number of annotations for this image', { variant: 'error' });
            return;
        }

        if (!drawing) {
            const { clientX, clientY } = ev;
            handleClick(ev, -1); // Clicked on outside so unselect curve
            const [viewBoxX, viewBoxY] = getPositions(clientX, clientY);
            setDrawStart(
                {
                    x: viewBoxX,
                    y: viewBoxY,
                },
            );
        }

    };

    const handleDrawing = ({ clientX, clientY }: MouseEvent<SVGRectElement>) => {
        if (!editable) return;

        if (drawStart == null) {
            // End if no drawing start point
            return;
        }
        const [viewBoxX, viewBoxY] = getPositions(clientX, clientY);
        if ((viewBoxX !== drawStart.x && viewBoxY !== drawStart.y) || drawing) {
            // Drawing will start on next move update
            // Set end point
            setDrawEnd(
                {
                    x: viewBoxX,
                    y: viewBoxY,
                },
            );
            // Center the control point
            setDrawControl(
                {
                    x: (viewBoxX + drawStart.x) / 2.0,
                    y: (viewBoxY + drawStart.y) / 2.0,
                },
            );
            setDrawing(true);
        }
    };

    const endDrawing = ({ clientX, clientY }: MouseEvent<SVGSVGElement> | MouseEvent<SVGRectElement>) => {
        if (!editable) return;

        handleMouseUp();
        const [viewBoxX, viewBoxY] = getPositions(clientX, clientY);
        if (drawing) {
            // Set end point
            setDrawEnd(
                {
                    x: viewBoxX,
                    y: viewBoxY,
                },
            );
            // Center the control point
            setDrawControl(
                {
                    x: (viewBoxX + (drawStart?.x ?? 0)) / 2.0,
                    y: (viewBoxY + (drawStart?.y ?? 0)) / 2.0,
                },
            );
            // Set new dragging point and add new points to sets
            setDraggingPointId((startPoints?.length ?? 0) + 1);
            if (startPoints && drawStart) {
                setStartPoints([...startPoints, drawStart]);
            }
            if (controlPoints && drawControl) {
                setControlPoints([...controlPoints, drawControl]);
            }
            if (endPoints && drawEnd) {
                setEndPoints([...endPoints, drawEnd]);
            }

            if (notifyCreate) {
                // Add empty annotation to list of annotations
                notifyCreate(startPoints.length + 1);
            }
            // Reset drawing vars
            setDrawing(false);
            setDrawEnd(null);
            setDrawStart(null);
            setDrawControl(null);
        } else {
            // Single click. Didn't drag to draw
            setDrawStart(null);
        }

    };

    const renderCurves = () => {
        const inst = [];
        for (let i = 0; i < startPoints.length; i += 1) {
            inst.push(
                <g onMouseDown={(ev) => handleClick(ev, i)} key={i}>
                    <InstanceHandler
                        start={startPoints[i]}
                        control={controlPoints[i]}
                        end={endPoints[i]}
                        handleMouseDown={handleMouseDown}
                        show={(currentCurve === i)}
                        index={i}
                        editable={editable}
                        annotationText={annotations[i]}
                    />
                </g>,
            );
        }
        return (inst);
    };

    // Render the drawing curve when user is dragging to draw
    const renderDrawCurve = editable && drawing ? (
        <g>
            <InstanceHandler
                start={drawStart}
                control={drawControl}
                end={drawEnd}
                handleMouseDown={handleMouseDown}
                show
                index={startPoints.length + 1}
                drawing
                editable={editable}
            />
        </g>
    ) : null;

    return (
        // eslint-disable-next-line jsx-a11y/no-static-element-interactions
        <div
            /* eslint-disable-next-line jsx-a11y/no-noninteractive-tabindex */
            tabIndex={0}
        >
            <svg
                ref={(node) => (setNode(node))}
                viewBox={`0 0 ${viewBoxWidth} ${viewBoxHeight}`}
                style={
                    {
                        overflow: 'visible',
                        width: styleWidth,
                        height: styleHeight,
                        backgroundImage: `url(${background})`,
                        backgroundSize: '100% 100%',
                    }
                }
                onMouseMove={(ev) => handleMouseMove(ev)}
                onMouseUp={(ev) => endDrawing(ev)}
                onMouseLeave={() => handleMouseUp()}
            >
                <rect
                    x='0'
                    y='0'
                    height={`${viewBoxHeight}`}
                    width={`${viewBoxWidth}`}
                    fill='#ffffff00'
                    onMouseDown={startDrawing}
                    onMouseMove={handleDrawing}
                    onClick={endDrawing}
                    style={
                        {
                            width: '100%',
                            height: '100%',
                        }
                    }
                />
                {renderCurves()}
                {renderDrawCurve}
            </svg>
        </div>
    );
};

Bezier.defaultProps = {
    notifyDelete: () => {},
    notifyCreate: () => {},
    notifySelect: () => {},
    editable: true,
    styleHeight: '100%',
    styleWidth: '',
    editorTier: UserTiers.FREE,
    toDelete: null,
};
