import React, { useEffect, useRef, useState } from 'react';
import { useDispatch, useSelector } from 'common/hooks';
// @ts-ignore
import jexcel from 'jexcel';
import SolidButton from 'components/buttons/StyledButton';
import PremiumAlert from 'components/PremiumAlert';
import { updateProject } from 'redux/actions';
import { firebaseAnalytics } from 'common/firebase';
import { useLocation } from 'react-router-dom';
import { hasHitColumnCap, hasHitRowCap } from 'common/tierChecks';
import { UserTiers } from 'redux/users/models/User';
import mainStyles from 'styles/table.module.css';
import '../../../node_modules/jexcel/dist/jexcel.css';
import 'styles/table.css';

const MeasurementsPanel = ({ isEditing }: { isEditing?: boolean }) => {

    const dispatch = useDispatch();
    const location = useLocation();
    const [minDimensions] = useState([5, 5]);
    const [updatedValues, setUpdatedValues] = useState(false);
    const [je, setJE] = useState(null);
    const [blockAddRows, setBlockAddRows] = useState(false);
    const [blockAddColumns, setBlockAddColumns] = useState(false);
    const wrapper = React.createRef<HTMLDivElement>();

    const {
        project,
        updateProjectPending,
        userAuthenData,
        userSubscription,
    } = useSelector((state) => ({
        project: state.projects.project,
        userSubscription: state.users.userSubscription,
        updateProjectPending: state.projects.updateProjectPending,
        userAuthenData: state.users.userAuthenData,
    }));

    const isCollaborator = project?.collaborators?.includes(userAuthenData?._id || '');
    const isEditPage = location.pathname.includes('edit');

    // Use this reference when using data in je and adding functionality to jexcel
    const jeRef = useRef<any>();

    // Update table on load
    useEffect(() => {

        // Create header data
        const columns = project?.measurements?.headers?.map((header) => ({
            type: 'text',
            title: header,
        }));

        setJE(jexcel(wrapper.current, {
            editable: isEditing,
            colWidths: project?.measurements?.headerWidths || [],
            data: project?.measurements?.data,
            minDimensions: project?.measurements ? [0, 0] : minDimensions,
            columns: columns || [],
            csvFileName: `${project?.project_name}_measurements`,
            tableHeight: '100%',
            about: 'Chiqpack',
            tableOverflow: true,
            oninsertrow: () => {
                const isCollaborator = project?.collaborators?.includes(userAuthenData?._id || '');
                const useTier = isCollaborator ? project?.ownerTier : userSubscription?.userTier;
                if (hasHitRowCap(useTier || UserTiers.FREE, jeRef.current?.rows.length)) {
                    jeRef.current.options.allowInsertRow = false;
                    setBlockAddRows(true);
                }
                firebaseAnalytics?.logEvent('table_interaction', {
                    user_id: userAuthenData?._id,
                    interaction_type: 'insert_row',
                });
            },
            oninsertcolumn: () => {
                const isCollaborator = project?.collaborators?.includes(userAuthenData?._id || '');
                const useTier = isCollaborator ? project?.ownerTier : userSubscription?.userTier;
                if (hasHitColumnCap(useTier || UserTiers.FREE, jeRef.current?.headers.length)) {
                    jeRef.current.options.allowInsertColumn = false;
                    setBlockAddColumns(true);
                }
                firebaseAnalytics?.logEvent('table_interaction', {
                    user_id: userAuthenData?._id,
                    interaction_type: 'insert_column',
                });
            },
            ondeleterow: () => {
                const isCollaborator = project?.collaborators?.includes(userAuthenData?._id || '');
                const useTier = isCollaborator ? project?.ownerTier : userSubscription?.userTier;
                if (!hasHitRowCap(useTier || UserTiers.FREE, jeRef.current?.rows.length)) {
                    jeRef.current.options.allowInsertRow = true;
                    setBlockAddRows(false);
                }
                firebaseAnalytics?.logEvent('table_interaction', {
                    user_id: userAuthenData?._id,
                    interaction_type: 'delete_row',
                });
            },
            ondeletecolumn: () => {
                const isCollaborator = project?.collaborators?.includes(userAuthenData?._id || '');
                const useTier = isCollaborator ? project?.ownerTier : userSubscription?.userTier;
                if (!hasHitColumnCap(useTier || UserTiers.FREE, jeRef.current?.headers.length)) {
                    jeRef.current.options.allowInsertColumn = true;
                    setBlockAddColumns(false);
                }
                firebaseAnalytics?.logEvent('table_interaction', {
                    user_id: userAuthenData?._id,
                    interaction_type: 'delete_column',
                });
            },
            onevent: () => {
                // Something in the table has changed
                if (!updatedValues) {
                    setUpdatedValues(true);
                }
                firebaseAnalytics?.logEvent('table_interaction', {
                    user_id: userAuthenData?._id,
                    interaction_type: 'general_interaction',
                });
            },
            onload: () => {
                // Make sure updatedValues is false on load
                setUpdatedValues(false);
            },
        }));

    }, []); // eslint-disable-line

    // Set reference to je
    useEffect(() => {
        if (je && jeRef.current === undefined) {
            jeRef.current = je;

            if (!isEditing) {
                jeRef.current.options.allowInsertColumn = false;
                jeRef.current.options.allowInsertRow = false;
                jeRef.current.options.allowDeleteColumn = false;
                jeRef.current.options.allowDeleteRow = false;
                jeRef.current.options.allowRenameColumn = false;
                return;
            }

            const useTier = isCollaborator ? project?.ownerTier : userSubscription?.userTier;

            // Limit table immediately after load if necessary
            if (hasHitRowCap(useTier || UserTiers.FREE, jeRef.current?.headers.length)) {
                jeRef.current.options.allowInsertColumn = false;
                setBlockAddColumns(true);
            }
            if (hasHitRowCap(useTier || UserTiers.FREE, jeRef.current?.rows.length)) {
                jeRef.current.options.allowInsertRow = false;
                setBlockAddRows(true);
            }
        }
    }, [je]); // eslint-disable-line

    // Update table on project update
    useEffect(() => {
        if (isEditing && je && !updateProjectPending) {
            // Create header data
            // eslint-disable-next-line no-unused-expressions
            project?.measurements?.headers?.forEach((header, index) => {
                // @ts-ignore
                // eslint-disable-next-line no-unused-expressions
                je?.setColumnData(index, header);
            });
            // @ts-ignore
            // eslint-disable-next-line no-unused-expressions
            je?.setData(project?.measurements?.data);
        }
    }, [project, updateProjectPending]); // eslint-disable-line

    const updateTable = () => {

        // Make sure array is of strings
        // @ts-ignore
        const stringWidths = je?.getWidth().map((width: any) => (typeof (width) !== 'string' ? `${width}` : width));

        const tempProject = {
            measurements: {
                headerWidths: stringWidths,
                // @ts-ignore
                headers: je?.getHeaders().split(','),
                // @ts-ignore
                data: je?.getData(),
            },
        };

        dispatch(
            updateProject(
                userAuthenData?._id,
                project?._id,
                tempProject,
            ),
        ).then(() => {
            setUpdatedValues(false);
        });
    };

    const rowText = !blockAddRows ? '' : 'rows';
    const columnText = !blockAddColumns ? '' : 'columns';
    const andText = blockAddColumns && blockAddRows ? ' and ' : '';

    const maxAlert = (!blockAddColumns && !blockAddRows) || isCollaborator || !isEditPage
        ? null
        : (
            <PremiumAlert
                text={`You have reached the maximum amount of ${rowText}${andText}${columnText}.`}
                width='80rem'
            />
        );

    return (
        <div style={{ margin: '2rem' }}>
            <div className={mainStyles.scrollable}>
                <div ref={wrapper} />
                <br />
            </div>
            <div className={mainStyles.alertContainer}>
                {maxAlert}
                {
                    isEditing
                        ? (
                            <div className={mainStyles.saveButton}>
                                <SolidButton
                                    bordRadius={4}
                                    onClick={updateTable}
                                    disabled={updateProjectPending || !updatedValues}
                                >
                                    Save
                                </SolidButton>
                            </div>
                        ) : undefined
                }
            </div>
        </div>
    );

};

MeasurementsPanel.defaultProps = {
    isEditing: true,
};

export default MeasurementsPanel;
