import { logPageView } from 'common/analytics/firebaseEvents';
import React, { useEffect, useMemo, useState } from 'react';
import { shallowEqual } from 'react-redux';
import { useSelector, useDispatch } from 'common/hooks';
import { Star } from '@material-ui/icons';
import { TabContext, TabPanel } from '@material-ui/lab';
import { getProjects, getShowcase } from 'redux/actions';
import ActiveTabContext, { ActiveTabProvider } from 'utils/ActiveTabContext';
import { Landing } from 'pages/Landing';
import ProjectCard from 'components/ProjectCard';
import TabBar from 'components/TabBar';
import styles from 'styles/home.module.css';
import { useHistory } from 'react-router-dom';
import { InputBase } from '@material-ui/core';
import SearchIcon from '@material-ui/icons/Search';
import { getRandom } from 'utils/utils';
import withHelmet from 'components/hocs/withHelmet';
import { PAGE_NAMES } from 'constants/metaData';

const SearchBar = () => {
    const [searchTerms, setSearchTerms] = useState<string>('');
    const history = useHistory();

    const searchKeyPress = (e: any) => {
        if (
            (e.key === 'Enter' || e.key === 'Return')
      && searchTerms.trim().length > 0
        ) {
            history.push('/search', {
                searchInput: searchTerms,
            });
        }
    };

    useEffect(() => {
        logPageView();
    }, []);

    return (
        <div className={styles.search_container}>
            <div className={styles.search_box}>
                <div className={styles.search_icon}>
                    <SearchIcon />
                </div>
                <InputBase
                    onChange={(e: any) => setSearchTerms(e.target.value)}
                    onKeyUp={searchKeyPress}
                    placeholder='Search'
                    className={styles.input_input}
                    inputProps={{ 'aria-label': 'search' }}
                    value={searchTerms}
                />
            </div>
        </div>
    );
};

const Home = () => {
    const dispatch = useDispatch();
    const {
        getProjectsError,
        getProjectsPending,
        projectList,
        userAuthenData,
        showcaseList,
    } = useSelector(
        (state) => ({
            projectList: state.projects.projectList,
            getProjectsPending: state.projects.getProjectsPending,
            getProjectsError: state.projects.getProjectsError,
            userAuthenData: state.users.userAuthenData,
            showcaseList: state.projects.showcaseList,
        }),
        shallowEqual,
    );

    useEffect(() => {
        if (userAuthenData && !getProjectsPending && !getProjectsError) {
            dispatch(getProjects(userAuthenData.liked_projects));
        }
  }, []); // eslint-disable-line

    useEffect(() => {
        // if user has no liked projects, grab some from showcase
        if (!showcaseList && projectList && !projectList.length) {
            dispatch(getShowcase());
        }
    }, [projectList]);

    const projectNum = !userAuthenData
        ? null
        : userAuthenData?.project_ids?.map((id) => <ProjectCard key={id} />);

    const projectLoaded = !projectList || !projectList?.length
        ? null
        : projectList?.map((p) => (
            <ProjectCard
                projectId={p?._id}
                projectName={p?.project_name}
                ownerName={p?.owner_data?.user_name}
                featuredImage={p?.featured_image}
                liked={
                    !userAuthenData
                        ? false
                        : p?.liked_by?.includes(userAuthenData?._id ?? '')
                }
                likeCount={p?.liked_by?.length}
                categories={p?.categories}
                projectDescription={p?.description}
                ownerImage={p?.owner_data?.user_image}
                ownerID={p?.owner_id}
                key={p?._id}
            />
        ));

    const showcaseLoaded = useMemo(() => (!showcaseList
        ? null
        : getRandom(showcaseList, 6).map((p) => (
            <ProjectCard
                projectId={p?._id}
                projectName={p?.project_name}
                ownerName={p?.owner_data?.user_name}
                featuredImage={p?.featured_image}
                liked={
                    !userAuthenData
                        ? false
                        : p?.liked_by?.includes(userAuthenData?._id ?? '')
                }
                likeCount={p?.liked_by?.length}
                categories={p?.categories}
                projectDescription={p?.description}
                ownerImage={p?.owner_data?.user_image}
                ownerID={p?.owner_id}
                key={p?._id}
            />
        ))), [showcaseList]);

    const tabList = [
        {
            icon: Star,
            label: showcaseLoaded ? 'Showcase Projects' : 'Liked Projects',
        },
    ];

    const tabBar = !userAuthenData ? null : (
        <TabBar
            // @ts-ignore
            title='Home'
            tabs={tabList}
        />
    );

    return !userAuthenData ? (
        <Landing />
    ) : (
        <div>
            <div className={styles.box}>
                <ActiveTabProvider value={{ activeTab: 0 }}>
                    <ActiveTabContext.Consumer>
                        {(activeTabContext) => (
                            <TabContext value={`${activeTabContext.activeTab}`}>
                                {tabBar}
                                {/* @ts-ignore */}
                                <TabPanel value='0' index={0}>
                                    <div className={styles.panel_container}>
                                        {(!projectList || !projectList?.length) && <SearchBar />}
                                        <div className={styles.grid}>
                                            {!projectLoaded && !showcaseLoaded && projectNum}
                                            {projectLoaded || showcaseLoaded}
                                        </div>
                                    </div>
                                </TabPanel>
                            </TabContext>
                        )}
                    </ActiveTabContext.Consumer>
                </ActiveTabProvider>
            </div>
        </div>
    );
};

Home.displayName = PAGE_NAMES.HOME;

export default withHelmet(Home);
