import React, { useEffect, useState } from 'react';
import { logPageView } from 'common/analytics/firebaseEvents';
import { Link, useHistory } from 'react-router-dom';
import {
    Container,
    Grid,
    TextField,
    Button,
} from '@material-ui/core';
import { Alert } from '@material-ui/lab';
import { EReCaptchaV2Size, EReCaptchaV2Theme, ReCaptchaV2, ReCaptchaV3, TReCaptchaV2Callback } from 'react-recaptcha-x';
import withHelmet from 'components/hocs/withHelmet';
import { useDispatch, useSelector } from 'common/hooks';
import { v2Callback, v3Callback } from 'common/recaptcha';
import { userAuthen, checkSubscription, dismissUserAuthenError } from 'redux/actions';
import { fontSize } from 'pages/constants/login';
import { PAGE_NAMES } from 'constants/metaData';
import styles from 'styles/login.module.css';
import logo from '../logo2_500.png';

const Login = () => {
    const history = useHistory();
    const dispatch = useDispatch();
    const [username, setUsername] = useState<string>('');
    const [password, setPassword] = useState<string>('');
    const [failedAttempt, setFailedAttempt] = useState<boolean>(false);
    const [recaptchaDone, setRecaptchaDone] = useState(false);
    const {
        authenticated,
        userAuthenPending,
        userAuthenError,
        userAuthenData,
    } = useSelector((state) => ({
        authenticated: state.users.authenticated,
        userAuthenData: state.users.userAuthenData,
        userAuthenPending: state.users.userAuthenPending,
        userAuthenError: state.users.userAuthenError,
    }));

    useEffect(() => {
        logPageView();
    }, []);

    useEffect(() => {
        if (authenticated && userAuthenData?._id) {
            dispatch(checkSubscription(userAuthenData._id)).then(() => history.push('/'));
        }
    }, [authenticated, userAuthenData, dispatch]);

    const login = () => {
        dispatch(userAuthen(username, password));
    };

    // TODO - Creat an error interface that the backend must follow
    const loginError = !userAuthenError ? null : userAuthenError.map((e: any) => (
        <Alert
            severity='error'
            onClose={() => { dispatch(dismissUserAuthenError()); }}
            className={styles.login_alert}
            key={e}
        >
            {e?.message}
        </Alert>
    ));

    if (userAuthenError && !failedAttempt) {
        setFailedAttempt(true);
    }

    const forgotPassword = failedAttempt ? (
        <Grid item xs={12}>
            <Link to='/forgotPassword'>Forgot Password</Link>
        </Grid>
    ) : null;

    return (
        <div>
            <div className={styles.outer_container}>
                <div className={styles.logo_wrapper}>
                    <img src={logo} alt='Chiqpack Logo' className={styles.logo} />
                    <h1 className={styles.logo_header}>Sign in to Chiqpack</h1>
                </div>
                {loginError}
                <Container className={styles.form_container} maxWidth={false}>
                    <form>
                        <Grid container spacing={3}>
                            <Grid item xs={12}>
                                <Grid container spacing={2}>
                                    <Grid item xs={12}>
                                        <TextField
                                            fullWidth
                                            label={<span style={{ fontSize, lineHeight: 0 }}>Username</span>}
                                            name='username'
                                            size='small'
                                            variant='outlined'
                                            value={username}
                                            inputProps={{ style: { fontSize } }}
                                            InputLabelProps={{ style: { fontSize } }}
                                            onChange={
                                                (event) => (setUsername(event.target.value))
                                            }
                                        />
                                    </Grid>
                                    <Grid item xs={12}>
                                        <TextField
                                            fullWidth
                                            label={<span style={{ fontSize, lineHeight: 0 }}>Password</span>}
                                            name='password'
                                            size='small'
                                            type='password'
                                            variant='outlined'
                                            value={password}
                                            inputProps={{ style: { fontSize } }}
                                            InputLabelProps={{ style: { fontSize } }}
                                            onChange={
                                                (event) => (setPassword(event.target.value))
                                            }
                                        />
                                    </Grid>
                                </Grid>
                            </Grid>
                            <Grid item xs={12}>
                                <ReCaptchaV2
                                    callback={(token) => v2Callback(token, setRecaptchaDone)}
                                    theme={EReCaptchaV2Theme.Light}
                                    size={EReCaptchaV2Size.Normal}
                                    id='recaptchaV2'
                                    tabindex={0}
                                />
                                <ReCaptchaV3 action='loginAction' callback={v3Callback} />
                            </Grid>
                            <Grid item xs={12}>
                                <Button
                                    disabled={authenticated || userAuthenPending || !recaptchaDone}
                                    color='inherit'
                                    fullWidth
                                    variant='contained'
                                    className={styles.login_button}
                                    onClick={login}
                                >
                                    Sign in
                                </Button>
                            </Grid>
                            {forgotPassword}
                        </Grid>
                    </form>
                </Container>

                <Container className={styles.sub_container} maxWidth={false}>
                    <h2 className={styles.sub_header}>
                        New to Chiqpack?
                        {' '}
                        <Link className={styles.sub_link} to='/join'>Create an account</Link>
                    </h2>
                </Container>

            </div>
        </div>
    );
};

Login.displayName = PAGE_NAMES.LOGIN;

export default withHelmet(Login);
