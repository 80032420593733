import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'common/hooks';
import { getProjects } from 'redux/actions';
import { Link } from 'react-router-dom';
import { Project } from 'redux/projects/models/Project';
import ProjectCard, { PROJECT_CARD_VARIANT } from 'components/ProjectCard';
import { NoContentPlaceHolder } from 'pages/Showcase';
import Slider from 'react-slick';
import THEME_COLORS from 'utils/themeColors';
import styles from 'styles/discoverSection.module.css';
import landingStyles from 'styles/landing.module.css';

const DISCOVER_RESULT = 'discoverResult';

// TODO - Move prod check to somewhere centralized
const DISCOVER_PROJECTS = process.env.NODE_ENV === 'production'
    ? ['612ef8b49b95981c980d1665', '612ef5b96ea9502278a724a5', '612ef423ba1ff02224399dbb']
    : ['5f13a551729f300f341d47d5', '5f14dbdb281bbe10e0b3d431', '605fd612dad048289006e92f'];

const DiscoverSection = () => {

    const dispatch = useDispatch();

    const [slideIndex, setSlideIndex] = useState(0);

    useEffect(() => {
        dispatch(getProjects(DISCOVER_PROJECTS, DISCOVER_RESULT));
    }, []);

    const {
        landingShowcase,
    } = useSelector((state) => ({
        // @ts-ignore
        landingShowcase: state.projects[DISCOVER_RESULT],
    }));

    const projects: JSX.Element[] = !landingShowcase ? [] : landingShowcase.map((p: Partial<Project>, i: number) => (
        <div className={`${styles.slideWrapper} ${(slideIndex === i ? '' : styles.slide)}`}>
            <ProjectCard
            // @ts-ignore
                variant={PROJECT_CARD_VARIANT.REGULAR}
                projectId={p?._id}
                projectName={p?.project_name}
                ownerName={p?.owner_data?.user_name}
                liked
                likeCount={p?.liked_by?.length}
                categories={p?.categories}
                projectDescription={p?.description}
                projectStats={[
                    p?.sketches?.length || 0,
                    p?.materials?.length || 0,
                    p?.measurements?.headers?.length || 0,
                    p?.outcome?.length || 0,
                ]}
                ownerImage={p?.owner_data?.user_image}
                ownerID={p?.owner_id}
                featuredImage={p?.featured_image}
                key={p?._id}
            />
        </div>
    ));

    // TODO - Revisit responsive settings once the project card sizing is fixed
    const slideSettings = {
        responsive: [
            {
                breakpoint: 1490,
                settings: {
                    slidesToShow: 1,
                    slidesToScroll: 1,
                },
            },
            {
                breakpoint: 820,
                settings: {
                    slidesToShow: 1,
                    slidesToScroll: 1,
                },
            },
        ],
    };

    return (
        <div className={styles.container}>
            <div className={styles.heading_text}>
                <h3 className={landingStyles.section_subtitle}>DISCOVER</h3>
                <h1 className={landingStyles.section_title}>
                    <b>Showcase</b>
                    &nbsp;Your Designs
                </h1>
            </div>
            <div className={styles.sliderWrapper}>
                <Slider
                    dots={false}
                    infinite
                    slidesToScroll={1}
                    slidesToShow={1}
                    autoplay
                    centerMode
                    centerPadding='0 0.5rem'
                    speed={2000}
                    autoplaySpeed={8000}
                    beforeChange={(_, next) => setSlideIndex(next)}
                    {...slideSettings}
                >
                    {projects?.length > 0 ? projects : (
                        <NoContentPlaceHolder color='white' />
                    )}
                </Slider>
            </div>
            <p className={landingStyles.section_blurb}>
                <Link style={{ color: THEME_COLORS.lightPurple, textDecoration: 'none' }} to='/showcase'><b>Discover</b></Link>
                {' '}
                what others are creating and you may even see your own designs being showcased.
            </p>
        </div>
    );

};

export default DiscoverSection;
