import React, { useState, useEffect, MouseEvent } from 'react';
import { useDispatch, useSelector } from 'common/hooks';
import { MuiThemeProvider, createMuiTheme } from '@material-ui/core/styles';
import { Image } from '@material-ui/icons';
import { Alert } from '@material-ui/lab';
import { DropzoneArea } from 'material-ui-dropzone';
import StyledButton from 'components/buttons/StyledButton';
import THEME_COLORS from 'utils/themeColors';
import styles from 'styles/changeProfilePicture.module.css';
import { dismissUpdateUserError, updateUser } from 'redux/actions';

const ChangeProfilePictureView = () => {
    type UpdateState = 'PENDING' | 'FAILED' | 'SUCCESS';

    const UPDATE_STATES: { [key: string]: UpdateState } = {
        PENDING: 'PENDING',
        FAILED: 'FAILED',
        SUCCESS: 'SUCCESS',
    };

    const [updateState, setUpdateState] = useState<UpdateState | null>(null);

    const dispatch = useDispatch();

    const {
        updateUserError,
        updateUserPending,
        userAuthenData,
    } = useSelector((state) => ({
        updateUserError: state.users.updateUserError,
        updateUserPending: state.users.updateUserPending,
        userAuthenData: state.users.userAuthenData,
    }));

    useEffect(() => {
        if (!updateUserPending && !updateState) {
            /* Case during initialization, so do nothing.  */
        } else if (updateUserPending && !updateUserError) {
            setUpdateState(UPDATE_STATES.PENDING);
        } else if (!updateUserPending && updateUserError) {
            setUpdateState(UPDATE_STATES.FAILED);
        } else if (!updateUserPending && !updateUserError) {
            setUpdateState(UPDATE_STATES.SUCCESS);
        }
    }, [updateUserPending]); // eslint-disable-line

    const [storedPicture] = useState(userAuthenData?.user_image);
    const [picture, setPicture] = useState<string | ArrayBuffer | null | undefined>(userAuthenData?.user_image);
    const [files, setFiles] = useState<File[]>([]);

    const reader = new FileReader();

    // Error when failing to save profile picture
    const profilePictureError = updateUserError ? (
        <Alert
            onClose={() => dispatch(dismissUpdateUserError())}
            className={styles.alert}
            severity='error'
        >
            There was an error changing your profile picture. Please try again later.
        </Alert>
    ) : null;

    const profilePictureSuccess = updateState === UPDATE_STATES.SUCCESS ? (
        <Alert
            onClose={() => setUpdateState(null)}
            severity='success'
            className={styles.alert}
        >
            Profile picture changed successfully.
        </Alert>
    ) : null;

    const theme = createMuiTheme({
        overrides: {
            MuiDropzoneArea: {
                root: {
                    display: 'flex',
                    backgroundColor: 'transparent',
                    color: THEME_COLORS.darkPurple,
                    height: '10rem',
                    width: '10rem',
                    borderRadius: '50px',
                    minHeight: 'fit-content',
                },
                textContainer: {
                    display: storedPicture !== picture ? 'none' : 'block',
                    alignSelf: 'center',
                    margin: '0 auto',
                    width: 'fit-content',
                    padding: '1rem',
                    borderRadius: '10px',
                    backgroundColor: 'transparent',
                },
            },
        },
    });

    /** Pull image url using FileReader and save to picture */
    const setURLFromFile = (file: File) => {
        if (file == null) return;

        let fileContent = null;
        reader.onload = () => {
            fileContent = reader.result;
            setPicture(fileContent);
        };
        reader.readAsDataURL(file);
    };

    /** Handle image file */
    const handleFileUpload = (filesProp: File[]) => {
        setURLFromFile(filesProp[0]);
        setFiles(filesProp);
    };

    const canSubmit = () => picture !== storedPicture;

    const handleSubmit = async (e: MouseEvent<HTMLButtonElement>) => {
        e.preventDefault();

        // Get uploaded profile pic
        let profilePicture;
        if (files?.length > 0) {
            // eslint-disable-next-line prefer-destructuring
            profilePicture = files[0];
        }
        if (profilePicture === null) {
            return;
        }

        // Create user object
        const updatedUser = {
            user_image: profilePicture,
            oldURL: userAuthenData?.user_image, // We need this to delete the original file
        };

        dispatch(updateUser(
            userAuthenData?._id,
            updatedUser,
        ));

    };

    const submitButtonContent = updateUserPending ? 'Saving...' : 'Save';

    return (
        <div className={styles.container}>
            {profilePictureError}
            {profilePictureSuccess}
            <div className={styles.profile_picture_container}>
                <MuiThemeProvider theme={theme}>
                    <div
                        className={styles.profile_picture}
                        style={files?.length > 0 || userAuthenData?.user_image !== ''
                            ? {
                                backgroundImage: `url("${picture}")`,
                                backgroundSize: 'cover',
                                backgroundPosition: 'center',
                                borderRadius: '50px',
                            }
                            : {
                                backgroundColor: THEME_COLORS.teal,
                                backgroundImage: 'none',
                                borderRadius: '50px',
                            }}
                    >
                        <DropzoneArea
                            initialFiles={files}
                            acceptedFiles={['image/jpeg', 'image/png', 'image/heic']}
                            filesLimit={1}
                            maxFileSize={5000000}
                            showPreviewsInDropzone={false}
                            dropzoneText=''
                            // @ts-ignore
                            Icon={() => (<Image style={{ fontSize: '3em', color: THEME_COLORS.teal }} />)}
                            onChange={handleFileUpload}
                        />
                    </div>
                </MuiThemeProvider>
            </div>
            <StyledButton
                disabled={!canSubmit() || updateUserPending}
                onClick={handleSubmit}
                bordRadius={5}
                style={{ width: '100%', marginTop: '2rem' }}
            >
                {submitButtonContent}
            </StyledButton>
        </div>
    );
};

export default ChangeProfilePictureView;
