import { firebaseAnalytics } from 'common/firebase';
import { ModifiedDetailedImage } from 'redux/projects/models/DetailedImage';
import { Project, UpdatedProject } from 'redux/projects/models/Project';

interface DetailedImageMetrics {
    numberDeleted: number;
    numberUploaded: number;
    annotationsAdded: number;
    annotationsDeleted: number;
}

const getImageMetrics = (images: ModifiedDetailedImage[] | undefined, originalImages: ModifiedDetailedImage[] | undefined) => {
    const metrics: DetailedImageMetrics = {
        numberDeleted: 0,
        numberUploaded: 0,
        annotationsAdded: 0,
        annotationsDeleted: 0,
    };

    images?.forEach((image, index) => {
        if (image.delete) {
            metrics.numberDeleted += 1;
        }
        if (image.uploadedImage) {
            metrics.numberUploaded += 1;
        }
        if (originalImages && index < (originalImages?.length || -1) && image?.annotations?.annotationInfo?.length !== originalImages[index]?.annotations?.annotationInfo?.length) {
            const difference = image?.annotations?.annotationInfo?.length - originalImages[index]?.annotations?.annotationInfo?.length;
            const result = Math.abs(difference);
            if (difference < 0) {
                metrics.annotationsDeleted = result;
            } else {
                metrics.annotationsAdded = result;
            }
        }
    });
    return metrics;
};

export const logPageView = () => {
    firebaseAnalytics?.logEvent('page_view', {
        page_location: window.location.pathname,
        page_host: window.location.host,
    });
};

export const trackUpdateProjectEvent = (userID: string, projectID: string, project: Partial<UpdatedProject> | Partial<Project> = {}, currentProject: Partial<Project> | null) => {

    const eventParams: any = {};
    eventParams.annotationsAdded = 0;
    eventParams.annotationsDeleted = 0;

    if (project.sketches) {
        const sketchesMetrics = getImageMetrics(project.sketches, currentProject?.sketches);
        eventParams.sketches_deleted = sketchesMetrics.numberDeleted;
        eventParams.sketches_uploaded = sketchesMetrics.numberUploaded;
        eventParams.annotationsAdded += sketchesMetrics.annotationsAdded;
        eventParams.annotationsDeleted += sketchesMetrics.annotationsDeleted;
    }
    if (project.materials) {
        const materialsMetrics = getImageMetrics(project.materials, currentProject?.materials);
        eventParams.materials_deleted = materialsMetrics.numberDeleted;
        eventParams.materials_uploaded = materialsMetrics.numberUploaded;
        eventParams.annotationsAdded += materialsMetrics.annotationsAdded;
        eventParams.annotationsDeleted += materialsMetrics.annotationsDeleted;
    }
    if (project.outcome) {
        const outcomeMetrics = getImageMetrics(project.outcome, currentProject?.outcome);
        eventParams.outcomes_deleted = outcomeMetrics.numberDeleted;
        eventParams.outcomes_uploaded = outcomeMetrics.numberUploaded;
        eventParams.annotationsAdded += outcomeMetrics.annotationsAdded;
        eventParams.annotationsDeleted += outcomeMetrics.annotationsDeleted;
    }

    firebaseAnalytics?.logEvent('update_project', {
        user_id: userID,
        project_id: projectID,
        ...eventParams,
    });
};

export const logError = (error: any, errorEvent: string) => {
    firebaseAnalytics?.logEvent('error', {
        error_event: errorEvent,
        error,
    });
};
