import React, { MouseEventHandler } from 'react';
import Modal from 'components/Modal';
import { useSelector } from 'common/hooks';
import { useHistory } from 'react-router-dom';
import FeatureBubble from 'components/modals/premiumSuggestionModal/FeatureBubble';
import StyledButton from 'components/buttons/StyledButton';
import hero from 'assets/premium_modal_graphic.svg';
import infinityIcon from 'assets/infinity.svg';
import teamworkIcon from 'assets/teamwork.svg';
import padlockIcon from 'assets/padlock.svg';

import styles from 'components/modals/premiumSuggestionModal/styles/premiumSuggestionModal.module.css';

const buttonStyle = {
    width: '40%',
};

const subscribeButtonStyle = {
    ...buttonStyle,
};

const cancelButtonStyle = {
    ...buttonStyle,
    backgroundColor: '#E9E9E9',
    color: '#717171',
};

interface PremiumSuggestionModalProps {
    title?: string;
    text?: React.ReactNode;
    // eslint-disable-next-line no-restricted-globals
    open: boolean;
    onClose: MouseEventHandler<any> | undefined;
}

const PremiumSuggestionModal = ({ title, text, open, onClose }: PremiumSuggestionModalProps) => {

    const history = useHistory();
    const {
        premiumCheckoutPending,
        userAuthenData,
    } = useSelector((state) => ({
        premiumCheckoutPending: state.users.premiumCheckoutPending,
        userAuthenData: state.users.userAuthenData,
    }));

    const getPremium = (e: React.MouseEvent<HTMLButtonElement> | undefined) => {
        if (userAuthenData?._id) {
            history.push('/premium');
            if (onClose && e) {
                onClose(e);
            }
        }
    };

    return (
        <Modal
            title={title}
            titleStyle={{
                fontWeight: 'bold',
                width: '30rem',
            }}
            open={open}
            onClose={premiumCheckoutPending ? undefined : onClose}
        >
            <div className={styles.container}>
                <div className={styles.topRow}>
                    <div className={styles.blurb}>
                        {text}
                    </div>
                    <div className={styles.heroContainer}>
                        <img className={styles.hero} src={hero} alt='hero' />
                    </div>
                </div>
                <div className={styles.middleRow}>
                    <FeatureBubble text='More Projects' icon={infinityIcon} iconStyle={{ width: '3rem' }} />
                    <FeatureBubble text='Collaboration' icon={teamworkIcon} />
                    <FeatureBubble text='Private projects' icon={padlockIcon} />
                </div>
                <div className={styles.bottomRow}>
                    <StyledButton
                        onClick={getPremium}
                        bordRadius={5}
                        style={subscribeButtonStyle}
                        disabled={premiumCheckoutPending}
                    >
                    Subscribe
                    </StyledButton>
                    <StyledButton
                        onClick={onClose}
                        bordRadius={5}
                        style={cancelButtonStyle}
                        disabled={premiumCheckoutPending}
                    >
                    Maybe later
                    </StyledButton>
                </div>
            </div>
        </Modal>
    );
};

PremiumSuggestionModal.defaultProps = {
    title: 'Have a look around',
    text: (
        <>
            If you like what you see, check out the available
            <b> Subscription Tiers </b>
            and get even more from
            <span className={styles.chiqpackText}> Chiqpack</span>
        </>
    ),
};

export default PremiumSuggestionModal;
