import React from 'react';
import ScrollAnimation from 'react-animate-on-scroll';
import styles from 'styles/pageSection.module.css';

export interface PageSectionProps {
    title: string | JSX.Element;
    subtitle: string | JSX.Element;
    blurb: string | JSX.Element;
    image: string;
    textSide: 'left' | 'right';
    // eslint-disable-next-line react/require-default-props
    backgroundColor?: string;
    // eslint-disable-next-line react/require-default-props
    titleColor?: string;
    // eslint-disable-next-line react/require-default-props
    subtitleColor?: string;
    // eslint-disable-next-line react/require-default-props
    blurbColor?: string;
    // eslint-disable-next-line react/require-default-props
    animationLeft?: string; // class for any animation from https://animate.style/
    // eslint-disable-next-line react/require-default-props
    animationRight?: string;
}

const PageSection = (props: PageSectionProps) => {
    const {
        title,
        subtitle,
        blurb,
        image,
        textSide = 'left',
        backgroundColor,
        titleColor,
        subtitleColor,
        blurbColor,
        animationLeft,
        animationRight,
    } = props;
    return (
        <div className={styles.section_outer_container} style={{ backgroundColor }}>
            <div className={styles.section_inner_container} style={{ flexWrap: textSide === 'left' ? 'wrap-reverse' : 'wrap' }}>
                { textSide === 'left'
                    ? (
                        <>
                            <ScrollAnimation animateOnce animateIn={animationLeft}>
                                <div className={styles.section_left_panel}>
                                    <h3 className={styles.section_subtitle} style={{ color: subtitleColor }}>{subtitle}</h3>
                                    <h1 className={styles.section_title} style={{ color: titleColor }}>{title}</h1>
                                    <p className={styles.section_blurb} style={{ color: blurbColor }}>{blurb}</p>
                                </div>
                            </ScrollAnimation>
                            <ScrollAnimation animateOnce animateIn={animationRight}>
                                <div className={styles.section_right_panel}>
                                    <img className={styles.section_hero} src={image} alt='hero' />
                                </div>
                            </ScrollAnimation>
                        </>
                    )
                    : (
                        <>
                            <ScrollAnimation animateOnce animateIn={animationLeft}>
                                <div className={styles.section_right_panel}>
                                    <img className={styles.section_hero} src={image} alt='hero' />
                                </div>
                            </ScrollAnimation>
                            <ScrollAnimation animateOnce animateIn={animationRight}>
                                <div className={styles.section_left_panel}>
                                    <h3 className={styles.section_subtitle} style={{ color: subtitleColor }}>{subtitle}</h3>
                                    <h1 className={styles.section_title} style={{ color: titleColor }}>{title}</h1>
                                    <p className={styles.section_blurb} style={{ color: blurbColor }}>{blurb}</p>
                                </div>
                            </ScrollAnimation>
                        </>
                    )}
            </div>
        </div>
    );
};

export default PageSection;
