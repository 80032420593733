import React, { useEffect, useState } from 'react';
import { isValidEmail } from 'common/authenUtils/authenUtils';
import { useDispatch, useSelector } from 'common/hooks';
import { Loading } from 'components/Loading';
import { TextField, useMediaQuery } from '@material-ui/core';
import StyledButton from 'components/buttons/StyledButton';
import { useHistory } from 'react-router-dom';
import { createUser } from 'redux/actions';
import styles from 'styles/formSection.module.css';

export const FormSection = () => {
    const dispatch = useDispatch();
    const history = useHistory();
    const isMobile = useMediaQuery('(max-width: 760px)');
    const [username, setUsername] = useState<string>('');
    const [email, setEmail] = useState<string>('');
    const [password, setPassword] = useState<string>('');
    const [emailError, setEmailError] = useState<boolean>(false);
    const [passwordError, setPasswordError] = useState<boolean>(false);
    const [usernameError, setUsernameError] = useState<boolean>(false);

    const {
        userCreated,
        createUserPending,
        createUserError,
    } = useSelector((state) => ({
        userCreated: state.users.userCreated,
        createUserPending: state.users.createUserPending,
        createUserError: state.users.createUserError,
    }));

    const join = () => {
        if (!isValidEmail(email)) {
            setEmailError(true);
        } else {
            dispatch(createUser(username, email, password));
        }
    };

    useEffect(() => {
        if (userCreated) {
            history.push('/emailVerificationSent');
        }
    }, [userCreated, history]);

    useEffect(() => {
        if (createUserError) {
            setPasswordError(true);
            setEmailError(true);
            setUsernameError(true);
        }
    }, [createUserError]);

    return (
        <div className={styles.container}>
            {createUserPending && <Loading />}
            <div className={styles.title_container}>
                <p className={styles.form_text}>
                    Get started for free — join other designers already
                    using Chiqpack to share their creations and inspire
                    each other.
                </p>
            </div>
            <div className={styles.form_container}>
                <form className={styles.form}>
                    <TextField
                        error={usernameError}
                        label='Username'
                        name='username'
                        size='small'
                        variant='outlined'
                        value={username}
                        required
                        className={styles.textfield}
                        onChange={
                            (event) => (setUsername(event.target.value))
                        }
                    />
                    <TextField
                        error={emailError}
                        label='Email'
                        name='email'
                        size='small'
                        variant='outlined'
                        value={email}
                        required
                        className={styles.textfield}
                        onChange={
                            (event) => (setEmail(event.target.value))
                        }
                    />
                    <TextField
                        error={passwordError}
                        label='Password'
                        name='password'
                        size='small'
                        type='password'
                        variant='outlined'
                        value={password}
                        required
                        className={styles.textfield}
                        onChange={
                            (event) => (setPassword(event.target.value))
                        }
                    />
                    <StyledButton
                        style={{
                            width: isMobile ? '100%' : '30rem',
                        }}
                        bordRadius={30}
                        onClick={join}
                    >
                        Sign up
                    </StyledButton>
                </form>
            </div>
            <p className={styles.gdpr}>
                {'By clicking “Sign up”, you agree to our terms of service and privacy policy. We\'ll occasionally send you account related emails.'}
            </p>
        </div>
    );
};
