import React from 'react';
import PropTypes from 'prop-types';

const ActiveTabContext = React.createContext();

class ActiveTabProvider extends React.Component {
    // context state
    constructor(props) {
        super(props);
        this.state = {
            activeTab: 0,
        };
    }

    setActiveTab = (tabIndex) => {
        this.setState(() => ({ activeTab: tabIndex }));
    }

    render() {
        const { children } = this.props;
        const { activeTab } = this.state;
        const { setActiveTab } = this;

        return (
            <ActiveTabContext.Provider
                value={{
                    activeTab,
                    setActiveTab,
                }}
            >
                {children}
            </ActiveTabContext.Provider>
        );
    }
}

ActiveTabProvider.propTypes = {
    children: PropTypes.any.isRequired,
};

const ActiveTabConsumer = ActiveTabContext.Consumer;

export default ActiveTabContext;

export { ActiveTabProvider, ActiveTabConsumer };
