import { Project } from 'redux/projects/models/Project';

interface SearchResults {
    projects: Project[] | null;
    pages: number | null;
}

interface ReduxCommonState {
    // Update Like
    updateLikeStatusError: any;
    updateLikeStatusPending: boolean;
    updatedLikeList: string[] | null;
    // Contact Send
    contactSendError: any;
    contactSendPending: boolean;
    contactSent: boolean;
    // Verify Email
    verifyEmailPending: boolean;
    verifyEmailError: any;
    emailVerified: boolean;
    // Search
    searchPending: boolean;
    searchError: any;
    searchResults: SearchResults | null;
    // Forgot Password
    forgotPasswordPending: boolean;
    forgotPasswordError: any;
    forgotPasswordEmailSent: boolean;
}

export const initialState: ReduxCommonState = {
    // Update Like
    updateLikeStatusError: null,
    updateLikeStatusPending: false,
    updatedLikeList: null,
    // Contact Send
    contactSendError: false,
    contactSendPending: false,
    contactSent: false,
    // Verify Email
    verifyEmailPending: false,
    verifyEmailError: false,
    emailVerified: false,
    // Search
    searchPending: false,
    searchError: false,
    searchResults: null,
    // Forgot Password
    forgotPasswordPending: false,
    forgotPasswordError: false,
    forgotPasswordEmailSent: false,
};
