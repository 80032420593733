import { AppAction } from 'common/configStore';
import { firebaseAnalytics } from 'common/firebase';
import { AnyAction } from 'redux';
import {
    USER_AUTHEN_BEGIN,
    USER_AUTHEN_SUCCESS,
    USER_AUTHEN_FAILURE,
    USER_AUTHEN_DISMISS_ERROR,
    USER_SIGN_OUT,
} from 'redux/users/constants';

export const userAuthen = (username: string, password: string): AppAction => async (dispatch) => {

    dispatch({
        type: USER_AUTHEN_BEGIN,
    });

    const query = `
            mutation login{
              login(
                username: "${username}"
                password: "${password}"
              ) {
                _id
                email
                user_name
                project_ids
                liked_projects
                collaborating_on
                user_image
                date_created
              }
            }
        `;

    const opts: RequestInit = {
        credentials: 'include',
        method: 'POST',
        headers: {
            'Content-Type': 'application/json',
        },
        body: JSON.stringify({ query }),
    };

    return fetch(`${process.env.REACT_APP_GRAPHQL}`, opts)
        .then((response) => response.json())
        .then((createdJson) => {
            if (createdJson?.data?.login && !createdJson?.errors) {
                firebaseAnalytics?.logEvent('login', {
                    user_name: username,
                });
                dispatch({
                    type: USER_AUTHEN_SUCCESS,
                    userData: createdJson.data.login,
                });
            } else {
                dispatch({
                    type: USER_AUTHEN_FAILURE,
                    errors: createdJson?.errors || ['Failed to login. Please try again later.'],
                });
            }
        })
        .catch((error) => {
            dispatch({
                type: USER_AUTHEN_FAILURE,
                errors: [error],
            });
        });
};

export const signOut = (): AppAction => async (dispatch, getState) => {

    const query = `
            mutation invalidateToken{
              invalidateToken
            }
        `;

    const opts: RequestInit = {
        credentials: 'include',
        method: 'POST',
        headers: {
            'Content-Type': 'application/json',
        },
        body: JSON.stringify({ query }),
    };

    const state = getState();

    return fetch(`${process.env.REACT_APP_GRAPHQL}`, opts)
        .then(() => {
            firebaseAnalytics?.logEvent('log_out', {
                user_name: state.users.userAuthenData?.user_name,
            });
            dispatch({
                type: USER_SIGN_OUT,
            });
        });

};

// Async action saves request error by default, this method is used to dismiss the error info.
// If you don't want errors to be saved in Redux store, just ignore this method.
export const dismissUserAuthenError = () => ({
    type: USER_AUTHEN_DISMISS_ERROR,
});

export const reducer = (state: any, action: AnyAction) => {
    switch (action.type) {
    case USER_AUTHEN_BEGIN:
        // Just after a request is sent
        return {
            ...state,
            userAuthenPending: true,
            userAuthenError: null,
        };

    case USER_AUTHEN_SUCCESS:
        // The request is success
        return {
            ...state,
            userAuthenPending: false,
            userAuthenError: null,
            authenticated: true,
            userAuthenData: action.userData,
        };

    case USER_AUTHEN_FAILURE:
        // The request is failed
        return {
            ...state,
            userAuthenPending: false,
            userAuthenError: action.errors,
            authenticated: false,
            userAuthenData: null,
        };

    case USER_AUTHEN_DISMISS_ERROR:
        // Dismiss the request failure error
        return {
            ...state,
            userAuthenError: null,
        };

    case USER_SIGN_OUT:
        // Dismiss the request failure error
        return {
            state: undefined,
        };

    default:
        return state;
    }
};
