import { TReCaptchaV2Callback, TReCaptchaV3Callback, TReCaptchaV3RefreshToken } from 'react-recaptcha-x';

export const v3Callback: TReCaptchaV3Callback = (token: string | void, refreshToken: TReCaptchaV3RefreshToken | void): void => {};

export const v2Callback = (token: string | false | Error, setRecaptchaDone: React.Dispatch<React.SetStateAction<boolean>>): void => {
    if (typeof token === 'string') {
        setRecaptchaDone(true);
    } else {
        setRecaptchaDone(false);
    }
};
