import { AppAction } from 'common/configStore';
import { AnyAction } from 'redux';
import {
    GET_PROJECTS_BEGIN,
    GET_PROJECTS_DISMISS_ERROR,
    GET_PROJECTS_FAILURE,
    GET_PROJECTS_SUCCESS,
} from 'redux/projects/constants';

// Get Projects using list of id's. customResultName will put the resulting data in a Redux State key named after customResultName
export const getProjects = (projects: string[] = [], customResultName: string = ''): AppAction => async (dispatch) => {

    dispatch({
        type: GET_PROJECTS_BEGIN,
        customName: customResultName,
    });

    // Don't send request if no projects in list
    if (projects.length === 0) {
        dispatch({
            type: GET_PROJECTS_SUCCESS,
            customName: customResultName,
            data: [],
        });
        return new Promise<void>(() => {});
    }

    const query = `
            query {
              getProjects(
                projects: ${JSON.stringify(projects)}
              ) {
                _id
                categories
                liked_by
                owner_id
                private
                project_name
                featured_image
                description
                date_created
                last_updated
                owner_data {
                    user_name
                    user_image
                }
                liked_by_users {
                    _id
                    user_name
                    user_image
                }
              }
            }
        `;

    const opts: RequestInit = {
        method: 'POST',
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify({ query }),
    };

    return fetch(process.env.REACT_APP_GRAPHQL || '', opts)
        .then((response) => response.json())
        .then((createdJson) => {
            if (createdJson.errors) {
                dispatch({
                    type: GET_PROJECTS_FAILURE,
                    errors: createdJson.errors,
                    customName: customResultName,
                });
            } else {
                dispatch({
                    type: GET_PROJECTS_SUCCESS,
                    data: createdJson.data.getProjects,
                    customName: customResultName,
                });
            }
        })
        .catch((errors) => {
            dispatch({
                type: GET_PROJECTS_FAILURE,
                errors,
                customName: customResultName,
            });
        });

};

// Async action saves request error by default, this method is used to dismiss the error info.
// If you don't want errors to be saved in Redux store, just ignore this method.
export const dismissGetProjectsError = () => ({
    type: GET_PROJECTS_DISMISS_ERROR,
});

export const reducer = (state: any, action: AnyAction) => {
    // Update data using default value or name specified by input
    const clearDataFrom = !action?.customName ? {
        projectList: null,
    } : {
        [action.customName]: null,
    };
    const saveDataTo = !action?.customName ? {
        projectList: action.data,
    } : {
        [action.customName]: action.data,
    };
    switch (action.type) {
    case GET_PROJECTS_BEGIN:
        // Just after a request is sent
        return {
            ...state,
            getProjectsPending: true,
            getProjectsError: null,
            ...clearDataFrom,
        };

    case GET_PROJECTS_SUCCESS:
        // The request is success
        return {
            ...state,
            getProjectsPending: false,
            getProjectsError: null,
            ...saveDataTo,
        };

    case GET_PROJECTS_FAILURE:
        // The request is failed
        return {
            ...state,
            ...clearDataFrom,
            getProjectsPending: false,
            getProjectsError: action.errors,
        };

    case GET_PROJECTS_DISMISS_ERROR:
        // Dismiss the request failure error
        return {
            ...state,
            getProjectsError: null,
        };
    default:
        return state;
    }
};
