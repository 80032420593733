const THEME_COLORS = {
    lightPurple: '#D0ABE1',
    darkPurple: '#3C376B',
    teal: '#98ECCC',
    salmon: '#FDACA7',
    creme: '#FFEDE1',
    burgundyRed: '#A5243D',
};

export type ThemeColor = keyof typeof THEME_COLORS;

export default THEME_COLORS;
