import React from 'react';
import { Link } from 'react-router-dom';
import styles from 'styles/footer.module.css';
import logo from 'logo2_500.png';

interface PanelProps {
    options: PanelOption[];
    title: string;
}

interface PanelOption {
    label: string;
    link: string;
}

const Panel = ({ options, title }: PanelProps) => (
    <div className={styles.panel}>
        <h2 className={styles.title}>{title}</h2>
        <ul className={styles.list}>
            {
                options.map((item) => (
                    <li key={item.link}>
                        {item.link.includes('http') ? (
                            <a href={item.link} target='_blank' className={styles.link} rel='noreferrer'>
                                {item.label}
                            </a>
                        ) : (
                            <Link to={item.link} className={styles.link}>
                                {item.label}
                            </Link>
                        )}
                    </li>
                ))
            }
        </ul>
    </div>
);

const Footer = () => {
    const companyOptions: PanelOption[] = [
        { label: 'About', link: '/about' },
        { label: 'Contact', link: '/contact' },
        { label: 'Premium', link: '/premium' },
    ];

    const resourcesOptions: PanelOption[] = [
        { label: 'Terms', link: '/terms' },
        { label: 'Privacy', link: '/privacy' },
    ];

    const socialOptions: PanelOption[] = [
        { label: 'LinkedIn', link: 'https://www.linkedin.com/company/76250120' },
        { label: 'Twitter', link: 'https://twitter.com/chiqpack' },
        { label: 'Facebook', link: 'https://facebook.com/chiqpack' },
    ];

    return (
        <div className={styles.container}>
            <div className={styles.logoSection}>
                <img className={styles.logo} src={logo} alt='Chiqpack Logo' />
                <h3>Copyright © 2022 Chiqpack. All rights reserved</h3>
            </div>
            <div className={styles.panelsContainer}>
                <Panel options={companyOptions} title='Company' />
                <Panel options={resourcesOptions} title='Resources' />
                <Panel options={socialOptions} title='Socials' />
            </div>
        </div>
    );
};

export default Footer;
