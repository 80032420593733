// TODO - convert to typescript
export const groupBy = (items, key, extraKey) => items.reduce(
    (result, item) => {
        if (typeof extraKey !== 'undefined' && extraKey !== null) {
            return {
                ...result,
                [item[key][extraKey]]: [
                    ...(result[item[key][extraKey]] || []),
                    item,
                ],
            };
        }
        return {
            ...result,
            [item[key]]: [
                ...(result[item[key]] || []),
                item,
            ],
        };
    }, {},
);
