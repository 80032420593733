import React from 'react';
import withHelmet from 'components/hocs/withHelmet';
import { PAGE_NAMES } from 'constants/metaData';
import styles from 'styles/notFound.module.css';

/** 404 page */
const NotFound = () => (
    <div>
        <div className={`${styles.root}`}>
            <h1 className={`${styles.title}`}>404. An error occurred.</h1>
            <p className={`${styles.description}`}>The requested URL does not exist on this server.</p>
        </div>
    </div>
);

NotFound.displayName = PAGE_NAMES.NOT_FOUND;

export default withHelmet(NotFound);
