import React, { CSSProperties } from 'react';
import Card from 'components/Card';
import CornerInfo from 'components/ExamplesCarousel/CornerInfo';
import { Corner } from 'components/ExamplesCarousel/constants';
import styles from './styles/examplesCarouselSlide.module.css';

interface Props {
    children: React.ReactNode;
    corner: Corner;
    blurb: string | JSX.Element;
}

const cardStyle: CSSProperties = {
    height: '100%',
    width: '100%',
    position: 'relative',
    overflow: 'clip',
};

const ExamplesCarouselSlide = ({ children, corner, blurb }: Props) => (
    <div className={styles.container}>
        <Card style={cardStyle}>
            <CornerInfo corner={corner} blurb={blurb} />
            <div className={styles.slide_content}>
                {children}
            </div>
        </Card>
    </div>
);

export default ExamplesCarouselSlide;
