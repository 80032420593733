export const ACTIVE = 'active';
export const INCOMPLETE = 'incomplete';
export const INCOMPLETE_EXPIRED = 'incomplete_expired';
export const TRIALING = 'trialing';
export const PAST_DUE = 'past_due';
export const CANCELED = 'canceled';
export const UNPAID = 'unpaid';

export function isActiveOrTrial(status: string = ''): boolean {
    return status === ACTIVE || status === TRIALING;
}

export function isCanceled(status: string = ''): boolean {
    return status === CANCELED;
}
