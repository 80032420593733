import React from 'react';
import { Link } from 'react-router-dom';
import StyledButton from 'components/buttons/StyledButton';
import styles from 'styles/leadingSection.module.css';
import landingStyles from 'styles/landing.module.css';
import hero from 'assets/hero.svg';

interface Props {
    isLoggedIn: boolean;
}

const LeadingSection = ({ isLoggedIn }: Props) => (
    <div className={styles.container}>
        <div className={styles.left_panel}>
            <h3 className={landingStyles.section_subtitle}>MEET CHIQPACK</h3>
            <h1 className={landingStyles.section_title}>
                A New Way to&nbsp;
                <br />
                <b>
                    Share Your Designs
                </b>
            </h1>
            <p className={landingStyles.section_blurb}>
                Your work deserves to be shared with the world.
                <br />
                It&apos;s simple and intuitive, so get started for free today!
            </p>
            {!isLoggedIn && (
                <Link to='/join' className={styles.start_button}>
                    <StyledButton
                        bordRadius={10}
                        width='100%'
                        style={
                            {
                                height: '100%',
                                fontSize: '1.7rem',
                                fontWeight: 'bold',
                            }
                        }
                    >
                        Get Started!
                    </StyledButton>
                </Link>
            )}
        </div>
        <div style={{ width: '1rem' }} />
        <div className={styles.right_panel}>
            <img className={styles.hero} src={hero} alt='hero' />
        </div>
    </div>
);

export default LeadingSection;
