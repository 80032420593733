import { logPageView } from 'common/analytics/firebaseEvents';
import React, { useEffect, useMemo } from 'react';
import { shallowEqual } from 'react-redux';
import { useSelector, useDispatch } from 'common/hooks';
import { useMediaQuery } from '@material-ui/core';
import { Alert } from '@material-ui/lab';
import Slider, { Settings as SliderSettings } from 'react-slick';
import { dismissGetProjectError, getShowcase } from 'redux/actions';
import { groupBy } from 'utils/groupBy';
import { categoryColors } from 'utils/categories';
import withHelmet from 'components/hocs/withHelmet';
import { PAGE_NAMES } from 'constants/metaData';
import { Loading } from 'components/Loading';
import ProjectCard, { PROJECT_CARD_VARIANT } from 'components/ProjectCard';
import Spacer from 'components/Spacer';
import { Project } from 'redux/projects/models/Project';
import sideSplatter from 'assets/side_splatter.svg';
import sadFaceWhite from 'assets/icons/sad-face-white.svg';
import sadFace from 'assets/icons/sad-face.svg';
import styles from 'styles/showcase.module.css';
import editStyles from 'styles/editProject.module.css';
import 'pages/styles/showcase.css';

export const NoContentPlaceHolder = ({ text, color, size }: { text?: string, color?: 'black' | 'white', size?: number }) => (
    <div>
        <div style={{ display: 'flex' }}>
            <p style={{ margin: 0, color, fontSize: size }}>{text}</p>
            <Spacer width='0.5rem' />
            <img style={{ width: size }} src={color === 'white' ? sadFaceWhite : sadFace} alt='sad face icon' />
        </div>
    </div>
);

NoContentPlaceHolder.defaultProps = {
    text: "We're sorry, but there was an error loading content",
    color: 'black',
    size: 18,
};

const Showcase = () => {
    const dispatch = useDispatch();
    const {
        getShowcaseError,
        getShowcasePending,
        showcaseList,
        userAuthenData,
    } = useSelector((state) => ({
        showcaseList: state.projects.showcaseList,
        getShowcasePending: state.projects.getShowcasePending,
        getShowcaseError: state.projects.getShowcaseError,
        userAuthenData: state.users.userAuthenData,
    }), shallowEqual);

    useEffect(() => {
        dispatch(getShowcase());
        logPageView();
    }, []); // eslint-disable-line

    // @ts-ignore
    const sortedShowcaseList = useMemo(() => (showcaseList ? showcaseList.sort((a, b) => b - a) : null),
        [showcaseList]);

    const carouselSettingsPopular: SliderSettings = {
        dots: false,
        infinite: true,
        speed: 500,
        slidesToShow: 1,
        slidesToScroll: 1,
    };

    const carouselSettingsCategories: SliderSettings = {
        dots: true,
        infinite: true,
        speed: 500,
    };

    const loadingScreen = getShowcasePending ? (<Loading />) : null;

    const errorScreen = getShowcaseError ? (
        <Alert
            onClose={() => dispatch(dismissGetProjectError())}
            className={editStyles.alert}
            severity='error'
        >
            Failed to get projects.
        </Alert>
    ) : null;

    const topThreeLoaded = !sortedShowcaseList ? null : sortedShowcaseList.slice(0, 3).map((p: Partial<Project>) => (
        <ProjectCard
            // @ts-ignore
            variant={PROJECT_CARD_VARIANT.LARGE}
            projectId={p?._id}
            projectName={p?.project_name}
            ownerName={p?.owner_data?.user_name}
            liked={!userAuthenData ? false : p?.liked_by?.includes(userAuthenData?._id || '')}
            likeCount={p?.liked_by?.length}
            categories={p?.categories}
            projectDescription={p?.description}
            projectStats={[
                p?.sketches?.length || 0,
                p?.materials?.length || 0,
                p?.measurements?.headers?.length || 0,
                p?.outcome?.length || 0,
            ]}
            ownerImage={p?.owner_data?.user_image}
            ownerID={p?.owner_id}
            featuredImage={p?.featured_image}
            key={p?._id}
        />
    ));

    const trendingLoaded = !sortedShowcaseList ? null : sortedShowcaseList.slice(3, 9).map((p: Partial<Project>) => (
        <ProjectCard
            projectId={p?._id}
            projectName={p?.project_name}
            ownerName={p?.owner_data?.user_name}
            liked={!userAuthenData ? false : p?.liked_by?.includes(userAuthenData?._id || '')}
            likeCount={p?.liked_by?.length}
            categories={p?.categories}
            projectDescription={p?.description}
            featuredImage={p?.featured_image}
            ownerImage={p?.owner_data?.user_image}
            ownerID={p?.owner_id}
            key={p?._id}
        />
    ));

    const renderMostPopular = (
        <div className={styles.popular_section}>
            <div className={styles.popular_section_content}>
                <h3 className={styles.section_title}>Most Popular</h3>
                <div style={{ padding: '0 3rem' }}>
                    <Slider {...carouselSettingsPopular}>
                        {topThreeLoaded?.length ? topThreeLoaded : (
                            <NoContentPlaceHolder color='white' />
                        )}
                    </Slider>
                </div>
            </div>
        </div>
    );

    const renderTrending = (
        <div className={styles.trending_section}>
            <h3 className={styles.section_title}>Trending</h3>
            <div style={{ padding: '0 3rem' }} className={styles.trending_section_content}>
                {trendingLoaded?.length ? trendingLoaded : (
                    <NoContentPlaceHolder text='No content' />
                )}
            </div>
        </div>
    );

    // group by the first item in the projects' 'categories' array
    const projectsByCategory = useMemo(() => !sortedShowcaseList || groupBy(sortedShowcaseList, 'categories', 0), [sortedShowcaseList]);
    const isTablet = useMediaQuery('(min-width: 900px)');
    const isDesktop = useMediaQuery('(min-width: 1300px)');

    const topCategoriesLoaded = () => {
        const getSlideCount = (numProjects: number) => {
            let slideCount = 1;

            if (isTablet) {
                slideCount = Math.min(numProjects, 2);
            }
            if (isDesktop) {
                slideCount = Math.min(numProjects, 3);
            }

            return slideCount;
        };

        const categories = Object.keys(projectsByCategory);

        return !categories.length ? null : (
            <>
                {
                    categories.map((category) => (
                        <div className={styles.top_categories_subsection} key={category}>
                            <h2
                                // @ts-ignore
                                style={{ color: categoryColors[category.toLowerCase()] || 'grey' }}
                                className={styles.category_subtitle}
                            >
                                {category}
                            </h2>
                            <Slider
                                {...carouselSettingsCategories}
                                centerMode={!(isDesktop || isTablet)}
                                slidesToScroll={getSlideCount(Math.min(projectsByCategory[category].length))}
                                slidesToShow={getSlideCount(Math.min(projectsByCategory[category].length))}
                            >
                                {
                                    Object.values(projectsByCategory[category]).map((p: any) => (
                                        <ProjectCard
                                            // @ts-ignore
                                            variant={PROJECT_CARD_VARIANT.SMALL}
                                            projectId={p?._id}
                                            projectName={p?.project_name}
                                            ownerName={p?.owner_data?.user_name}
                                            featuredImage={p?.featured_image}
                                            liked={!userAuthenData ? false : p?.liked_by?.includes(userAuthenData?._id || '')}
                                            likeCount={p?.liked_by?.length}
                                            categories={p?.categories}
                                            ownerImage={p?.owner_data?.user_image}
                                            ownerID={p?.owner_id}
                                            ownerPremium={p?.owner_data?.premium}
                                            key={p?._id}
                                        />
                                    ))
                                }
                            </Slider>
                        </div>
                    ))
                }
            </>
        );
    };

    const renderTopCategories = (
        <div className={styles.top_categories_section}>
            <h3 className={styles.section_title}>Top Categories</h3>
            <div className={`${styles.top_categories_content} ${styles.top_categories_content_padding}`}>
                {topCategoriesLoaded() || <NoContentPlaceHolder text='No content' />}
            </div>
        </div>
    );

    return (
        <div className={styles.outer_container}>
            <div className={styles.inner_container}>
                <h1 className={styles.title}>
                    Showcase&nbsp;
                    <span role='img' aria-label='sparkles emoji'>✨</span>
                </h1>
                {loadingScreen || errorScreen || (
                    <>
                        {renderMostPopular}
                        {renderTrending}
                        {renderTopCategories}
                    </>
                )}
            </div>
            <img
                src={sideSplatter}
                alt='side splatter background decoration 1'
                className={`${styles.side_splatter_1} ${styles.side_splatter}`}
            />
            <img
                src={sideSplatter}
                alt='side splatter background decoration 2'
                className={`${styles.side_splatter_2} ${styles.side_splatter}`}
            />
            <img
                src={sideSplatter}
                alt='side splatter background decoration 3'
                className={`${styles.side_splatter_3} ${styles.side_splatter}`}
            />
        </div>
    );
};

Showcase.displayName = PAGE_NAMES.SHOWCASE;

export default withHelmet(Showcase);
