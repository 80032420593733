import React, { useCallback, useEffect, useState } from 'react';
import Slider from 'react-slick';
import { CircularProgress, TextField, useMediaQuery, withStyles } from '@material-ui/core';
import { ProjectSection } from 'pages/Project';
import { DetailedImage } from 'redux/projects/models/DetailedImage';
import { Bezier } from 'components/annotations/Bezier';
import { Point } from 'redux/projects/models/Point';
import { Table } from 'redux/projects/models/Table';
import { isArray } from 'lodash';
import MeasurementsPanel from 'components/panels/MeasurementsPanel';
import logo from 'logo_500.png';
import styles from 'components/modals/componentCardModal/styles/mainPanel.module.css';

interface MainPanelProps {
    section: ProjectSection;
    components?: DetailedImage[] | Table;
    componentIndex: number;
    setComponentIndex: (idx: number) => void;
}

const carouselSettings = {
    dots: true,
    infinite: false,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
};

export const MainPanel = ({
    section,
    components,
    componentIndex,
    setComponentIndex,
}: MainPanelProps) => {
    const [description, setDescription] = useState('');
    // Used when updating annotation descriptions
    const [annotationInfo, setAnnotationInfo] = useState<string[] | undefined>([]);
    const [selectedAnnotation, setSelectedAnnotation] = useState(-1);
    // Used to store point information when making change to image
    const [startPoints, setStartPoints] = useState<Point[]>([]);
    const [midPoints, setMidPoints] = useState<Point[]>([]);
    const [endPoints, setEndpoints] = useState<Point[]>([]);
    // Used to store image information when showing update dialog
    const [image, setImage] = useState<HTMLImageElement | null>(null);
    const isMeasurements = section === 'measurements';
    const [imageWidth, setImageWidth] = useState<string>('');
    const [imageHeight, setImageHeight] = useState<string>('');

    // We want to calculate a working image size when the dialog opens and when the window changes
    useEffect(() => {
        if (isMeasurements || !isArray(components)) {
            return;
        }
        const item = components?.[componentIndex];
        // Create and Image object to show in the update popup
        const img = new Image();
        img.src = item?.image || logo;
        setImage(img);
        // Set values from item to be used in dialog
        setDescription(item?.description || '');
        setStartPoints(item?.annotations?.startPoints || []);
        setMidPoints(item?.annotations?.midPoints || []);
        setEndpoints(item?.annotations?.endPoints || []);
        setAnnotationInfo(item?.annotations?.annotationInfo || []);

        if (img.width > img.height) {
            setImageWidth('90vw');
            setImageHeight('');
        } else {
            setImageHeight('75vh');
            setImageWidth('');
        }
    }, [components, componentIndex]);

    const onChangeSlide = useCallback(
        (_: number, slideIdx: number) => {
            setComponentIndex(slideIdx);
        },
        [setComponentIndex],
    );

    const sliderPanels = isArray(components) && components?.map((item: DetailedImage, index: number) => {
        if (componentIndex === index) {
            return (
                <div key={item.image}>
                    <div className={styles.description}>
                        {item.description}
                    </div>
                    <div className={styles.bezierContainer}>
                        <Bezier
                            viewBoxWidth={image?.width || 1}
                            viewBoxHeight={image?.height || 1}
                            // styleWidth and styleHeight should be changed to fit space in MainPanel
                            styleHeight={imageHeight}
                            styleWidth={imageWidth}
                            background={image?.src || ''}
                            onStartPoints={setStartPoints}
                            onMidPoints={setMidPoints}
                            onEndPoints={setEndpoints}
                            originalStart={startPoints}
                            originalMid={midPoints}
                            originalEnd={endPoints}
                            editable={false}
                            annotations={annotationInfo || []}
                        />
                    </div>
                    <div>
                        {annotationInfo?.map((a: string, index: number) => (
                            <DarkerDisabledTextField
                                disabled
                                autoFocus
                                margin='dense'
                                id={`annot ${item.image} ${index} ${a}`}
                                label={`Annotation ${index + 1}`}
                                type='text'
                                defaultValue={a}
                                focused={selectedAnnotation === index}
                                key={`annot ${item.image} ${a}`}
                                fullWidth
                            />
                        ))}
                    </div>
                </div>
            );
        }
        return (
            <div
                className={styles.loadingContainer}
            >
                <CircularProgress size={150} />
            </div>
        );
    });

    return (
        <div className={styles.container}>
            { !isMeasurements && isArray(components)
                ? (
                    <Slider
                        className={styles.carousel}
                        {...carouselSettings}
                        initialSlide={componentIndex}
                        beforeChange={onChangeSlide}
                    >
                        {sliderPanels}
                    </Slider>
                )
                : (
                    <MeasurementsPanel isEditing={false} />
                )}
        </div>
    );
};

// Only used in this component
const DarkerDisabledTextField = withStyles({
    root: {
        marginRight: 8,
        '& .MuiInputBase-root.Mui-disabled': {
            color: 'black',
        },
    },
})(TextField);

MainPanel.defaultProps = {
    components: [],
};
