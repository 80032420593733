import React, { CSSProperties } from 'react';
import { Button } from '@material-ui/core';
import THEME_COLORS from 'utils/themeColors';

interface StyledButtonProps {
    children?: React.ReactNode;
    bgColor?: string;
    textColor?: string;
    bordRadius?: number;
    onClick?: React.MouseEventHandler<HTMLButtonElement> | undefined;
    disabled?: boolean;
    outline?: boolean;
    width?: string;
    style?: CSSProperties;
}

const StyledButton = (props: StyledButtonProps) => {
    const {
        children,
        bgColor,
        textColor,
        bordRadius,
        onClick,
        disabled,
        outline,
        width,
        style: passedStyle,
    } = props;

    const mainStyle: CSSProperties = {
        backgroundColor: bgColor,
        color: textColor,
        borderRadius: bordRadius,
        fontSize: '1.1rem',
        textTransform: 'none',
        width,
        opacity: disabled ? '0.5' : '1.0',
        ...passedStyle,
    };

    const outlinedStyle: CSSProperties = {
        border: `2px solid ${bgColor}`,
        borderRadius: bordRadius,
        color: bgColor,
        fontSize: '1.1rem',
        fontWeight: 'bold',
        textTransform: 'none',
        width,
        opacity: disabled ? '0.5' : '1.0',
        ...passedStyle,
    };

    return (
        <Button
            disabled={disabled}
            style={outline ? outlinedStyle : mainStyle}
            onClick={onClick}
        >
            {children}
        </Button>
    );
};

StyledButton.defaultProps = {
    children: undefined,
    bgColor: THEME_COLORS.lightPurple,
    textColor: 'white',
    bordRadius: 10,
    onClick: undefined,
    disabled: false,
    outline: false,
    width: 'fit-content',
    style: {},
};

export default StyledButton;
