import React, { useCallback, useContext, useEffect, useState } from 'react';
import { Prompt } from 'react-router-dom';
import { useDispatch, useSelector } from 'common/hooks';
import { Backdrop, CircularProgress, IconButton, TextField, Typography, useMediaQuery } from '@material-ui/core';
import { createMuiTheme, MuiThemeProvider } from '@material-ui/core/styles';
import { Image as ImageIcon, Delete as DeleteIcon } from '@material-ui/icons';
import RemoveCircleIcon from '@material-ui/icons/RemoveCircle';
import { DropzoneArea } from 'material-ui-dropzone';
import { isEqual } from 'lodash';
import { useSnackbar } from 'notistack';
import { useWindowSize } from 'hooks/Window';
import { updateProject } from 'redux/actions';
import SolidButton from 'components/buttons/StyledButton';
import { Bezier } from 'components/annotations/Bezier';
import { ModifiedDetailedImage } from 'redux/projects/models/DetailedImage';
import { Point } from 'redux/projects/models/Point';
import BlockNavigationContext from 'utils/BlockNavigationContext';
import { FormDialog } from 'components/FormDialog';
import ComponentCard from 'components/ComponentCard';
import THEME_COLORS from 'utils/themeColors';
import styles from 'styles/imagesPanel.module.css';
import PremiumAlert from 'components/PremiumAlert';
import { SectionPlaceholder } from 'pages/partials/SectionPlaceholder';
import { ProjectSection } from 'pages/Project';
import { MAX_IMAGE_SIZE_BYTES } from 'common/imageConstants';
import { UserTiers } from 'redux/users/models/User';
import { hasHitImageCap } from 'common/tierChecks';

interface ImagesPanelProps {
    type: ProjectSection;
}

// TODO - Figure out how to get owner subscription information
const ImagesPanel = ({ type }: ImagesPanelProps) => {

    const { enqueueSnackbar } = useSnackbar();

    const dispatch = useDispatch();
    const isMobile = useMediaQuery('(max-width: 760px)');
    const windowSize = useWindowSize();
    const blockNavigationContext = useContext(BlockNavigationContext);
    const { blockNavigation, setBlockNavigation } = blockNavigationContext;
    const [openUpload, setOpenUpload] = useState<boolean>(false);
    const [openUpdate, setOpenUpdate] = useState<boolean>(false);
    // Used when updating description value
    const [description, setDescription] = useState<string>('');
    // Used when updating annotation descriptions
    const [annotationInfo, setAnnotationInfo] = useState<string[]>([]);
    const [selectedAnnotation, setSelectedAnnotation] = useState<number>(-1);
    // Used to store point information when making change to image
    const [startPoints, setStartPoints] = useState<Point[]>([]);
    const [midPoints, setMidPoints] = useState<Point[]>([]);
    const [endPoints, setEndpoints] = useState<Point[]>([]);
    // Used to store image information when showing update dialog
    const [image, setImage] = useState<HTMLImageElement | null>(null);
    const [imageWidth, setImageWidth] = useState<number>(1);
    const [imageHeight, setImageHeight] = useState<number>(1);
    const [files, setFiles] = useState<File[]>([]);
    const reader: FileReader = new FileReader();
    // Index of annotation to be deleted
    const [toDelete, setToDelete] = useState<{ index: number, seed: number} | null>(null);

    const {
        project,
        updateProjectPending,
        userAuthenData,
        userSubscription,
    } = useSelector((state) => ({
        project: state.projects.project,
        updateProjectPending: state.projects.updateProjectPending,
        userAuthenData: state.users.userAuthenData,
        userSubscription: state.users.userSubscription,
    }));

    const isCollaborator = project?.collaborators?.includes(userAuthenData?._id || '');
    const useTier: UserTiers | undefined = isCollaborator ? project?.ownerTier : userSubscription?.userTier;

    /** Values from redux store (current, saved project data)
     * Will be updated with actual values in useEffect
     */
    const [storedValues, setStoredValues] = useState({
        [type]: [],
        imageURL: null,
        lastFile: null,
        item: null,
    });

    /** Values of the TextFields in the form */
    const [values, setValues] = useState({
        [type]: [],
        imageURL: null,
        lastFile: null,
        item: null,
    });

    /** https://github.com/Yuvaleros/material-ui-dropzone/blob/master/docs/theming.md */
    const theme = createMuiTheme({
        overrides: {
            MuiDialog: {
                paperWidthFalse: {
                    maxWidth: '100%',
                },
                paper: {
                    margin: 0,
                },
            },
            MuiDropzoneArea: {
                root: {
                    display: 'flex',
                    backgroundColor: 'transparent',
                    color: THEME_COLORS.darkPurple,
                    borderRadius: '10px',
                },
                textContainer: {
                    alignSelf: 'center',
                    margin: '0 auto',
                    width: 'fit-content',
                    padding: '1rem',
                    borderRadius: '10px',
                    backgroundColor: 'rgba(152, 236, 204, 0.95)',
                },
            },
        },
    });

    // @ts-ignore
    const premiumBlock = hasHitImageCap(useTier || UserTiers.FREE, values?.[type]?.filter((v) => !v?.delete)?.length);

    // reset all values when both dialogs are closed
    useEffect(() => {
        if (!openUpload && !openUpdate) {
            setDescription('');
            setStartPoints([]);
            setMidPoints([]);
            setEndpoints([]);
            setAnnotationInfo([]);
            setImageHeight(1);
            setImageWidth(1);
        }
    }, [openUpload, openUpdate]);

    // We want to calculate a working image size when the dialog opens and when the window changes
    useEffect(() => {
        if (openUpload || openUpdate) {
            let imageW: number = image?.width ?? 1;
            let imageH: number = image?.height ?? 1;
            // Adjust width first
            if (image?.width && image?.width > (windowSize?.width ?? 0)) {
                imageW = (windowSize?.width ?? 0) - 64; // 64 pixels is to keep margin in the dialog
                const differenceRatio = imageW / image?.width;
                imageH = (image?.height * differenceRatio); // Change the height by the same ratio we changed the width
            }
            // Adjust height if after adjustment height is longer than dialog
            if (imageH > (windowSize?.height ?? 0) - 256) {
                const imageHeightNew: number = (windowSize?.height ?? 256) - 256; // Keep image in dialog
                const differenceRatio = imageHeightNew / imageH;
                imageW *= differenceRatio;
                imageH = imageHeightNew;
            }

            setImageWidth(imageW);
            setImageHeight(imageH);
        }
    }, [image, openUpload, openUpdate, windowSize]);

    // load values on project change
    useEffect(() => {
        if (!updateProjectPending) {
            // TODO - Find best way to index properly
            const val = {
                // @ts-ignore
                [type]: project?.[type]?.map(((item) => ({ ...item, delete: false }))),
            };

            setStoredValues((v) => ({
                ...v,
                ...val,
            }));
            setValues((v) => ({
                ...v,
                ...val,
            }));

            setBlockNavigation(false);
        }
    }, [project, updateProjectPending, setBlockNavigation, type]);

    /* window.onbeforeunload helps avoid unsafe reload */
    useEffect(() => {
        if (blockNavigation) {
            window.onbeforeunload = () => true;
        } else {
            window.onbeforeunload = null;
        }
    }, [blockNavigation]);

    // works in a similar way as componentWillUnmount (cleanup)
    useEffect(() => () => {
        setBlockNavigation(false);
        window.onbeforeunload = null;
    }, []); // eslint-disable-line

    // This function will execute on creation of a new annotation
    const notifyCreate = () => {
        setAnnotationInfo([...annotationInfo, '']);
    };

    // This function will execute on deletion of a new annotation
    const notifyDelete = (index: number | null) => {
        if (index !== null && index !== undefined) {
            const tempAnnotations = [...annotationInfo];
            tempAnnotations.splice(index, 1);
            setAnnotationInfo(tempAnnotations);
            setToDelete(null);
        }
    };

    // This function executes when an annotation is selected in Bezier
    const notifySelect = (annotation: number | null) => {
        setSelectedAnnotation(annotation ?? -1);
    };

    /** Check if input differs from what's in the store. Set blockNavigation appropriately */
    const compareValues = (val: any) => {
        const equal = isEqual(storedValues, val);
        if (equal && blockNavigation) {
            setBlockNavigation(false);
        } else if (!equal && !blockNavigation) {
            setBlockNavigation(true);
        }
    };

    const handleAnnotationTextChange = useCallback((event, index) => {
        const { value } = event.target;
        setAnnotationInfo((state) => {
            const newState = [...state];
            newState[index] = value;
            return newState;
        });
    }, [setAnnotationInfo]);

    // Update the annotations array once editing has finished
    const exitAnnotation = (newAnnotation: string, i: number) => {
        const tempAnnotations = [...annotationInfo];
        tempAnnotations[i] = newAnnotation;
        setAnnotationInfo(tempAnnotations);
    };

    /** Helper function to avoid code duplication */
    const updateValues = (val: any) => {
        compareValues(val);
        setValues(val);
    };

    /** Pull image url using FileReader and save */
    const setFileValues = (file: File) => {
        if (file == null) return;

        let fileContent = null;
        reader.onload = () => {
            fileContent = reader.result;
            const val = {
                ...values,
                imageURL: fileContent,
                lastFile: file,
            };
            const img = new Image();
            // Set the image after a size has been found
            img.onload = () => {
                setImage(img);
            };
            // @ts-ignore
            img.src = fileContent;
            updateValues(val);
        };
        reader.readAsDataURL(file);
    };

    /** Handle file upload */
    const handleFileUpload = (filesProp: File[]) => {
        // Stop file upload if not premium and max images is hit
        // @ts-ignore
        if (premiumBlock) {
            return;
        }
        // TODO - Display loading in dialog when image is not available
        setImage(null); // Clear image before opening upload to prevent displaying previously displayed image
        setOpenUpload(true); // open FormDialog after upload
        setFileValues(filesProp[0]);
    };

    /** Removes an image */
    const handleImageDelete = (imageToDelete: ModifiedDetailedImage) => {
        const val = {
            ...values,
            [type]: values[type]?.map((item) => {
                if (item === imageToDelete) {
                    return {
                        // @ts-ignore
                        ...item,
                        delete: true,
                    };
                }
                return item;
            }),
        };
        updateValues(val);
    };

    const saveChanges = async () => {
        // blockNavigation is only true when there are changes
        // If true, update accordingly, else do nothing
        if (blockNavigation) {
            const projectObject = {
                [type]: values[type],
            };
            // We need to pass the original owner id
            // @ts-ignore
            projectObject.owner_id = project?.owner_id;
            setStoredValues({
                ...values,
            });
            dispatch(
                updateProject(
                    userAuthenData?._id,
                    project?._id,
                    projectObject,
                ),
            );
        }
    };

    // Action when opening a card
    const cardButtonAction = (item: ModifiedDetailedImage) => () => {
        setValues({
            ...values,
            // @ts-ignore
            item,
        });
        // Create and Image object to show in the update popup
        const img = new Image();
        setImage(img); // Empty image first
        // If newly uploaded image convert image file to uri
        if (typeof (item?.image) === 'object') {
            let fileContent = null;
            reader.onload = () => {
                fileContent = reader.result;
                const img = new Image();
                // Set the image after a size has been found
                img.onload = () => {
                    setImage(img);
                };
                // @ts-ignore
                img.src = fileContent;
            };
            reader.readAsDataURL(item?.image);
        } else {
            img.src = item?.image;
            setImage(img);
        }
        // Set values from item to be used in dialog
        setDescription(item?.description || '');
        setStartPoints(item?.annotations?.startPoints || []);
        setMidPoints(item?.annotations?.midPoints || []);
        setEndpoints(item?.annotations?.endPoints || []);
        setAnnotationInfo(item?.annotations?.annotationInfo || []);
        setOpenUpdate(true);
    };

    // Action performed when closing form dialog
    const cancelAction = {
        text: 'Cancel',
        action: () => {
            // reset imageURL after cancel
            setValues({ ...values, imageURL: null, lastFile: null, item: null });
            if (openUpload) {
                setOpenUpload(false);
            } else {
                setOpenUpdate(false);
            }
        },
    };

    // Action performed when uploading new image on the form dialog
    const uploadFileAction = () => {
        // @ts-ignore
        setFiles([...files, values?.lastFile]);
        const val = {
            [type]: [
                // @ts-ignore
                ...values[type],
                {
                    image: values.lastFile,
                    description,
                    uploadedImage: values.imageURL,
                    annotations: {
                        startPoints,
                        midPoints,
                        endPoints,
                        annotationInfo,
                    },
                },
            ],
            imageURL: null,
            lastFile: null, // reset after upload
        };
        updateValues(val);
        setOpenUpload(false);
    };

    // Action used when updating a file in the form dialog
    const updateFileAction = () => {
        const valuesCopy = values[type]?.slice();
        const itemIndex = valuesCopy?.findIndex((i) => i === values.item);

        // Copy new values
        // @ts-ignore
        valuesCopy[itemIndex] = {
            // @ts-ignore
            ...valuesCopy[itemIndex],
            description,
            annotations: {
                startPoints,
                midPoints,
                endPoints,
                annotationInfo,
            },
        };

        updateValues({
            ...values,
            [type]: valuesCopy,
            item: null,
        });
        setOpenUpdate(false);
    };

    const dialogActions = [
        cancelAction,
        {
            text: openUpload ? 'Upload' : 'Save',
            action: openUpload ? uploadFileAction : updateFileAction,
            style: { backgroundColor: THEME_COLORS.darkPurple, color: 'white' },
        },
    ];

    const dropzoneIcon = () => (
        <ImageIcon style={{ fontSize: '4em', color: THEME_COLORS.darkPurple }} />
    );

    const uploadErrorNotify = (files: File[]) => {
        if (files.length > 0 && files[0].size > MAX_IMAGE_SIZE_BYTES) {
            enqueueSnackbar(`File size is larger than ${MAX_IMAGE_SIZE_BYTES / 100000} MB`, { variant: 'error' });
            return;
        }
        enqueueSnackbar('Failed to upload file. Please try again later.', { variant: 'error' });
    };

    /** Will hold Dropzone Area */
    // TODO - Fix memory leak caused by this (Check console for error)
    const uploadImageSection = (
        <>
            { !premiumBlock && (
                <form className={styles.form} noValidate autoComplete='off'>
                    <section className={styles.section}>
                        <Typography className={styles.subtitle}>{`Upload ${type}`}</Typography>
                        <div
                            style={{
                                backgroundColor: THEME_COLORS.teal,
                                backgroundImage: 'none',
                                borderRadius: '10px',
                            }}
                        >
                            <DropzoneArea
                                initialFiles={files}
                                acceptedFiles={['image/jpeg', 'image/png', 'image/heic']}
                                filesLimit={1}
                                showPreviewsInDropzone={false}
                                dropzoneText='Drag and drop an image here or click'
                                // @ts-ignore
                                Icon={dropzoneIcon}
                                onDrop={handleFileUpload}
                                clearOnUnmount
                                showAlerts={false}
                                onDropRejected={uploadErrorNotify}
                            />
                        </div>
                    </section>
                </form>
            )}
        </>
    );

    /** Will display stored images and currently uploaded images (similar to categories in General) */
    const imagesSection = (
        <section className={styles.section}>
            <Typography className={styles.subtitle}>{`${type}`}</Typography>
            {
                !values?.[type]?.length
                    ? <SectionPlaceholder section={type} />
                    : (
                        <div className={styles.section_images}>
                            {
                                values?.[type]?.map(
                                    (item: ModifiedDetailedImage, index) => {
                                        if (item.delete) {
                                            return null;
                                        }
                                        return (
                                        // eslint-disable-next-line react/no-array-index-key
                                            <div key={index} className={styles.remove_button_wrap}>
                                                <ComponentCard
                                                    onClick={cardButtonAction(item)}
                                                    data={{ image: item.uploadedImage || item.image, description: item.description }}
                                                />
                                                <IconButton
                                                    className={styles.remove_button}
                                                    onClick={() => handleImageDelete(item)}
                                                >
                                                    <RemoveCircleIcon className={styles.remove_image_icon} />
                                                </IconButton>
                                            </div>
                                        );
                                    },
                                )
                            }
                        </div>
                    )
            }
        </section>
    );

    const dialogTextFieldProps = {
        labelProps: {
            style: {
                fontSize: '1.4rem',
            },
        },
        inputProps: {
            style: {
                fontSize: '1.4rem',
            },
        },
    };

    // Dialog that appears to upload or update image
    const imageForm = (
        <FormDialog
            actions={dialogActions}
            open={openUpdate || openUpload}
            onCancel={openUpdate ? () => setOpenUpdate(false) : () => setOpenUpload(false)}
        >
            <TextField
                InputLabelProps={dialogTextFieldProps.labelProps}
                inputProps={dialogTextFieldProps.inputProps}
                autoFocus
                margin='dense'
                id='name'
                label='Description'
                type='text'
                defaultValue={description}
                onBlur={(e) => setDescription(e.target.value)}
                fullWidth
            />
            <Bezier
                viewBoxWidth={image?.width || 1}
                viewBoxHeight={image?.height || 1}
                styleWidth={imageWidth || 1}
                styleHeight={imageHeight || 1}
                background={image?.src || ''}
                onStartPoints={setStartPoints}
                onMidPoints={setMidPoints}
                onEndPoints={setEndpoints}
                originalStart={startPoints}
                originalMid={midPoints}
                originalEnd={endPoints}
                notifyCreate={notifyCreate}
                notifyDelete={notifyDelete}
                notifySelect={notifySelect}
                editable={!isMobile}
                annotations={annotationInfo}
                editorTier={useTier}
                toDelete={toDelete}
            />
            {
                annotationInfo.map((a, index) => (
                    <div style={{ display: 'flex', flexWrap: 'nowrap', alignItems: 'center' }}>
                        <TextField
                            InputLabelProps={dialogTextFieldProps.labelProps}
                            inputProps={dialogTextFieldProps.inputProps}
                            autoFocus
                            margin='dense'
                            id={`Annotation ${index + 1}`}
                            label={`Annotation ${index + 1}`}
                            type='text'
                            defaultValue={a}
                            value={annotationInfo[index]}
                            focused={selectedAnnotation === index}
                            key={`Annotation ${index + 1}`}
                            onBlur={(e) => exitAnnotation(e.target.value, index)}
                            onChange={(e) => handleAnnotationTextChange(e, index)}
                            fullWidth
                            style={{
                                display: 'block',
                            }}
                        />
                        <IconButton onClick={() => setToDelete({ index, seed: Math.random() })}>
                            <DeleteIcon style={{ fontSize: '1.4em', color: 'red' }} />
                        </IconButton>
                    </div>
                ))
            }
            {
                isMobile && (
                    <p>Editing annotations is currently only available on the desktop version of Chiqpack, but you can continue to rename them on mobile.</p>
                )
            }
        </FormDialog>
    );

    // @ts-ignore
    const maxAlert = premiumBlock && (
        <PremiumAlert
            text='You have reached the maximum amount of allowed images for this section.'
            width='fit-content'
        />
    );

    return (
        <div className={styles.container}>
            <MuiThemeProvider theme={theme}>
                <>
                    <Prompt
                        when={blockNavigation}
                        message='You have unsaved changes, are you sure you want to leave?'
                    />
                </>
                {imageForm}
                {
                    updateProjectPending
                        ? (
                            <Backdrop open>
                                <CircularProgress />
                            </Backdrop>
                        )
                        : null
                }
                {uploadImageSection}
                {imagesSection}
                <SolidButton
                    disabled={updateProjectPending || !blockNavigation}
                    bgColor={THEME_COLORS.lightPurple}
                    textColor='white'
                    onClick={saveChanges}
                >
                Save Changes
                </SolidButton>
            </MuiThemeProvider>
            <br />
            {maxAlert}
        </div>
    );
};

export default ImagesPanel;
