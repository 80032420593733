import React from 'react';
import PropTypes from 'prop-types';

const BlockNavigationContext = React.createContext();

class BlockNavigationProvider extends React.Component {
    // context state
    constructor(props) {
        super(props);
        this.state = {
            blockNavigation: false,
        };
    }

    setBlockNavigation = (blockNav) => {
        this.setState((state) => ({ blockNavigation: blockNav }));
    }

    render() {
        const { children } = this.props;
        const { blockNavigation } = this.state;
        const { setBlockNavigation } = this;

        return (
            <BlockNavigationContext.Provider
                value={{
                    blockNavigation,
                    setBlockNavigation,
                }}
            >
                {children}
            </BlockNavigationContext.Provider>
        );
    }
}

BlockNavigationProvider.propTypes = {
    children: PropTypes.any.isRequired,
};

const BlockNavigationConsumer = BlockNavigationContext.Consumer;

export default BlockNavigationContext;

export { BlockNavigationProvider, BlockNavigationConsumer };
