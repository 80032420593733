import { AppAction } from 'common/configStore';
import { AnyAction } from 'redux';
import {
    VERIFY_EMAIL_BEGIN,
    VERIFY_EMAIL_DISMISS_ERROR,
    VERIFY_EMAIL_FAILURE,
    VERIFY_EMAIL_SUCCESS,
} from 'redux/common/constants';

export const verifyEmail = (userID: string, email: string): AppAction => async (dispatch) => {

    dispatch({
        type: VERIFY_EMAIL_BEGIN,
    });

    const query = `
       mutation {
          verifyEmail(
            userID: "${userID}"
            email: "${email}"
          )
        } 
    `;

    const opts: RequestInit = {
        method: 'POST',
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify({ query }),
    };

    return fetch(`${process.env.REACT_APP_GRAPHQL}`, opts)
        .then((response) => response.json())
        .then((createdJson) => {
            if (!createdJson?.errors && createdJson?.data?.verifyEmail) {
                dispatch({
                    type: VERIFY_EMAIL_SUCCESS,
                });
            } else {
                dispatch({
                    type: VERIFY_EMAIL_FAILURE,
                });
            }
        })
        .catch((errors) => {
            dispatch({
                type: VERIFY_EMAIL_FAILURE,
                errors,
            });
        });
};

// Async action saves request error by default, this method is used to dismiss the error info.
// If you don't want errors to be saved in Redux store, just ignore this method.
export const dismissVerifyEmailError = () => ({
    type: VERIFY_EMAIL_DISMISS_ERROR,
});

export const reducer = (state: any, action: AnyAction) => {
    switch (action.type) {
    case VERIFY_EMAIL_BEGIN:
        // Just after a request is sent
        return {
            ...state,
            verifyEmailPending: true,
            verifyEmailError: false,
            emailVerified: false,
        };

    case VERIFY_EMAIL_SUCCESS:
        // The request is success
        return {
            ...state,
            verifyEmailPending: false,
            verifyEmailError: false,
            emailVerified: true,
        };

    case VERIFY_EMAIL_FAILURE:
        // The request is failed
        return {
            ...state,
            verifyEmailError: true,
            verifyEmailPending: false,
            emailVerified: false,
        };

    case VERIFY_EMAIL_DISMISS_ERROR:
        // Dismiss the request failure error
        return {
            ...state,
            verifyEmailError: false,
            emailVerified: false,
        };

    default:
        return state;
    }
};
