import { logPageView } from 'common/analytics/firebaseEvents';
import React, { useEffect, useState, useCallback, KeyboardEvent } from 'react';
import { useSelector, useDispatch } from 'common/hooks';
import { shallowEqual } from 'react-redux';
import { InputBase } from '@material-ui/core';
import { DefaultTheme } from '@material-ui/styles';
import { Alert, Pagination } from '@material-ui/lab';
import SearchIcon from '@material-ui/icons/Search';
import { useLocation } from 'react-router-dom';
import { createMuiTheme, MuiThemeProvider } from '@material-ui/core/styles';
import { dismissSearchError, search } from 'redux/actions';
import ProjectCard from 'components/ProjectCard';
import { Loading } from 'components/Loading';
import editStyles from 'styles/editProject.module.css';
import navStyles from 'styles/navbar.module.css';
import withHelmet from 'components/hocs/withHelmet';
import { PAGE_NAMES } from 'constants/metaData';
import styles from 'styles/search.module.css';

interface SearchState {
    searchInput?: string;
}

const Search = () => {
    const dispatch = useDispatch();
    const location = useLocation<SearchState>();
    const { state } = location;
    const {
        searchError,
        searchPending,
        searchResults,
        userAuthenData,
    } = useSelector((state) => ({
        searchResults: state.common.searchResults,
        searchPending: state.common.searchPending,
        searchError: state.common.searchPending,
        userAuthenData: state.users.userAuthenData,
    }), shallowEqual);

    const [pageNumber, setPageNumber] = useState<number>(0);
    const [searchTerms, setSearchTerms] = useState<string>(state?.searchInput ?? '');
    const shouldShowEmptyResults = !searchPending && !searchResults?.projects?.length;

    const splitTerms = useCallback((terms) => {
        if (!terms || terms === '') {
            return [''];
        }
        return terms.split(' ');
    }, []);

    useEffect(() => {
        dispatch(search(splitTerms(searchTerms), 0, 10));
        logPageView();
    }, []); // eslint-disable-line

    /** https://github.com/Yuvaleros/material-ui-dropzone/blob/master/docs/theming.md */
    const paginationTheme = (defaultTheme: DefaultTheme) => createMuiTheme({
        ...defaultTheme,
        overrides: {
            // @ts-ignore
            MuiPagination: {
                ul: {
                    justifyContent: 'center',
                },
            },
        },
    });

    const paginationChange = (_: any, value: number) => {
        if (value - 1 === pageNumber) {
            return;
        }
        window.scroll(0, 0);
        setPageNumber(value - 1);
        dispatch(search(splitTerms(searchTerms), value - 1, 10));
    };

    const enterKey = (e: KeyboardEvent) => {
        if ((e.key === 'Enter' || e.key === 'Return') && searchTerms.trim().length > 0) {
            dispatch(search(splitTerms(searchTerms), 0, 10));
        }
    };

    const loadingCardsList = [];
    for (let i = 0; i < 10; i += 1) {
        loadingCardsList.push(<ProjectCard key={i} />);
    }
    const loadingCards = searchPending ? loadingCardsList : null;

    const loadingScreen = searchPending ? (<Loading />) : null;

    const errorScreen = searchError ? (
        <Alert
            onClose={() => dispatch(dismissSearchError())}
            className={editStyles.alert}
            severity='error'
        >
            Failed to search.
        </Alert>
    ) : null;

    const projectLoaded = !shouldShowEmptyResults ? searchResults?.projects?.map((p) => (
        <ProjectCard
            projectId={p._id}
            projectName={p.project_name}
            ownerName={p.owner_data?.user_name}
            liked={!userAuthenData ? false : p.liked_by.includes(userAuthenData?._id ?? '')}
            likeCount={p.liked_by.length}
            categories={p.categories}
            projectDescription={p.description}
            featuredImage={p.featured_image}
            ownerImage={p.owner_data.user_image}
            ownerID={p.owner_id}
            key={p._id}
        />
    )) : null;

    return (
        <div>
            <div className={styles.searchBar}>
                <div className={styles.searchIcon}>
                    <SearchIcon />
                </div>
                <InputBase
                    onKeyUp={enterKey}
                    onChange={(e) => setSearchTerms(e.target.value)}
                    placeholder='Search Projects'
                    fullWidth
                    className={navStyles.input_input}
                    inputProps={{ 'aria-label': 'search' }}
                    value={searchTerms}
                />
            </div>
            { shouldShowEmptyResults && (
                <div>
                    <h1 style={{ fontSize: '4rem' }}>:(</h1>
                    <p style={{ fontSize: '1.4rem' }}>No projects matched your query.</p>
                </div>
            )}
            <div className={styles.grid}>
                {loadingScreen}
                {projectLoaded || loadingCards || errorScreen}
            </div>
            <div className={styles.paginationBar}>
                <MuiThemeProvider theme={(t) => paginationTheme(t)}>
                    <Pagination
                        count={searchResults?.pages ?? 1}
                        page={pageNumber + 1}
                        onChange={paginationChange}
                        color='primary'
                    />
                </MuiThemeProvider>
            </div>
        </div>
    );
};

Search.displayName = PAGE_NAMES.SEARCH;

export default withHelmet(Search);
