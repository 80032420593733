import React, { useCallback } from 'react';
import { Link } from 'react-router-dom';
import {
    Card,
    CardActions,
    Avatar,
    Typography,
} from '@material-ui/core';
import { LikeButton } from 'components/LikeButton';
import CategoryTag from 'components/CategoryTag';
import ShareButton from 'components/buttons/ShareButton';
import logo from 'logo2_500.png';
import premiumIcon from 'assets/all-inclusive.svg';
import styles from 'styles/projectCardLarge.module.css';
import projectCardStyles from 'styles/projectCard.module.css';

interface Props {
    categories?: Array<string>;
    projectId?: string;
    projectName?: string;
    projectDescription?: string;
    projectStats?: Array<any>; // TODO: type properly
    ownerName?: string;
    ownerImage?: string;
    ownerPremium?: boolean;
    likeCount?: number;
    liked?: boolean;
    featuredImage?: any; // TODO: type properly
    // eslint-disable-next-line no-restricted-globals
    handleLike: (event: any, likeVal: boolean) => void;
}

const ProjectCardLarge = (props: Props) => {
    const {
        categories,
        projectId,
        projectName,
        projectDescription,
        projectStats,
        ownerName,
        ownerImage,
        ownerPremium,
        likeCount,
        liked,
        handleLike,
        featuredImage,
    } = props;

    const renderCategories = useCallback(() => {
        let categoriesToShow = categories;
        const showMoreText = categories && categories?.length > 3;
        if (showMoreText) {
            categoriesToShow = categories?.slice(0, 8);
        }
        return !categoriesToShow ? null : (
            <>
                {categoriesToShow.map(
                    (item) => <CategoryTag category={item} displayLong key={item} />,
                )}
                {showMoreText && (
                    <Link to={`/${ownerName}/${projectId}`} className={projectCardStyles.section_category_more}>+</Link>
                )}
            </>
        );
    }, [categories]);

    const renderStats = useCallback(() => {
        const statType = ['Sketches', 'Materials', 'Measurements', 'Outcome'];
        return (
            projectStats?.map((item, idx) => (
                <div className={styles.stat} key={statType[idx]}>
                    <Typography className={styles.stat_type}>{statType[idx]}</Typography>
                    <Typography className={styles.stat_value}>{item}</Typography>
                </div>
            ))
        );
    }, [projectStats]);

    return (
        <Card className={styles.card}>
            <div className={styles.card_content}>
                <div className={styles.panel_left}>
                    <Link
                        to={`/${ownerName}/${projectId}`}
                        className={styles.featured_image_container}
                        style={{
                            backgroundImage: `url(${featuredImage || logo})`,
                            backgroundSize: 'cover',
                            backgroundPosition: 'center',
                        }}
                    />
                    <div className={styles.section_author}>
                        <div className={styles.avatar_container}>
                            <Link to={`/profile/${ownerName}`} className={styles.card_link}>
                                <Avatar
                                    src={ownerImage}
                                    className={styles.author_avatar}
                                />
                            </Link>
                        </div>
                        <div>
                            <Link to={`/profile/${ownerName}`} className={styles.card_link}>
                                <div className={styles.author_inner_content}>
                                    <div className={styles.author_name}>{ownerName || ''}</div>
                                    { ownerPremium && <img src={premiumIcon} alt='Premium Icon' /> }
                                </div>
                            </Link>
                        </div>
                    </div>
                </div>
                <div className={styles.panel_right}>
                    <div className={styles.heading}>
                        <div className={styles.project_name_like_wrapper}>
                            <div className={styles.project_name}>
                                <Link to={`/${ownerName}/${projectId}`} className={styles.card_link}>
                                    <h1>{projectName || ''}</h1>
                                </Link>
                            </div>
                            <CardActions className={styles.card_actions} disableSpacing>
                                {
                                    !projectName
                                        ? null
                                        : (
                                            <LikeButton
                                                liked={liked || false}
                                                likeCount={likeCount || 0}
                                                likeAction={handleLike}
                                            />
                                        )
                                }
                                <ShareButton
                                    projectName={projectName}
                                    projectId={projectId}
                                    projectDescription={projectDescription}
                                    ownerName={ownerName}
                                    className={styles.action_button}
                                    iconClass={styles.action_button_icon}
                                />
                            </CardActions>
                        </div>
                        <div className={projectCardStyles.section_category}>
                            {renderCategories()}
                        </div>
                    </div>
                    <div className={styles.description}>
                        {projectDescription}
                    </div>
                    <div style={{ flexGrow: 1 }} />
                    <div className={styles.stats}>
                        {renderStats()}
                    </div>
                </div>
            </div>
        </Card>
    );
};

ProjectCardLarge.defaultProps = {
    categories: [],
    projectId: undefined,
    projectName: undefined,
    projectDescription: undefined,
    ownerName: undefined,
    ownerImage: undefined,
    ownerPremium: undefined,
    liked: false,
    likeCount: 0,
    featuredImage: null,
    projectStats: [],
};

export default ProjectCardLarge;
