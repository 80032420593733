import React from 'react';
import PropTypes from 'prop-types';
import styles from '../styles/iconCard.module.css';

const IconCard = ({ config }) => (
    <div className={styles.card}>
        <div className={styles.top_section}>
            {config.icon}
        </div>
        <div className={styles.bottom_section}>
            <h3 className={styles.card_title}>{config.title}</h3>
            <p>{config.description}</p>
        </div>
    </div>
);

IconCard.propTypes = {
    config: PropTypes.object,
};

IconCard.defaultProps = {
    config: {
        icon: null,
        title: '',
        description: '',
    },
};

export default IconCard;
