import { AnyAction } from 'redux';
import { initialState } from 'redux/common/initialState';
import { reducer as updateLikeStatusReducer } from 'redux/common/updateLikeStatus';
import { reducer as contactSendReducer } from 'redux/common/contactSend';
import { reducer as verifyEmailReducer } from 'redux/common/verifyEmail';
import { reducer as searchReducer } from 'redux/common/search';
import { reducer as forgotPasswordReducer } from 'redux/common/forgotPassword';

const reducers = [
    updateLikeStatusReducer,
    contactSendReducer,
    verifyEmailReducer,
    searchReducer,
    forgotPasswordReducer,
];

export const reducer = (state = initialState, action: AnyAction) => {
    let newState;
    switch (action.type) {
    // Handle cross-topic actions here
    default:
        newState = state;
        break;
    }
    return reducers.reduce((s, r) => r(s, action), newState);
};
