import { logPageView } from 'common/analytics/firebaseEvents';
import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'common/hooks';
import {
    Container,
    Grid,
    TextField,
    Button,
} from '@material-ui/core';
import { Alert } from '@material-ui/lab';
import { forgotPassword, dismissForgotPasswordError } from 'redux/actions';
import { Loading } from 'components/Loading';
import withHelmet from 'components/hocs/withHelmet';
import { PAGE_NAMES } from 'constants/metaData';
import logo from 'logo2_500.png';
import styles from 'styles/login.module.css';

const ForgotPassword = () => {
    const dispatch = useDispatch();
    const [email, setEmail] = useState('');

    const {
        forgotPasswordPending,
        forgotPasswordError,
        forgotPasswordEmailSent,
    } = useSelector((state) => ({
        forgotPasswordPending: state.common.forgotPasswordPending,
        forgotPasswordError: state.common.forgotPasswordError,
        forgotPasswordEmailSent: state.common.forgotPasswordEmailSent,
    }));

    useEffect(() => {
        dispatch(dismissForgotPasswordError());
        logPageView();
    }, []); // eslint-disable-line

    const loading = forgotPasswordPending ? (<Loading />) : null;

    const emailError = !forgotPasswordError ? null : (
        <Alert
            severity='error'
            onClose={() => { dispatch(dismissForgotPasswordError()); }}
            className={styles.login_alert}
        >
            Failed to send password reset email. Please try again later.
        </Alert>
    );

    const resetSent = forgotPasswordEmailSent ? (<h1>Please check your email to reset your password.</h1>) : null;

    const mainPage = (
        <div className={styles.outer_container}>
            <div className={styles.logo_wrapper}>
                <img src={logo} alt='Chiqpack Logo' className={styles.logo} />
                <h1 className={styles.logo_header}>Enter your email to reset your password.</h1>
            </div>
            <Container className={styles.form_container} maxWidth={false}>
                <form>
                    <Grid container spacing={3}>
                        <Grid item xs={12}>
                            <Grid container spacing={2}>
                                <Grid item xs={12}>
                                    <TextField
                                        fullWidth
                                        label='Email'
                                        name='email'
                                        size='small'
                                        variant='outlined'
                                        value={email}
                                        onChange={
                                            (event) => (setEmail(event.target.value))
                                        }
                                    />
                                </Grid>
                            </Grid>
                        </Grid>
                        <Grid item xs={12}>
                            <Button
                                disabled={email === ''}
                                color='inherit'
                                fullWidth
                                variant='contained'
                                className={styles.login_button}
                                onClick={() => dispatch(forgotPassword(email))}
                            >
                                Reset Password
                            </Button>
                        </Grid>
                    </Grid>
                </form>
            </Container>
        </div>
    );

    return (
        <div>
            {loading}
            {emailError}
            {resetSent || mainPage}
        </div>
    );
};

ForgotPassword.displayName = PAGE_NAMES.FORGOT_PASSWORD;

export default withHelmet(ForgotPassword);
