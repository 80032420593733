import Stripe from 'stripe';
import { User, UserTiers } from 'redux/users/models/User';

export interface UserSubscription extends Stripe.Subscription {
    userTier: UserTiers;
}

export interface ReduxUserState {
    userAuthenPending: boolean;
    userAuthenError: any;
    userAuthenData: Partial<User> | null;
    authenticated: boolean;

    // getUser
    getUserError: any;
    getUserPending: boolean;
    getUserData: Partial<User> | null;

    // createUser
    createUserPending: boolean;
    createUserError: any;
    userCreated: boolean;

    // userTokenRefresh
    userTokenRefreshPending: boolean;
    userTokenRefreshError: any;

    // getUsers
    getUsersPending: boolean;
    getUsersError: any;
    getUsersData: Partial<User>[] | null;

    // searchUsers
    searchUsersPending: boolean;
    searchUsersError: any;
    searchUsersData: Partial<User>[] | null;

    // updateUser
    updateUserPending: boolean;
    updateUserError: any;
    userUpdated: boolean;

    // resetPassword
    resetPasswordPending: boolean;
    resetPasswordError: any;
    passwordReset: boolean;

    // reactivatePremium
    reactivatePremiumPending: boolean;
    reactivatePremiumError: any;
    reactivatedPremium: boolean;

    // premiumCheckout
    premiumCheckoutPending: boolean;
    premiumCheckoutError: any;
    premiumBought: false;

    // cancelPremium
    cancelPremiumPending: boolean;
    cancelPremiumError: any;
    premiumCancelled: boolean;

    // checkSubscription
    checkSubscriptionPending: boolean;
    checkSubscriptionError: any;
    userSubscription: Partial<UserSubscription> | null;

    // updateSubscription
    updateSubscriptionPending: boolean,
    updateSubscriptionError: any,
    subscriptionUpdated: boolean,
}

export const initialState: ReduxUserState = {
    // userAuthen
    userAuthenPending: false,
    userAuthenError: null,
    userAuthenData: null,
    authenticated: false,

    // getUser
    getUserError: null,
    getUserPending: false,
    getUserData: null,

    // createUser
    createUserPending: false,
    createUserError: null,
    userCreated: false,

    // userTokenRefresh
    userTokenRefreshPending: true, // This is true initially so that nothing is shown until the action executes
    userTokenRefreshError: null,

    // getUsers
    getUsersPending: false,
    getUsersError: null,
    getUsersData: null,

    // searchUsers
    searchUsersPending: false,
    searchUsersError: null,
    searchUsersData: null,

    // updateUser
    updateUserPending: false,
    updateUserError: null,
    userUpdated: false,

    // resetPassword
    resetPasswordPending: false,
    resetPasswordError: null,
    passwordReset: false,

    // reactivatePremium
    reactivatePremiumPending: false,
    reactivatePremiumError: null,
    reactivatedPremium: false,

    // premiumCheckout
    premiumCheckoutPending: false,
    premiumCheckoutError: null,
    premiumBought: false,

    // cancelPremium
    cancelPremiumPending: false,
    cancelPremiumError: null,
    premiumCancelled: false,

    // checkSubscription
    checkSubscriptionPending: false,
    checkSubscriptionError: null,
    userSubscription: null,

    // updateSubscription
    updateSubscriptionPending: false,
    updateSubscriptionError: null,
    subscriptionUpdated: false,
};
