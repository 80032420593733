import React, { CSSProperties, KeyboardEvent, MouseEvent, useEffect, useState } from 'react';
import { Link, useHistory, useLocation } from 'react-router-dom';
import { useDispatch, useSelector } from 'common/hooks';
import { shallowEqual } from 'react-redux';
import { AppBar, Avatar, Button, IconButton, InputBase, Menu, MenuItem, Toolbar, Typography } from '@material-ui/core';
import SearchIcon from '@material-ui/icons/Search';
import MenuIcon from '@material-ui/icons/Menu';
import AccountCircle from '@material-ui/icons/AccountCircle';
import StarsIcon from '@material-ui/icons/Stars';
import ContactSupportIcon from '@material-ui/icons/ContactSupport';
import AddIcon from '@material-ui/icons/Add';
import AddCircleIcon from '@material-ui/icons/AddCircle';
import SettingsIcon from '@material-ui/icons/Settings';
import ExitToAppIcon from '@material-ui/icons/ExitToApp';
import PowerSettingsNewIcon from '@material-ui/icons/PowerSettingsNew';
import LaunchIcon from '@material-ui/icons/Launch';
import NewProjectModal from 'components/NewProjectModal';
import AccountSettingsModal from 'components/modals/AccountSettingsModal';
import premiumIcon from 'assets/all-inclusive.svg';
import { signOut } from 'redux/actions';
import { Loading } from 'components/Loading';
import styles from 'styles/navbar.module.css';
import logo from 'logo2_500.png';
import logoAlt from 'logo_500.png';
import THEME_COLORS from 'utils/themeColors';
import { UserTiers } from 'redux/users/models/User';
import { isPremium } from 'common/tierChecks';

const Navbar = () => {
    const dispatch = useDispatch();
    const [anchorEl, setAnchorEl] = useState<(EventTarget & Element) | null>(null);
    const [mobileMoreAnchorEl, setMobileMoreAnchorEl] = useState<(EventTarget & Element) | null>(null);
    const [searchTerms, setSearchTerms] = useState('');
    const [shouldRedir, setShouldRedir] = useState(false);
    const [modalOpen, setModalOpen] = useState(false);
    const [accountSettingsModalOpen, setAccountSettingsModalOpen] = useState(false);

    const isMenuOpen = Boolean(anchorEl);
    const isMobileMenuOpen = Boolean(mobileMoreAnchorEl);

    const {
        authenticated,
        userAuthenData,
        userSubscription,
        projectCreated,
        createProjectPending,
    } = useSelector((state) => ({
        authenticated: state.users.authenticated,
        userAuthenData: state.users.userAuthenData,
        userSubscription: state.users.userSubscription,
        projectCreated: state.projects.projectCreated,
        createProjectPending: state.projects.createProjectPending,
    }), shallowEqual);

    const history = useHistory();
    const location = useLocation();
    // use alt navbar style for Home page only when not authenticated
    const isAlt = !authenticated && location.pathname === '/';
    // Hide search when on search page
    const onSearch = location.pathname === '/search';

    useEffect(() => {
        if (projectCreated && modalOpen) {
            setModalOpen(false);
        }
    }, [projectCreated]); // eslint-disable-line

    useEffect(() => {
        if (shouldRedir) {
            setShouldRedir(false);
        }
    }, [authenticated]); // eslint-disable-line

    const handleProfileMenuOpen = (event: MouseEvent) => {
        setAnchorEl(event.currentTarget);
    };

    const handleMobileMenuClose = () => {
        setMobileMoreAnchorEl(null);
    };

    const handleMenuClose = () => {
        setAnchorEl(null);
        handleMobileMenuClose();
    };

    const handleMobileMenuOpen = (event: MouseEvent) => {
        setMobileMoreAnchorEl(event.currentTarget);
    };

    const openAccountSettings = () => {
        handleMenuClose();
        setAccountSettingsModalOpen(true);
    };

    const signOutUser = () => {
        handleMenuClose();
        dispatch(signOut()).then(() => history.push('/login'));
    };

    const searchKeyPress = (e: KeyboardEvent) => {
        if ((e.key === 'Enter' || e.key === 'Return') && searchTerms.trim().length > 0) {
            history.push('/search', {
                searchInput: searchTerms,
            });
        }
    };

    /* General styling (includes misc) */
    const appbarStyle: CSSProperties = {
        backgroundColor: isAlt ? 'white' : THEME_COLORS.salmon,
        boxShadow: isAlt ? 'none' : '1',
    };

    const rootLinkColorStyle: CSSProperties = {
        color: isAlt ? THEME_COLORS.salmon : 'whitesmoke',
    };

    const linkColorStyle: CSSProperties = {
        color: isAlt ? '#626262' : 'whitesmoke',
        // @ts-ignore
        fontWeight: '500',
    };

    const menuIconColor = 'default'; // TODO: get this working

    const menuId = 'primary-search-account-menu';

    const profileLink = !userAuthenData ? '/' : `/profile/${userAuthenData.user_name}`;

    const loadingBackdrop = createProjectPending ? (<Loading />) : null;

    const renderAuthenOptions = authenticated ? (
        <div className={styles.section_desktop}>
            <IconButton
                className={styles.menu_button}
                aria-label='create project'
                color='inherit'
                onClick={() => setModalOpen(true)}
            >
                <AddIcon className={styles.menu_button_icon} />
            </IconButton>
            <IconButton
                edge='end'
                aria-label='account of current user'
                aria-controls={menuId}
                aria-haspopup='true'
                onClick={handleProfileMenuOpen}
                color='inherit'
            >
                <Avatar src={userAuthenData?.user_image} className={styles.menu_button_icon} />
            </IconButton>
            <div style={{ width: '0.5rem' }} />
            { isPremium(userSubscription?.userTier || UserTiers.FREE) && <img src={premiumIcon} className={styles.premiumIndicator} alt='Premium Icon' /> }
        </div>
    )
        : (
            <div className={styles.section_desktop}>
                <Button className={styles.sign_in_button}>
                    <Link to='/login' className={styles.sign_in_link}>Sign In</Link>
                </Button>
                <Button
                    className={styles.sign_up_button}
                    variant='outlined'
                >
                    <Link to='/join' className={styles.sign_in_link}>Sign Up</Link>
                </Button>
            </div>
        );

    const renderMenu = (
        <Menu
            anchorEl={anchorEl}
            anchorOrigin={{ vertical: 'top', horizontal: 'right' }}
            id={menuId}
            keepMounted
            transformOrigin={{ vertical: 'top', horizontal: 'right' }}
            open={isMenuOpen}
            onClose={handleMenuClose}
        >
            <Link to={profileLink} className={styles.no_style}>
                <MenuItem onClick={handleMenuClose}>Profile</MenuItem>
            </Link>
            <MenuItem onClick={openAccountSettings}>Settings</MenuItem>
            <MenuItem onClick={signOutUser}>Sign Out</MenuItem>
        </Menu>
    );

    const mobileMenuId = 'primary-search-account-menu-mobile';
    const renderMobileMenu = (
        <Menu
            anchorEl={mobileMoreAnchorEl}
            anchorOrigin={{ vertical: 'top', horizontal: 'right' }}
            id={mobileMenuId}
            keepMounted
            transformOrigin={{ vertical: 'top', horizontal: 'right' }}
            open={isMobileMenuOpen}
            onClose={handleMobileMenuClose}
        >
            {
                authenticated
                    ? (
                        <div>
                            <MenuItem
                                className={styles.primary_button}
                                onClick={() => setModalOpen(true)}
                            >
                                <IconButton
                                    aria-label='new project'
                                    aria-controls='primary-search-account-menu'
                                    aria-haspopup='false'
                                    color={menuIconColor}
                                >
                                    <AddCircleIcon />
                                </IconButton>
                                <p>New Project</p>
                            </MenuItem>

                            <Link
                                to={profileLink}
                                className={styles.no_style}
                            >
                                <MenuItem onClick={handleMenuClose}>
                                    <IconButton
                                        aria-label='account of current user'
                                        aria-controls='primary-search-account-menu'
                                        aria-haspopup='true'
                                        color={menuIconColor}
                                    >
                                        <AccountCircle />
                                    </IconButton>
                                    <p>Profile</p>
                                    { isPremium(userSubscription?.userTier || UserTiers.FREE) && <img src={premiumIcon} className={styles.premiumIndicator} alt='Premium Icon' /> }
                                </MenuItem>
                            </Link>
                            <Link
                                to='/showcase'
                                className={styles.no_style}
                            >
                                <MenuItem onClick={handleMenuClose}>
                                    <IconButton
                                        aria-label='showcase'
                                        aria-controls='primary-search-account-menu'
                                        aria-haspopup='false'
                                        color={menuIconColor}
                                    >
                                        <StarsIcon />
                                    </IconButton>
                                    <p>Showcase</p>
                                </MenuItem>
                            </Link>
                            <Link
                                to='/contact'
                                className={styles.no_style}
                            >
                                <MenuItem onClick={handleMenuClose}>
                                    <IconButton
                                        aria-label='contact'
                                        aria-controls='primary-search-account-menu'
                                        aria-haspopup='false'
                                        color={menuIconColor}
                                    >
                                        <ContactSupportIcon />
                                    </IconButton>
                                    <p>Contact</p>
                                </MenuItem>
                            </Link>
                            <MenuItem onClick={openAccountSettings}>
                                <IconButton
                                    aria-label='account settings'
                                    aria-controls='primary-search-account-menu'
                                    aria-haspopup='false'
                                    color={menuIconColor}
                                >
                                    <SettingsIcon />
                                </IconButton>
                                <p>Settings</p>
                            </MenuItem>
                            <MenuItem onClick={signOutUser}>
                                <IconButton
                                    aria-label='sign out'
                                    aria-controls='primary-search-account-menu'
                                    aria-haspopup='false'
                                    color='inherit'
                                >
                                    <PowerSettingsNewIcon />
                                </IconButton>
                                <p style={{ color: 'black' }}>Sign Out</p>
                            </MenuItem>
                        </div>
                    )
                    : (
                        <div>
                            <Link
                                to='/showcase'
                                className={styles.no_style}
                            >
                                <MenuItem onClick={handleMenuClose}>
                                    <IconButton
                                        aria-label='showcase'
                                        aria-controls='primary-search-account-menu'
                                        aria-haspopup='false'
                                        color={menuIconColor}
                                    >
                                        <StarsIcon />
                                    </IconButton>
                                    <p>Showcase</p>
                                </MenuItem>
                            </Link>
                            <Link
                                to='/contact'
                                className={styles.no_style}
                            >
                                <MenuItem onClick={handleMenuClose}>
                                    <IconButton
                                        aria-label='contact'
                                        aria-controls='primary-search-account-menu'
                                        aria-haspopup='false'
                                        color={menuIconColor}
                                    >
                                        <ContactSupportIcon />
                                    </IconButton>
                                    <p>Contact</p>
                                </MenuItem>
                            </Link>
                            <Link
                                to='/login'
                                className={styles.no_style}
                            >
                                <MenuItem onClick={handleMenuClose}>
                                    <IconButton
                                        aria-label='sign in'
                                        aria-controls='primary-search-account-menu'
                                        aria-haspopup='false'
                                        color={menuIconColor}
                                    >
                                        <ExitToAppIcon />
                                    </IconButton>
                                    <p>Sign in</p>
                                </MenuItem>
                            </Link>
                            <Link
                                to='/join'
                                className={styles.no_style}
                            >
                                <MenuItem onClick={handleMenuClose} className={styles.primary_button}>
                                    <IconButton
                                        aria-label='sign up'
                                        aria-controls='primary-search-account-menu'
                                        aria-haspopup='false'
                                        color='inherit'
                                    >
                                        <LaunchIcon />
                                    </IconButton>
                                    <p style={{ color: 'white' }}>Sign up</p>
                                </MenuItem>
                            </Link>
                        </div>
                    )
            }
        </Menu>
    );

    const searchBar = onSearch ? (
        <div className={styles.spacer} />
    ) : (
        <div className={styles.search}>
            <div className={styles.search_icon}>
                <SearchIcon />
            </div>
            <InputBase
                onChange={(e) => setSearchTerms(e.target.value)}
                onKeyUp={searchKeyPress}
                placeholder='Search'
                fullWidth
                className={styles.input_input}
                inputProps={{ 'aria-label': 'search' }}
                value={searchTerms}
            />
        </div>
    );

    return (
        <div className={styles.grow}>
            {loadingBackdrop}
            <AppBar style={appbarStyle} position='static'>
                <Toolbar>
                    <Link to='/'><img src={isAlt ? logoAlt : logo} className={styles.logo} alt='logo' /></Link>
                    <div style={{ marginRight: '1rem' }} />
                    <Link to='/' className={styles.no_style} style={rootLinkColorStyle}>
                        <Typography className={styles.title} variant='h1' noWrap>Chiqpack</Typography>
                    </Link>
                    {searchBar}
                    <Link to='/showcase' className={`${styles.no_style} ${styles.header_link}`} style={linkColorStyle}>
                        <Typography variant='inherit' noWrap>Showcase</Typography>
                    </Link>
                    <Link to='/contact' className={`${styles.no_style} ${styles.header_link}`} style={linkColorStyle}>
                        <Typography variant='inherit' noWrap>Contact</Typography>
                    </Link>
                    <div className={styles.grow} />
                    {renderAuthenOptions}
                    <div className={styles.section_mobile}>
                        <IconButton
                            aria-label='show more'
                            aria-controls={mobileMenuId}
                            aria-haspopup='true'
                            onClick={handleMobileMenuOpen}
                            color='inherit'
                        >
                            <MenuIcon className={styles.menu_button_icon} />
                        </IconButton>
                    </div>
                </Toolbar>
            </AppBar>
            {renderMobileMenu}
            {renderMenu}
            <NewProjectModal
                open={modalOpen}
                closeAction={() => setModalOpen(false)}
            />
            <AccountSettingsModal
                open={accountSettingsModalOpen}
                onClose={() => setAccountSettingsModalOpen(false)}
            />
        </div>
    );
};

export default Navbar;
