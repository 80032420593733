import { AppAction } from 'common/configStore';
import { firebaseAnalytics } from 'common/firebase';
import { AnyAction } from 'redux';
import {
    SEARCH_BEGIN,
    SEARCH_DISMISS_ERROR,
    SEARCH_FAILURE,
    SEARCH_SUCCESS,
} from 'redux/common/constants';

export const search = (filter: string[], limit: number, amount: number): AppAction => async (dispatch) => {

    dispatch({
        type: SEARCH_BEGIN,
    });

    const query = `
            query {
                search(
                    filter: ${JSON.stringify(filter)}
                    limit: ${limit}
                    amount: ${amount}
                ) {
                    projects {
                        _id
                        categories
                        liked_by
                        owner_id
                        private
                        project_name
                        featured_image
                        description
                        date_created
                        last_updated
                        owner_data {
                          user_name
                          _id
                          user_image
                        }
                    }
                    pages
                }
            }
        `;

    const opts: RequestInit = {
        method: 'POST',
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify({ query }),
    };

    return fetch(process.env.REACT_APP_GRAPHQL || '', opts)
        .then((response) => response.json())
        .then((createdJson) => {
            if (createdJson.errors) {
                firebaseAnalytics?.logEvent('search', {
                    query: filter,
                    error: createdJson?.errors,
                });
                dispatch({
                    type: SEARCH_FAILURE,
                    errors: createdJson.errors,
                });
            } else {
                firebaseAnalytics?.logEvent('search', {
                    query: filter,
                });
                dispatch({
                    type: SEARCH_SUCCESS,
                    data: createdJson.data.search,
                });
            }
        })
        .catch((errors) => {
            firebaseAnalytics?.logEvent('search', {
                query: filter,
                error: errors,
            });
            dispatch({
                type: SEARCH_FAILURE,
                errors,
            });
        });
};

// Async action saves request error by default, this method is used to dismiss the error info.
// If you don't want errors to be saved in Redux store, just ignore this method.
export const dismissSearchError = () => ({
    type: SEARCH_DISMISS_ERROR,
});

export const reducer = (state: any, action: AnyAction) => {
    switch (action.type) {
    case SEARCH_BEGIN:
        // Just after a request is sent
        return {
            ...state,
            searchPending: true,
            searchError: false,
            searchResults: null,
        };

    case SEARCH_SUCCESS:
        // The request is success
        return {
            ...state,
            searchPending: false,
            searchError: false,
            searchResults: action.data,
        };

    case SEARCH_FAILURE:
        // The request is failed
        return {
            ...state,
            searchError: true,
            searchPending: false,
            searchResults: null,
        };

    case SEARCH_DISMISS_ERROR:
        // Dismiss the request failure error
        return {
            ...state,
            searchError: false,
        };

    default:
        return state;
    }
};
