import { AppAction } from 'common/configStore';
import { AnyAction } from 'redux';
import { inspect } from 'util';
import {
    UPDATE_USER_BEGIN,
    UPDATE_USER_DISMISS_ERROR,
    UPDATE_USER_FAILURE,
    UPDATE_USER_SUCCESS,
} from 'redux/users/constants';
import { convertFileObjectToProjectOutput, FileSection } from 'redux/utils/projectUtils';

export const updateUser = (id: string = '', user: any, oldPassword: string | null = null): AppAction => async (dispatch) => {
    dispatch({
        type: UPDATE_USER_BEGIN,
    });

    // Removes oldURL from object
    const { oldURL, ...updatedUser } = user;

    // Upload user image first if available
    const imageFile = {
        image: user.user_image,
        oldURL: user.oldURL, // We need this to delete the original file
    };

    if (oldURL || user.user_image) {
        // Upload and get url of uploaded image
        const imageURL = await convertFileObjectToProjectOutput(id, '', imageFile, FileSection.UserImage);
        if (imageURL !== '') {
            updatedUser.user_image = imageURL;
        } else {
            dispatch({
                type: UPDATE_USER_FAILURE,
                errors: ['Failed to upload user image. Please try again later'],
            });
            return;
        }

    }

    // Include password update data if available
    let passwordUpdate = '';
    if (oldPassword !== null) {
        passwordUpdate = `oldPassword: "${oldPassword}"`;
    }

    const query = `
            mutation {
              updateUser(
                id: "${id}"
                user: ${inspect(updatedUser, false, 4).replace(/'/g, '"')}
                ${passwordUpdate}
              ) {
                _id
                email
                user_name
                project_ids
                liked_projects
                user_image
                date_created
              }
            }
        `;

    const opts: RequestInit = {
        credentials: 'include',
        method: 'POST',
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify({ query }),
    };

    // eslint-disable-next-line consistent-return
    return fetch(process.env.REACT_APP_GRAPHQL || '', opts)
        .then((response) => response.json())
        .then((createdJson) => {
            if (createdJson.errors) {
                dispatch({
                    type: UPDATE_USER_FAILURE,
                    errors: createdJson,
                });
            } else {
                dispatch({
                    type: UPDATE_USER_SUCCESS,
                    data: createdJson.data.updateUser,
                });
            }
        })
        .catch((errors) => {
            dispatch({
                type: UPDATE_USER_FAILURE,
                errors,
            });
        });
};

// Async action saves request error by default, this method is used to dismiss the error info.
// If you don't want errors to be saved in Redux store, just ignore this method.
export const dismissUpdateUserError = () => ({
    type: UPDATE_USER_DISMISS_ERROR,
});

export const reducer = (state: any, action: AnyAction) => {
    switch (action.type) {
    case UPDATE_USER_BEGIN:
        // Just after a request is sent
        return {
            ...state,
            updateUserPending: true,
            updateUserError: null,
            userUpdated: false,
        };

    case UPDATE_USER_SUCCESS:
        // The request is success
        return {
            ...state,
            updateUserPending: false,
            updateUserError: null,
            userUpdated: true,
            userAuthenData: action.data,
        };

    case UPDATE_USER_FAILURE:
        // The request is failed
        return {
            ...state,
            userUpdated: false,
            updateUserPending: false,
            updateUserError: action.errors,
        };

    case UPDATE_USER_DISMISS_ERROR:
        // Dismiss the request failure error
        return {
            ...state,
            updateUserError: null,
            userUpdated: false,
        };

    default:
        return state;
    }
};
