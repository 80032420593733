import React, { ReactElement } from 'react';
import { Tooltip, withStyles } from '@material-ui/core';
import { Theme } from '@material-ui/core/styles';
import themeColors from 'utils/themeColors';

type AnnotationTooltipProps = {
    title: string,
    children: ReactElement,
    disable: boolean,
}

export const AnnotationTooltip = ({ title, children, disable }: AnnotationTooltipProps) => {

    const StyledTooltip = withStyles((theme: Theme) => ({
        tooltip: {
            backgroundColor: 'white',
            color: 'rgba(0, 0, 0, 0.87)',
            maxWidth: 220,
            fontSize: '2.4rem',
            border: `0.4rem solid ${themeColors.creme}`,
            borderRadius: '1rem',
        },
    }))(Tooltip);

    return (
        <StyledTooltip title={title} disableFocusListener={disable} disableHoverListener={disable} disableTouchListener={disable}>
            {children}
        </StyledTooltip>
    );

};
