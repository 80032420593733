import { AppAction } from 'common/configStore';
import { firebaseAnalytics } from 'common/firebase';
import { AnyAction } from 'redux';
import {
    UPDATE_SUBSCRIPTION_BEGIN,
    UPDATE_SUBSCRIPTION_DISMISS_ERROR,
    UPDATE_SUBSCRIPTION_FAILURE,
    UPDATE_SUBSCRIPTION_SUCCESS,
} from 'redux/users/constants';

export const updateSubscription = (userID: string = '', priceID: string = ''): AppAction => async (dispatch) => {
    dispatch({
        type: UPDATE_SUBSCRIPTION_BEGIN,
    });

    const query = `
       mutation {
          updateSubscription(
            userID: "${userID}"
            priceID: "${priceID}"
          )
        } 
    `;

    const opts: RequestInit = {
        method: 'POST',
        credentials: 'include',
        headers: {
            'Content-Type': 'application/json',
        },
        body: JSON.stringify({ query }),
    };

    return fetch(`${process.env.REACT_APP_GRAPHQL}`, opts)
        .then((response) => response.json())
        .then((res) => {
            if (!res.errors && res?.data?.updateSubscription) {
                firebaseAnalytics?.logEvent(
                    'subscription_update',
                    {
                        user_Id: userID,
                        newPriceID: priceID,
                    },
                );
                dispatch({
                    type: UPDATE_SUBSCRIPTION_SUCCESS,
                });
                return;
            }
            firebaseAnalytics?.logEvent(
                'subscription_update',
                {
                    user_Id: userID,
                    newPriceID: priceID,
                    error: res.errors,
                },
            );
            dispatch({
                type: UPDATE_SUBSCRIPTION_FAILURE,
                error: res.errors,
            });
        })
        .catch((err) => {
            firebaseAnalytics?.logEvent(
                'subscription_update',
                {
                    user_Id: userID,
                    newPriceID: priceID,
                    error: err,
                },
            );
            dispatch({
                type: UPDATE_SUBSCRIPTION_FAILURE,
                error: err,
            });
        });
};

// Async action saves request error by default, this method is used to dismiss the error info.
// If you don't want errors to be saved in Redux store, just ignore this method.
export const dismissUpdateSubscriptionError = (): AppAction => async (dispatch) => {
    dispatch({
        type: UPDATE_SUBSCRIPTION_DISMISS_ERROR,
    });
};

export const reducer = (state: any, action: AnyAction) => {
    switch (action.type) {
    case UPDATE_SUBSCRIPTION_BEGIN:
        // Just after a request is sent
        return {
            ...state,
            updateSubscriptionPending: true,
            updateSubscriptionError: null,
            subscriptionUpdated: false,
        };

    case UPDATE_SUBSCRIPTION_SUCCESS:
        // The request is success
        return {
            ...state,
            updateSubscriptionPending: false,
            updateSubscriptionError: null,
            subscriptionUpdated: true,
        };

    case UPDATE_SUBSCRIPTION_FAILURE:
        // The request is failed
        return {
            ...state,
            updateSubscriptionPending: false,
            updateSubscriptionError: action.error,
            subscriptionUpdated: false,
        };

    case UPDATE_SUBSCRIPTION_DISMISS_ERROR:
        // Dismiss the request failure error
        return {
            ...state,
            subscriptionUpdated: false,
            updateSubscriptionError: null,
        };

    default:
        return state;
    }
};
