import { AppAction } from 'common/configStore';
import { AnyAction } from 'redux';
import {
    REACTIVATE_PREMIUM_BEGIN,
    REACTIVATE_PREMIUM_DISMISS_ERROR,
    REACTIVATE_PREMIUM_FAILURE,
    REACTIVATE_PREMIUM_SUCCESS,
} from 'redux/users/constants';

export const reactivatePremium = (userID: string = ''): AppAction => async (dispatch) => {

    dispatch({
        type: REACTIVATE_PREMIUM_BEGIN,
    });

    const query = `
       mutation {
          reactivatePremium(
            userID: "${userID}"
          )
        } 
    `;

    const opts: RequestInit = {
        method: 'POST',
        credentials: 'include',
        headers: {
            'Content-Type': 'application/json',
        },
        body: JSON.stringify({ query }),
    };

    return fetch(`${process.env.REACT_APP_GRAPHQL}`, opts)
        .then((response) => response.json())
        .then((res) => {
            if (res?.errors || !res?.data?.reactivatePremium) {
                dispatch({
                    type: REACTIVATE_PREMIUM_FAILURE,
                    errors: res.errors,
                });
                return;
            }
            dispatch({
                type: REACTIVATE_PREMIUM_SUCCESS,
            });
        }).catch((err) => {
            dispatch({
                type: REACTIVATE_PREMIUM_FAILURE,
                errors: err,
            });
        });

};

// Async action saves request error by default, this method is used to dismiss the error info.
// If you don't want errors to be saved in Redux store, just ignore this method.
export const dismissReactivatePremiumError = () => ({
    type: REACTIVATE_PREMIUM_DISMISS_ERROR,
});

export const reducer = (state: any, action: AnyAction) => {
    switch (action.type) {
    case REACTIVATE_PREMIUM_BEGIN:
        // Just after a request is sent
        return {
            ...state,
            reactivatePremiumPending: true,
            reactivatePremiumError: null,
            reactivatedPremium: false,
        };

    case REACTIVATE_PREMIUM_SUCCESS:
        // The request is success
        return {
            ...state,
            reactivatePremiumPending: false,
            reactivatePremiumError: null,
            reactivatedPremium: true,
        };

    case REACTIVATE_PREMIUM_FAILURE:
        // The request is failed
        return {
            ...state,
            reactivatePremiumPending: false,
            reactivatePremiumError: action.errors,
            reactivatedPremium: false,
        };

    case REACTIVATE_PREMIUM_DISMISS_ERROR:
        // Dismiss the request failure error
        return {
            ...state,
            reactivatePremiumError: null,
            reactivatedPremium: true,
        };

    default:
        return state;
    }
};
