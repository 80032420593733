import { logPageView } from 'common/analytics/firebaseEvents';
import React, { useEffect } from 'react';
import { Switch, Route, Redirect, useHistory } from 'react-router-dom';
import PropTypes from 'prop-types';
import { useSelector, useDispatch } from 'common/hooks';
import { InfoOutlined, Edit, Palette, TableChart, PhotoFilter, VpnKey } from '@material-ui/icons';
import { Alert, TabContext, TabPanel } from '@material-ui/lab';
import {
    dismissDeleteProjectError,
    dismissUpdateProjectError,
    dismissProjectUpdated,
    getProject,
} from 'redux/actions';
import NotFound from 'pages/NotFound';
import TabBar from 'components/TabBar';
import { Access } from 'components/panels/Access';
import { General } from 'components/panels/General';
import ImagesPanel from 'components/panels/ImagesPanel';
import MeasurementsPanel from 'components/panels/MeasurementsPanel';
import { Loading } from 'components/Loading';
import withHelmet from 'components/hocs/withHelmet';
import { PAGE_NAMES } from 'constants/metaData';
import ActiveTabContext, { ActiveTabProvider } from 'utils/ActiveTabContext';
import styles from 'styles/editProject.module.css';

// TODO - Add type to props / match
const EditProject = (props: any) => {
    const { match } = props;
    const { params } = match;
    const { projectId } = params;
    const dispatch = useDispatch();
    const history = useHistory();

    const {
        createProjectPending,
        userAuthenData,
        project,
        getProjectPending,
        getProjectError,
        updateProjectPending,
        updateProjectError,
        projectUpdated,
        fileArrayError,
        deleteProjectPending,
        deleteProjectError,
    } = useSelector((state) => ({
        userAuthenData: state.users.userAuthenData,
        project: state.projects.project,
        getProjectPending: state.projects.getProjectPending,
        getProjectError: state.projects.getProjectError,
        updateProjectPending: state.projects.updateProjectPending,
        projectUpdated: state.projects.projectUpdated,
        updateProjectError: state.projects.updateProjectError,
        fileArrayError: state.projects.fileArrayError,
        createProjectPending: state.projects.createProjectPending,
        deleteProjectPending: state.projects.deleteProjectPending,
        deleteProjectError: state.projects.deleteProjectError,
    }));

    useEffect(() => {
        dispatch(getProject(projectId, userAuthenData?._id));
        logPageView();
    }, []); // eslint-disable-line

    const allowedToEdit = (userAuthenData && project && (userAuthenData._id === project.owner_id || project.collaborators?.includes(userAuthenData._id || '')));

    // Passing in a component instead of an anonymous function prevents reloading of the component dispatch events
    const imagesPanels = {
        sketches: <ImagesPanel type='sketches' />,
        materials: <ImagesPanel type='materials' />,
        outcome: <ImagesPanel type='outcome' />,
    };

    const tabList = [
        {
            icon: InfoOutlined,
            label: 'General',
            panel: <General />,
            route: 'general',
        },
        {
            icon: Edit,
            label: 'Sketches',
            panel: imagesPanels.sketches,
            route: 'sketches',
        },
        {
            icon: Palette,
            label: 'Materials',
            panel: imagesPanels.materials,
            route: 'materials',
        },
        {
            icon: TableChart,
            label: 'Measurements',
            panel: (
                <div style={{ marginTop: '3rem' }}>
                    <MeasurementsPanel />
                </div>
            ),
            route: 'measurements',
        },
        {
            icon: PhotoFilter,
            label: 'Outcome',
            panel: imagesPanels.outcome,
            route: 'outcome',
        },
        {
            icon: VpnKey,
            label: 'Access',
            panel: <Access />,
            route: 'access',
        },
    ];

    // Used when updating or loading the project
    const loadingScreen = (updateProjectPending || getProjectPending || createProjectPending || deleteProjectPending) ? (<Loading />) : null;

    // Show an alert when the project fails to load
    const getProjectErrorScreen = getProjectError ? (
        <div>
            <Alert
                className={styles.alert}
                severity='error'
            >
                There was a problem loading this project. Please try again later
            </Alert>
        </div>
    ) : null;

    const updateProjectFailed = updateProjectError ? (
        <Alert
            onClose={() => dispatch(dismissUpdateProjectError())}
            className={styles.alert}
            severity='error'
        >
            Failed to update project
        </Alert>
    ) : null;

    const deleteProjectFailed = deleteProjectError ? (
        <Alert
            onClose={() => dispatch(dismissDeleteProjectError())}
            className={styles.alert}
            severity='error'
        >
            Failed to delete project
        </Alert>
    ) : null;

    const partialUpdateFailure = !updateProjectError && fileArrayError?.length > 0 ? (
        <div>
            <Alert
                onClose={() => dispatch(dismissUpdateProjectError())}
                className={styles.alert}
                severity='warning'
            >
                Parts of this project failed to update.
            </Alert>
            {fileArrayError.map((err: any) => (
                <Alert
                    key={err}
                    onClose={() => dispatch(dismissUpdateProjectError())}
                    className={styles.alert}
                    severity='error'
                >
                    {err}
                </Alert>
            ))}
        </div>
    ) : null;

    // Show the user that the project updated successfully
    const updateProjectSuccess = projectUpdated ? (
        <Alert
            onClose={() => dispatch(dismissProjectUpdated())}
            className={styles.alert}
            severity='success'
        >
            Updated project successfully
        </Alert>
    ) : null;

    const mainEdit = (
        <ActiveTabProvider value={{ activeTab: match.url }}>
            <ActiveTabContext.Consumer>
                {() => (
                    <TabContext value={`${history.location.pathname}`}>
                        {/* @ts-ignore */}
                        <TabBar title='Editing' projectId={projectId} url={match.url} tabs={tabList} />
                        <Switch>
                            {/* Build out routes and panels */
                            /* Initial route will be first tab */}
                            {
                                tabList.map((item, index) => (
                                    <Route
                                        key={item.label}
                                        path={`${match.url}/${item.route}`}
                                        exact
                                        render={() => (
                                            <TabPanel value={`${match.url}/${item.route}`}>
                                                {item.panel}
                                            </TabPanel>
                                        )}
                                    />
                                ))
                            }
                            {/* if not a valid route, redirect to initial route (i.e. first tab) */}
                            <Redirect to={`${match.url}/general`} />
                        </Switch>
                    </TabContext>
                )}
            </ActiveTabContext.Consumer>
        </ActiveTabProvider>
    );

    const authenCheck = allowedToEdit ? (
        <div>
            {loadingScreen}
            {updateProjectFailed || partialUpdateFailure || updateProjectSuccess || deleteProjectFailed}
            {getProjectErrorScreen || mainEdit}
        </div>
    ) : (
        <div>
            {loadingScreen || (<NotFound />)}
        </div>
    );

    return (
        <div className={styles.container}>
            {authenCheck}
        </div>
    );
};

EditProject.propTypes = {
    match: PropTypes.object.isRequired,
};

EditProject.displayName = PAGE_NAMES.EDIT_PROJECT;

export default withHelmet(EditProject);
