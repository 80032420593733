import { logPageView } from 'common/analytics/firebaseEvents';
import React, { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import { useDispatch, useSelector } from 'common/hooks';
import { Alert } from '@material-ui/lab';
import { Avatar, Button } from '@material-ui/core';
import { Favorite, StarBorder, WorkOutline } from '@material-ui/icons';
import { dismissProjectDeleted, getProjects, getUser } from 'redux/actions';
import ProjectCard from 'components/ProjectCard';
import { Loading } from 'components/Loading';
import NewProjectModal from 'components/NewProjectModal';
import PremiumAlert from 'components/PremiumAlert';
import { Project } from 'redux/projects/models/Project';
import { hasHitProjectCap, isPremium } from 'common/tierChecks';
import { UserTiers } from 'redux/users/models/User';
import withHelmet from 'components/hocs/withHelmet';
import { PAGE_NAMES } from 'constants/metaData';
import premiumIcon from 'assets/all-inclusive.svg';
import styles from 'styles/profile.module.css';

const COLLABORATOR_LIST_NAME = 'collaboratingOn';

interface ProfileParams {
    userName: string;
}

const Profile = () => {

    const { userName } = useParams<ProfileParams>();
    const dispatch = useDispatch();
    const [gotProjects, setGotProjects] = useState(false);
    const [countedLikes, setCountedLikes] = useState(false);
    const [numLikes, setNumLikes] = useState(0);
    const [openProjectModel, setOpenProjectModal] = useState(false);
    const [initialUserFetch, setInitialUserFetch] = useState(true);

    const {
        getUserPending,
        getUserError,
        getUserData,
        getProjectsPending,
        userSubscription,
        projectList,
        userAuthenData,
        projectDeleted,
        collaboratingOn,
    } = useSelector((state) => ({
        getUserPending: state.users.getUserPending,
        getUserError: state.users.getUserError,
        getUserData: state.users.getUserData,
        projectList: state.projects.projectList,
        getProjectsPending: state.projects.getProjectsPending,
        userSubscription: state.users.userSubscription,
        userAuthenData: state.users.userAuthenData,
        projectDeleted: state.projects.projectDeleted,
        // @ts-ignore
        collaboratingOn: state.projects[COLLABORATOR_LIST_NAME],
    }));

    // TODO - Simplify getting user data logic
    // TODO - optimize if user is authened user. There should be no need to fetch user data since we already have it
    useEffect(() => {
        dispatch(getUser(userName)).then(() => setInitialUserFetch(false));
        logPageView();
    }, []); // eslint-disable-line

    // Update if viewing another user and redirecting from this page
    useEffect(() => {
        if (!initialUserFetch && !getUserPending && getUserData && userName !== getUserData.user_name) {
            dispatch(getUser(userName)).then(() => setGotProjects(false));
        }
    }, [userName, getUserData, getUserPending, initialUserFetch]);

    useEffect(() => {
        if (!getUserPending && getUserData && getUserData.user_name === userName && !gotProjects) {
            // Get user projects then projects user is collaborating on
            dispatch(getProjects(getUserData?.project_ids)).then(() => {
                dispatch(getProjects(getUserData?.collaborating_on, COLLABORATOR_LIST_NAME));
                setGotProjects(true);
            });
            setGotProjects(true);
        }
    }, [getUserData, getUserPending, gotProjects]); // eslint-disable-line

    useEffect(() => {
        if (projectList && !countedLikes) {
            setCountedLikes(true);
            const c = projectList?.reduce((accumulator: number, project: Partial<Project>) => accumulator + (project?.liked_by?.length || 0), 0);
            setNumLikes(c);
        }
    }, [projectList, countedLikes]);

    const loadingScreen = getUserPending || getProjectsPending ? (<Loading />) : null;

    const isProfileOwner = getUserData?._id === userAuthenData?._id;

    const newProjectButton = userAuthenData && getUserData && isProfileOwner ? (
        <Button className={styles.newButton} onClick={() => setOpenProjectModal(true)}>
            New Project
        </Button>
    ) : null;

    const profileError = getUserError && (
        <Alert severity='error' className={styles.alert}>
            Failed to get user
        </Alert>
    );

    const projectDeletedAlert = projectDeleted && (
        <Alert
            severity='success'
            className={styles.alert}
            onClose={() => dispatch(dismissProjectDeleted())}
        >
            Project deleted successfully.
        </Alert>
    );

    const loadingCardsList = [];
    for (let i = 0; i < 10; i += 1) {
        loadingCardsList.push(<ProjectCard key={i} />);
    }
    const loadingCards = getProjectsPending ? loadingCardsList : null;

    const projectLoaded = !projectList ? null : projectList?.filter((project: Partial<Project>) => (!isProfileOwner ? !project?.private : true)).map((p: Partial<Project>) => (
        <ProjectCard
            projectId={p._id}
            projectName={p.project_name}
            ownerName={p?.owner_data?.user_name}
            liked={!userAuthenData ? false : p?.liked_by?.includes(userAuthenData?._id || '')}
            likeCount={p?.liked_by?.length}
            categories={p.categories}
            projectDescription={p.description}
            featuredImage={p.featured_image}
            ownerImage={p?.owner_data?.user_image}
            ownerID={p?.owner_id}
            key={p?._id}
        />
    ));

    const collaboratingList = collaboratingOn?.filter((project: Partial<Project>) => (!isProfileOwner ? !project?.private : true)).map((p: Partial<Project>) => (
        <ProjectCard
            projectId={p._id}
            projectName={p.project_name}
            ownerName={p?.owner_data?.user_name}
            liked={!userAuthenData ? false : p?.liked_by?.includes(userAuthenData?._id || '')}
            likeCount={p?.liked_by?.length}
            categories={p.categories}
            projectDescription={p.description}
            featuredImage={p.featured_image}
            ownerImage={p?.owner_data?.user_image}
            ownerID={p?.owner_id}
            key={p?._id}
        />
    ));

    const userSide = !getUserData ? null : (
        <div className={styles.user}>
            <Avatar src={getUserData?.user_image} className={styles.avatar} />
            <div className={styles.usernameAndPremium}>
                <h1 className={styles.userName}>{getUserData?.user_name}</h1>
                { isPremium(userSubscription?.userTier || UserTiers.FREE) && <img src={premiumIcon} className={styles.premiumIndicator} alt='Premium Icon' /> }
            </div>
            <div className={styles.userInfo}>
                <StarBorder className={`${styles.icon} ${styles.icon_star}`} />
                <h4 className={styles.iconInfo}>{`${getUserData?.liked_projects?.length ?? 0} Likes Given`}</h4>
            </div>
            <div className={styles.userInfo}>
                <Favorite className={`${styles.icon} ${styles.icon_heart}`} />
                <h4 className={styles.iconInfo}>{`${numLikes} Likes Received`}</h4>
            </div>
            {newProjectButton}
        </div>
    );

    const projectsHeader = !projectList ? null : (
        <div className={styles.projectsHeader}>
            <div className={styles.projectHeader}>
                <WorkOutline className={`${styles.icon} ${styles.icon_star}`} />
                <h4 className={styles.iconInfo}>Projects</h4>
            </div>
            {/* TODO - re-enable at a later date */}
            {/* <div className={styles.searchBar}> */}
            {/*    <div className={styles.searchIcon}> */}
            {/*        <SearchIcon /> */}
            {/*    </div> */}
            {/*    <InputBase */}
            {/*        placeholder='Filter Projects' */}
            {/*        fullWidth */}
            {/*        className={navStyles.input_input} */}
            {/*        inputProps={{ 'aria-label': 'search' }} */}
            {/*    /> */}
            {/* </div> */}
        </div>
    );

    const collaboratingHeader = (
        <div className={styles.projectsHeader}>
            <div className={styles.projectHeader}>
                <WorkOutline className={`${styles.icon} ${styles.icon_star}`} />
                <h4 className={styles.iconInfo}>Collaborating On</h4>
            </div>
            {/* TODO - re-enable at a later date */}
            {/* <div className={styles.searchBar}> */}
            {/*    <div className={styles.searchIcon}> */}
            {/*        <SearchIcon /> */}
            {/*    </div> */}
            {/*    <InputBase */}
            {/*        placeholder='Filter Projects' */}
            {/*        fullWidth */}
            {/*        className={navStyles.input_input} */}
            {/*        inputProps={{ 'aria-label': 'search' }} */}
            {/*    /> */}
            {/* </div> */}
        </div>
    );

    const collaboratingSection = collaboratingOn?.length > 0 && (
        <div
            className={styles.projectsColumn}
            style={{
                marginTop: '9rem',
            }}
        >
            {collaboratingHeader}
            <div className={styles.projects}>
                {loadingCards || collaboratingList}
            </div>
        </div>
    );

    return (
        <div>
            {loadingScreen}
            {projectDeletedAlert}
            <div className={styles.root}>
                {profileError}
                {userSide}
                <div className={styles.projectsColumn}>
                    {projectsHeader}
                    <div className={styles.projects}>
                        {loadingCards || projectLoaded}
                    </div>
                    {!getProjectsPending
                    && isProfileOwner
                    && hasHitProjectCap(userSubscription?.userTier || UserTiers.FREE, userAuthenData?.project_ids?.length || 0)
                    && (<PremiumAlert text="You've reached your project limit." />)}
                    {collaboratingSection}
                </div>
            </div>
            <NewProjectModal
                open={openProjectModel}
                closeAction={() => setOpenProjectModal(false)}
            />
        </div>
    );

};

Profile.displayName = PAGE_NAMES.PROFILE;

export default withHelmet(Profile);
