import { logPageView } from 'common/analytics/firebaseEvents';
import React, { useEffect } from 'react';
import { Link } from 'react-router-dom';
import withHelmet from 'components/hocs/withHelmet';
import { PAGE_NAMES } from 'constants/metaData';
import styles from 'styles/privacyPolicy.module.css';

const Terms = () => {
    useEffect(() => {
        logPageView();
    }, []);

    return (
        <div className={styles.body}>
            <h1>Terms and Conditions</h1>
            <h2 className={styles.modified}>Last Revision October 17, 2021</h2>
            <p>
                By visiting or using Chiqpack (this website and its services) you are bound by these terms and conditions
                and Chiqpack&apos;s
                {' '}
                <Link to='/privacy'>Privacy Policy</Link>
                .
                &quot;You&quot; refers to any user or organization utilizing Chiqpack in any way, shape, or form.
            </p>
            <h2>Service</h2>
            <p>
                The Chiqpack service consists of this website and all of its included features and capabilities.
                Chiqpack is a service that allows you to create and share techpacks, art, or any other creations made by users.
                These creations may include images, text, video, audio, comments, graphics, or any other item made
                accessible through Chiqpack.
                Any features added to Chiqpack will also be bound to these terms and conditions.
                Chiqpack is free to use and access. Additional features may require a paid subscription.
                <h3>
Chiqpack is not targeted at children and we expect any use by children will be done under the guidance
                    of a parent or guardian!
                </h3>
            </p>
            <h2>User Generated Content</h2>
            <p>
                Chiqpack allows you to create and share content with anyone who has access to Chiqpack. Some content may be
                hosted by a third-party service.
                Chiqpack can limit what user generated content is allowed on the site. These limits can change without
                notice.
                Forbidden content consists of but is not limited to:
                <ul>
                    <li>Hate Speech</li>
                    <li>Gore</li>
                    <li>Defamation</li>
                    <li>Copyrighted Content</li>
                    <li>Pornography</li>
                </ul>
                Uploading any of the above content or anything that Chiqpack deems inappropriate will result in a ban from
                using any Chiqpack services and involving the authorities if necessary.
                User generated content that a user marks as &quot;public&quot; will be freely usable by users of
                Chiqpack and Chiqpack itself.

            </p>
            <h2>Subscription and Payments</h2>
            <p>
                <Link to='premium'>Chiqpack Paid</Link>
                {' '}
                subscriptions are available and payments are handled by
                {' '}
                <a href='https://stripe.com' target='_blank' rel='noopener noreferrer'>Stripe</a>
                , pursuant to
                {' '}
                <a href='https://stripe.com/privacy' target='_blank' rel='noopener noreferrer'>Stripes privacy policy</a>
                . A Chiqpack paid subscription is not required to access Chiqpack and a portion of its services.
            </p>
            <h2>Refunds and Resale</h2>
            <p>
                There will be no refunds for Chiqpack paid monthly subscriptions even if the features are unused.
                Upgrading or downgrading between paid tiers is allowed. Upgrading will grant a user immediate access to
                higher tiered features but will incur a prorated charge for the days a user utilizes this higher tier until their next billing period.
                Downgrading will immediately limit a user from higher tiered features and can result in a refund for the days a user is in
                the lower tier until their next billing period.
                Resale of Chiqpack accounts or services is strictly forbidden.
            </p>
            <h2>Warranty</h2>
            <p>
                Chiqpack is provided &quot;as-is&quot; as thus does not come with a warranty of any kind.
            </p>
            <h2>Limitation of Liability</h2>
            <p>
                In no event will Chiqpack or its affiliates be liable for any damages arising from use or inability to use
                Chiqpack.
            </p>
        </div>
    );
};

Terms.displayName = PAGE_NAMES.TERMS;

export default withHelmet(Terms);
