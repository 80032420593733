import React from 'react';
import { Alert } from '@material-ui/lab';
import { Link } from 'react-router-dom';
import styles from 'styles/premiumAlert.module.css';

export interface PremiumAlertProps {
    text: string,
    // eslint-disable-next-line react/require-default-props
    width?: string,
}

const PremiumAlert = (props: PremiumAlertProps): JSX.Element => {
    const { text, width } = props;
    return (
        <Alert
            variant='outlined'
            severity='info'
            style={{
                width,
            }}
            classes={{
                root: styles.root,
                icon: styles.icon,
            }}
        >
            {`${text} Checkout what `}
            <Link to='/premium'>Premium </Link>
            has to offer.
        </Alert>
    );

};

export default PremiumAlert;
