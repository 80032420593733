import {
    CHECK_SUBSCRIPTION_BEGIN,
    CHECK_SUBSCRIPTION_DISMISS_ERROR,
    CHECK_SUBSCRIPTION_FAILURE,
    CHECK_SUBSCRIPTION_SUCCESS,
} from 'redux/users/constants';
import { AppAction } from 'common/configStore';
import { AnyAction } from 'redux';
import { getUserTier } from 'common/tierChecks';

export const checkSubscription = (userID: string = ''): AppAction => async (dispatch) => {
    dispatch({
        type: CHECK_SUBSCRIPTION_BEGIN,
    });

    const query = `
       query {
          checkSubscription(
            userID: "${userID}"
          ) {
                id
                object
                application_fee_percent
                billing_cycle_anchor
                cancel_at
                cancel_at_period_end
                canceled_at
                created
                current_period_end
                current_period_start
                days_until_due
                ended_at
                livemode
                next_pending_invoice_item_invoice
                start_date
                status
                trial_end
                trial_start
                userTier
          }
        } 
    `;

    const opts: RequestInit = {
        method: 'POST',
        credentials: 'include',
        headers: {
            'Content-Type': 'application/json',
        },
        body: JSON.stringify({ query }),
    };

    try {
        const response = await fetch(`${process.env.REACT_APP_GRAPHQL}`, opts);
        const res = await response.json();
        if (res?.errors && !res?.data?.checkSubscription) {
            dispatch({
                type: CHECK_SUBSCRIPTION_FAILURE,
                errors: res.errors,
            });
            return;
        }
        dispatch({
            type: CHECK_SUBSCRIPTION_SUCCESS,
            subscription: res?.data?.checkSubscription,
        });
    } catch (err) {
        dispatch({
            type: CHECK_SUBSCRIPTION_FAILURE,
            errors: err,
        });
    }
};

// Async action saves request error by default, this method is used to dismiss the error info.
// If you don't want errors to be saved in Redux store, just ignore this method.
export const dismissCheckSubscriptionError = () => ({
    type: CHECK_SUBSCRIPTION_DISMISS_ERROR,
});

export const reducer = (state: any, action: AnyAction) => {
    switch (action.type) {
    case CHECK_SUBSCRIPTION_BEGIN:
        // Just after a request is sent
        return {
            ...state,
            checkSubscriptionPending: true,
            checkSubscriptionError: null,
            userSubscription: null,
        };

    case CHECK_SUBSCRIPTION_SUCCESS:
        // The request is success
        return {
            ...state,
            checkSubscriptionPending: false,
            checkSubscriptionError: null,
            userSubscription: {
                ...action.subscription,
                userTier: getUserTier(action.subscription.userTier),
            },
        };

    case CHECK_SUBSCRIPTION_FAILURE:
        // The request is failed
        return {
            ...state,
            checkSubscriptionPending: false,
            checkSubscriptionError: action.errors,
            userSubscription: null,
        };

    case CHECK_SUBSCRIPTION_DISMISS_ERROR:
        // Dismiss the request failure error
        return {
            ...state,
            checkSubscriptionError: null,
        };

    default:
        return state;
    }
};
