import { AppAction } from 'common/configStore';
import { AnyAction } from 'redux';
import {
    UPDATE_USER_AUTHEN_BEGIN,
    UPDATE_USER_AUTHEN_SUCCESS,
    UPDATE_USER_AUTHEN_FAILURE,
    UPDATE_USER_AUTHEN_DISMISS_ERROR,
} from 'redux/users/constants';
import { User } from 'redux/users/models/User';

export const addProjectToUserAuthen = (projectID: string): AppAction => async (dispatch, getState) => {
    dispatch({
        type: UPDATE_USER_AUTHEN_BEGIN,
    });

    const state = getState();

    // Clone and update
    const storedIDs = state.users.userAuthenData?.project_ids;
    let projectIDs: string[] = [];
    if (storedIDs) {
        projectIDs = Object.assign([], storedIDs);
    }

    projectIDs.push(projectID);

    const updatedUser: Partial<User> = {
        project_ids: projectIDs,
    };

    // Push update to store
    dispatch({
        type: UPDATE_USER_AUTHEN_SUCCESS,
        data: updatedUser,
    });
};

export const removeProjectToUserAuthen = (projectID: string): AppAction => async (dispatch, getState) => {
    dispatch({
        type: UPDATE_USER_AUTHEN_BEGIN,
    });

    const state = getState();

    // Clone and update
    const storedIDs = state.users.userAuthenData?.project_ids;
    let projectIDs: string[] = [];
    if (storedIDs) {
        projectIDs = Object.assign([], storedIDs);
    }

    const index = projectIDs.indexOf(projectID, 0);
    if (index > -1) {
        projectIDs.splice(index, 1);
    }

    const updatedUser: Partial<User> = {
        project_ids: projectIDs,
    };

    // Push update to store
    dispatch({
        type: UPDATE_USER_AUTHEN_SUCCESS,
        data: updatedUser,
    });
};

export const reducer = (state: any, action: AnyAction) => {
    switch (action.type) {
    case UPDATE_USER_AUTHEN_BEGIN:
        // Just after a request is sent
        return {
            ...state,
        };

    case UPDATE_USER_AUTHEN_SUCCESS:
        // The request is success
        return {
            ...state,
            userAuthenData: {
                ...state.userAuthenData,
                ...action.data,
            },
        };

    case UPDATE_USER_AUTHEN_FAILURE:
        // The request is failed
        return {
            ...state,
        };

    case UPDATE_USER_AUTHEN_DISMISS_ERROR:
        // Dismiss the request failure error
        return {
            ...state,
        };

    default:
        return state;
    }
};
