import React from 'react';
import { ProjectSection } from 'pages/Project';
import styles from './styles/sectionPlaceholder.module.css';

interface Props {
    section: ProjectSection;
}

export const SectionPlaceholder = ({ section }: Props) => (
    <div className={styles.container}>
        <div>
            {
                section === 'outcome'
                    ? (
                        <>
                            No photos have been uploaded for
                            {' '}
                            <span className={styles.section_title}>Outcome</span>
                        </>
                    )
                    : (
                        <>
                            No
                            {' '}
                            <span className={styles.section_title}>{section}</span>
                            {' '}
                            have been uploaded
                        </>
                    )
            }
        </div>
    </div>
);
