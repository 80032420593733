export const USER_AUTHEN_BEGIN = 'USER_AUTHEN_BEGIN';
export const USER_AUTHEN_SUCCESS = 'USER_AUTHEN_SUCCESS';
export const USER_AUTHEN_FAILURE = 'USER_AUTHEN_FAILURE';
export const USER_AUTHEN_DISMISS_ERROR = 'USER_AUTHEN_DISMISS_ERROR';
export const USER_SIGN_OUT = 'USER_SIGN_OUT';

export const GET_USER_BEGIN = 'GET_USER_BEGIN';
export const GET_USER_FAILURE = 'GET_USER_FAILURE';
export const GET_USER_SUCCESS = 'GET_USER_SUCCESS';
export const GET_USER_DISMISS_ERROR = 'GET_USER_DISMISS_ERROR';

export const CREATE_USER_BEGIN = 'CREATE_USER_BEGIN';
export const CREATE_USER_FAILURE = 'CREATE_USER_FAILURE';
export const CREATE_USER_SUCCESS = 'CREATE_USER_SUCCESS';
export const CREATE_USER_DISMISS_ERROR = 'CREATE_USER_DISMISS_ERROR';

export const USER_TOKEN_REFRESH_BEGIN = 'USER_TOKEN_REFRESH_BEGIN';
export const USER_TOKEN_REFRESH_SUCCESS = 'USER_TOKEN_REFRESH_SUCCESS';
export const USER_TOKEN_REFRESH_FAILURE = 'USER_TOKEN_REFRESH_FAILURE';
export const USER_TOKEN_REFRESH_DISMISS_ERROR = 'USER_TOKEN_REFRESH_DISMISS_ERROR';

export const GET_USERS_BEGIN = 'GET_USERS_BEGIN';
export const GET_USERS_FAILURE = 'GET_USERS_FAILURE';
export const GET_USERS_SUCCESS = 'GET_USERS_SUCCESS';
export const GET_USERS_DISMISS_ERROR = 'GET_USERS_DISMISS_ERROR';

export const SEARCH_USERS_BEGIN = 'SEARCH_USERS_BEGIN';
export const SEARCH_USERS_FAILURE = 'SEARCH_USERS_FAILURE';
export const SEARCH_USERS_SUCCESS = 'SEARCH_USERS_SUCCESS';
export const SEARCH_USERS_DISMISS_ERROR = 'SEARCH_USERS_DISMISS_ERROR';
export const SEARCH_USERS_CLEAR_DATA = 'SEARCH_USERS_CLEAR_DATA';

export const UPDATE_USER_BEGIN = 'UPDATE_USER_BEGIN';
export const UPDATE_USER_FAILURE = 'UPDATE_USER_FAILURE';
export const UPDATE_USER_SUCCESS = 'UPDATE_USER_SUCCESS';
export const UPDATE_USER_DISMISS_ERROR = 'UPDATE_USER_DISMISS_ERROR';

export const RESET_PASSWORD_BEGIN = 'RESET_PASSWORD_BEGIN';
export const RESET_PASSWORD_FAILURE = 'RESET_PASSWORD_FAILURE';
export const RESET_PASSWORD_SUCCESS = 'RESET_PASSWORD_SUCCESS';
export const RESET_PASSWORD_DISMISS_ERROR = 'RESET_PASSWORD_DISMISS_ERROR';

export const PREMIUM_CHECKOUT_BEGIN = 'PREMIUM_CHECKOUT_BEGIN';
export const PREMIUM_CHECKOUT_FAILURE = 'PREMIUM_CHECKOUT_FAILURE';
export const PREMIUM_CHECKOUT_SUCCESS = 'PREMIUM_CHECKOUT_SUCCESS';
export const PREMIUM_CHECKOUT_DISMISS_ERROR = 'PREMIUM_CHECKOUT_DISMISS_ERROR';

export const CANCEL_PREMIUM_BEGIN = 'CANCEL_PREMIUM_BEGIN';
export const CANCEL_PREMIUM_FAILURE = 'CANCEL_PREMIUM_FAILURE';
export const CANCEL_PREMIUM_SUCCESS = 'CANCEL_PREMIUM_SUCCESS';
export const CANCEL_PREMIUM_DISMISS_ERROR = 'CANCEL_PREMIUM_DISMISS_ERROR';

export const REACTIVATE_PREMIUM_BEGIN = 'REACTIVATE_PREMIUM_BEGIN';
export const REACTIVATE_PREMIUM_FAILURE = 'REACTIVATE_PREMIUM_FAILURE';
export const REACTIVATE_PREMIUM_SUCCESS = 'REACTIVATE_PREMIUM_SUCCESS';
export const REACTIVATE_PREMIUM_DISMISS_ERROR = 'REACTIVATE_PREMIUM_DISMISS_ERROR';

export const CHECK_SUBSCRIPTION_BEGIN = 'CHECK_SUBSCRIPTION_BEGIN';
export const CHECK_SUBSCRIPTION_FAILURE = 'CHECK_SUBSCRIPTION_FAILURE';
export const CHECK_SUBSCRIPTION_SUCCESS = 'CHECK_SUBSCRIPTION_SUCCESS';
export const CHECK_SUBSCRIPTION_DISMISS_ERROR = 'CHECK_SUBSCRIPTION_DISMISS_ERROR';

export const UPDATE_SUBSCRIPTION_BEGIN = 'UPDATE_SUBSCRIPTION_BEGIN';
export const UPDATE_SUBSCRIPTION_FAILURE = 'UPDATE_SUBSCRIPTION_FAILURE';
export const UPDATE_SUBSCRIPTION_SUCCESS = 'UPDATE_SUBSCRIPTION_SUCCESS';
export const UPDATE_SUBSCRIPTION_DISMISS_ERROR = 'UPDATE_SUBSCRIPTION_DISMISS_ERROR';

export const UPDATE_USER_AUTHEN_BEGIN = 'UPDATE_USER_AUTHEN_BEGIN';
export const UPDATE_USER_AUTHEN_SUCCESS = 'UPDATE_USER_AUTHEN_SUCCESS';
export const UPDATE_USER_AUTHEN_FAILURE = 'UPDATE_USER_AUTHEN_FAILURE';
export const UPDATE_USER_AUTHEN_DISMISS_ERROR = 'UPDATE_USER_AUTHEN_DISMISS_ERROR';
