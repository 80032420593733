import React, { ReactEventHandler, MouseEventHandler, ReactNode, CSSProperties } from 'react';
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import THEME_COLORS from 'utils/themeColors';
// import DialogTitle from '@material-ui/core/DialogTitle';

interface Actions {
    text: string;
    action: MouseEventHandler<HTMLAnchorElement>;
    style?: CSSProperties;
}

interface FormDialogProps {
    children?: ReactNode;
    actions?: Actions[];
    // eslint-disable-next-line no-restricted-globals
    open: boolean;
    onCancel: ReactEventHandler;
}

export const FormDialog = ({ children, actions, open, onCancel }: FormDialogProps) => (
    <Dialog
        open={open}
        onBackdropClick={onCancel}
        onClose={onCancel}
        aria-labelledby='form-dialog-title'
        maxWidth={false}
    >
        {/* <DialogTitle id='form-dialog-title'> */}
        {/*    <span style={{ fontSize: '1.8rem', fontWeight: 'bold' }}> */}
        {/*        {title} */}
        {/*    </span> */}
        {/* </DialogTitle> */}
        <DialogContent>
            {children}
        </DialogContent>
        <DialogActions>
            {actions?.map(({ text, action, style }) => (
                <Button
                    href=''
                    style={{ fontSize: '1.2rem', color: THEME_COLORS.darkPurple, ...style }}
                    key={text}
                    onClick={action}
                >
                    {text}
                </Button>
            ))}
        </DialogActions>
    </Dialog>
);

FormDialog.defaultProps = {
    children: null,
    // title: '',
    actions: [],
};
