import React, { CSSProperties } from 'react';
import { Tooltip, useMediaQuery } from '@material-ui/core';
import { categoryColors, CategoryColor } from 'utils/categories';
import styles from 'styles/categoryTag.module.css';

interface CategoryTagProps {
    category: string,
    displayShort?: boolean;
    displayLong?: boolean;
    maxWidth?: string | number;
}

const CategoryTag = ({
    category,
    displayShort,
    displayLong,
    maxWidth,
}: CategoryTagProps) => {
    const matches = useMediaQuery('(min-width: 760px)');
    const color = categoryColors[category.toLowerCase() as CategoryColor] || 'grey';

    const tagStyle: CSSProperties = {
        backgroundColor: color,
        maxWidth,
        textOverflow: 'ellipsis',
    };

    return (
        // @ts-ignore
        <Tooltip title={<h1 style={{ color: 'white' }}>{category}</h1>}>
            <div className={styles.container} style={tagStyle}>
                {
                    // display first letter only when on mobile and not explicitly overridden
                    (matches || displayLong) && !displayShort
                        ? category
                        : category[0]
                }
            </div>
        </Tooltip>
    );
};

CategoryTag.defaultProps = {
    displayShort: false,
    displayLong: false,
    maxWidth: undefined,
};

export default CategoryTag;
