import { logPageView } from 'common/analytics/firebaseEvents';
import React, { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import { useDispatch, useSelector } from 'common/hooks';
import {
    Container,
    Grid,
    TextField,
    Button,
} from '@material-ui/core';
import { Alert } from '@material-ui/lab';
import { dismissGetUserError, getUser, resetPassword, dismissResetPasswordError } from 'redux/actions';
import NotFound from 'pages/NotFound';
import { Loading } from 'components/Loading';
import withHelmet from 'components/hocs/withHelmet';
import { PAGE_NAMES } from 'constants/metaData';
import styles from 'styles/login.module.css';
import logo from 'logo2_500.png';

interface ResetPasswordParams {
    username: string;
    token: string;
}

const ResetPassword = () => {
    const { username, token } = useParams<ResetPasswordParams>();
    const dispatch = useDispatch();
    const [password, setPassword] = useState('');
    const [confirmPassword, setConfirmPassword] = useState('');

    const {
        getUserPending,
        getUserError,
        getUserData,
        resetPasswordError,
        resetPasswordPending,
        passwordReset,
    } = useSelector((state) => ({
        getUserPending: state.users.getUserPending,
        getUserError: state.users.getUserError,
        getUserData: state.users.getUserData,
        resetPasswordPending: state.users.resetPasswordPending,
        resetPasswordError: state.users.resetPasswordError,
        passwordReset: state.users.passwordReset,
    }));

    useEffect(() => {
        dispatch(getUser(username, true));
        dispatch(dismissResetPasswordError());
        logPageView();
    }, []); // eslint-disable-line

    const updatePassword = () => {
        dispatch(resetPassword(
            getUserData?._id,
            password,
            token,
        ));
    };

    const loading = getUserPending || resetPasswordPending ? (<Loading />) : null;

    const userError = !getUserError ? null : getUserError.map((e: any) => (
        <Alert
            severity='error'
            onClose={() => { dispatch(dismissGetUserError()); }}
            className={styles.login_alert}
            key={e}
        >
            {e?.message}
        </Alert>
    ));

    const updatePasswordError = !resetPasswordError ? null : (
        <Alert
            severity='error'
            onClose={() => { dispatch(dismissResetPasswordError()); }}
            className={styles.login_alert}
        >
            Failed to reset password. Please try again later.
        </Alert>
    );

    const updatePasswordSuccess = !passwordReset ? null : (
        <h1>Password updated successfully</h1>
    );

    const tokenFailure = getUserData?.resetPasswordToken !== token ? (<NotFound />) : null;

    const mainPage = (
        <div className={styles.outer_container}>
            <div className={styles.logo_wrapper}>
                <img src={logo} alt='Chiqpack Logo' className={styles.logo} />
                <h1 className={styles.logo_header}>Reset Password</h1>
            </div>
            {updatePasswordError}
            <Container className={styles.form_container} maxWidth={false}>
                <form>
                    <Grid container spacing={3}>
                        <Grid item xs={12}>
                            <Grid container spacing={2}>
                                <Grid item xs={12}>
                                    <TextField
                                        fullWidth
                                        label='New Password'
                                        name='newPassword'
                                        size='small'
                                        variant='outlined'
                                        value={password}
                                        type={password}
                                        onChange={
                                            (event) => (setPassword(event.target.value))
                                        }
                                    />
                                </Grid>
                                <Grid item xs={12}>
                                    <TextField
                                        fullWidth
                                        label='Confirm Password'
                                        name='confirmPassword'
                                        size='small'
                                        type='password'
                                        variant='outlined'
                                        value={confirmPassword}
                                        onChange={
                                            (event) => (setConfirmPassword(event.target.value))
                                        }
                                    />
                                </Grid>
                            </Grid>
                        </Grid>
                        <Grid item xs={12}>
                            <Button
                                disabled={password !== confirmPassword}
                                color='inherit'
                                fullWidth
                                variant='contained'
                                className={styles.login_button}
                                onClick={updatePassword}
                            >
                            Reset Password
                            </Button>
                        </Grid>
                    </Grid>
                </form>
            </Container>
        </div>
    );

    return (
        <div>
            {loading || userError || tokenFailure || updatePasswordSuccess || mainPage}
        </div>
    );
};

ResetPassword.displayName = PAGE_NAMES.RESET_PASSWORD;

export default withHelmet(ResetPassword);
