import { AppAction } from 'common/configStore';
import { firebaseAnalytics } from 'common/firebase';
import { AnyAction } from 'redux';
import {
    getUpdateProjectAuthentication,
} from 'redux/utils/projectUtils';
import {
    UPDATE_COLLABORATORS_BEGIN,
    UPDATE_COLLABORATORS_DISMISS_ERROR,
    UPDATE_COLLABORATORS_FAILURE,
    UPDATE_COLLABORATORS_SUCCESS,
} from 'redux/projects/constants';

export const updateCollaborators = (
    userID: string = '',
    projectID: string = '',
    collaborators: string[] = [],
): AppAction => async (dispatch) => {

    dispatch({
        type: UPDATE_COLLABORATORS_BEGIN,
    });

    // Get authentication before proceeding
    const authen = await getUpdateProjectAuthentication(userID, projectID);

    if (!authen) {
        dispatch({
            type: UPDATE_COLLABORATORS_FAILURE,
            errors: ['You don\'t have permissions to edit this project'],
        });
        return;
    }

    const query = `
            mutation {
            updateCollaborators(
                userID: "${userID}"
                projectID: "${projectID}"
                collaborators: ${JSON.stringify(collaborators)}
            ) 
            }
        `;

    const opts: RequestInit = {
        credentials: 'include',
        method: 'POST',
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify({ query }),
    };

    fetch(process.env.REACT_APP_GRAPHQL || '', opts)
        .then((response) => response.json())
        .then((createdJson) => {
            if (createdJson.errors) {
                dispatch({
                    type: UPDATE_COLLABORATORS_FAILURE,
                    errors: createdJson.errors,
                });
            } else {
                firebaseAnalytics?.logEvent('update_collaborators', {
                    user_id: userID,
                    project_id: projectID,
                });
                dispatch({
                    type: UPDATE_COLLABORATORS_SUCCESS,
                    data: createdJson.data.updateCollaborators,
                });
            }
        })
        .catch((errors) => {
            dispatch({
                type: UPDATE_COLLABORATORS_FAILURE,
                errors,
            });
        });

};

// Async action saves request error by default, this method is used to dismiss the error info.
// If you don't want errors to be saved in Redux store, just ignore this method.
export const dismissUpdateCollaboratorsError = () => ({
    type: UPDATE_COLLABORATORS_DISMISS_ERROR,
});

export const reducer = (state: any, action: AnyAction) => {
    switch (action.type) {
    case UPDATE_COLLABORATORS_BEGIN:
        // Just after a request is sent
        return {
            ...state,
            updateCollaboratorsPending: true,
            updateCollaboratorsError: null,
            projectUpdated: false,
        };

    case UPDATE_COLLABORATORS_SUCCESS:
        // The request is success
        return {
            ...state,
            updateCollaboratorsPending: false,
            updateCollaboratorsError: null,
            project: {
                ...state.project,
                collaborators: action.data,
            },
            projectUpdated: true,
        };

    case UPDATE_COLLABORATORS_FAILURE:
        // The request is failed
        return {
            ...state,
            updateCollaboratorsPending: false,
            updateCollaboratorsError: action.errors,
            projectUpdated: false,
        };

    case UPDATE_COLLABORATORS_DISMISS_ERROR:
        // Dismiss the request failure error
        return {
            ...state,
            updateCollaboratorsError: null,
            projectUpdated: false,
        };

    default:
        return state;
    }
};
