import React, { useCallback } from 'react';
import { Link } from 'react-router-dom';
import {
    Card,
    CardActions,
    Avatar,
} from '@material-ui/core';
import { LikeButton } from 'components/LikeButton';
import CategoryTag from 'components/CategoryTag';
import ShareButton from 'components/buttons/ShareButton';
import logo from 'logo2_500.png';
import premiumIcon from 'assets/all-inclusive.svg';
import styles from 'styles/projectCardSmall.module.css';
import projectCardStyles from 'styles/projectCard.module.css';

interface Props {
    categories?: Array<string> | null;
    projectId?: string;
    projectName?: string;
    projectDescription?: string;
    ownerName?: string;
    ownerImage?: string;
    ownerPremium?: boolean;
    likeCount?: number;
    liked?: boolean;
    featuredImage?: any;
    // eslint-disable-next-line no-restricted-globals
    handleLike: (event: any, likeVal: boolean) => void;
}

const ProjectCardSmall = (props: Props) => {
    const {
        categories,
        projectId,
        projectName,
        projectDescription,
        ownerName,
        ownerImage,
        ownerPremium,
        likeCount,
        liked,
        featuredImage,
        handleLike,
    } = props;

    const renderCategories = useCallback(() => {
        let categoriesToShow = categories;
        const showMoreText = categories && categories.length > 3;
        if (showMoreText) {
            categoriesToShow = categories?.slice(0, 3);
        }
        return !categoriesToShow ? null : (
            <>
                {categoriesToShow.map(
                    (item) => <CategoryTag category={item} displayShort key={item} />,
                )}
                {showMoreText && (
                    <Link to={`/${ownerName}/${projectId}`} className={projectCardStyles.section_category_more}>+</Link>
                )}
            </>
        );
    }, [categories]);

    return (
        <Card className={styles.card}>
            <div className={styles.card_content}>
                <Link
                    to={`/${ownerName}/${projectId}`}
                    className={styles.featured_image_container}
                    style={{
                        backgroundImage: `url(${featuredImage || logo})`,
                        backgroundSize: 'cover',
                        backgroundPosition: 'center',
                    }}
                />
                <div className={styles.section_details}>
                    <div className={styles.section_details_content}>
                        <div className={styles.details_row}>
                            <Link to={`/${ownerName}/${projectId}`} className={styles.card_link}>
                                <h1 className={styles.project_name}>{projectName || ''}</h1>
                            </Link>
                            <CardActions className={styles.card_actions}>
                                {
                                    !projectName
                                        ? null
                                        : (
                                            <LikeButton
                                                liked={liked || false}
                                                likeCount={likeCount || 0}
                                                likeAction={handleLike}
                                            />
                                        )
                                }
                                <ShareButton
                                    projectName={projectName}
                                    projectId={projectId}
                                    projectDescription={projectDescription}
                                    ownerName={ownerName}
                                    className={styles.action_button}
                                    iconClass={styles.action_button_icon}
                                />
                            </CardActions>
                        </div>
                        <div className={styles.details_row}>
                            <div className={projectCardStyles.section_author}>
                                <Link to={`/profile/${ownerName}`} className={styles.card_link}>
                                    <Avatar
                                        src={ownerImage}
                                        className={projectCardStyles.author_avatar}
                                    />
                                </Link>
                                <Link to={`/profile/${ownerName}`} className={styles.card_link}>
                                    <div className={styles.author_inner_content}>
                                        <div className={projectCardStyles.author_name}>{ownerName || ''}</div>
                                        { ownerPremium && <img src={premiumIcon} alt='Premium Icon' /> }
                                    </div>
                                </Link>
                            </div>
                            <div className={projectCardStyles.section_category}>
                                {renderCategories()}
                            </div>
                        </div>
                        <div className={styles.description}>
                            {projectDescription}
                        </div>
                    </div>
                </div>
            </div>
        </Card>

    );
};

ProjectCardSmall.defaultProps = {
    categories: [],
    projectId: undefined,
    projectName: undefined,
    projectDescription: undefined,
    ownerName: undefined,
    ownerImage: undefined,
    ownerPremium: undefined,
    liked: false,
    likeCount: 0,
    featuredImage: null,
};

export default ProjectCardSmall;
