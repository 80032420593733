/* eslint-disable jsx-a11y/click-events-have-key-events, jsx-a11y/no-static-element-interactions */

import { logPageView } from 'common/analytics/firebaseEvents';
import React, { useEffect } from 'react';
import withHelmet from 'components/hocs/withHelmet';
import { PAGE_NAMES } from 'constants/metaData';
import LeadingSection from 'pages/partials/landing/LeadingSection';
import InsightSection from 'pages/partials/landing/InsightSection';
import DiscoverSection from 'pages/partials/landing/DiscoverSection';
import { FormSection } from 'pages/partials/landing/FormSection';
import { useSelector } from 'common/hooks';
import { shallowEqual } from 'react-redux';
import styles from 'styles/landing.module.css';
import cornerSpatter from 'assets/corner_splatter.svg';
import { PremiumTable } from 'components/PremiumTable';
import ExamplesCarousel from 'components/ExamplesCarousel/ExamplesCarousel';

export const Landing = () => {
    const {
        userAuthenData,
    } = useSelector((state) => ({
        userAuthenData: state.users.userAuthenData,
    }), shallowEqual);

    useEffect(() => {
        logPageView();
    }, []);

    return (
        <div className={styles.outer_container}>
            <img
                src={cornerSpatter}
                alt='corner splatter background decoration'
                className={styles.corner_splatter}
            />
            <div className={styles.inner_container}>
                <LeadingSection isLoggedIn={!!userAuthenData} />
                <InsightSection />
                <DiscoverSection />
                <ExamplesCarousel />
                <div style={{ display: 'flex', justifyContent: 'center', margin: '4rem 0' }}>
                    <div style={{ borderTop: '1px solid #E9E9E9', width: '50%' }} />
                </div>
                <div style={{ display: 'flex', justifyContent: 'center', width: '100%' }}>
                    <PremiumTable />
                </div>
            </div>
            {!userAuthenData && <FormSection />}
        </div>
    );
};

Landing.displayName = PAGE_NAMES.LANDING;

export default withHelmet(Landing);
