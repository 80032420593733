import { logPageView } from 'common/analytics/firebaseEvents';
import { firebaseAnalytics } from 'common/firebase';
import React, { useEffect, useState } from 'react';
// import Particles from 'react-tsparticles';
// import { loadConfettiShape } from 'tsparticles-shape-confetti';
// import type { Main } from 'tsparticles';
import { useDispatch, useSelector } from 'common/hooks';
import { checkSubscription, dismissPremiumCheckoutError } from 'redux/actions';
import { Redirect, useLocation } from 'react-router-dom';
import { Alert } from '@material-ui/lab';
import { Loading } from 'components/Loading';
import { isPremium } from 'common/tierChecks';
import { UserTiers } from 'redux/users/models/User';
import withHelmet from 'components/hocs/withHelmet';
import { PAGE_NAMES } from 'constants/metaData';
import logo from 'logo2_500.png';
import styles from 'pages/styles/premiumSuccess.module.css';

// const loadConfetti = (tsparticles: Main) => {
//     loadConfettiShape(tsparticles);
// };

const PremiumSuccess = () => {
    const dispatch = useDispatch();
    const location = useLocation();
    // @ts-ignore
    const reactivating = location?.state?.reactivating;

    const {
        checkSubscriptionPending,
        userAuthenData,
        userSubscription,
    } = useSelector((state) => ({
        userAuthenData: state.users.userAuthenData,
        checkSubscriptionPending: state.users.checkSubscriptionPending,
        userSubscription: state.users.userSubscription,
    }));

    const [redir, setRedir] = useState<boolean>(false);
    const [showParticles, setShowParticles] = useState<boolean>(false);

    useEffect(() => {
        logPageView();
    }, []);

    useEffect(() => {
        if (userAuthenData?._id) {
            dispatch(checkSubscription(userAuthenData._id)).then(() => {
                setTimeout(() => setRedir(true), 10000);
            });
        }
        dispatch(dismissPremiumCheckoutError());

    }, []); // eslint-disable-line

    // Premium has been purchased successfully
    useEffect(() => {
        if (userSubscription?.status && isPremium(userSubscription?.userTier || UserTiers.FREE) && !showParticles) {
            setShowParticles(true);
            firebaseAnalytics?.logEvent(
                'premium_purchased',
                {
                    user_Id: userAuthenData?._id,
                },
            );
        }
    }, [userSubscription, isPremium]); // eslint-disable-line

    const welcomeMessage = reactivating ? 'Welcome back!' : 'Welcome to Premium!';

    const successBody = (
        <div className={styles.container}>

            {/* eslint-disable-next-line react/no-unescaped-entities */}
            <h1 className={styles.premiumText}>{`${welcomeMessage} Let's jump right in!`}</h1>
            <div className={styles.logo}>
                <img src={logo} alt='logo' className={styles.logo} />
            </div>

            <h2>You will be redirected shortly</h2>

        </div>
    );

    // const particles = showParticles ? (
    //     <Particles
    //         init={loadConfetti}
    //         options={{
    //             fullScreen: {
    //                 enable: true,
    //             },
    //             particles: {
    //                 number: {
    //                     value: 0,
    //                 },
    //                 color: {
    //                     value: ['#1E00FF', '#FF0061', '#E1FF00', '#00FF9E'],
    //                 },
    //                 shape: {
    //                     type: 'confetti',
    //                     options: {
    //                         confetti: {
    //                             /* @ts-ignore */
    //                             type: ['circle', 'square'],
    //                         },
    //                     },
    //                 },
    //                 opacity: {
    //                     value: 1,
    //                     animation: {
    //                         enable: true,
    //                         minimumValue: 0,
    //                         speed: 2,
    //                         startValue: 'max',
    //                         destroy: 'min',
    //                     },
    //                 },
    //                 size: {
    //                     value: 7,
    //                     random: {
    //                         enable: true,
    //                         minimumValue: 3,
    //                     },
    //                 },
    //                 life: {
    //                     duration: {
    //                         sync: true,
    //                         value: 5,
    //                     },
    //                     count: 1,
    //                 },
    //                 move: {
    //                     enable: true,
    //                     gravity: {
    //                         enable: true,
    //                         acceleration: 20,
    //                     },
    //                     speed: 50,
    //                     decay: 0.05,
    //                     direction: 'none',
    //                     outModes: {
    //                         default: 'destroy',
    //                         top: 'none',
    //                     },
    //                 },
    //             },
    //             interactivity: {
    //                 detectsOn: 'window',
    //                 events: {
    //                     resize: true,
    //                 },
    //             },
    //             detectRetina: true,
    //             background: {
    //                 opacity: 0,
    //             },
    //             responsive: [
    //                 {
    //                     options: {
    //                         particles: {
    //                             move: {
    //                                 speed: 30,
    //                                 decay: 0.05,
    //                             },
    //                         },
    //                     },
    //                 },
    //             ],
    //             emitters: [
    //                 {
    //                     direction: 'top',
    //                     rate: {
    //                         delay: 0.1,
    //                         quantity: 15,
    //                     },
    //                     position: {
    //                         x: 50,
    //                         y: 0,
    //                     },
    //                     size: {
    //                         width: 100,
    //                         height: 0,
    //                     },
    //                 },
    //             ],
    //         }}
    //     />
    // ) : null;

    const premiumSubscription = isPremium(userSubscription?.userTier || UserTiers.FREE);

    // eslint-disable-next-line react/no-unescaped-entities
    const body = premiumSubscription ? (successBody) : (
        <Alert severity='error' className={styles.alert}>An error has occurred. You will be redirected shortly.</Alert>
    );

    return (
        <div>
            {redir && (<Redirect to='/' />)}
            {/* {premiumSubscription && particles} */}
            {checkSubscriptionPending ? <Loading /> : body}
        </div>
    );
};

PremiumSuccess.displayName = PAGE_NAMES.PREMIUM_SUCCESS;

export default withHelmet(PremiumSuccess);
