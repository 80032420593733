import React from 'react';
import {
    ConnectingLine,
    Curve,
    LargeHandle,
    SmallHandle,
} from 'components/annotations/AnnotationComponents';
import { AnnotationTooltip } from 'components/annotations/AnnotationTooltip';
import { Point } from 'redux/projects/models/Point';

interface InstanceHandlerProps {
    start: Point | null;
    control: Point | null;
    end: Point | null;
    show: boolean;
    drawing?: boolean;
    index: number;
    handleMouseDown: (controlPoint: string) => void;
    editable: boolean | undefined;
    annotationText?: string;
}

const InstanceHandler = ({ start, control, end, handleMouseDown, show, index, drawing, editable, annotationText }: InstanceHandlerProps) => {

    if (start && control && end) {

        const mString = start ? `M ${start.x},${start.y}` : 'M 0 0';
        const qString = control ? `Q ${control.x},${control.y}` : 'Q 0 0';
        const lString = end ? `${end.x},${end?.y}` : '0 0';
        const instructions: string = `
      ${mString}
      ${qString}
      ${lString} `;

        const showLines = () => {
            if (show) {
                return (
                    <g>
                        <ConnectingLine from={start} to={control} />
                        <ConnectingLine from={control} to={end} />
                        <SmallHandle
                            coordinates={control}
                            onMouseDown={() => handleMouseDown(`controlPoint ${index}`)}
                            editable={editable}
                        />
                    </g>
                );
            } return (<span />);
        };

        const disableAnnotation = editable !== undefined ? editable && annotationText !== '' : true;

        return (
            <AnnotationTooltip title={annotationText || ''} disable={disableAnnotation}>
                <g>
                    {showLines()}
                    <Curve instructions={instructions} />
                    <LargeHandle
                        coordinates={start}
                        onMouseDown={() => handleMouseDown(`startPoint ${index}`)}
                        num={!drawing ? index + 1 : 0}
                        editable={editable}
                    />

                    <LargeHandle
                        coordinates={end}
                        onMouseDown={() => handleMouseDown(`endPoint ${index}`)}
                        num={!drawing ? index + 1 : 0}
                        editable={editable}
                    />
                </g>
            </AnnotationTooltip>
        );
    }
    return null;

};

InstanceHandler.defaultProps = {
    drawing: false,
    annotationText: 'Annotation',
};

export default InstanceHandler;
