import { firebaseAnalytics } from 'common/firebase';
import {
    CANCEL_PREMIUM_BEGIN,
    CANCEL_PREMIUM_DISMISS_ERROR,
    CANCEL_PREMIUM_FAILURE,
    CANCEL_PREMIUM_SUCCESS,
} from 'redux/users/constants';
import { AppAction } from 'common/configStore';
import { AnyAction } from 'redux';

export const cancelPremium = (userID: string = ''): AppAction => async (dispatch) => {
    dispatch({
        type: CANCEL_PREMIUM_BEGIN,
    });

    const query = `
       mutation {
          cancelPremium(
            userID: "${userID}"
          )
        } 
    `;

    const opts: RequestInit = {
        method: 'POST',
        credentials: 'include',
        headers: {
            'Content-Type': 'application/json',
        },
        body: JSON.stringify({ query }),
    };

    return fetch(`${process.env.REACT_APP_GRAPHQL}`, opts)
        .then((response) => response.json())
        .then((res) => {
            if (res?.errors || !res?.data?.cancelPremium) {
                firebaseAnalytics?.logEvent('premium_cancelled', {
                    user_id: userID,
                    error: res?.errors,
                });
                dispatch({
                    type: CANCEL_PREMIUM_FAILURE,
                    errors: res.errors,
                });
            } else {
                firebaseAnalytics?.logEvent('premium_cancelled', {
                    user_id: userID,
                });
                dispatch({
                    type: CANCEL_PREMIUM_SUCCESS,
                });
            }
        }).catch((err) => {
            firebaseAnalytics?.logEvent('premium_cancelled', {
                user_id: userID,
                error: err,
            });
            dispatch({
                type: CANCEL_PREMIUM_FAILURE,
                errors: err,
            });
        });
};

// Async action saves request error by default, this method is used to dismiss the error info.
// If you don't want errors to be saved in Redux store, just ignore this method.
export const dismissCancelPremiumError = () => ({
    type: CANCEL_PREMIUM_DISMISS_ERROR,
});

export const reducer = (state: any, action: AnyAction) => {
    switch (action.type) {
    case CANCEL_PREMIUM_BEGIN:
        // Just after a request is sent
        return {
            ...state,
            cancelPremiumPending: true,
            cancelPremiumError: null,
            premiumCancelled: false,
        };

    case CANCEL_PREMIUM_SUCCESS:
        // The request is success
        return {
            ...state,
            cancelPremiumPending: false,
            cancelPremiumError: null,
            premiumCancelled: true,
        };

    case CANCEL_PREMIUM_FAILURE:
        // The request is failed
        return {
            ...state,
            cancelPremiumPending: false,
            cancelPremiumError: action.errors,
            premiumCancelled: false,
        };

    case CANCEL_PREMIUM_DISMISS_ERROR:
        // Dismiss the request failure error
        return {
            ...state,
            cancelPremiumError: null,
            premiumCancelled: false,
        };

    default:
        return state;
    }
};
