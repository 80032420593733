import React, { useContext, useEffect, useState } from 'react';
import { Prompt } from 'react-router-dom';
import { useDispatch, useSelector } from 'common/hooks';
import {
    Avatar,
    Backdrop,
    Fade,
    FormControlLabel,
    IconButton,
    List,
    ListItem,
    ListItemAvatar,
    ListItemSecondaryAction,
    ListItemText,
    Modal,
    Radio,
    RadioGroup,
    Typography,
} from '@material-ui/core';
import DeleteIcon from '@material-ui/icons/Delete';
import { clearSearchUsersData } from 'redux/users/searchUsers';
import BlockNavigationContext from 'utils/BlockNavigationContext';
import StyledButton from 'components/buttons/StyledButton';
import { CollaboratorModalContainer as CollaboratorModal } from 'components/modals/CollaboratorModal/CollaboratorModalContainer';
import THEME_COLORS from 'utils/themeColors';
import { getUsers, updateCollaborators, updateProjectVisibility as updateProject } from 'redux/actions';
import styles from 'styles/basicInfoPanel.module.css';
import accessStyles from 'styles/accessPanel.module.css';
import { Loading } from 'components/Loading';
import { isFinalTier, isPremium } from 'common/tierChecks';
import { UserTiers } from 'redux/users/models/User';

export const Access = () => {
    const dispatch = useDispatch();
    const blockNavigationContext = useContext(BlockNavigationContext);
    const [projectPrivate, setProjectPrivate] = useState<boolean>(false);
    const [visibilityChanged, setVisibilityChanged] = useState<boolean>(false);
    const [deleteModalOpen, setDeleteModalOpen] = useState<boolean>(false);
    const [addModalOpen, setAddModalOpen] = useState<boolean>(false);
    const [useCollaborator, setUseCollaborator] = useState<string>('');
    const [useID, setUseID] = useState<string>('');

    const { blockNavigation, setBlockNavigation } = blockNavigationContext;

    const {
        project,
        updateProjectVisibilityPending,
        updateCollaboratorsPending,
        userAuthenData,
        getUsersPending,
        getUsersData,
        getUsersError,
        userSubscription,
    } = useSelector((state) => ({
        project: state.projects.project,
        updateProjectVisibilityPending: state.projects.updateProjectVisibilityPending,
        userAuthenData: state.users.userAuthenData,
        getUsersData: state.users.getUsersData,
        getUsersError: state.users.getUsersError,
        getUsersPending: state.users.getUsersPending,
        userSubscription: state.users.userSubscription,
        updateCollaboratorsPending: state.projects.updateCollaboratorsPending,
    }));

    useEffect(() => {
        if (!getUsersData && !getUsersPending && !getUsersError && project?.collaborators) {
            dispatch(getUsers(project?.collaborators || []));
        }
    }, []); // eslint-disable-line

    useEffect(() => {
        setProjectPrivate(project?.private ?? false);
        if (getUsersData && (project?.collaborators?.length ?? 0) !== getUsersData.length && !getUsersPending) {
            dispatch(getUsers(project?.collaborators || []));
        }
    }, [project, getUsersData]); // eslint-disable-line

    const updateProjectVisibility = (val: boolean) => {
        setProjectPrivate(val);
        setVisibilityChanged(true);
        setBlockNavigation(true);
    };

    const saveVisibility = () => {
        dispatch(updateProject(userAuthenData?._id, project?._id, projectPrivate));
        setBlockNavigation(false);
        setVisibilityChanged(false);
    };

    /** Save collaborators to remove */
    const saveCollaborators = () => {
        const removedCollaborators: string[] = [];

        // eslint-disable-next-line no-unused-expressions
        project?.collaborators?.forEach((id) => {
            if (id !== useID) {
                removedCollaborators.push(id);
            }
        });

        dispatch(updateCollaborators(userAuthenData?._id, project?._id, removedCollaborators));
        setDeleteModalOpen(false);
    };

    const removeProjectCollaborator = (username: string, id: string) => {
        setUseCollaborator(username);
        setUseID(id);
        setDeleteModalOpen(true);
    };

    const loading = updateProjectVisibilityPending || updateCollaboratorsPending ? (<Loading />) : null;

    const collaboratorList = !getUsersData ? null : getUsersData.map((user) => (
        <ListItem key={user._id}>
            <ListItemAvatar>
                <Avatar src={user.user_image} />
            </ListItemAvatar>
            <ListItemText
                primary={user.user_name}
            />
            <ListItemSecondaryAction>
                <IconButton
                    disabled={userAuthenData?._id !== project?.owner_id}
                    edge='end'
                    aria-label='delete'
                    onClick={() => removeProjectCollaborator(user?.user_name ?? '', user?._id ?? '')}
                >
                    <DeleteIcon />
                </IconButton>
            </ListItemSecondaryAction>
        </ListItem>
    ));

    const deleteModal = (
        <div>
            <Modal
                open={deleteModalOpen}
                onClose={() => setDeleteModalOpen(false)}
                className={accessStyles.modal}
                closeAfterTransition
                BackdropComponent={Backdrop}
                BackdropProps={{ timeout: 500 }}
            >
                <Fade in={deleteModalOpen}>
                    <div className={accessStyles.container}>
                        <h1>
                            {`Remove ${useCollaborator} from collaborators?`}
                        </h1>
                        <div className={accessStyles.button}>
                            <StyledButton
                                bgColor={THEME_COLORS.lightPurple}
                                textColor='white'
                                onClick={saveCollaborators}
                            >
                                Accept
                            </StyledButton>
                        </div>
                        <div className={accessStyles.button}>
                            <StyledButton
                                style={{
                                    borderColor: THEME_COLORS.lightPurple,
                                }}
                                outline
                                textColor='white'
                                onClick={() => setDeleteModalOpen(false)}
                            >
                            Cancel
                            </StyledButton>
                        </div>
                    </div>
                </Fade>
            </Modal>
        </div>
    );

    const handleClose = async () => {
        setAddModalOpen(false);
        dispatch(clearSearchUsersData());
    };

    return (
        <>
            {deleteModal}
            <div className={styles.container}>
                {loading}
                <CollaboratorModal projectName={project?.project_name} open={addModalOpen} onClose={handleClose} />
                <>
                    <Prompt
                        when={blockNavigation}
                        message='You have unsaved changes, are you sure you want to leave?'
                    />
                </>
                <form className={styles.form} noValidate autoComplete='off'>
                    <section className={styles.section}>
                        <Typography variant='h6' className={styles.subtitle}>
                            Project Visibility
                        </Typography>
                        <RadioGroup
                            aria-label='private'
                            name='private'
                            value={projectPrivate}
                            onChange={(e) => updateProjectVisibility(e.target.value === 'true')}
                        >
                            <FormControlLabel
                                value={false}
                                control={<Radio />}
                                label={<span className={accessStyles.text}>Public</span>}
                                disabled={
                                    !isPremium(userSubscription?.userTier || UserTiers.FREE)
                                    || updateProjectVisibilityPending
                                    || userAuthenData?._id !== project?.owner_id
                                }
                            />
                            <FormControlLabel
                                value
                                control={<Radio />}
                                label={<span className={accessStyles.text}>Private</span>}
                                disabled={
                                    !isPremium(userSubscription?.userTier || UserTiers.FREE)
                                    || updateProjectVisibilityPending
                                    || userAuthenData?._id !== project?.owner_id
                                }
                            />
                        </RadioGroup>
                        <StyledButton
                            disabled={
                                updateProjectVisibilityPending || !blockNavigation || !userAuthenData || !visibilityChanged
                            }
                            bgColor={THEME_COLORS.lightPurple}
                            textColor='white'
                            onClick={saveVisibility}
                        >
                            Update Visibility
                        </StyledButton>
                    </section>
                    <section className={styles.section}>
                        <Typography variant='h6' className={styles.subtitle}>
                            Collaborators
                        </Typography>
                        <div>
                            {(project?.collaborators?.length ?? 0) > 0 ? (
                                <List dense>
                                    {collaboratorList}
                                </List>
                            ) : (
                                <span className={accessStyles.text}>
                                    Solo for this one.&nbsp;
                                    <span role='img' aria-label='sunglasses emoji'>😎</span>
                                </span>
                            )}
                        </div>
                    </section>
                </form>
                <StyledButton
                    disabled={
                        updateProjectVisibilityPending || !userAuthenData
                        || !isFinalTier(userSubscription?.userTier || UserTiers.FREE)
                        || updateProjectVisibilityPending
                        || userAuthenData?._id !== project?.owner_id
                    }
                    bgColor={THEME_COLORS.lightPurple}
                    textColor='white'
                    onClick={() => setAddModalOpen(true)}
                >
                    Add Collaborator
                </StyledButton>
            </div>
        </>
    );
};
