import { UserTiers } from 'redux/users/models/User';
import {
    FREE_ANNOTATION_CAP,
    FREE_IMAGES_SECTION_CAP,
    FREE_PROJECT_CAP,
    FREE_TABLE_CAP, TIER_1_ANNOTATION_CAP,
    TIER_1_IMAGES_SECTION_CAP,
    TIER_1_PROJECT_CAP,
    TIER_1_TABLE_CAP, TIER_2_ANNOTATION_CAP,
    TIER_2_IMAGES_SECTION_CAP,
    TIER_2_TABLE_CAP,
} from 'common/envConstants';

export const getUserTier = (userTier: string) => {
    let tier = UserTiers.FREE;
    switch (userTier) {
    case UserTiers.TIER_1:
        tier = UserTiers.TIER_1;
        break;
    case UserTiers.TIER_2:
        tier = UserTiers.TIER_2;
        break;
    default:
        break;
    }
    return tier;
};

export const isPremium = (tier: UserTiers) => tier !== UserTiers.FREE;

export const isUpgradable = (tier: UserTiers) => tier === UserTiers.TIER_1;

export const isFinalTier = (tier: UserTiers) => tier === UserTiers.TIER_2;

export const hasHitImageCap = (tier: UserTiers, images: number): boolean => (tier === UserTiers.FREE && images >= FREE_IMAGES_SECTION_CAP)
    || (tier === UserTiers.TIER_1 && images >= TIER_1_IMAGES_SECTION_CAP)
    || (tier === UserTiers.TIER_2 && images >= TIER_2_IMAGES_SECTION_CAP);

export const hasHitProjectCap = (tier: UserTiers, projects: number): boolean => (tier === UserTiers.FREE && projects >= FREE_PROJECT_CAP) || (tier === UserTiers.TIER_1 && projects >= TIER_1_PROJECT_CAP);

export const hasHitColumnCap = (tier: UserTiers, columns: number): boolean => (tier === UserTiers.FREE && columns >= FREE_TABLE_CAP)
    || (tier === UserTiers.TIER_1 && columns >= TIER_1_TABLE_CAP)
    || (tier === UserTiers.TIER_2 && columns >= TIER_2_TABLE_CAP);

export const hasHitRowCap = (tier: UserTiers, rows: number): boolean => (tier === UserTiers.FREE && rows >= FREE_TABLE_CAP)
    || (tier === UserTiers.TIER_1 && rows >= TIER_1_TABLE_CAP)
    || (tier === UserTiers.TIER_2 && rows >= TIER_2_TABLE_CAP);

export const hasHitAnnotationCap = (tier: UserTiers, annotations: number): boolean => (tier === UserTiers.FREE && annotations >= FREE_ANNOTATION_CAP)
    || (tier === UserTiers.TIER_1 && annotations >= TIER_1_ANNOTATION_CAP)
    || (tier === UserTiers.TIER_2 && annotations >= TIER_2_ANNOTATION_CAP);
