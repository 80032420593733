export const categoryColors = {
    women: 'palevioletred',
    men: 'navy',
    fitness: 'blue',
    boy: 'skyblue',
    girl: 'pink',
    business: 'grey',
    casual: 'burlywood',
    outdoor: 'green',
} as const;

export type CategoryColor = keyof typeof categoryColors;
