import { AnyAction } from 'redux';
import { initialState } from 'redux/projects/initialState';
import { reducer as getProjectReducer } from 'redux/projects/getProject';
import { reducer as getProjectsReducer } from 'redux/projects/getProjects';
import { reducer as getShowcaseReducer } from 'redux/projects/getShowcase';
import { reducer as updateProjectReducer } from 'redux/projects/updateProject';
import { reducer as createProjectReducer } from 'redux/projects/createProject';
import { reducer as updateProjectVisibility } from 'redux/projects/updateProjectVisibility';
import { reducer as updateCollaborators } from 'redux/projects/updateCollaborators';
import { reducer as deleteProjectReducer } from 'redux/projects/deleteProject';

const reducers = [
    getProjectReducer,
    getProjectsReducer,
    getShowcaseReducer,
    updateProjectReducer,
    createProjectReducer,
    updateProjectVisibility,
    updateCollaborators,
    deleteProjectReducer,
];

export const reducer = (state = initialState, action: AnyAction) => {
    let newState;
    switch (action.type) {
    // Handle cross-topic actions here
    default:
        newState = state;
        break;
    }
    return reducers.reduce((s, r) => r(s, action), newState);
};
