import { logPageView } from 'common/analytics/firebaseEvents';
import React, { useEffect } from 'react';
import { Link } from 'react-router-dom';
import withHelmet from 'components/hocs/withHelmet';
import { PAGE_NAMES } from 'constants/metaData';
import styles from 'styles/privacyPolicy.module.css';

const PrivacyPolicy = () => {
    useEffect(() => {
        logPageView();
    }, []);

    return (
        <div className={styles.body}>
            <h1>Privacy Policy</h1>
            <h2 className={styles.modified}>Last Revision October 28, 2021</h2>
            <p>
                At Chiqpack, accessible from
                {' '}
                <a href='https://www.chiqpack.com'>https://www.chiqpack.com/</a>
                , one of our main priorities is the privacy of our visitors. This Privacy Policy document contains types of
                information that is collected and recorded by Chiqpack and how we use it.
            </p>
            <p>
                If you have additional questions or require more information about our Privacy Policy, do not hesitate to
                contact us through
                {' '}
                <a href='mailto: contact@chiqpack.com'>email</a>
                {' '}
                or by visiting our
                {' '}
                <Link to='/contact'>contact page</Link>
                .
            </p>
            <h2>Log Files</h2>
            <p>
                Chiqpack follows a standard procedure of using log files. These files log visitors when they visit this website.
                All hosting companies do this and a part of hosting services&apos; analytics.
                The information collected by log files include internet protocol (IP) addresses, browser type, Internet
                Service Provider (ISP), date and time stamp, referring/exit pages, and possibly the number of clicks.
                These are not linked to any information that is personally identifiable.
                The purpose of the information is for analyzing trends, administering the site, tracking
                users&apos; movement on the website, and gathering demographic information.
                Our Privacy Policy was created with the help of the
                {' '}
                <a href='https://www.privacypolicyonline.com/privacy-policy-generator/'>Privacy Policy Generator</a>
                .
            </p>
            <h2>Cookies and Web Beacons</h2>
            <p>
                Like any other website, Chiqpack uses &apos;cookies&apos;. These cookies are used for core site
                functionality and analytics.
            </p>
            <p>
                For more general information on cookies, please read the &quot;What Are Cookies&quot; article on
                <a href='https://www.privacypolicyonline.com/what-are-cookies/'> Cookie Consent website</a>
                .
            </p>
            {/* <h2>Privacy Policies</h2> */}
            {/* <p>You may consult this list to find the Privacy Policy for each of the advertising partners of Chiqpack.</p> */}
            {/* <p>Third-party ad servers or ad networks uses technologies like cookies, JavaScript, or Web Beacons that are used in their respective advertisements and links that appear on Chiqpack, which are sent directly to users' browser. They automatically receive your IP address when this occurs. These technologies are used to measure the effectiveness of their advertising campaigns and/or to personalize the advertising content that you see on websites that you visit.</p> */}
            {/* <p>Note that Chiqpack has no access to or control over these cookies that are used by third-party advertisers.</p> */}
            <h2>Third Party Privacy Policies</h2>
            <p>
                Chiqpack&apos;s Privacy Policy does not apply to other advertisers or websites. Thus, we are advising you to
                consult the respective Privacy Policies of these third-party ad servers for more detailed information. It
                may include their practices and instructions about how to opt-out of certain options.
                {' '}
            </p>
            <p>
                You can choose to disable cookies through your individual browser options. To know more detailed information
                about cookie management with specific web browsers, it can be found at the browsers&apos; respective
                websites.
            </p>
            <ul>
                <li>
                    <h3>Google Analytics</h3>
                    <p>
                        Chiqpack uses
                        {' '}
                        <a href='https://policies.google.com/technologies/partner-sites' target='_blank' rel='noreferrer'>Google Analytics</a>
                        {' '}
                        for aggregated, anonymized website traffic analysis.
                        In order to track your session usage, Google drops a cookie (_ga) with a randomly-generated ClientID in your browser.
                        This ID is anonymized and contains no identifiable information like email, phone number, name, etc.
                        We also send Google your IP Address. We use GA to track aggregated website behavior, such as what pages you looked at, for how long, and so on.
                        This information is important to us for improving the user experience and determining site effectiveness.
                        This behaviour can be disabled through our cookie consent pop-up.
                    </p>
                </li>
                <li>
                    <h3>Hotjar</h3>
                    <p>
                        Chiqpack uses
                        {' '}
                        <a href='https://policies.google.com/technologies/partner-sites' target='_blank' rel='noreferrer'>Hotjar</a>
                        {' '}
                        for website usage analysis.
                        Hotjar will periodically record website usage and will store it for future analysis.
                        This record does not contain identifiable information like email, phone number, or name but can contain
                        IP address, browser name, and device type (IE: desktop, mobile).
                        This information is important to us for improving the user experience and determining site effectiveness.
                        This behaviour can be disabled through our cookie consent pop-up.
                    </p>
                </li>
            </ul>
            <h2>Children&apos;s Information</h2>
            <p>
Another part of our priority is adding protection for children while using the internet. We encourage parents
                and guardians to observe, participate in, and/or monitor and guide their online activity.
            </p>
            <p>
Chiqpack does not knowingly collect any Personal Identifiable Information from children under the age of 13.
                If you think that your child provided this kind of information on our website, we strongly encourage you to
                contact us immediately and we will do our best efforts to promptly remove such information from our
                records.
            </p>
            <h2>Online Privacy Policy Only</h2>
            <p>
This Privacy Policy applies only to our online activities and is valid for visitors to our website with
                regards to the information that they shared and/or collect in Chiqpack. This policy is not applicable to any
                information collected offline or via channels other than this website.
            </p>
            <h2>Consent</h2>
            <p>
                By using our website, you hereby consent to our Privacy Policy and agree to its
                {' '}
                <Link to='/terms'>Terms and Conditions</Link>
                .
            </p>
        </div>
    );
};

PrivacyPolicy.displayName = PAGE_NAMES.PRIVACY_POLICY;

export default withHelmet(PrivacyPolicy);
