import { logPageView } from 'common/analytics/firebaseEvents';
import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'common/hooks';
import { Redirect, useParams } from 'react-router-dom';
import withHelmet from 'components/hocs/withHelmet';
import { PAGE_NAMES } from 'constants/metaData';
import { Loading } from 'components/Loading';
import { verifyEmail } from 'redux/actions';
import styles from 'styles/general.module.css';
import logo from 'logo2_500.png';

interface VerifyEmailParams {
    userID: string;
    email: string;
}

const VerifyEmail = () => {
    const { userID, email } = useParams<VerifyEmailParams>();
    const dispatch = useDispatch();
    const [redirSignIn, setRedirSignIn] = useState(false);
    const [timerStarted, setTimerStarted] = useState(false);

    const {
        verifyEmailPending,
        verifyEmailError,
        emailVerified,
    } = useSelector((state) => ({
        verifyEmailPending: state.common.verifyEmailPending,
        verifyEmailError: state.common.verifyEmailError,
        emailVerified: state.common.emailVerified,
    }));

    useEffect(() => {
        if (!verifyEmailPending) {
            dispatch(verifyEmail(userID, email));
        }
        logPageView();
    }, []); // eslint-disable-line

    useEffect(() => {
        if (emailVerified && !timerStarted) {
            setTimerStarted(true);
            setTimeout(() => {
                setRedirSignIn(true);
            }, 7000);
        }
    }, [emailVerified]); // eslint-disable-line

    const redirectSignIn = redirSignIn ? (<Redirect to='/login' />) : null;

    const loadingScreen = verifyEmailPending ? (<Loading />) : null;

    const verifyFailed = verifyEmailError ? (
        <h2 className={styles.mediumCenterTitle}>
            Email verification failed.
        </h2>
    ) : null;

    const verifySuccess = emailVerified ? (
        <>
            <h2 className={styles.mediumCenterTitle}>
                Email verified! You will be redirected shortly.
            </h2>
            <div>
                <img
                    src={logo}
                    alt='logo'
                    style={{
                        width: '20rem',
                    }}
                />
            </div>
        </>
    ) : null;

    return (
        <div>
            {redirectSignIn}
            {loadingScreen || verifyFailed || verifySuccess}
        </div>
    );
};

VerifyEmail.displayName = PAGE_NAMES.VERIFY_EMAIL;

export default withHelmet(VerifyEmail);
