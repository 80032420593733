import React, { CSSProperties } from 'react';
import IconCard from 'components/IconCard';
import sketchesIcon from 'assets/icons/pencil.svg';
import materialIcon from 'assets/icons/fabrics.svg';
import measurementsIcon from 'assets/icons/tape_measure.svg';
import outcomeIcon from 'assets/icons/fashion_design.svg';
import styles from 'styles/insightSection.module.css';
import landingStyles from 'styles/landing.module.css';

type Section = 'sketches' | 'materials' | 'measurements' | 'outcome';

const InsightSection = () => {
    // assets are not all the same size, so specific adjustment is needed
    const iconStyles: { [key in Section]: CSSProperties } = {
        sketches: {
            height: '9rem',
            width: '9rem',
        },
        materials: {
            height: '5rem',
            width: '5rem',
        },
        measurements: {
            height: '7rem',
            width: '7rem',
        },
        outcome: {
            height: '6.5rem',
            width: '6.5rem',
        },
    };

    const cardConfig: { [key in Section]: {
        icon: JSX.Element;
        title: string;
        description: string;
    } } = {
        sketches: {
            icon: <img style={iconStyles.sketches} src={sketchesIcon} alt='edit icon' />,
            title: 'Sketches',
            description: 'Every design starts with a simple sketch.',
        },
        materials: {
            icon: <img style={iconStyles.materials} src={materialIcon} alt='edit icon' />,
            title: 'Materials',
            description: 'Materials give the design character.',
        },
        measurements: {
            icon: <img style={iconStyles.measurements} src={measurementsIcon} alt='edit icon' />,
            title: 'Measurements',
            description: 'Necessary to bring your design to life.',
        },
        outcome: {
            icon: <img style={iconStyles.outcome} src={outcomeIcon} alt='edit icon' />,
            title: 'Outcome',
            description: 'Show the world your creation.',
        },
    };

    return (
        <div>
            <h3 className={landingStyles.section_subtitle}>INSIGHT</h3>
            <h2 className={styles.title}>What&apos;s a Tech Pack?</h2>
            <p className={landingStyles.section_blurb}>A tech pack is simply a spec sheet used by fashion designers and manufacturers.</p>
            <div className={styles.cards}>
                <IconCard config={cardConfig.sketches} />
                <IconCard config={cardConfig.materials} />
                <IconCard config={cardConfig.measurements} />
                <IconCard config={cardConfig.outcome} />
            </div>
        </div>
    );
};

export default InsightSection;
