import firebase from 'firebase/app';
import 'firebase/analytics';

const loadFirebaseApp = () => {
    try {
        return firebase.initializeApp({
            projectId: process.env.REACT_APP_FIREBASE_PROJECT_ID,
            appId: process.env.REACT_APP_FIREBASE_APP_ID,
            storageBucket: process.env.REACT_APP_FIREBASE_STORAGE_BUCKET,
            apiKey: process.env.REACT_APP_FIREBASE_API_KEY,
            measurementId: process.env.REACT_APP_FIREBASE_MEASUREMENT_ID,
        });
    } catch (e) {
        console.log('failed to initialize firebase app', e);
        return undefined;
    }
};

export const firebaseApp = loadFirebaseApp();

const loadFirebaseAnalytics = () => {
    try {
        return firebase.analytics(firebaseApp);
    } catch (e) {
        console.log('failed to initialize firebase analytics', e);
        return undefined;
    }
};

export const firebaseAnalytics = loadFirebaseAnalytics();

export const setAnalyticsState = (analyticsState: boolean) => {
    try {
        firebaseAnalytics?.setAnalyticsCollectionEnabled(analyticsState);
    } catch {
        console.log('Failed to update analytics');
    }
};
