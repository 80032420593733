import { trackUpdateProjectEvent } from 'common/analytics/firebaseEvents';
import { AppAction } from 'common/configStore';
import { AnyAction } from 'redux';
import { Project, UpdatedProject } from 'redux/projects/models/Project';
import { inspect } from 'util';
import {
    convertFileArrayToProjectOutput,
    convertFileObjectToProjectOutput,
    FileSection,
    getUpdateProjectAuthentication,
} from 'redux/utils/projectUtils';
import {
    UPDATE_PROJECT_BEGIN,
    UPDATE_PROJECT_DISMISS_ERROR,
    UPDATE_PROJECT_DISMISS_SUCCESS,
    UPDATE_PROJECT_FAILURE,
    UPDATE_PROJECT_SUCCESS,
} from 'redux/projects/constants';

export const updateProject = (
    userID: string = '',
    projectID: string = '',
    project: Partial<UpdatedProject> | Partial<Project> = {}): AppAction => async (dispatch, getState) => {

    dispatch({
        type: UPDATE_PROJECT_BEGIN,
    });

    // Get authentication before proceeding
    const authen = await getUpdateProjectAuthentication(userID, projectID);

    if (!authen) {
        dispatch({
            type: UPDATE_PROJECT_FAILURE,
            errors: ['You don\'t have permissions to edit this project'],
        });
        return;
    }

    const updatedProject = {
        ...project,
    };

    // Create usable data from values passed in
    // TODO - repeated logic should be simplified
    // TODO - remove suppressed error
    let fileArrayError: any;
    if (updatedProject.sketches) {
        // @ts-ignore
        [updatedProject.sketches, fileArrayError] = await convertFileArrayToProjectOutput(userID, projectID, updatedProject.sketches, FileSection.Sketches);
    }
    if (updatedProject.outcome) {
        // @ts-ignore
        [updatedProject.outcome, fileArrayError] = await convertFileArrayToProjectOutput(userID, projectID, updatedProject.outcome, FileSection.Outcome);
    }
    if (updatedProject.materials) {
        // @ts-ignore
        [updatedProject.materials, fileArrayError] = await convertFileArrayToProjectOutput(userID, projectID, updatedProject.materials, FileSection.Materials);
    }

    // Featured image should be of type fileObject.
    // We will change it to a URL once the upload succeeds.
    const imageFile = updatedProject.featured_image;

    if (imageFile) {
        // Upload and get url of uploaded image
        const imageURL = await convertFileObjectToProjectOutput(userID, projectID, imageFile, FileSection.FeaturedImage);
        if (imageURL !== '') {
            updatedProject.featured_image = imageURL;
        } else {
            dispatch({
                type: UPDATE_PROJECT_FAILURE,
                errors: ['Failed to upload featured image. Please try again later'],
            });
            return;
        }

    }

    const imagePanelQuery = `
            annotations {
                startPoints {
                    x
                    y
                }
                midPoints {
                    x
                    y
                }
                endPoints {
                    x
                    y
                }
                annotationInfo
            }
            description
            image
        `;

    // We use depth to make sure annotations are converted to string format
    const query = `
            mutation {
              updateProject(
                userID: "${userID}"
                projectID: "${projectID}"
                project: ${inspect(updatedProject, false, 5).replace(/'/g, '"')}
              ) {
                _id
                categories
                liked_by
                owner_id
                private
                is_showcase
                project_name
                description
                date_created
                last_updated
                featured_image
                collaborators
                owner_data {
                    user_name
                    user_image
                }
                sketches {
                    ${imagePanelQuery}
                }
                materials {
                    ${imagePanelQuery}
                }
                outcome {
                    ${imagePanelQuery}
                }
                measurements {
                    headerWidths
                    headers
                    data
                }
              }
            }
        `;

    const opts: RequestInit = {
        credentials: 'include',
        method: 'POST',
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify({ query }),
    };

    const state = getState();

    fetch(process.env.REACT_APP_GRAPHQL || '', opts)
        .then((response) => response.json())
        .then((createdJson) => {
            if (createdJson.errors) {
                dispatch({
                    type: UPDATE_PROJECT_FAILURE,
                    errors: createdJson.errors,
                    // @ts-ignore
                    fileArrayError,
                });
            } else {
                trackUpdateProjectEvent(userID, projectID, project, state.projects.project);
                dispatch({
                    type: UPDATE_PROJECT_SUCCESS,
                    data: createdJson.data.updateProject,
                    // @ts-ignore
                    fileArrayError,
                });
            }
        })
        .catch((errors) => {
            dispatch({
                type: UPDATE_PROJECT_FAILURE,
                errors,
                // @ts-ignore
                fileArrayError,
            });
        });
};

// Async action saves request error by default, this method is used to dismiss the error info.
// If you don't want errors to be saved in Redux store, just ignore this method.
export const dismissUpdateProjectError = () => ({
    type: UPDATE_PROJECT_DISMISS_ERROR,
});

export const dismissProjectUpdated = () => ({
    type: UPDATE_PROJECT_DISMISS_SUCCESS,
});

export const reducer = (state: any, action: AnyAction) => {
    switch (action.type) {
    case UPDATE_PROJECT_BEGIN:
        // Just after a request is sent
        return {
            ...state,
            updateProjectPending: true,
            updateProjectError: null,
            projectUpdated: false,
            // We use this to show the user if individual files have failed to upload
            // It's possible that some files successfully uploaded and we want to show them this as well
            fileArrayError: null,
        };

    case UPDATE_PROJECT_SUCCESS:
        // The request is success
        return {
            ...state,
            updateProjectPending: false,
            updateProjectError: null,
            project: {
                // No need to recalculate owner tier after updating project
                ownerTier: state.project.ownerTier,
                ...action.data,
            },
            projectUpdated: true,
            fileArrayError: action.fileArrayError,
        };

    case UPDATE_PROJECT_FAILURE:
        // The request is failed
        return {
            ...state,
            updateProjectPending: false,
            updateProjectError: action.errors,
            projectUpdated: false,
            fileArrayError: action.fileArrayError,
        };

    case UPDATE_PROJECT_DISMISS_ERROR:
        // Dismiss the request failure error
        return {
            ...state,
            updateProjectError: null,
            fileArrayError: null,
            projectUpdated: false,
        };

    case UPDATE_PROJECT_DISMISS_SUCCESS:
        // Dismiss the success state
        return {
            ...state,
            projectUpdated: false,
        };

    default:
        return state;
    }
};
