export const PAGE_NAMES = {
    LANDING: 'LANDING',
    HOME: 'HOME',
    SHOWCASE: 'SHOWCASE',
    PREMIUM: 'PREMIUM',
    JOIN: 'JOIN',
    LOGIN: 'LOGIN',
    PROJECT: 'PROJECT',
    EDIT_PROJECT: 'EDIT_PROJECT',
    CONTACT: 'CONTACT',
    SEARCH: 'SEARCH',
    PROFILE: 'PROFILE',
    FORGOT_PASSWORD: 'FORGOT_PASSWORD',
    RESET_PASSWORD: 'RESET_PASSWORD',
    VERIFY_EMAIL: 'VERIFY_EMAIL',
    ABOUT: 'ABOUT',
    TERMS: 'TERMS',
    PRIVACY_POLICY: 'PRIVACY_POLICY',
    NOT_FOUND: 'NOT_FOUND',
    CANCELLED_PREMIUM: 'CANCELLED_PREMIUM',
    PREMIUM_SUCCESS: 'PREMIUM_SUCCESS',
    EMAIL_SENT: 'EMAIL_SENT',
} as const;

export type PageName = keyof typeof PAGE_NAMES;

const generalDescription = `
    Chiqpack allows you to create and share fashion tech packs.
    It's a place that welcomes creativity and provides a platform to showcase your designs.
    Sign up with Chiqpack today!
`;

export const PAGE_META_DATA: Record<PageName, { helmetTitle?: string, helmetDescription?: string }> = {
    [PAGE_NAMES.LANDING]: {
        helmetDescription: generalDescription,
    },
    [PAGE_NAMES.HOME]: {
        helmetDescription: generalDescription,
    },
    [PAGE_NAMES.SHOWCASE]: {
        helmetTitle: 'Showcase | Chiqpack',
        helmetDescription: 'Discover the top projects on Chipack and trending categories. Your designs might be the next to be showcased!',
    },
    [PAGE_NAMES.PREMIUM]: {
        helmetTitle: 'Premium | Chiqpack',
        helmetDescription: 'Chiqpack\'s Premium gives you, the designer, power to do more with your creations.',
    },
    [PAGE_NAMES.JOIN]: {
        helmetTitle: 'Join | Chiqpack',
        helmetDescription: 'Sign up for Chiqpack - The place for you to create and share fashion tech packs',
    },
    [PAGE_NAMES.LOGIN]: {
        helmetTitle: 'Login | Chiqpack',
        helmetDescription: 'Welcome back to Chiqpack. Sign in to check out all the latest projects.',
    },
    [PAGE_NAMES.PROJECT]: {
        helmetTitle: 'Project | Chiqpack',
        helmetDescription: 'Viewing page of a project on Chiqpack.',
    },
    [PAGE_NAMES.EDIT_PROJECT]: {
        helmetTitle: 'Edit Project | Chiqpack',
        helmetDescription: 'Editing page for a project on Chiqpack.',
    },
    [PAGE_NAMES.CONTACT]: {
        helmetTitle: 'Contact | Chiqpack',
    },
    [PAGE_NAMES.SEARCH]: {
        helmetTitle: 'Search | Chiqpack',
        helmetDescription: 'Search projects for new inspo and people to follow.',
    },
    [PAGE_NAMES.PROFILE]: {
        helmetTitle: 'Profile | Chiqpack',
        helmetDescription: 'Viewing page of a user profile on Chiqpack.',
    },
    [PAGE_NAMES.FORGOT_PASSWORD]: {
        helmetTitle: 'Forgot Password | Chiqpack',
    },
    [PAGE_NAMES.RESET_PASSWORD]: {
        helmetTitle: 'Password Reset | Chiqpack',
    },
    [PAGE_NAMES.VERIFY_EMAIL]: {
        helmetTitle: 'Verify Email | Chiqpack',
    },
    [PAGE_NAMES.ABOUT]: {
        helmetTitle: 'About | Chiqpack',
        helmetDescription: generalDescription,
    },
    [PAGE_NAMES.TERMS]: {
        helmetTitle: 'Terms | Chiqpack',
        helmetDescription: 'Terms & Conditions for Chiqpack.',
    },
    [PAGE_NAMES.PRIVACY_POLICY]: {
        helmetTitle: 'Privacy Policy | Chiqpack',
        helmetDescription: 'Chipack\'s privacy policy.',
    },
    [PAGE_NAMES.NOT_FOUND]: {
        helmetTitle: '404 | Chiqpack',
        helmetDescription: 'Error 404. Page not found.',
    },
    [PAGE_NAMES.CANCELLED_PREMIUM]: {}, // default helmetTitle is Chiqpack and default helmetDescription with fallback to content on page
    [PAGE_NAMES.PREMIUM_SUCCESS]: {}, // default helmetTitle is Chiqpack and default helmetDescription with fallback to content on page
    [PAGE_NAMES.EMAIL_SENT]: {}, // default helmetTitle is Chiqpack and default helmetDescription with fallback to content on page
} as const;
