import React, { CSSProperties, useCallback } from 'react';
import { useHistory } from 'react-router-dom';
import { useSelector } from 'common/hooks';
import { useMediaQuery } from '@material-ui/core';
import { ArrowBack, CheckSharp } from '@material-ui/icons';
import StyledButton from 'components/buttons/StyledButton';
import THEME_COLORS from 'utils/themeColors';
import styles from 'components/styles/premiumTable.module.css';
import { isPremium } from 'common/tierChecks';
import { UserTiers } from 'redux/users/models/User';

interface TableProps {
    title: string;
    subtitle?: string;
    titleStyle?: CSSProperties;
    color: string;
    backgroundColor: string;
    dividerColor: string;
    cta?: JSX.Element | boolean;
    children: JSX.Element;
}

interface PremiumTableProps {
    ctaLabelTier1?: string;
    ctaOnClickTier1?: (() => void) | undefined;
    ctaLabelTier2?: string;
    ctaOnClickTier2?: (() => void) | undefined;
}

const Table = ({ title, subtitle, titleStyle, color, backgroundColor, dividerColor, cta, children }: TableProps) => (
    <div
        className={styles.table}
        style={{ color, backgroundColor }}
    >
        <div className={styles.table_title_container}>
            <div className={styles.table_title} style={titleStyle}>{title}</div>
            <div className={styles.table_subtitle} style={titleStyle}>{subtitle}</div>
        </div>
        <div className={styles.table_divider} style={{ borderColor: dividerColor }} />
        <div style={{ paddingBottom: '1rem' }}>
            <div className={styles.table_body}>
                {children}
            </div>
            <div style={{ margin: '0 1rem' }}>{cta}</div>
        </div>
    </div>
);

export const PremiumTable = (props: PremiumTableProps) => {
    const {
        ctaLabelTier1,
        ctaOnClickTier1,
        ctaLabelTier2,
        ctaOnClickTier2,
    } = props;
    const history = useHistory();
    const isMobile = useMediaQuery('(max-width: 760px)');

    const {
        userSubscription,
        userAuthenData,
    } = useSelector((state) => ({
        userSubscription: state.users.userSubscription,
        userAuthenData: state.users.userAuthenData,
    }));

    const freeFeatures: Array<string | JSX.Element> = [
        <>
            Create projects&nbsp;
            <span className={styles.list_item_small_text}>(limit 5)</span>
        </>,
        'View and like any project',
        '4 images per section',
        '5 annotations per image',
        '10x10 measurements table',
    ];

    const tier1Features: Array<string | JSX.Element> = [
        <>
            Everything in Designer,
            <span style={{ fontWeight: 'bold' }}> plus</span>
        </>,
        'Private projects',
        <>
            5 additional projects&nbsp;
            <span className={styles.list_item_small_text}>(10 total)</span>
        </>,
        <>
            2 additional images per section&nbsp;
            <span className={styles.list_item_small_text}>(6 total)</span>
        </>,
        <>
            5 additional annotations per image&nbsp;
            <span className={styles.list_item_small_text}>(10 total)</span>
        </>,
        '20x20 measurements table',
    ];

    const tier2Features: Array<string | JSX.Element> = [
        <>
            Everything in Couture,
            <span style={{ fontWeight: 'bold' }}> plus</span>
        </>,
        'Unlimited projects',
        'Collaboration',
        <>
            4 additional images per section&nbsp;
            <span className={styles.list_item_small_text}>(10 total)</span>
        </>,
        <>
            15 additional annotations per image&nbsp;
            <span className={styles.list_item_small_text}>(25 total)</span>
        </>,
        '30x30 measurements table',
    ];

    const ctaButtonStyle: CSSProperties = {
        fontWeight: 'bold',
        fontSize: isMobile ? '1rem' : '1.4rem',
    };

    const navigateToPremium = useCallback(() => {
        window.scrollTo(0, 0); // reset scroll position
        history.push('/premium');
    }, [history]);

    const navigateToJoin = useCallback(() => {
        window.scrollTo(0, 0); // reset scroll position
        history.push('/join');
    }, [history]);

    return (
        <div className={styles.container}>
            <div className={styles.title}>
                Get even more from&nbsp;
                <span className={styles.title_chiqpack}>Chiqpack</span>
            </div>
            <div className={styles.tables_row}>
                <Table
                    title='Designer'
                    subtitle='Free'
                    color='black'
                    backgroundColor='#F3F3F3'
                    dividerColor='#D3D3D3'
                    cta={!userAuthenData
                        && (
                            <StyledButton
                                bgColor='white'
                                textColor={THEME_COLORS.salmon}
                                style={ctaButtonStyle}
                                width='100%'
                                onClick={navigateToJoin}
                            >
                                Sign Up For Free
                            </StyledButton>
                        )}
                >
                    <div className={styles.feature_list} style={{ color: '#696969', marginBottom: '7.6rem' }}>
                        {
                            freeFeatures.map((item) => (
                                <div key={Math.random()} className={styles.list_item}>
                                    <CheckSharp className={styles.list_item_text} />
                                    <div style={{ width: '0.5rem' }} />
                                    <div className={styles.list_item_text}>
                                        {item}
                                    </div>
                                </div>
                            ))
                        }
                    </div>
                </Table>
                <Table
                    title='Couture'
                    subtitle='$9/mo'
                    titleStyle={{ fontWeight: 'bold' }}
                    color='black'
                    backgroundColor={THEME_COLORS.salmon}
                    dividerColor='#EDEDED'
                    cta={!isPremium(userSubscription?.userTier || UserTiers.FREE)
                        && (
                            <StyledButton
                                bgColor='white'
                                textColor={THEME_COLORS.salmon}
                                style={ctaButtonStyle}
                                width='100%'
                                onClick={ctaOnClickTier1 || navigateToPremium}
                            >
                                {ctaLabelTier1 || 'Learn more'}
                            </StyledButton>
                        )}
                >
                    <div className={styles.feature_list}>
                        {
                            tier1Features.map((item, index) => (
                                <div key={Math.random()} className={styles.list_item}>
                                    { index > 0
                                        ? <CheckSharp className={styles.list_item_text} />
                                        : <ArrowBack className={styles.list_item_text} />}
                                    <div style={{ width: '0.5rem' }} />
                                    <div className={`${styles.list_item_text} ${styles.list_item_font_color_black}`}>
                                        {item}
                                    </div>
                                </div>
                            ))
                        }
                    </div>
                </Table>
                <Table
                    title='Très Chic'
                    subtitle='$29/mo'
                    titleStyle={{ fontWeight: 'bold' }}
                    color='black'
                    backgroundColor={THEME_COLORS.salmon}
                    dividerColor='#EDEDED'
                    cta={!isPremium(userSubscription?.userTier || UserTiers.FREE)
                        && (
                            <StyledButton
                                bgColor='white'
                                textColor={THEME_COLORS.salmon}
                                style={ctaButtonStyle}
                                width='100%'
                                onClick={ctaOnClickTier2 || navigateToPremium}
                            >
                                {ctaLabelTier2 || 'Learn more'}
                            </StyledButton>
                        )}
                >
                    <div className={styles.feature_list}>
                        {
                            tier2Features.map((item, index) => (
                                <div key={Math.random()} className={styles.list_item}>
                                    { index > 0
                                        ? <CheckSharp className={styles.list_item_text} />
                                        : <ArrowBack className={styles.list_item_text} />}
                                    <div style={{ width: '0.5rem' }} />
                                    <div className={`${styles.list_item_text} ${styles.list_item_font_color_black}`}>
                                        {item}
                                    </div>
                                </div>
                            ))
                        }
                    </div>
                </Table>
            </div>
        </div>
    );
};

Table.defaultProps = {
    titleStyle: {},
    subtitle: '',
    cta: undefined,
};

PremiumTable.defaultProps = {
    ctaOnClickTier1: undefined,
    ctaLabelTier1: '',
    ctaOnClickTier2: undefined,
    ctaLabelTier2: '',
};
