import { SvgIconComponent, ArrowBack } from '@material-ui/icons';
import React, { useContext } from 'react';
import { Link, useHistory, withRouter } from 'react-router-dom';
import { AppBar, Tabs, Tab, IconButton } from '@material-ui/core';
import { useTabContext } from '@material-ui/lab';
import { useSelector } from 'common/hooks';
import ActiveTabContext from 'utils/ActiveTabContext';

import styles from 'styles/tabBar.module.css';
import Spacer from 'components/Spacer';

/**
 * projectId is to be passed only in the case of project editing
 * url prop is optional, but if it's passed, be sure to include route property
 * in each item of tabs
 */

export interface Tab {
    icon: SvgIconComponent;
    label: string;
    panel: JSX.Element;
    route?: string;
}

interface TabBarProps {
    title: string;
    projectId?: string;
    url?: string;
    tabs: Tab[];
}

const TabBar = ({ title, projectId, url, tabs }: TabBarProps) => {
    const tabContext = useTabContext(); // value holds activeTab (as a String)

    let value = '';
    if (tabContext) {
        value = tabContext?.value;
    }

    const history = useHistory();

    const activeTabContext = useContext(ActiveTabContext);
    const { activeTab, setActiveTab } = activeTabContext;

    const {
        project,
    } = useSelector((state) => ({
        project: state.projects.project,
    }));

    const handleChange = (event: React.ChangeEvent<{}>, value: any) => {
        // change tab if blockNavigation is false and it's a different index
        if (!url && activeTab !== `${value}`) {
            setActiveTab(value);
        }

    };

    const navigateToProject = () => {
        const { owner_data: ownerData, _id } = project || {};
        if (ownerData?.user_name && _id) {
            history.push(`/${ownerData.user_name}/${_id}`);
        }
    };

    const backButtonContent = projectId ? (
        <>
            <IconButton onClick={navigateToProject} style={{ padding: '0.2rem' }}>
                <ArrowBack fontSize='large' />
            </IconButton>
            <Spacer width='1rem' />
        </>
    ) : null;

    return (
        <div>
            <AppBar className={styles.appbar} position='static'>
                <div className={styles.title}>
                    {backButtonContent}
                    {title}
                    &nbsp;
                    {!projectId ? null : (
                        <span className={styles.titleProjectName}>
                            <strong>{project?.project_name}</strong>
                        </span>
                    )}
                </div>
                <Tabs
                    classes={{
                        fixed: styles.tabs_element,
                        indicator: styles.tab_indicator,
                        scrollButtons: styles.tabs_scroll_buttons,
                        scrollButtonsDesktop: styles.tabs_scroll_buttons_desktop,
                    }}
                    value={!url ? parseInt(value, 10) : history.location.pathname}
                    onChange={handleChange}
                    variant='scrollable'
                    scrollButtons='auto'
                >
                    {
                        tabs?.map((item) => (
                            !url
                                ? (
                                    <Tab
                                        key={item.label}
                                        classes={{ wrapper: styles.tab_wrapper }}
                                        label={(
                                            <div className={styles.tab_label}>
                                                &nbsp;
                                                {item.label}
                                            </div>
                                        )}
                                        icon={<item.icon htmlColor='gold' className={styles.tab_icon} />}
                                    />
                                )
                                : (
                                    <Tab
                                        key={item.label}
                                        value={`${url}/${item.route}`}
                                        classes={{ wrapper: styles.tab_wrapper }}
                                        component={Link}
                                        to={`${url}/${item.route}`}
                                        label={(
                                            <div className={styles.tab_label}>
                                                &nbsp;
                                                {item.label}
                                            </div>
                                        )}
                                        icon={<item.icon className={styles.tab_icon} />}
                                    />
                                )
                        ))
                    }
                </Tabs>
            </AppBar>
        </div>
    );
};

TabBar.defaultProps = {
    projectId: undefined,
    url: undefined,
};

// @ts-ignore
export default withRouter(TabBar);
