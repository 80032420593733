import { AppAction } from 'common/configStore';
import { AnyAction } from 'redux';
import {
    GET_USER_BEGIN,
    GET_USER_DISMISS_ERROR,
    GET_USER_FAILURE,
    GET_USER_SUCCESS,
} from 'redux/users/constants';

export const getUser = (userName: string, resetToken: boolean = false): AppAction => async (dispatch) => {
    dispatch({
        type: GET_USER_BEGIN,
    });

    const queryValues = !resetToken ? `
                _id
                email
                user_name
                project_ids
                liked_projects
                collaborating_on
                user_image
                date_created
        ` : `
        _id
        resetPasswordToken
        `;

    const query = `
            query {
              getUser(
                user_name: "${userName}"
              ) {
                ${queryValues}
              }
            }
        `;

    const opts: RequestInit = {
        method: 'POST',
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify({ query }),
    };

    return fetch(process.env.REACT_APP_GRAPHQL || '', opts)
        .then((response) => response.json())
        .then((createdJson) => {
            if (createdJson.errors) {
                dispatch({
                    type: GET_USER_FAILURE,
                    errors: createdJson.errors,
                });
            } else {
                dispatch({
                    type: GET_USER_SUCCESS,
                    data: createdJson.data.getUser,
                });
            }
        })
        .catch((errors) => {
            dispatch({
                type: GET_USER_FAILURE,
                errors,
            });
        });
};

// Async action saves request error by default, this method is used to dismiss the error info.
// If you don't want errors to be saved in Redux store, just ignore this method.
export const dismissGetUserError = () => ({
    type: GET_USER_DISMISS_ERROR,
});

export const reducer = (state: any, action: AnyAction) => {
    switch (action.type) {
    case GET_USER_BEGIN:
        // Just after a request is sent
        return {
            ...state,
            getUserPending: true,
            getUserError: null,
            getUserData: null,
        };

    case GET_USER_SUCCESS:
        // The request is success
        return {
            ...state,
            getUserPending: false,
            getUserError: null,
            getUserData: action.data,
        };

    case GET_USER_FAILURE:
        // The request is failed
        return {
            ...state,
            getUserData: null,
            getUserPending: false,
            getUserError: action.errors,
        };

    case GET_USER_DISMISS_ERROR:
        // Dismiss the request failure error
        return {
            ...state,
            getUserError: null,
        };

    default:
        return state;
    }
};
