import { AnyAction } from 'redux';
import { reducer as userTokenRefresh } from 'redux/users/userTokenRefresh';
import { initialState } from 'redux/users/initialState';
import { reducer as userAuthenReducer } from 'redux/users/userAuthen';
import { reducer as getUserReducer } from 'redux/users/getUser';
import { reducer as createUserReducer } from 'redux/users/createUser';
import { reducer as getUsersReducer } from 'redux/users/getUsers';
import { reducer as searchUsersReducer } from 'redux/users/searchUsers';
import { reducer as updateUserReducer } from 'redux/users/updateUser';
import { reducer as resetPasswordReducer } from 'redux/users/resetPassword';
import { reducer as checkSubscriptionReducer } from 'redux/users/checkSubscription';
import { reducer as cancelPremiumReducer } from 'redux/users/cancelPremium';
import { reducer as premiumCheckoutReducer } from 'redux/users/premiumCheckout';
import { reducer as reactivatePremiumReducer } from 'redux/users/reactivatePremium';
import { reducer as updateSubscriptionReducer } from 'redux/users/updateSubscription';
import { reducer as updateUserAuthenStoreReducer } from 'redux/users/updateUserAuthenStore';

const reducers = [
    userAuthenReducer,
    getUserReducer,
    createUserReducer,
    userTokenRefresh,
    getUsersReducer,
    searchUsersReducer,
    updateUserReducer,
    resetPasswordReducer,
    checkSubscriptionReducer,
    cancelPremiumReducer,
    premiumCheckoutReducer,
    reactivatePremiumReducer,
    updateSubscriptionReducer,
    updateUserAuthenStoreReducer,
];

export const reducer = (state = initialState, action: AnyAction) => {
    let newState;
    switch (action.type) {
    // Handle cross-topic actions here
    default:
        newState = state;
        break;
    }
    return reducers.reduce((s, r) => r(s, action), newState);
};
