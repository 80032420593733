import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import { useHistory } from 'react-router-dom';
import { Backdrop, Fade, IconButton, Modal } from '@material-ui/core';
import { useDispatch, useSelector } from 'common/hooks';
import styles from 'styles/newProjectModal.module.css';
import { General } from 'components/panels/General';
import { addProjectToUserAuthen, setProjectCreatedToFalse } from 'redux/actions';
import PremiumSuggestionModal from 'components/modals/premiumSuggestionModal/PremiumSuggestionModal';
import { hasHitProjectCap } from 'common/tierChecks';
import { UserTiers } from 'redux/users/models/User';
import { Close } from '@material-ui/icons';

interface NewProjectModalProps {
    closeAction: () => void;
    // eslint-disable-next-line no-restricted-globals
    open: boolean;
}

const NewProjectModal = ({ open, closeAction }: NewProjectModalProps) => {
    const dispatch = useDispatch();
    const history = useHistory();

    const {
        projectCreated,
        project,
        userSubscription,
        userAuthenData,
    } = useSelector((state) => ({
        projectCreated: state.projects.projectCreated,
        project: state.projects.project,
        userSubscription: state.users.userSubscription,
        userAuthenData: state.users.userAuthenData,
    }));

    useEffect(() => {
        if (projectCreated) {
            dispatch(setProjectCreatedToFalse());
            if (project?._id) {
                dispatch(addProjectToUserAuthen(project._id)).then(() => history.push(`/edit/project/${project._id}/sketches`));
            }
        }
    }, [projectCreated]); // eslint-disable-line

    const premiumWarning = hasHitProjectCap(userSubscription?.userTier || UserTiers.FREE, userAuthenData?.project_ids?.length || 0)
        ? (<PremiumSuggestionModal onClose={closeAction} open={open} title="You've reached your project limit." />) : null;

    return (
        <div>
            {premiumWarning || (
                <Modal
                    open={open}
                    onClose={closeAction}
                    className={styles.modal}
                    closeAfterTransition
                    BackdropComponent={Backdrop}
                    BackdropProps={{
                        timeout: 500,
                    }}
                >
                    <Fade in={open}>
                        <div className={styles.container}>
                            <div className={styles.top_container}>
                                <div className={styles.title}>New Project</div>
                                <IconButton onClick={closeAction}>
                                    <Close className={styles.close_icon} />
                                </IconButton>
                            </div>
                            <General newProject />
                        </div>
                    </Fade>
                </Modal>
            )}
        </div>
    );
};

NewProjectModal.propTypes = {
    open: PropTypes.bool.isRequired,
    closeAction: PropTypes.func.isRequired,
};

export default NewProjectModal;
