import React, { ChangeEvent, useState } from 'react';
import { useDispatch, useSelector } from 'common/hooks';
import { TextField, InputAdornment, IconButton } from '@material-ui/core';
import { Visibility, VisibilityOff } from '@material-ui/icons';
import { Alert } from '@material-ui/lab';
import StyledButton from 'components/buttons/StyledButton';
import styles from 'styles/changePassword.module.css';
import { dismissUpdateUserError, updateUser } from 'redux/actions';

interface ChangePasswordState {
    currPassword: string;
    newPassword: string;
    newPasswordConfirm: string;
    showPassword: boolean;
    showNewPassword: boolean;
}

const ChangePasswordView = () => {
    const dispatch = useDispatch();

    const [values, setValues] = useState<Partial<ChangePasswordState>>({
        currPassword: '',
        newPassword: '',
        newPasswordConfirm: '',
        showPassword: false,
        showNewPassword: false,
    });

    const {
        updateUserError,
        updateUserPending,
        userAuthenData,
        userUpdated,
    } = useSelector((state) => ({
        updateUserError: state.users.updateUserError,
        updateUserPending: state.users.updateUserPending,
        userAuthenData: state.users.userAuthenData,
        userUpdated: state.users.userUpdated,
    }));

    const handlePasswordChange = (field: string) => (e: ChangeEvent<HTMLTextAreaElement | HTMLInputElement>) => {
        const { value } = e.target;
        setValues((prevFields) => ({
            ...prevFields,
            [field]: value,
        }));
    };

    const newPasswordError = () => {
        let error = null;
        if (values?.newPasswordConfirm && values?.newPasswordConfirm?.length > 0) {
            if (values.newPassword !== values.newPasswordConfirm) {
                error = '*Passwords do not match';
            } else if (values?.newPasswordConfirm?.length < 8) {
                error = '*Password must be at least 8 characters';
            } else if (values.currPassword === values.newPasswordConfirm) {
                error = '*Must be different than current password';
            }
        }
        return error;
    };

    const handleShowPassword = () => {
        setValues((prevValues) => ({ ...prevValues, showPassword: !prevValues.showPassword }));
    };

    const handleShowNewPassword = () => {
        setValues((prevValues) => ({ ...prevValues, showNewPassword: !prevValues.showNewPassword }));
    };

    // Used after successful password change
    const resetState = () => {
        setValues({
            currPassword: '',
            newPassword: '',
            newPasswordConfirm: '',
            showPassword: false,
        });
    };

    // Error when failing to save password
    const passwordError = updateUserError ? (
        <Alert
            onClose={() => dispatch(dismissUpdateUserError())}
            className={styles.alert}
            severity='error'
        >
            There was an error changing your password. Please try again later.
        </Alert>
    ) : null;

    const passwordChanged = userUpdated && (
        <Alert
            onClose={() => dispatch(dismissUpdateUserError())}
            className={styles.alert}
            severity='success'
        >
            Password changed successfully.
        </Alert>
    );

    const canSubmit = () => (
        values.currPassword
        && values.newPasswordConfirm
        && values.currPassword.length > 0
        && values.newPasswordConfirm.length > 0
        && !newPasswordError()
    );

    const handleSubmit = async () => {
        // Create user object
        const updatedUser = {
            password: values.newPassword,
        };
        dispatch(updateUser(
            userAuthenData?._id,
            updatedUser,
            values.currPassword,
        )).then(() => resetState());
    };

    const submitButtonContent = updateUserPending ? 'Saving...' : 'Save';

    return (
        <div className={styles.container}>
            {passwordError || passwordChanged}
            <TextField
                variant='outlined'
                type={values.showPassword ? 'text' : 'password'}
                margin='dense'
                placeholder='Current password'
                value={values.currPassword}
                onChange={(e) => handlePasswordChange('currPassword')(e)}
                onKeyDown={(e) => {
                    if (e.key === 'Enter' && canSubmit()) {
                        handleSubmit();
                    }
                }}
                InputProps={{
                    endAdornment: (
                        <InputAdornment position='end'>
                            <IconButton
                                aria-label='toggle password visibility'
                                onClick={handleShowPassword}
                                onMouseDown={(e) => e.preventDefault()}
                                edge='end'
                            >
                                {values.showPassword ? <Visibility /> : <VisibilityOff />}
                            </IconButton>
                        </InputAdornment>
                    ),
                }}
            />
            <TextField
                variant='outlined'
                type={values.showNewPassword ? 'text' : 'password'}
                margin='dense'
                placeholder='New password'
                value={values.newPassword}
                onChange={(e) => handlePasswordChange('newPassword')(e)}
                onKeyDown={(e) => {
                    if (e.key === 'Enter' && canSubmit()) {
                        handleSubmit();
                    }
                }}
                InputProps={{
                    endAdornment: (
                        <InputAdornment position='end'>
                            <IconButton
                                aria-label='toggle password visibility'
                                onClick={handleShowNewPassword}
                                onMouseDown={(e) => e.preventDefault()}
                                edge='end'
                            >
                                {values.showNewPassword ? <Visibility /> : <VisibilityOff />}
                            </IconButton>
                        </InputAdornment>
                    ),
                }}
            />
            <TextField
                variant='outlined'
                type={values.showNewPassword ? 'text' : 'password'}
                margin='dense'
                placeholder='Confirm new password'
                error={!!newPasswordError()}
                helperText={newPasswordError()}
                value={values.newPasswordConfirm}
                onChange={(e) => handlePasswordChange('newPasswordConfirm')(e)}
                onKeyDown={(e) => {
                    if (e.key === 'Enter' && canSubmit()) {
                        handleSubmit();
                    }
                }}
                InputProps={{
                    endAdornment: (
                        <InputAdornment position='end'>
                            <IconButton
                                aria-label='toggle password visibility'
                                onClick={handleShowNewPassword}
                                onMouseDown={(e) => e.preventDefault()}
                                edge='end'
                            >
                                {values.showNewPassword ? <Visibility /> : <VisibilityOff />}
                            </IconButton>
                        </InputAdornment>
                    ),
                }}
            />
            <StyledButton
                disabled={!canSubmit() || updateUserPending}
                onClick={handleSubmit}
                bordRadius={5}
                style={{ width: '100%', marginTop: '1rem' }}
            >
                {submitButtonContent}
            </StyledButton>
        </div>
    );
};

export default ChangePasswordView;
