import { Project } from 'redux/projects/models/Project';

export interface ReduxProjectState {
    // getProject
    getProjectPending: boolean;
    getProjectError: any;
    project: Partial<Project> | null;
    // getProjects
    getProjectsPending: boolean;
    getProjectsError: any;
    projectList: Partial<Project>[] | null;
    // getShowcase
    getShowcaseError: any;
    getShowcasePending: boolean;
    showcaseList: Partial<Project>[] | null;
    // updateProject
    updatedProject: Partial<Project> | null;
    updateProjectError: any;
    updateProjectPending: boolean;
    projectUpdated: boolean;
    fileArrayError: any;
    // createProject
    createProjectError: any;
    createProjectPending: boolean;
    projectCreated: boolean;
    // updateProjectVisibility
    updateProjectVisibilityPending: boolean;
    updateProjectVisibilityError: any;
    // updateCollaborators
    updateCollaboratorsPending: boolean;
    updateCollaboratorsError: any;
    // deleteProject
    deleteProjectPending: boolean;
    deleteProjectError: any;
    projectDeleted: boolean;
}

export const initialState: ReduxProjectState = {
    // getProject
    getProjectPending: false,
    getProjectError: null,
    project: null,
    // getProjects
    getProjectsPending: false,
    getProjectsError: null,
    projectList: null,
    // getShowcase
    getShowcaseError: null,
    getShowcasePending: false,
    showcaseList: null,
    // updateProject
    updatedProject: null,
    updateProjectError: null,
    updateProjectPending: false,
    projectUpdated: false,
    fileArrayError: null,
    // createProject
    createProjectError: null,
    createProjectPending: false,
    projectCreated: false,
    // updateProjectVisibility
    updateProjectVisibilityPending: false,
    updateProjectVisibilityError: null,
    // updateCollaborators
    updateCollaboratorsPending: false,
    updateCollaboratorsError: null,
    // deleteProject
    deleteProjectPending: false,
    deleteProjectError: null,
    projectDeleted: false,
};
