import React from 'react';
import Modal from 'components/Modal';
import { CollaboratorModalView } from 'components/modals/CollaboratorModal/CollaboratorModalView';
import styles from 'styles/collaboratorModal.module.css';

interface CollaboratorModalProps {
    projectName?: string;
    // eslint-disable-next-line no-restricted-globals
    open: boolean;
    onClose: any;
}

export const CollaboratorModalContainer = (props: CollaboratorModalProps) => {
    const { projectName, open, onClose } = props;

    const title = (
        <>
            Add collaborators
            {projectName && (
                <span>
                    {' to '}
                    <span className={styles.project_name}>{projectName}</span>
                </span>
            )}
        </>
    );

    return (
        <Modal title={title} open={open} onClose={onClose}>
            <CollaboratorModalView onClose={onClose} />
        </Modal>
    );
};

CollaboratorModalContainer.defaultProps = {
    projectName: '',
};
