import { logPageView } from 'common/analytics/firebaseEvents';
import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'common/hooks';
import {
    Container,
    Grid,
    TextField,
    Button,
} from '@material-ui/core';
import { Alert } from '@material-ui/lab';
import { Loading } from 'components/Loading';
import { contactSend } from 'redux/actions';
import { EReCaptchaV2Size, EReCaptchaV2Theme, ReCaptchaV2, ReCaptchaV3 } from 'react-recaptcha-x';
import { v2Callback, v3Callback } from 'common/recaptcha';
import withHelmet from 'components/hocs/withHelmet';
import { PAGE_NAMES } from 'constants/metaData';
import styles from 'styles/contact.module.css';

const Contact = () => {
    const dispatch = useDispatch();
    const [name, setName] = useState<string>('');
    const [email, setEmail] = useState<string>('');
    const [message, setMessage] = useState<string>('');
    const [recaptchaDone, setRecaptchaDone] = useState<boolean>(false);

    const {
        contactSendPending,
        contactSendError,
        contactSent,
    } = useSelector((state) => ({
        contactSendPending: state.common.contactSendPending,
        contactSendError: state.common.contactSendError,
        contactSent: state.common.contactSent,
    }));

    useEffect(() => {
        logPageView();
    }, []);

    const sendMessage = () => {
        dispatch(contactSend(name, email, message));
    };

    const loadingScreen: JSX.Element | null = contactSendPending ? (<Loading />) : null;

    const errorAlert: JSX.Element | null = contactSendError ? (
        <Alert
            severity='error'
            className={styles.alert}
        >
            Failed to send message. Please try again later
        </Alert>
    ) : null;

    const successAlert: JSX.Element | null = contactSent ? (
        <Alert
            severity='success'
            className={styles.alert}
        >
            Message sent. We&apos;ll get in contact with you soon.
        </Alert>
    ) : null;

    return (
        <div>
            {loadingScreen}
            {errorAlert}
            {successAlert}
            <h1 className={styles.form_header}>Get In Touch</h1>
            {/* eslint-disable-next-line react/no-unescaped-entities */}
            <h3 className={styles.form_info}>We would love to hear from you. Fill out this short form and we'll get back to you ASAP.</h3>

            <Container className={styles.form_container} maxWidth={false}>
                <form>
                    <Grid container spacing={3}>
                        <Grid item xs={12}>
                            <Grid container spacing={2}>
                                <Grid item xs={12}>
                                    <TextField
                                        disabled={contactSent || contactSendError}
                                        fullWidth
                                        label='Name'
                                        name='name'
                                        size='small'
                                        variant='outlined'
                                        value={name}
                                        required
                                        onChange={
                                            (event) => (setName(event.target.value))
                                        }
                                    />
                                </Grid>
                                <Grid item xs={12}>
                                    <TextField
                                        disabled={contactSent || contactSendError}
                                        fullWidth
                                        label='Email'
                                        name='email'
                                        size='small'
                                        variant='outlined'
                                        value={email}
                                        required
                                        onChange={
                                            (event) => (setEmail(event.target.value))
                                        }
                                    />
                                </Grid>
                                <Grid item xs={12}>
                                    <TextField
                                        disabled={contactSent || contactSendError}
                                        fullWidth
                                        label='Message'
                                        name='message'
                                        size='medium'
                                        type='message'
                                        variant='outlined'
                                        value={message}
                                        multiline
                                        rows={10}
                                        max-rows={20}
                                        required
                                        onChange={
                                            (event) => (setMessage(event.target.value))
                                        }
                                    />
                                </Grid>
                            </Grid>
                        </Grid>
                        <Grid
                            container
                            spacing={0}
                            direction='row'
                            alignContent='center'
                            justify='center'
                        >
                            <ReCaptchaV2
                                callback={(token) => v2Callback(token, setRecaptchaDone)}
                                theme={EReCaptchaV2Theme.Light}
                                size={EReCaptchaV2Size.Normal}
                                id='recaptchaV2'
                                tabindex={0}
                            />
                            <ReCaptchaV3 action='loginAction' callback={v3Callback} />
                        </Grid>
                        <Grid item xs={12}>
                            <Button
                                disabled={email === '' || name === '' || message === '' || contactSent || contactSendError || !recaptchaDone}
                                color='inherit'
                                fullWidth
                                variant='contained'
                                className={styles.send_button}
                                onClick={() => sendMessage()}
                            >
                                Send
                            </Button>
                        </Grid>
                    </Grid>
                </form>
            </Container>
        </div>
    );
};

Contact.displayName = PAGE_NAMES.CONTACT;

export default withHelmet(Contact);
