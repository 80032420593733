import React, { useEffect, useRef, useState, MouseEvent } from 'react';
import { Button } from '@material-ui/core';
import FavoriteIcon from '@material-ui/icons/Favorite';
import FavoriteBorder from '@material-ui/icons/FavoriteBorder';
import { shallowEqual } from 'react-redux';
import { useSelector } from 'common/hooks';
import styles from 'styles/likeButton.module.css';

interface LikeButtonProps {
    liked: boolean;
    likeCount: number;
    likeAction: any;
}

export const LikeButton = ({ liked, likeCount, likeAction }: LikeButtonProps) => {

    const [isLiked, setIsLiked] = useState<boolean>(liked); // Local like state
    const [newCount, setNewCount] = useState<number>(likeCount); // Local count state
    const [updateCount, setUpdateCount] = useState<boolean>(false); // Flag to determine if the like count should update

    // TODO - Add an error state should liking fail
    const {
        authenticated,
        updateLikeStatusList,
        updateLikeStatusPending,
        // updateLikeStatusError,
    } = useSelector((state) => ({
        authenticated: state.users.authenticated,
        updateLikeStatusList: state.common.updatedLikeList,
        updateLikeStatusPending: state.common.updateLikeStatusPending,
        updateLikeStatusError: state.common.updateLikeStatusError,
    }), shallowEqual);

    const pendingRef = useRef(updateLikeStatusPending);

    useEffect(() => {
        // This will only run when updateLikeStatusPending changes and the like count needs to update
        if (!pendingRef.current && updateCount && updateLikeStatusList) {
            setNewCount(updateLikeStatusList?.length);
            setUpdateCount(false);
            setIsLiked(!isLiked);
        }
    }, [updateLikeStatusList, isLiked, setIsLiked]); // eslint-disable-line

    // Updates like value passed from props if there is any change
    useEffect(() => {
        setIsLiked(liked);
        setNewCount(likeCount);
    }, [liked, likeCount]);

    const updateLike = (event: MouseEvent<HTMLButtonElement>) => {
        setUpdateCount(true);
        likeAction(event, !isLiked);
    };

    return (
        <div className={styles.container}>
            <Button
                className={styles.button}
                aria-label='add to favorites'
                onClick={updateLike}
                disabled={!authenticated || updateLikeStatusPending}
            >
                {!isLiked ? <FavoriteBorder className={styles.icon} /> : <FavoriteIcon className={styles.icon} />}
            </Button>
            <div className={styles.divider} />
            <div className={styles.counter}>{newCount}</div>
        </div>
    );
};
