import { AppAction } from 'common/configStore';
import { firebaseAnalytics } from 'common/firebase';
import { AnyAction } from 'redux';
import {
    UPDATE_LIKE_STATUS_BEGIN,
    UPDATE_LIKE_STATUS_DISMISS_ERROR,
    UPDATE_LIKE_STATUS_FAILURE,
    UPDATE_LIKE_STATUS_SUCCESS,
} from 'redux/common/constants';

export const updateLikeStatus = (
    userId: string = '',
    projectId: string = '',
    likeStatus: boolean,
): AppAction => async (dispatch) => {

    dispatch({
        type: UPDATE_LIKE_STATUS_BEGIN,
    });
    // Strings for GraphQL need to have double-quotes around them or else the query fails

    const query = `
            mutation {
                updateLikeStatus(
                    project_id: "${projectId}"
                    user_id: "${userId}"
                    liked: ${likeStatus}
                )
            }
        `;

    const opts: RequestInit = {
        credentials: 'include',
        method: 'POST',
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify({ query }),
    };

    return fetch(process.env.REACT_APP_GRAPHQL || '', opts)
        .then((response) => response.json())
        .then((createdJson) => {
            if (createdJson.errors) {
                dispatch({
                    type: UPDATE_LIKE_STATUS_FAILURE,
                    errors: createdJson.errors,
                });
            } else {
                firebaseAnalytics?.logEvent('like_clicked', {
                    user_id: userId,
                    project_id: projectId,
                    liked: likeStatus,
                });
                dispatch({
                    type: UPDATE_LIKE_STATUS_SUCCESS,
                    data: createdJson.data.updateLikeStatus,
                });
            }
        })
        .catch((errors) => {
            dispatch({
                type: UPDATE_LIKE_STATUS_FAILURE,
                errors,
            });
        });
};

// Async action saves request error by default, this method is used to dismiss the error info.
// If you don't want errors to be saved in Redux store, just ignore this method.
export const dismissUpdateLikeStatusError = () => ({
    type: UPDATE_LIKE_STATUS_DISMISS_ERROR,
});

export const reducer = (state: any, action: AnyAction) => {
    switch (action.type) {
    case UPDATE_LIKE_STATUS_BEGIN:
        // Just after a request is sent
        return {
            ...state,
            updateLikeStatusPending: true,
            updateLikeStatusError: null,
            updatedLikeList: null,
        };

    case UPDATE_LIKE_STATUS_SUCCESS:
        // The request is success
        return {
            ...state,
            updateLikeStatusPending: false,
            updateLikeStatusError: null,
            updatedLikeList: action.data,
        };

    case UPDATE_LIKE_STATUS_FAILURE:
        // The request is failed
        return {
            ...state,
            updatedLikeList: null,
            updateLikeStatusPending: false,
            updateLikeStatusError: action.errors,
        };

    case UPDATE_LIKE_STATUS_DISMISS_ERROR:
        // Dismiss the request failure error
        return {
            ...state,
            updateLikeStatusError: null,
        };

    default:
        return state;
    }
};
