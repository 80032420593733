import React, { CSSProperties, useState } from 'react';
import PropTypes from 'prop-types';
import { useSelector, useDispatch } from 'common/hooks';
import { FixedSizeList } from 'react-window';
import { OutlinedInput, Checkbox } from '@material-ui/core';
import { Alert } from '@material-ui/lab';
import SearchIcon from '@material-ui/icons/Search';
import { Project } from 'redux/projects/models/Project';
import { User } from 'redux/users/models/User';
import { searchUsers, dismissSearchUsersError, clearSearchUsersData } from 'redux/users/searchUsers';
import { updateCollaborators } from 'redux/projects/updateCollaborators';
import { Loading } from 'components/Loading';
import StyledButton from 'components/buttons/StyledButton';
import styles from 'styles/collaboratorModal.module.css';

interface RowProps {
    index: number;
    style: CSSProperties;
    data: any;
}

const Row = ({ index, style, data }: RowProps) => {
    const user = data.filteredResults[index];
    const { selected, handleSelect } = data;

    const isChecked = selected.includes(user._id);

    return (
        <div style={style} key={user._id}>
            <div className={styles.result}>
                <div>{user.user_name}</div>
                <Checkbox
                    id={`${user._id}`}
                    checked={isChecked}
                    style={{ padding: 0 }}
                    onChange={(e) => handleSelect(e, user._id)}
                />
            </div>
        </div>
    );
};

interface ResultsListProps {
    user: Partial<User> | null;
    project: Partial<Project> | null;
    results?: Partial<User>[];
    selected: string[];
    handleSelect: any;
}

const ResultsList = (props: ResultsListProps) => {
    const { user, project, results, selected, handleSelect } = props;
    const collaborators = project?.collaborators ?? [];

    // filter out the project owner and users who are already collaborators from search results
    const filteredResults = results?.filter((u) => (
        !collaborators.includes(u?._id ?? '') && u._id !== user?._id
    ));

    const calculatedHeight = (filteredResults?.length ?? 0) < 5 ? (filteredResults?.length ?? 0) * 40 : 200;

    return (!filteredResults ? null : (
        <FixedSizeList
            height={calculatedHeight}
            itemSize={40}
            itemCount={filteredResults.length}
            itemData={{ filteredResults, selected, handleSelect }}
            width={350}
        >
            {Row}
        </FixedSizeList>
    ));
};

ResultsList.defaultProps = {
    results: [],
};

interface CollaboratorModalViewProps {
    onClose: any;
}
// TODO - Revisit implementation and types
export const CollaboratorModalView = (props: CollaboratorModalViewProps) => {
    const { onClose } = props;
    const [query, setQuery] = useState('');
    const [selected, setSelected] = useState([]); // array of user ids selected
    const dispatch = useDispatch();
    const {
        userAuthenData,
        project,
        searchUsersPending,
        searchUsersError,
        searchUsersData,
    } = useSelector((state) => ({
        userAuthenData: state.users.userAuthenData,
        project: state.projects.project,
        searchUsersPending: state.users.searchUsersPending,
        searchUsersError: state.users.searchUsersError,
        searchUsersData: state.users.searchUsersData,
    }));

    const handleChange = (e: any) => {
        setQuery(e.target.value);
    };

    const handleSearch = () => {
        dispatch(searchUsers(query));
    };

    // @ts-ignore
    const handleSelect = (e, _id) => {
        if (e.target.checked) {
            // @ts-ignore
            setSelected([...selected, _id]);
        } else {
            const newSelected = selected.filter((id) => id !== _id);
            setSelected(newSelected);
        }
    };

    const handleSubmit = () => {
        dispatch(updateCollaborators(userAuthenData?._id, project?._id, [...(project?.collaborators ?? []), ...selected]))
            .then(() => {
                dispatch(clearSearchUsersData());
                onClose();
            });
    };

    const errorSection = (
        <div>
            <Alert
                severity='error'
                onClose={() => dispatch(dismissSearchUsersError())}
            >
                Failed to fetch users. Please try again.
            </Alert>
        </div>
    );

    const buttonContainer = (
        <div className={styles.button_container}>
            <div style={{ flex: 1 }}>
                {searchUsersData ? (
                    <StyledButton
                        width='100%'
                        bordRadius={5}
                        disabled={!query}
                        onClick={handleSearch}
                        outline
                    >
                        Search
                    </StyledButton>
                ) : (
                    <StyledButton
                        width='100%'
                        bordRadius={5}
                        disabled={!query}
                        onClick={handleSearch}
                    >
                        Search
                    </StyledButton>
                )}
            </div>
            {searchUsersData && (
                <div style={{ flex: 2 }}>
                    <StyledButton
                        width='100%'
                        bordRadius={5}
                        disabled={selected.length < 1}
                        onClick={handleSubmit}
                    >
                        Add
                    </StyledButton>
                </div>
            )}
        </div>
    );

    return (
        <>
            <OutlinedInput
                margin='dense'
                placeholder='Search by username'
                endAdornment={<SearchIcon color='disabled' />}
                onChange={handleChange}
                onKeyDown={(e) => {
                    if (e.key === 'Enter') {
                        handleSearch();
                    }
                }}
                autoFocus
            />
            {searchUsersPending && <Loading />}
            {searchUsersError && errorSection}
            {searchUsersData && (
                <ResultsList
                    user={userAuthenData}
                    project={project}
                    results={searchUsersData}
                    selected={selected}
                    handleSelect={handleSelect}
                />
            )}
            {buttonContainer}
        </>
    );
};

CollaboratorModalView.propTypes = {
    onClose: PropTypes.func.isRequired,
};
