import React, { CSSProperties } from 'react';

const Card = ({ children, style }: { children: React.ReactNode, style?: CSSProperties }) => (
    <div style={{ ...style, borderRadius: 10, boxShadow: 'rgba(0, 0, 0, 0.35) 0px 5px 15px' }}>
        {children}
    </div>
);

Card.defaultProps = {
    style: {},
};

export default Card;
