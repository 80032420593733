import React, { MouseEventHandler, useCallback, useEffect, useState } from 'react';
import Modal from 'components/Modal';
import { useDispatch, useSelector } from 'common/hooks';
import ChangePasswordView from 'components/account/ChangePasswordView';
import ChangeProfilePictureView from 'components/account/ChangeProfilePictureView';
import StyledButton from 'components/buttons/StyledButton';
import { isCanceled } from 'constants/subscription';
import {
    cancelPremium,
    dismissCancelPremiumError,
    dismissReactivatePremiumError,
    premiumCheckout,
    updateSubscription,
    reactivatePremium,
    dismissUpdateSubscriptionError,
} from 'redux/actions';
import THEME_COLORS from 'utils/themeColors';
import changePasswordStyles from 'styles/changePassword.module.css';
import { Loading } from 'components/Loading';
import { Alert } from '@material-ui/lab';
import styles from 'styles/accountSettings.module.css';
import { useHistory } from 'react-router-dom';
import { isFinalTier, isPremium, isUpgradable } from 'common/tierChecks';
import { UserTiers } from 'redux/users/models/User';
import { PremiumTable } from 'components/PremiumTable';
import { USER_TIER_1_PRICE_ID, USER_TIER_2_PRICE_ID } from 'common/envConstants';
import { COUTURE, TRES_CHIC } from 'common/textConstants';

export interface AccountSettingsModalProps {
    // eslint-disable-next-line no-restricted-globals
    open: boolean;
    onClose: MouseEventHandler<HTMLAnchorElement> | undefined;
}

const AccountSettingsModal = (props: AccountSettingsModalProps) => {
    const { open, onClose: superOnClose } = props;
    const dispatch = useDispatch();
    const history = useHistory();
    const {
        cancelPremiumPending,
        cancelPremiumError,
        premiumCancelled,
        reactivatePremiumPending,
        reactivatePremiumError,
        reactivatedPremium,
        userAuthenData,
        userSubscription,
        updateSubscriptionPending,
        updateSubscriptionError,
        subscriptionUpdated,
    } = useSelector((state) => ({
        cancelPremiumPending: state.users.cancelPremiumPending,
        cancelPremiumError: state.users.cancelPremiumError,
        premiumCancelled: state.users.premiumCancelled,
        reactivatePremiumPending: state.users.reactivatePremiumPending,
        reactivatePremiumError: state.users.reactivatePremiumError,
        reactivatedPremium: state.users.reactivatedPremium,
        userAuthenData: state.users.userAuthenData,
        userSubscription: state.users.userSubscription,
        updateSubscriptionPending: state.users.updateSubscriptionPending,
        updateSubscriptionError: state.users.updateSubscriptionError,
        subscriptionUpdated: state.users.subscriptionUpdated,
    }));

    const [viewOption, setViewOption] = useState<number>(0);

    const viewMapping = new Map<number, string>([
        [0, 'Account Settings'],
        [1, 'Change Password'],
        [2, 'Change Profile Picture'],
        [3, 'Manage Premium'],
    ]);

    const onClose = useCallback(() => {
        // state is retained when the modal is closed for some reason
        // likely because the modal component isn't actually unmounted - just hidden
        // reset so next time account settings is opened, the menu is presented
        setViewOption(0);
        if (superOnClose) {
            // @ts-ignore
            superOnClose();
        }
    }, [superOnClose]);

    useEffect(() => {
        if (subscriptionUpdated) {
            dispatch(dismissUpdateSubscriptionError()).then(() => {
                onClose();
                history.push('/premium-welcome');
            });
        }
    }, [subscriptionUpdated]);

    useEffect(() => {
        if (premiumCancelled) {
            history.push('/cancelled');
        }
    }, [premiumCancelled]);

    useEffect(() => {
        if (reactivatedPremium) {
            history.push('/premium-welcome', {
                reactivating: true,
            });
        }
    }, [reactivatedPremium]);

    const [title, setTitle] = useState<string | undefined>(viewMapping.get(0));

    const changeView = (option: number) => {
        setTitle(viewMapping.get(option));
        setViewOption(option);
    };

    const handlePremiumCheckout = (priceID: string) => {
        if (userAuthenData?._id) {
            dispatch(premiumCheckout(userAuthenData._id, priceID));
        }
    };

    const changeSubscription = (priceID: string) => {
        dispatch(updateSubscription(userAuthenData?._id, priceID));
    };

    const menuButtonStyle = {
        width: '100%',
        backgroundColor: 'transparent',
        color: 'black',
        fontSize: '1.2rem',
        border: 'none',
        borderRadius: '0',
    };

    const premiumButtonStyle = {
        ...menuButtonStyle,
        backgroundColor: THEME_COLORS.salmon,
        color: 'white',
    };

    const reactivateButtonStyle = {
        ...menuButtonStyle,
        backgroundColor: THEME_COLORS.lightPurple,
        color: 'white',
    };

    const reactivateListItem = isCanceled(userSubscription?.status) || userSubscription?.cancel_at_period_end ? (
        <li>
            <StyledButton
                onClick={() => changeView(3)}
                style={reactivateButtonStyle}
            >
                Reactivate Premium
            </StyledButton>
        </li>
    ) : null;

    const downgradeItemList = !reactivateListItem && isFinalTier(userSubscription?.userTier || UserTiers.FREE) ? (
        <li>
            <StyledButton
                style={menuButtonStyle}
                onClick={() => setViewOption(6)}
            >
                Downgrade your subscription
            </StyledButton>
        </li>
    ) : null;

    const upgradeItemList = !reactivateListItem && isUpgradable(userSubscription?.userTier || UserTiers.FREE) ? (
        <li>
            <StyledButton
                style={reactivateButtonStyle}
                onClick={() => setViewOption(5)}
            >
                Upgrade your subscription
            </StyledButton>
        </li>
    ) : null;

    const cancelListItem = isPremium(userSubscription?.userTier || UserTiers.FREE) && !reactivateListItem ? (
        <li>
            <StyledButton
                onClick={() => changeView(3)}
                style={menuButtonStyle}
            >
                Cancel Premium
            </StyledButton>
        </li>
    ) : null;

    // TODO - Change view to show a table of both tiers
    const premiumListItem = !isPremium(userSubscription?.userTier || UserTiers.FREE) ? (
        <li>
            <StyledButton
                onClick={() => changeView(4)}
                style={premiumButtonStyle}
            >
                Get Premium!
            </StyledButton>
        </li>
    ) : null;

    const Menu = () => {
        const listElements: JSX.Element[] = [];

        viewMapping.forEach((itemTitle, key) => {
            if (key !== 0 && key !== 3) {
                listElements.push(
                    <li className={styles.menu_item} key={itemTitle}>
                        <StyledButton
                            onClick={() => changeView(key)}
                            style={menuButtonStyle}
                        >
                            {itemTitle}
                        </StyledButton>
                    </li>,
                );
            }
        });

        return (
            <ul className={styles.menu}>
                {premiumListItem}
                {upgradeItemList}
                {downgradeItemList}
                {cancelListItem}
                {reactivateListItem}
                {listElements}
            </ul>
        );
    };

    const AcceptPanel = () => {
        const reactivating = cancelListItem === null;

        const cancelError = cancelPremiumError ? (
            <Alert
                className={styles.alert}
                severity='error'
                onClose={() => dispatch(dismissCancelPremiumError())}
            >
            Failed to cancel premium. Please try again later.
            </Alert>
        ) : null;
        const reactivateError = reactivatePremiumError ? (
            <Alert
                className={styles.alert}
                severity='error'
                onClose={() => dispatch(dismissReactivatePremiumError())}
            >
            Failed to re-activate premium. Please try again later.
            </Alert>
        ) : null;

        const acceptAction = () => {
            if (reactivating && userAuthenData?._id) {
                dispatch(reactivatePremium(userAuthenData._id)).then(() => {
                    changeView(0);
                    if (onClose) {
                        // @ts-ignore
                        onClose();
                    }
                });
            } else if (!reactivating && userAuthenData?._id) {
                dispatch(cancelPremium(userAuthenData._id)).then(() => {
                    changeView(0);
                    if (onClose) {
                        // @ts-ignore
                        onClose();
                    }
                });
            }
        };

        return (
            <div className={changePasswordStyles.container}>
                {reactivating ? reactivateError : cancelError}
                <h1>
                Are you sure you want to
                    {reactivating ? ' re-activate premium' : ' cancel premium'}
                ?
                </h1>
                <StyledButton
                    onClick={() => acceptAction()}
                    style={{
                        marginBottom: '1.5em',
                        marginTop: '1.5rem',
                    }}
                    width='100%'
                    disabled={reactivatePremiumPending || cancelPremiumPending}
                >
                Yes
                </StyledButton>
                <StyledButton outline width='100%' onClick={() => changeView(0)}>No</StyledButton>
            </div>
        );
    };

    const ChangeSubscriptionPanel = (props: { upgrade: boolean }) => {

        const { upgrade } = props;

        const priceID = upgrade ? USER_TIER_2_PRICE_ID : USER_TIER_1_PRICE_ID;
        const description = upgrade ? 'Are you sure you want to upgrade your subscription to (Tier 2)?\n'
            + 'This will apply immediately and you will be prorated for the days in this tier during your next billing period.'
            : 'Are you sure you want to downgrade your subscription to (Tier 1)?\n'
            + 'This will apply immediately and you will be prorated/refunded for the days in this new tier during your next billing period.';

        const yesColor = upgrade ? THEME_COLORS.salmon : THEME_COLORS.burgundyRed;

        return (
            <div className={styles.modalInnerContainer}>
                {updateSubscriptionError && (
                    <Alert
                        onClose={() => dispatch(dismissUpdateSubscriptionError())}
                        className={styles.alert}
                        severity='error'
                    >
                        There was a problem updating your subscription. Please try again later
                    </Alert>
                )}
                <h1 className={styles.modalTitle}>
                    {description}
                </h1>
                <div className={styles.buttonRow}>
                    <div className={styles.modalButton}>
                        <StyledButton
                            style={{
                                borderColor: THEME_COLORS.lightPurple,
                            }}
                            outline
                            textColor='white'
                            onClick={() => setViewOption(0)}
                        >
                            No
                        </StyledButton>
                    </div>
                    <div className={styles.spacer} />
                    <div className={styles.modalButton}>
                        <StyledButton
                            bgColor={yesColor}
                            textColor='white'
                            disabled={false}
                            onClick={() => changeSubscription(priceID || '')}
                        >
                            Yes
                        </StyledButton>
                    </div>
                </div>
            </div>
        );
    };

    return (
        <>
            <Modal
                title={title}
                open={open}
                onClose={onClose}
                onBack={viewOption !== 0 ? () => changeView(0) : undefined}
                style={{
                    zIndex: 1,
                }}
                maxWidth={viewOption === 4 ? 'md' : 'sm'}
            >
                {/* TODO - Fix styling for premium table */}
                <div className={styles.container}>
                    {viewOption === 0 && <Menu />}
                    {viewOption === 1 && <ChangePasswordView />}
                    {viewOption === 2 && <ChangeProfilePictureView />}
                    {viewOption === 3 && <AcceptPanel />}
                    {viewOption === 4 && (
                        <PremiumTable
                            ctaOnClickTier1={() => handlePremiumCheckout(USER_TIER_1_PRICE_ID || '')}
                            ctaOnClickTier2={() => handlePremiumCheckout(USER_TIER_2_PRICE_ID || '')}
                            // TODO - Move these to constants files
                            ctaLabelTier1={`Get ${COUTURE}`}
                            ctaLabelTier2={`Get ${TRES_CHIC}`}
                        />
                    )}
                    {viewOption === 5 && <ChangeSubscriptionPanel upgrade />}
                    {viewOption === 6 && <ChangeSubscriptionPanel upgrade={false} />}
                </div>
            </Modal>
            {cancelPremiumPending || reactivatePremiumPending || updateSubscriptionPending ? (<Loading />) : null}
        </>
    );
};

export default AccountSettingsModal;
