import React from 'react';
import { useMediaQuery } from '@material-ui/core';
import { ProjectSection } from 'pages/Project';
import sketchesIcon from 'assets/icons/pencil.svg';
import materialIcon from 'assets/icons/fabrics.svg';
import measurementsIcon from 'assets/icons/tape_measure.svg';
import outcomeIcon from 'assets/icons/fashion_design.svg';
import THEME_COLORS from 'utils/themeColors';
import styles from './styles/navigationPanel.module.css';

interface Props {
    section: ProjectSection;
    setSection: (section: ProjectSection) => void;
    availableSections: ProjectSection[];
}

export const NavigationPanel = ({ section, setSection, availableSections }: Props) => {
    const matches = useMediaQuery('(min-width: 760px)');

    // assets are not all the same size, so specific adjustment is needed
    const iconMap = {
        sketches: {
            icon: sketchesIcon,
            style: {
                height: '3rem',
                width: '3rem',
            },
        },
        materials: {
            icon: materialIcon,
            style: {
                height: '1.5rem',
                width: '1.5rem',
            },
        },
        measurements: {
            icon: measurementsIcon,
            style: {
                height: '2.2rem',
                width: '2.2rem',
            },
        },
        outcome: {
            icon: outcomeIcon,
            style: {
                height: '2rem',
                width: '2rem',
            },
        },
    };
    return (
        <div className={styles.container}>
            <ul className={styles.menu_list}>
                {
                    availableSections.map((s) => (
                        // eslint-disable-next-line jsx-a11y/click-events-have-key-events, jsx-a11y/no-noninteractive-element-interactions
                        <li
                            key={s}
                            className={styles.menu_item}
                            style={s === section
                                ? {
                                    backgroundColor: 'white',
                                    color: THEME_COLORS.salmon,
                                    borderRadius: '5px',
                                }
                                : undefined}
                            onClick={() => setSection(s)}
                        >
                            <div style={{ display: 'flex', justifyContent: 'center', width: '3rem' }}>
                                <img style={iconMap[s].style} src={iconMap[s].icon} alt={`${s} icon`} />
                            </div>
                            <span>{s}</span>
                        </li>
                    ))
                }
            </ul>
        </div>
    );
};
