import { AppAction } from 'common/configStore';
import { firebaseAnalytics } from 'common/firebase';
import { AnyAction } from 'redux';
import {
    PREMIUM_CHECKOUT_BEGIN,
    PREMIUM_CHECKOUT_DISMISS_ERROR,
    PREMIUM_CHECKOUT_FAILURE,
    PREMIUM_CHECKOUT_SUCCESS,
} from 'redux/users/constants';

export const premiumCheckout = (userID: string = '', priceID: string = ''): AppAction => async (dispatch) => {
    dispatch({
        type: PREMIUM_CHECKOUT_BEGIN,
    });

    const query = `
       mutation {
          premiumCheckout(
            userID: "${userID}"
            priceID: "${priceID}"
          )
        } 
    `;

    const opts: RequestInit = {
        method: 'POST',
        credentials: 'include',
        headers: {
            'Content-Type': 'application/json',
        },
        body: JSON.stringify({ query }),
    };

    return fetch(`${process.env.REACT_APP_GRAPHQL}`, opts)
        .then((response) => response.json())
        .then((session) => {
            if (!session.errors && session?.data?.premiumCheckout) {
                firebaseAnalytics?.logEvent(
                    'premium_checkout',
                    {
                        user_Id: userID,
                    },
                );
                // @ts-ignore
                const stripe = window.Stripe(process.env.REACT_APP_STRIPE_KEY);
                return stripe.redirectToCheckout({
                    sessionId: session?.data?.premiumCheckout,
                });
            }
            return session;
        }).then((results) => {
            if (results?.errors) {
                dispatch({
                    type: PREMIUM_CHECKOUT_FAILURE,
                    error: results.errors,
                });
            } else {
                dispatch({
                    type: PREMIUM_CHECKOUT_SUCCESS,
                });
            }
        })
        .catch((err) => {
            dispatch({
                type: PREMIUM_CHECKOUT_FAILURE,
                error: err,
            });
        });
};

// Async action saves request error by default, this method is used to dismiss the error info.
// If you don't want errors to be saved in Redux store, just ignore this method.
export const dismissPremiumCheckoutError = () => ({
    type: PREMIUM_CHECKOUT_DISMISS_ERROR,
});

export const reducer = (state: any, action: AnyAction) => {
    switch (action.type) {
    case PREMIUM_CHECKOUT_BEGIN:
        // Just after a request is sent
        return {
            ...state,
            premiumCheckoutPending: true,
            premiumCheckoutError: null,
            premiumBought: false,
        };

    case PREMIUM_CHECKOUT_SUCCESS:
        // The request is success
        return {
            ...state,
            premiumCheckoutPending: false,
            premiumCheckoutError: null,
            premiumBought: true,
        };

    case PREMIUM_CHECKOUT_FAILURE:
        // The request is failed
        return {
            ...state,
            premiumCheckoutPending: false,
            premiumCheckoutError: action.error,
            premiumBought: false,
        };

    case PREMIUM_CHECKOUT_DISMISS_ERROR:
        // Dismiss the request failure error
        return {
            ...state,
            premiumCheckoutError: null,
        };

    default:
        return state;
    }
};
