/* eslint-disable react/require-default-props */
import React, { CSSProperties, MouseEventHandler } from 'react';
import { Dialog, IconButton, DialogProps } from '@material-ui/core';
import { ArrowBack, Close } from '@material-ui/icons';
import styles from 'styles/modal.module.css';

interface OwnProps {
    title?: string | JSX.Element;
    titleStyle?: CSSProperties;
    // eslint-disable-next-line no-restricted-globals
    open: boolean;
    // eslint-disable-next-line no-restricted-globals
    onClose?: MouseEventHandler<any>;
    onBack?: () => void;
    children?: React.ReactNode;
}

// Dialog already has a title prop which conflicts with our own
type ModalProps = OwnProps & Omit<DialogProps, 'title'>;

const defaultTitleStyle = { fontWeight: 500 };

const Modal = (props: ModalProps) => {
    const {
        title = '',
        titleStyle = defaultTitleStyle,
        open,
        onClose,
        children,
        onBack,
        ...dialogProps
    } = props;

    return (
        <Dialog open={open} onClose={onClose} {...dialogProps}>
            <div className={styles.top_container}>
                <IconButton onClick={onClose}>
                    <Close className={styles.close_icon} />
                </IconButton>
            </div>
            <div className={styles.container}>
                <div className={styles.title} style={titleStyle as CSSProperties}>
                    {onBack && (
                        <IconButton
                            className={styles.back_button}
                            onClick={onBack}
                            disableRipple
                        >
                            <ArrowBack className={styles.back_icon} />
                        </IconButton>
                    )}
                    <h1 className={styles.title_text}>
                        <span>{title}</span>
                    </h1>
                </div>
                {children}
            </div>
        </Dialog>
    );
};

export default Modal;
