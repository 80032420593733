import { logPageView } from 'common/analytics/firebaseEvents';
import React, { useState, useEffect, MouseEvent } from 'react';
import { Link, useParams } from 'react-router-dom';
import { useSelector, useDispatch } from 'common/hooks';
import { IconButton, Avatar } from '@material-ui/core';
import EditIcon from '@material-ui/icons/Edit';
import { Alert } from '@material-ui/lab'; // mock image for ComponentCards
import { dismissGetProjectError, getProject, updateLikeStatus } from 'redux/actions';
import withHelmet from 'components/hocs/withHelmet';
import { PAGE_NAMES } from 'constants/metaData';
import CategoryTag from 'components/CategoryTag';
import ShareButton from 'components/buttons/ShareButton';
import { LikeButton } from 'components/LikeButton';
import ComponentCard from 'components/ComponentCard';
import { ComponentCardModal } from 'components/modals/componentCardModal/ComponentCardModal';
import { Loading } from 'components/Loading';
import { SectionPlaceholder } from 'pages/partials/SectionPlaceholder';
import MeasurementsPanel from 'components/panels/MeasurementsPanel';
import styles from 'styles/project.module.css';
import editStyles from 'styles/editProject.module.css';

import logo from 'logo_500.png';

export const PROJECT_SECTIONS = ['sketches', 'materials', 'measurements', 'outcome'] as const;
export type ProjectSection = typeof PROJECT_SECTIONS[number];

const Project = () => {
    // eslint-disable-next-line no-use-before-define
    const { projectId } = useParams<{ projectId: string }>();
    const dispatch = useDispatch();
    const [open, setOpen] = useState<boolean>(false);
    const [currModalSection, setCurrModalSection] = useState<ProjectSection>('sketches');
    const [componentIndex, setComponentIndex] = useState<number>(0);

    const {
        userAuthenData,
        project,
        getProjectPending,
        getProjectError,
    } = useSelector((state) => ({
        userAuthenData: state.users.userAuthenData,
        project: state.projects.project,
        getProjectPending: state.projects.getProjectPending,
        getProjectError: state.projects.getProjectError,
    }));

    const allowedToEdit = (userAuthenData && project && (userAuthenData._id === project.owner_id || project.collaborators?.includes(userAuthenData._id || '')));

    const likeClick = (event: MouseEvent, likeVal: boolean) => {
        event.preventDefault();
        if (userAuthenData !== null && userAuthenData._id) {
            dispatch(updateLikeStatus(userAuthenData._id, projectId, likeVal));
        }
    };

    const handleModalOpen = (section: ProjectSection, index: number) => {
        setCurrModalSection(section);
        setComponentIndex(index);
        setOpen(true);
    };

    const handleModalClose = () => setOpen(false);

    useEffect(() => {
        dispatch(getProject(projectId, userAuthenData?._id));
        logPageView();
    }, []); // eslint-disable-line

    const loadingScreen = getProjectPending ? (<Loading />) : null;

    const errorScreen = getProjectError ? (
        <Alert
            onClose={() => dispatch(dismissGetProjectError())}
            className={editStyles.alert}
            severity='error'
        >
            Failed to get this project.
        </Alert>
    ) : null;

    const mainPage = (loadingScreen || errorScreen || (
        <div className={styles.outer_container}>
            <div className={styles.inner_container}>
                <div
                    style={{ backgroundImage: `url("${project?.featured_image || 'none'}")` }}
                    className={`${styles.row_1} ${styles.column_span_4} ${styles.featured_image}`}
                />
                <h1
                    className={`${styles.row_2} ${styles.column_1_3} ${styles.project_name}`}
                >
                    { project?.project_name || '' }
                </h1>
                <div className={`${styles.row_2} ${styles.column_4_5} ${styles.section_action_tag}`}>
                    <div className={`${styles.section_actions}`}>
                        {/* check to see if current user's ID matches the project owner's ID */}
                        {allowedToEdit
                        && (
                            <Link to={`/edit/project/${projectId}/general`}>
                                <IconButton className={`${styles.action_button}`} aria-label='edit'>
                                    <EditIcon className={styles.action_button_icon} />
                                </IconButton>
                            </Link>
                        )}
                        {!project?.liked_by ? null : (
                            <LikeButton
                                liked={!userAuthenData?._id
                                    ? false
                                    : project.liked_by.includes(userAuthenData._id)}
                                likeCount={project?.liked_by.length || 0}
                                likeAction={likeClick}
                            />
                        )}
                        <ShareButton
                            projectName={project?.project_name}
                            projectId={projectId}
                            projectDescription={project?.description}
                            ownerName={project?.owner_data?.user_name}
                            className={styles.action_button}
                            iconClass={styles.action_button_icon}
                        />
                    </div>
                </div>
                <div className={`${styles.row_3} ${styles.categories_wrapper}`}>
                    <div className={styles.section_category}>
                        { project?.categories?.map((item) => <CategoryTag category={item} key={item} />) }
                    </div>
                </div>
                <div className={`${styles.row_4} ${styles.column_1_3} ${styles.section_author}`}>
                    <Avatar
                        src={project?.owner_data?.user_image}
                        className={styles.author_avatar}
                    />
                    <div className={styles.author_name}>{ project?.owner_data?.user_name || '' }</div>
                </div>
                <div
                    className={`${styles.row_5} ${styles.column_span_4} ${styles.description}`}
                >
                    { project?.description }
                </div>
                <div className={`${styles.row_6} ${styles.column_1_3}`}>
                    <h3 className={styles.subtitle}>Sketches</h3>
                </div>
                <div className={`${styles.row_7} ${styles.column_span_4} ${!project?.sketches?.length ? '' : styles.cards}`}>
                    {
                        !project?.sketches?.length ? <SectionPlaceholder section='sketches' />
                            : project.sketches.map((item, index) => (
                                <ComponentCard
                                    data={{
                                        image: item.image || logo,
                                        description: item.description,
                                    }}
                                    onClick={() => handleModalOpen('sketches', index)}
                                    key={item.description}
                                />
                            ),
                            )
                    }
                </div>
                <div className={`${styles.row_8} ${styles.column_1_3}`}>
                    <h3 className={styles.subtitle}>Materials</h3>
                </div>
                <div className={`${styles.row_9} ${styles.column_span_4} ${!project?.materials?.length ? '' : styles.cards}`}>
                    {
                        !project?.materials?.length ? <SectionPlaceholder section='materials' />
                            : project.materials.map((item, index) => (
                                <ComponentCard
                                    data={{
                                        image: item.image || logo,
                                        description: item.description,
                                    }}
                                    onClick={() => handleModalOpen('materials', index)}
                                    key={item.description}
                                />
                            ),
                            )
                    }
                </div>
                <div className={`${styles.row_10} ${styles.column_1_3}`}>
                    <h3 className={styles.subtitle}>Measurements</h3>
                </div>
                <div className={`${styles.row_11} ${styles.column_span_4}`}>
                    {
                        !project?.measurements?.data.length ? <SectionPlaceholder section='measurements' />
                            : <MeasurementsPanel isEditing={false} />
                    }
                </div>
                <div className={`${styles.row_12} ${styles.column_1_3}`}>
                    <h3 className={styles.subtitle}>Outcome</h3>
                </div>
                <div className={`${styles.row_13} ${styles.column_span_4} ${!project?.outcome?.length ? '' : styles.cards}`}>
                    {
                        !project?.outcome?.length ? <SectionPlaceholder section='outcome' />
                            : project.outcome.map((item, index) => (
                                <ComponentCard
                                    data={{
                                        image: item.image || logo,
                                        description: item.description,
                                    }}
                                    onClick={() => handleModalOpen('outcome', index)}
                                    key={item.description}
                                />
                            ))
                    }
                </div>
            </div>
        </div>
    ));

    return (
        <div className={styles.wrapper}>
            <ComponentCardModal
                section={currModalSection}
                setSection={(section: ProjectSection) => {
                    setComponentIndex(0); // reset for now
                    setCurrModalSection(section);
                }}
                components={{
                    sketches: project?.sketches,
                    materials: project?.materials,
                    measurements: project?.measurements,
                    outcome: project?.outcome,
                }}
                componentIndex={componentIndex}
                setComponentIndex={setComponentIndex}
                open={open}
                onClose={handleModalClose}
            />
            {mainPage}
        </div>
    );
};

Project.displayName = PAGE_NAMES.PROJECT;

export default withHelmet(Project);
