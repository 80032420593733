import React from 'react';
import { Card, CardMedia, CardContent, Typography } from '@material-ui/core';
import { DetailedImage } from 'redux/projects/models/DetailedImage';
import styles from 'styles/componentCard.module.css';

interface Props {
    data: Omit<DetailedImage, 'annotations'>;
    // eslint-disable-next-line react/require-default-props
    onClick?: () => void;
}

const ComponentCard = ({ data, onClick }: Props) => {
    const card = (
        <Card className={styles.card}>
            <CardMedia className={styles.media} image={data.image} />
            <CardContent className={styles.content}>
                <Typography className={styles.description} variant='body2' component='p'>
                    {data.description}
                </Typography>
            </CardContent>
        </Card>
    );

    return (onClick
        ? (
            <button type='button' className={styles.wrap} onClick={onClick}>
                {card}
            </button>
        )
        : (
            card
        )
    );
};

export default ComponentCard;
