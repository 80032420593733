import React, { useEffect, useState } from 'react';

import Contact from 'pages/Contact';
import Home from 'pages/Home';
import PrivacyPolicy from 'pages/PrivacyPolicy';
import Profile from 'pages/Profile';
import Project from 'pages/Project';
import EditProject from 'pages/EditProject';
import Login from 'pages/Login';
import Join from 'pages/Join';
import VerifyEmail from 'pages/VerifyEmail';
import NotFound from 'pages/NotFound';
import Premium from 'pages/Premium';
import EmailSent from 'pages/EmailSent';
import Search from 'pages/Search';
import ResetPassword from 'pages/ResetPassword';
import ForgotPassword from 'pages/ForgotPassword';
import Showcase from 'pages/Showcase';
import CancelledPremium from 'pages/CancelledPremium';
import PremiumSuccess from 'pages/PremiumSuccess';
import Landing from 'pages/Landing';
import Terms from 'pages/Terms';

import { Loading } from 'components/Loading';
import { useSelector, useDispatch } from 'common/hooks';
import { Switch, Route, Redirect } from 'react-router-dom';
import { createMuiTheme, MuiThemeProvider, Theme } from '@material-ui/core/styles';
import {
    ReCaptchaProvider,
} from 'react-recaptcha-x';
import { SnackbarProvider } from 'notistack';
// @ts-ignore
import * as klaro from 'klaro/dist/klaro-no-css';
import ScrollToTop from 'components/ScrollToTop';
import Navbar from 'components/Navbar';
import Footer from 'components/Footer';
import { userTokenRefresh, checkSubscription } from 'redux/actions';
import { firebaseAnalytics, setAnalyticsState } from 'common/firebase';
import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';
import 'animate.css/animate.min.css';
import { useCookies } from 'react-cookie';

firebaseAnalytics?.setAnalyticsCollectionEnabled(process.env.NODE_ENV === 'production' || process.env.REACT_APP_ENV === 'prod');

const App = (): JSX.Element => {

    const dispatch = useDispatch();
    const [cookies] = useCookies();
    const [shouldCheckSubscription, setShouldCheckSubscription] = useState(false);
    const {
        userTokenRefreshPending,
        userAuthenData,
        checkSubscriptionPending,
    } = useSelector((state) => ({
        checkSubscriptionPending: state.users.checkSubscriptionPending,
        userTokenRefreshPending: state.users.userTokenRefreshPending,
        userAuthenData: state.users.userAuthenData,
    }));

    // Check if the user has a valid authen cookie. Log in this way
    useEffect(() => {
        dispatch(userTokenRefresh()).then(() => {
            setShouldCheckSubscription(true);
        });
        // Check for analytics cookie and disable is user chooses to disable
        if (cookies['chiqpack-gdpr-cookies'] && !cookies['chiqpack-gdpr-cookies']['Google Analytics']) {
            setAnalyticsState(false);
        }
    }, []); // eslint-disable-line

    useEffect(() => {
        if (shouldCheckSubscription && !checkSubscriptionPending && userAuthenData) {
            dispatch(checkSubscription(userAuthenData?._id));
            setShouldCheckSubscription(false);
        }
    }, [shouldCheckSubscription, checkSubscriptionPending, userAuthenData, dispatch]);

    // TODO - Improve this theme so it matches the css theme
    const mainPalette: Theme = createMuiTheme({
        palette: {
            primary: {
                main: '#fdaca7',
                contrastText: 'white',
            },
            secondary: {
                main: '#d0abe1',
                contrastText: 'white',
            },
        },
    });

    // we define a minimal configuration
    const klaroConfig = {
        testing: process.env.NODE_ENV === 'development',
        hideDeclineAll: true,
        hideLearnMore: false,
        storageName: 'chiqpack-gdpr-cookies',
        translations: {
            en: {
                consentNotice: {
                    description: 'This site requires the use of cookies for core functionality.'
                        + ' Additional cookies are used for analytics.',
                },
                functionality: {
                    title: 'Site Functionality',
                    description: 'Services needed for the site to function properly.',
                },
                purposes: {
                    functionality: 'Functionality ',
                    analytics: 'Analytics',
                },
                consentModal: {
                    description: 'This site uses cookies for core functionality and analytics.',
                },
            },
        },
        services: [
            {
                name: 'Chiqpack ',
                purposes: ['functionality'],
                description: 'Cookies needed for website functionality. Includes no tracking or advertisement cookies.',
                default: true,
                required: true,
            },
            {
                name: 'Google Analytics',
                purposes: ['analytics'],
                description: 'Cookies used to track usage of this website.',
                default: true,
                required: false,
                cookies: 'ga',
                callback: (consent: boolean) => setAnalyticsState(consent),
            },
        ],
    };

    // we assign the Klaro module to the window, so that we can access it in JS
    // @ts-ignore
    window.klaro = klaro;
    // @ts-ignore
    window.klaroConfig = klaroConfig;
    // we set up Klaro with the config
    klaro.setup();

    const mainBody: JSX.Element = userTokenRefreshPending ? <Loading /> : (
        <ScrollToTop>
            <Switch>
                <Route exact path='/' component={Home} />
                <Route exact path='/about' component={Landing} />
                <Route exact path='/showcase' component={Showcase} />
                <Route exact path='/contact' component={Contact} />
                <Route exact path='/premium' component={Premium} />
                <Route exact path='/profile/:userName' component={Profile} />
                <Route exact path='/:ownerName/:projectId' component={Project} />
                <Route path='/edit/project/:projectId' component={EditProject} />
                <Route exact path='/search' component={Search} />
                <Route exact path='/login' component={Login} />
                <Route exact path='/join' component={Join} />
                <Route exact path='/forgotPassword' component={ForgotPassword} />
                <Route exact path='/verifyEmail/:userID/:email' component={VerifyEmail} />
                <Route exact path='/emailVerificationSent' component={EmailSent} />
                <Route exact path='/resetPassword/:username/:token' component={ResetPassword} />
                <Route exact path='/privacy' component={PrivacyPolicy} />
                <Route exact path='/terms' component={Terms} />
                <Route exact path='/cancelled' component={CancelledPremium} />
                <Route exact path='/premium-welcome' component={PremiumSuccess} />
                <Route exact path='/404' component={NotFound} />
                <Redirect to='/404' />
            </Switch>
        </ScrollToTop>
    );

    return (
        <div className='App'>
            <ReCaptchaProvider
                siteKeyV2='6Le4qTUcAAAAAAv6JlNefgjmWZHYYPj3wDvxlE36'
                siteKeyV3='6Le1qTUcAAAAAA2SwVkhQVh-qVraBllvsib7zUDD'
                langCode='en'
                hideV3Badge
            >
                <MuiThemeProvider theme={mainPalette}>
                    <SnackbarProvider>
                        <div className='content-wrap'>
                            <Navbar />
                            {mainBody}
                            <Footer />
                        </div>
                    </SnackbarProvider>
                </MuiThemeProvider>
            </ReCaptchaProvider>
        </div>
    );
};

export default App;
