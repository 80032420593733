import React from 'react';
import ReactDOM from 'react-dom';
import { BrowserRouter } from 'react-router-dom';
import { Provider } from 'react-redux';
import { StylesProvider } from '@material-ui/core/styles';
import { Store } from 'common/configStore';
import App from 'App';
import * as serviceWorker from 'serviceWorker';
import { BlockNavigationProvider } from 'utils/BlockNavigationContext';
import { CookiesProvider } from 'react-cookie';
import { DEV_ENV } from 'utils/utils';
import 'common/muiThemeFix';
import 'common/firebase';
import 'index.css';

if (!DEV_ENV) {
    global.console = {
        ...global.console,
        log: () => undefined,
    };
}

ReactDOM.render(
    <React.StrictMode>
        <Provider store={Store}>
            <BlockNavigationProvider value={{ blockNavigation: false }}>
                <BrowserRouter>
                    <CookiesProvider>
                        <StylesProvider injectFirst>
                            <App />
                        </StylesProvider>
                    </CookiesProvider>
                </BrowserRouter>
            </BlockNavigationProvider>
        </Provider>
    </React.StrictMode>,
    document.getElementById('root'),
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
