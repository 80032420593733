import React from 'react';
import { Share } from '@material-ui/icons';
import { EmailShareButton } from 'react-share';
import { makeShareEmailBody, SHARE_EMAIL_SUBJECT } from 'constants/share';

interface Props {
    projectName?: string;
    projectId?: string;
    ownerName?: string;
    projectDescription?: string;
    className?: string;
    iconClass?: string;
}

const ShareButton = ({ projectName, ownerName, projectDescription, projectId, className, iconClass }: Props) => (
    <EmailShareButton
        url={`https://chiqpack.com/${ownerName}/${projectId}`}
        subject={SHARE_EMAIL_SUBJECT}
        body={makeShareEmailBody(projectName, ownerName, projectDescription)}
        aria-label='share'
        className={className}
    >
        <Share className={iconClass} />
    </EmailShareButton>
);

ShareButton.defaultProps = {
    projectName: '',
    projectId: '',
    ownerName: '',
    projectDescription: '',
    className: '',
    iconClass: '',
};

export default ShareButton;
