import React, { CSSProperties } from 'react';
import styles from './styles/featureBubble.module.css';

interface Props {
    text: string;
    icon: any; // TODO: figure out typing for icons
    textStyle?: CSSProperties;
    iconStyle?: CSSProperties;
}

const FeatureBubble = ({ text, icon, textStyle, iconStyle }: Props) => (
    <div className={styles.container}>
        <div className={styles.iconContainer}>
            <img
                className={styles.icon}
                src={icon}
                style={iconStyle}
                alt={`feature bubble icon: ${text}`}
            />
        </div>
        <div className={styles.text} style={textStyle}>
            {text}
        </div>
    </div>
);

FeatureBubble.defaultProps = {
    textStyle: {},
    iconStyle: {},
};

export default FeatureBubble;
