import { logPageView } from 'common/analytics/firebaseEvents';
import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'common/hooks';
import { checkSubscription, dismissCancelPremiumError } from 'redux/actions';
import { Redirect } from 'react-router-dom';
import withHelmet from 'components/hocs/withHelmet';
import { PAGE_NAMES } from 'constants/metaData';

const CancelledPremium = () => {

    const dispatch = useDispatch();

    const {
        userAuthenData,
    } = useSelector((state) => ({
        userAuthenData: state.users.userAuthenData,
    }));

    const [redir, setRedir] = useState<boolean>(false);

    useEffect(() => {
        logPageView();
    }, []);

    useEffect(() => {
        if (userAuthenData?._id) {
            dispatch(checkSubscription(userAuthenData._id));
        }
        dispatch(dismissCancelPremiumError());
        setTimeout(() => setRedir(true), 10000);
    }, []); // eslint-disable-line

    return (
        <div>
            {redir && (<Redirect to='/' />)}
            {/* eslint-disable-next-line react/no-unescaped-entities */}
            <h1>We're sorry to see you go</h1>
        </div>
    );
};

CancelledPremium.displayName = PAGE_NAMES.CANCELLED_PREMIUM;

export default withHelmet(CancelledPremium);
