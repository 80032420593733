import React from 'react';
import { useSelector, useDispatch } from 'common/hooks';
import { updateLikeStatus } from 'redux/actions';
import ProjectCardLarge from 'components/projectCardVariants/ProjectCardLarge';
import ProjectCardSmall from 'components/projectCardVariants/ProjectCardSmall';
import ProjectCardRegular from 'components/projectCardVariants/ProjectCardRegular';

export const PROJECT_CARD_VARIANT = {
    SMALL: 'SMALL',
    REGULAR: 'REGULAR',
    LARGE: 'LARGE',
};

type ProjectCardVariant = keyof typeof PROJECT_CARD_VARIANT;

interface Props {
    variant?: ProjectCardVariant;
    categories?: Array<string>;
    projectId?: string;
    projectName?: string;
    projectDescription?: string;
    projectStats?: Array<string | number>;
    ownerName?: string;
    ownerImage?: string;
    ownerPremium?: boolean;
    ownerID?: string;
    likeCount?: number;
    liked?: boolean;
    featuredImage?: any;
}

const ProjectCard = (props: Props) => {
    const { userAuthenData } = useSelector((state) => ({
        userAuthenData: state.users.userAuthenData,
    }));

    const dispatch = useDispatch();

    const {
        variant,
        ...restOfProps
    } = props;

    const handleLike = (event: any, likeVal: boolean) => { // TODO: type properly
        const { projectId } = restOfProps;

        event.preventDefault();
        if (userAuthenData) {
            dispatch(updateLikeStatus(userAuthenData._id, projectId, likeVal));
        }
    };

    const renderCard = () => {
        // if (variant === PROJECT_CARD_VARIANT.SMALL) {
        //     return <ProjectCardSmall {...restOfProps} handleLike={handleLike} />;
        // }
        if (variant === PROJECT_CARD_VARIANT.LARGE) {
            return <ProjectCardLarge {...restOfProps} handleLike={handleLike} />;
        }
        return (
            // Use small card as default until we have a better solution for the regular card
            <ProjectCardSmall {...restOfProps} handleLike={handleLike} />
        );
    };

    return renderCard();
};

ProjectCard.defaultProps = {
    variant: PROJECT_CARD_VARIANT.REGULAR,
    categories: [],
    projectId: undefined,
    projectName: undefined,
    projectDescription: undefined,
    projectStats: undefined,
    ownerName: undefined,
    ownerImage: undefined,
    ownerPremium: undefined,
    likeCount: 0,
    liked: false,
    featuredImage: undefined,
};

export default ProjectCard;
