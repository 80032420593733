import { AppAction } from 'common/configStore';
import { AnyAction } from 'redux';
import {
    GET_USERS_BEGIN,
    GET_USERS_DISMISS_ERROR,
    GET_USERS_FAILURE,
    GET_USERS_SUCCESS,
} from 'redux/users/constants';

export const getUsers = (users: string[]): AppAction => async (dispatch) => {
    dispatch({
        type: GET_USERS_BEGIN,
    });

    if (users.length === 0) {
        dispatch({
            type: GET_USERS_SUCCESS,
            data: [],
        });
        return new Promise(() => {});
    }

    const query = `
            query {
              getUsers(
                users: ${JSON.stringify(users)}
              ) {
                _id
                email
                user_name
              }
            }
        `;

    const opts = {
        method: 'POST',
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify({ query }),
    };

    return fetch(process.env.REACT_APP_GRAPHQL || '', opts)
        .then((response) => response.json())
        .then((createdJson) => {
            if (createdJson.errors) {
                dispatch({
                    type: GET_USERS_FAILURE,
                    errors: createdJson.errors,
                });
            } else {
                dispatch({
                    type: GET_USERS_SUCCESS,
                    data: createdJson.data.getUsers,
                });
            }
        })
        .catch((errors) => {
            dispatch({
                type: GET_USERS_FAILURE,
                errors,
            });
        });
};

// Async action saves request error by default, this method is used to dismiss the error info.
// If you don't want errors to be saved in Redux store, just ignore this method.
export const dismissGetUsersError = () => ({
    type: GET_USERS_DISMISS_ERROR,
});

export const reducer = (state: any, action: AnyAction) => {
    switch (action.type) {
    case GET_USERS_BEGIN:
        // Just after a request is sent
        return {
            ...state,
            getUsersPending: true,
            getUsersError: null,
            getUsersData: null,
        };

    case GET_USERS_SUCCESS:
        // The request is success
        return {
            ...state,
            getUsersPending: false,
            getUsersError: null,
            getUsersData: action.data,
        };

    case GET_USERS_FAILURE:
        // The request is failed
        return {
            ...state,
            getUsersData: null,
            getUsersPending: false,
            getUsersError: action.errors,
        };

    case GET_USERS_DISMISS_ERROR:
        // Dismiss the request failure error
        return {
            ...state,
            getUsersError: null,
        };

    default:
        return state;
    }
};
