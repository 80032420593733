import { AppAction } from 'common/configStore';
import { firebaseAnalytics } from 'common/firebase';
import { AnyAction } from 'redux';
import { Project } from 'redux/projects/models/Project';
import { inspect } from 'util';
import { convertFileObjectToProjectOutput, FileSection, getCreateProjectAuthentication } from 'redux/utils/projectUtils';
import {
    CREATE_PROJECT_BEGIN,
    CREATE_PROJECT_DISMISS_ERROR,
    CREATE_PROJECT_FAILURE,
    CREATE_PROJECT_SUCCESS,
    SET_CREATE_PROJECT_TO_FALSE,
} from 'redux/projects/constants';

export const createProject = (
    userID: string = '',
    project: Partial<Project> = {},
): AppAction => async (dispatch, getState) => {
    dispatch({
        type: CREATE_PROJECT_BEGIN,
    });

    const state = getState();

    // Get authentication before proceeding
    const authen = await getCreateProjectAuthentication(userID);

    if (!authen) {
        dispatch({
            type: CREATE_PROJECT_FAILURE,
            errors: ['You don\'t have permissions to create a project.'],
        });
        return;
    }

    const updatedProject = {
        ...project,
    };

    // Create a temporary id for images when creating a new project. This will get updated if these images are updated/replaced
    const projectID = `${userID}_${project?.project_name}`;

    // Featured image should be of type fileObject.
    // We will change it to a URL once the upload succeeds.
    const imageFile = updatedProject.featured_image;

    if (imageFile) {

        // Upload and get url of uploaded image
        const imageURL = await convertFileObjectToProjectOutput(
            userID,
            projectID,
            imageFile,
            FileSection.FeaturedImage,
        );
        if (imageURL !== '') {
            updatedProject.featured_image = imageURL;
        } else {
            dispatch({
                type: CREATE_PROJECT_FAILURE,
                errors: ['Failed to upload featured image. Please try again later'],
            });
            return;
        }

    }

    const query = `
            mutation {
              createProject(
                owner_id: "${userID}"
                project: ${inspect(updatedProject).replace(/'/g, '"')}
              ) {
                _id
                categories
                liked_by
                owner_id
                private
                is_showcase
                project_name
                description
                date_created
                last_updated
                featured_image
                owner_data {
                    user_name
                    user_image
                }
                sketches {
                    description
                    image
                }
                materials {
                    description
                    image
                }
                outcome {
                    description
                    image
                }
                measurements {
                    headerWidths
                    headers
                    data
                }
              }
            }
        `;

    const opts: RequestInit = {
        credentials: 'include',
        method: 'POST',
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify({ query }),
    };

    fetch(process.env.REACT_APP_GRAPHQL || '', opts)
        .then((response) => response.json())
        .then((createdJson) => {
            if (createdJson.errors) {
                dispatch({
                    type: CREATE_PROJECT_FAILURE,
                    errors: createdJson.errors,
                });
            } else {
                firebaseAnalytics?.logEvent('project_created', {
                    user_id: userID,
                    user_status: state.users.userSubscription?.userTier,
                });
                dispatch({
                    type: CREATE_PROJECT_SUCCESS,
                    data: createdJson.data.createProject,
                });
            }
        })
        .catch((errors) => {
            dispatch({
                type: CREATE_PROJECT_FAILURE,
                errors,
            });
        });
};

// Async action saves request error by default, this method is used to dismiss the error info.
// If you don't want errors to be saved in Redux store, just ignore this method.
export const dismissCreateProjectError = () => ({
    type: CREATE_PROJECT_DISMISS_ERROR,
});

// This is to aid with redirecting through the modal after the project was created
export const setProjectCreatedToFalse = () => ({
    type: SET_CREATE_PROJECT_TO_FALSE,
});

export const reducer = (state: any, action: AnyAction) => {
    switch (action.type) {
    case CREATE_PROJECT_BEGIN:
        // Just after a request is sent
        return {
            ...state,
            createProjectPending: true,
            createProjectError: null,
            project: null,
            projectCreated: false,
        };

    case CREATE_PROJECT_SUCCESS:
        // The request is success
        return {
            ...state,
            createProjectPending: false,
            createProjectError: null,
            project: action.data,
            projectCreated: true,
        };

    case CREATE_PROJECT_FAILURE:
        // The request is failed
        return {
            ...state,
            createProjectPending: false,
            createProjectError: action.errors,
            project: null,
        };

    case CREATE_PROJECT_DISMISS_ERROR:
        // Dismiss the request failure error
        return {
            ...state,
            createProjectError: null,
        };

    case SET_CREATE_PROJECT_TO_FALSE:
        return {
            ...state,
            projectCreated: false,
        };

    default:
        return state;
    }
};
