import { logPageView } from 'common/analytics/firebaseEvents';
import { useSelector, useDispatch } from 'common/hooks';
import React, { useEffect } from 'react';
import { useHistory } from 'react-router-dom';
import ScrollAnimation from 'react-animate-on-scroll';
import { premiumCheckout } from 'redux/actions';
import styles from 'styles/premium.module.css';
import { PremiumTable } from 'components/PremiumTable';
import PageSection, { PageSectionProps } from 'pages/partials/PageSection';
import collabGraphic from 'assets/collaboration_graphic.jpg';
import infinityGraphic from 'assets/infinity_sign_pink_bg.jpg';
import privacyGraphic from 'assets/privacy_graphic.jpg';
import THEME_COLORS from 'utils/themeColors';
import { USER_TIER_1_PRICE_ID, USER_TIER_2_PRICE_ID } from 'common/envConstants';
import withHelmet from 'components/hocs/withHelmet';
import { PAGE_NAMES } from 'constants/metaData';

const features: Array<PageSectionProps> = [
    {
        title: (
            <span>
                <b>Unlimited</b>
                {' '}
                Projects
            </span>
        ),
        subtitle: 'No Limits',
        blurb: (
            <>
                Creativity is a value we hold very deeply.
                <br />
                {/* eslint-disable-next-line react/no-unescaped-entities */}
                Whether they're large projects, or small
                <br />
                Have the freedom to create all the designs you can think of.
            </>
        ),
        image: infinityGraphic,
        textSide: 'left',
    },
    {
        title: (
            <span>
                <b>Collaborate</b>
                {' '}
                with others
            </span>
        ),
        subtitle: 'Collaboration',
        blurb: (
            <>
                They say two minds are better than one.
                <br />
                {/* eslint-disable-next-line react/no-unescaped-entities */}
                We'd have to agree, but we won't limit you to two.
                <br />
                Feel free to add any number of collaborators to your project!
            </>
        ),
        image: collabGraphic,
        textSide: 'right',
    },
    {
        title: (
            <span>
                <b>Private</b>
                {' '}
                Projects
            </span>
        ),
        subtitle: 'Privacy',
        blurb: (
            <>
                We know how important privacy is.
                <br />
                {/* eslint-disable-next-line react/no-unescaped-entities */}
                That's why we give you the option to hide projects.
                <br />
                {/* eslint-disable-next-line react/no-unescaped-entities */}
                Don't worry - you can still give access to collaborators.
            </>
        ),
        image: privacyGraphic,
        textSide: 'left',
    },
];

const Premium = () => {
    const dispatch = useDispatch();
    const history = useHistory(); // eslint-disable-line
    const isEven = (x: number) => x % 2 === 0;

    const {
        userAuthenData,
    } = useSelector((state) => ({
        userAuthenData: state.users.userAuthenData,
    }));

    useEffect(() => {
        logPageView();
    }, []);

    const handlePremiumCtaClick = (priceID: string) => {
        if (userAuthenData) {
            dispatch(premiumCheckout(userAuthenData?._id, priceID));
        } else {
            history.push('/join');
        }
    };

    const handleTier1Click = () => handlePremiumCtaClick(USER_TIER_1_PRICE_ID || '');

    const handleTier2Click = () => handlePremiumCtaClick(USER_TIER_2_PRICE_ID || '');

    const ctaLabelTier1 = userAuthenData ? 'Get Couture' : 'Sign Up';
    const ctaLabelTier2 = userAuthenData ? 'Get Très Chic' : 'Sign Up';

    return (
        <div className={styles.outer_container}>
            <div className={styles.inner_container}>
                <h1 className={styles.page_title}>Premium</h1>
                <div className={styles.content_container}>
                    {
                        features.map(({ title, subtitle, blurb, image, textSide }, index) => (
                            <PageSection
                                /* eslint-disable-next-line react/no-array-index-key */
                                key={`${textSide}_${index}`}
                                title={title}
                                subtitle={subtitle}
                                blurb={blurb}
                                image={image}
                                textSide={textSide}
                                backgroundColor={isEven(index) ? THEME_COLORS.salmon : undefined}
                                subtitleColor={isEven(index) ? 'white' : undefined}
                                animationLeft='animate__slideInLeft'
                                animationRight='animate__slideInRight'
                            />
                        ))
                    }
                </div>
                <div style={{ display: 'flex', justifyContent: 'center', margin: '5rem 0' }}>
                    <div style={{ borderTop: '1px solid #E9E9E9', width: '50%' }} />
                </div>
                <ScrollAnimation animateOnce animateIn='animate__slideInUp'>
                    <div style={{ display: 'flex', justifyContent: 'center', width: '100%' }}>
                        <PremiumTable
                            ctaLabelTier1={ctaLabelTier1}
                            ctaOnClickTier1={handleTier1Click}
                            ctaLabelTier2={ctaLabelTier2}
                            ctaOnClickTier2={handleTier2Click}
                        />
                    </div>
                </ScrollAnimation>
            </div>
        </div>
    );
};

Premium.displayName = PAGE_NAMES.PREMIUM;

export default withHelmet(Premium);
