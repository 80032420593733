export enum UserTiers {
    // eslint-disable-next-line no-unused-vars
    FREE = 'free',
    // eslint-disable-next-line no-unused-vars
    TIER_1 = 'tier_1',
    // eslint-disable-next-line no-unused-vars
    TIER_2 = 'tier_2',
}

export interface User {
    _id: string;
    email: string;
    // eslint-disable-next-line camelcase
    user_name: string;
    // eslint-disable-next-line camelcase
    date_created: Date;
    password: string;
    // eslint-disable-next-line camelcase
    project_ids: string[];
    // eslint-disable-next-line camelcase
    liked_projects: string[];
    // eslint-disable-next-line camelcase
    collaborating_on: string[];
    // eslint-disable-next-line camelcase
    user_image: string;
    emailVerified: boolean;
    resetPasswordToken: string;
    useResetToken: boolean;
}
