import React, { useState, useContext, useEffect, ChangeEvent, KeyboardEvent } from 'react';
import { Prompt, useHistory } from 'react-router-dom';
import { useSelector, useDispatch } from 'common/hooks';
import { Typography, TextField, IconButton, Modal, Backdrop, Fade } from '@material-ui/core';
import { MuiThemeProvider, createMuiTheme } from '@material-ui/core/styles';
import ImageIcon from '@material-ui/icons/Image';
import RemoveCircleIcon from '@material-ui/icons/RemoveCircle';
import { DropzoneArea } from 'material-ui-dropzone';
import { Alert } from '@material-ui/lab';
import { isEqual } from 'lodash';
import {
    updateProject,
    createProject,
    dismissCreateProjectError, deleteProject, removeProjectToUserAuthen, dismissProjectDeleted,
} from 'redux/actions';
import { Project, UpdatedProject } from 'redux/projects/models/Project';
import BlockNavigationContext from 'utils/BlockNavigationContext';
import CategoryTag from 'components/CategoryTag';
import SolidButton from 'components/buttons/StyledButton';
import THEME_COLORS from 'utils/themeColors';
import styles from 'styles/basicInfoPanel.module.css';

interface GeneralProps {
    newProject?: boolean;
}

interface StoredValues {
    projectName: string;
    description: string;
    imageURL: string | ArrayBuffer | null;
    categories: string[];
}

export const General = ({ newProject }: GeneralProps) => {
    const dispatch = useDispatch();
    const blockNavigationContext = useContext(BlockNavigationContext);
    const history = useHistory();

    const { blockNavigation, setBlockNavigation } = blockNavigationContext;

    const {
        project,
        updateProjectPending,
        userAuthenData,
        createProjectPending,
        createProjectError,
        projectDeleted,
    } = useSelector((state) => ({
        project: state.projects.project,
        updateProjectPending: state.projects.updateProjectPending,
        projectUpdated: state.projects.projectUpdated,
        userAuthenData: state.users.userAuthenData,
        createProjectPending: state.projects.createProjectPending,
        createProjectError: state.projects.createProjectError,
        projectDeleted: state.projects.projectDeleted,
    }));

    /** Values from redux store (current, saved project data)
     * Will be updated with actual values in useEffect
     */
    const [storedValues, setStoredValues] = useState<Partial<StoredValues>>({
        projectName: '',
        description: '',
        imageURL: '',
        categories: [],
    });

    /** Values of the TextFields in the form */
    const [values, setValues] = useState<Partial<StoredValues>>({
        projectName: '',
        description: '',
        imageURL: '',
        categories: [],
    });

    const [category, setCategory] = useState('');

    const [files, setFiles] = useState<File[]>([]);
    const [deleteModalOpen, setDeleteModalOpen] = useState<boolean>(false);

    const reader = new FileReader();

    /** https://github.com/Yuvaleros/material-ui-dropzone/blob/master/docs/theming.md */
    const theme = createMuiTheme({
        overrides: {
            MuiDropzoneArea: {
                root: {
                    display: 'flex',
                    backgroundColor: 'transparent',
                    color: THEME_COLORS.darkPurple,
                    borderRadius: '10px',
                },
                textContainer: {
                    display: storedValues.imageURL !== values.imageURL ? 'none' : 'block',
                    alignSelf: 'center',
                    margin: '0 auto',
                    width: 'fit-content',
                    padding: '1rem',
                    borderRadius: '10px',
                    backgroundColor: 'rgba(152, 236, 204, 0.95)',
                },
            },
        },
    });

    // load values on startup
    useEffect(() => {
        const val = {
            projectName: project?.project_name,
            description: project?.description,
            imageURL: project?.featured_image,
            categories: project?.categories?.map((item) => item.toLowerCase()),
        };

        if (!newProject) {
            setStoredValues((v) => ({
                ...v,
                ...val,
            }));
            setValues((v) => ({
                ...v,
                ...val,
            }));
        }

        setBlockNavigation(false);
    }, [project, newProject, setBlockNavigation]);

    useEffect(() => {
        if (projectDeleted) {
            dispatch(dismissProjectDeleted());
            dispatch(removeProjectToUserAuthen(project?._id || '')).then(() => history.push(`/profile/${userAuthenData?.user_name}`));
        }
    }, [projectDeleted]);

    /* window.onbeforeunload helps avoid unsafe reload */
    useEffect(() => {
        if (blockNavigation) {
            window.onbeforeunload = () => true;
        } else {
            window.onbeforeunload = null;
        }
    }, [blockNavigation]);

    // works in a similar way as componentWillUnmount (cleanup)
    useEffect(() => () => {
        setBlockNavigation(false);
        window.onbeforeunload = null;
    }, []); // eslint-disable-line

    const deleteProjectAction = () => {
        dispatch(deleteProject(
            userAuthenData?._id,
            project?._id,
        ));
    };

    /** Check if input differs from what's in the store. Set blockNavigation appropriately */
    const compareValues = (val: Partial<StoredValues>) => {
        if (newProject) return;
        const equal = isEqual(storedValues, val);
        if (equal && blockNavigation) {
            setBlockNavigation(false);
        } else if (!equal && !blockNavigation) {
            setBlockNavigation(true);
        }
    };

    /** Helper function to avoid code duplication */
    const updateValues = (val: Partial<StoredValues>) => {
        compareValues(val);
        setValues(val);
    };

    /** Pull image url using FileReader and save to imageURL */
    const setURLFromFile = (file: File) => {
        if (file == null) return;

        let fileContent = null;
        reader.onload = () => {
            fileContent = reader.result;
            const val = { ...values, imageURL: fileContent };
            updateValues(val);
        };
        reader.readAsDataURL(file);
    };

    /** Handle featured image file */
    const handleFileUpload = (filesProp: File[]) => {
        setURLFromFile(filesProp[0]);
        setFiles(filesProp);
    };

    /** Remove uploaded image file */
    const handleFileDelete = () => {
        const val = { ...values, imageURL: storedValues.imageURL };
        setFiles([]);
        updateValues(val); // restore original image for project
    };

    /** Update values in TextFields appropriately */
    const handleChange = (prop: string) => (event: ChangeEvent<HTMLTextAreaElement | HTMLInputElement>) => {
        // wait for project to load from store, then block navigation if input differs from what's in the store
        const val = { ...values, [prop]: event.target.value };
        updateValues(val);
    };

    /** Update value in category textfield */
    const handleChangeInCategory = (event: ChangeEvent<HTMLTextAreaElement | HTMLInputElement>) => {
        setCategory(event.target.value);
    };

    /** Removes a category from values.categories */
    const handleCategoryDelete = (categoryToDelete: string) => {
        const val = { ...values, categories: values?.categories?.filter((item) => item !== categoryToDelete) };
        updateValues(val);
    };

    /** If enter clicked in category textfield, add to categories array  */
    const handleKeyDown = (event: KeyboardEvent) => {
        if (event.key === 'Enter' && category !== '') {
            const c = category.toLowerCase();
            let val;
            if (!values?.categories?.includes(c)) {
                val = { ...values, categories: [...(values?.categories ?? []), c] };
                updateValues(val);
            }
            setCategory('');
        }
    };

    const createNewProject = () => {
        // eslint-disable-next-line camelcase
        const projectObject: Partial<Project> & { featured_image?: { image: File } } = {
            project_name: values.projectName,
            description: values.description,
            categories: values.categories,
        };
        if (files?.length > 0) {
            // @ts-ignore
            projectObject.featured_image = {
                image: files[0],
            };
        }
        dispatch(createProject(
            userAuthenData?._id,
            projectObject,
        ));
    };

    const saveChanges = async () => {
        // blockNavigation is only true when there are changes
        // If true, update accordingly, else do nothing
        if (blockNavigation) {
            // eslint-disable-next-line camelcase
            const projectObject: Partial<UpdatedProject> = {
                project_name: values.projectName,
                description: values.description,
                categories: values.categories,
            };
            if (files?.length > 0) {
                projectObject.featured_image = {
                    oldURL: project?.featured_image,
                    image: files[0],
                };
            }

            // We need to pass the original owner id
            projectObject.owner_id = project?.owner_id;

            dispatch(
                updateProject(
                    userAuthenData?._id,
                    project?._id,
                    projectObject,
                ),
            );
        }
    };

    // Error when failing to create project
    const projectError = createProjectError ? (
        <Alert
            onClose={() => dispatch(dismissCreateProjectError())}
            className={styles.alert}
            severity='error'
        >
            There was a problem creating this project. Please try again later
        </Alert>
    ) : null;

    const projectNameSection = (
        <section className={styles.section}>
            <Typography className={styles.subtitle}>Project Name</Typography>
            <TextField
                className={styles.textfield}
                id='project_name'
                placeholder='Project Name'
                value={values?.projectName || ''}
                onChange={handleChange('projectName')}
                fullWidth={false}
                variant='outlined'
            />
        </section>
    );

    const dropzoneIcon = () => (
        <ImageIcon style={{ fontSize: '4em', color: THEME_COLORS.darkPurple }} />
    );

    const featuredImageSection = (
        <section className={styles.section}>
            <Typography className={styles.subtitle}>Featured Image</Typography>
            <MuiThemeProvider theme={theme}>
                <div
                    className={styles.featured_image}
                    style={files?.length > 0 || project?.featured_image !== ''
                        ? {
                            backgroundImage: `url("${values.imageURL}")`,
                            backgroundSize: 'cover',
                            backgroundPosition: 'center',
                        }
                        : {
                            backgroundColor: THEME_COLORS.teal,
                            backgroundImage: 'none',
                        }}
                >
                    <DropzoneArea
                        initialFiles={files}
                        acceptedFiles={['image/jpeg', 'image/png', 'image/heic']}
                        filesLimit={1}
                        maxFileSize={5000000}
                        showPreviewsInDropzone={false}
                        dropzoneText='Drag and drop an image here or click'
                        // @ts-ignore
                        Icon={dropzoneIcon}
                        onChange={handleFileUpload}
                    />
                    <IconButton
                        className={styles.remove_button}
                        style={{ display: files?.length > 0 ? 'block' : 'none', height: '2rem', width: '2rem', padding: '0' }}
                        onClick={handleFileDelete}
                    >
                        <RemoveCircleIcon className={styles.remove_image_icon} />
                    </IconButton>
                </div>
            </MuiThemeProvider>
        </section>
    );

    const descriptionSection = (
        <section className={styles.section}>
            <Typography className={styles.subtitle}>Description</Typography>
            <TextField
                id='description'
                placeholder='Description'
                value={values?.description || ''}
                onChange={handleChange('description')}
                fullWidth
                multiline
                rows={4}
                rowsMax={4}
                variant='outlined'
            />
        </section>
    );

    const categoriesSection = (
        <section className={styles.section}>
            <Typography className={styles.subtitle}>Categories</Typography>
            <div className={styles.section_category}>
                <TextField
                    className={styles.textfield}
                    id='category'
                    placeholder='Category'
                    value={category || ''}
                    onChange={handleChangeInCategory}
                    onKeyDown={handleKeyDown}
                    variant='outlined'
                />
                <div className={styles.category_tags}>
                    {
                        !project && !newProject
                            ? null
                            : values?.categories?.map((item) => (
                                <div key={item} className={styles.remove_button_wrap}>
                                    <CategoryTag category={item} />
                                    <IconButton
                                        className={styles.remove_button}
                                        onClick={() => handleCategoryDelete(item)}
                                    >
                                        <RemoveCircleIcon className={styles.remove_category_icon} />
                                    </IconButton>
                                </div>
                            ))
                    }
                </div>
            </div>
        </section>
    );

    const deleteModal = (
        <div>
            <Modal
                open={deleteModalOpen}
                onClose={() => setDeleteModalOpen(false)}
                className={styles.modal}
                closeAfterTransition
                BackdropComponent={Backdrop}
                BackdropProps={{ timeout: 500 }}
            >
                <Fade in={deleteModalOpen}>
                    <div className={styles.modalInnerContainer}>
                        <h1 className={styles.modalTitle}>
                            Are you sure you want to delete this project? This cannot be undone.
                        </h1>
                        <div className={styles.buttonRow}>
                            <div className={styles.modalButton}>
                                <SolidButton
                                    style={{
                                        borderColor: THEME_COLORS.lightPurple,
                                    }}
                                    outline
                                    textColor='white'
                                    onClick={() => setDeleteModalOpen(false)}
                                >
                                    No
                                </SolidButton>
                            </div>
                            <div className={styles.spacer} />
                            <div className={styles.modalButton}>
                                <SolidButton
                                    bgColor={THEME_COLORS.burgundyRed}
                                    textColor='white'
                                    onClick={() => deleteProjectAction()}
                                >
                                    Yes
                                </SolidButton>
                            </div>
                        </div>

                    </div>
                </Fade>
            </Modal>
        </div>
    );

    return (
        <>
            {deleteModal}
            <div className={styles.container}>
                {projectError}
                <div>
                    <>
                        <Prompt
                            when={blockNavigation}
                            message='You have unsaved changes, are you sure you want to leave?'
                        />
                    </>
                    <form className={styles.form} noValidate autoComplete='off'>
                        {projectNameSection}
                        {featuredImageSection}
                        {descriptionSection}
                        {categoriesSection}
                    </form>
                    <div className={styles.buttonRow}>
                        <SolidButton
                            disabled={
                                createProjectPending || updateProjectPending
                                || (!newProject && !blockNavigation)
                                || (newProject && (!userAuthenData || values.projectName === ''))
                            }
                            bgColor={THEME_COLORS.lightPurple}
                            textColor='white'
                            onClick={newProject ? createNewProject : saveChanges}
                        >
                            {newProject ? 'Create Project' : 'Save Changes'}
                        </SolidButton>
                        <div className={styles.spacer} />
                        {!newProject && userAuthenData?.project_ids?.includes(`${project?._id}`)
                        && (
                            <SolidButton
                                bgColor={THEME_COLORS.burgundyRed}
                                textColor='white'
                                onClick={() => { setDeleteModalOpen(true); }}
                            >
                                Delete Project
                            </SolidButton>
                        )}
                    </div>
                </div>
            </div>
        </>
    );
};

General.defaultProps = {
    newProject: false,
};
