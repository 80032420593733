export const UPDATE_LIKE_STATUS_SUCCESS = 'UPDATE_LIKE_STATUS_SUCCESS';
export const UPDATE_LIKE_STATUS_FAILURE = 'UPDATE_LIKE_STATUS_FAILURE';
export const UPDATE_LIKE_STATUS_BEGIN = 'UPDATE_LIKE_STATUS_BEGIN';
export const UPDATE_LIKE_STATUS_DISMISS_ERROR = 'UPDATE_LIKE_STATUS_DISMISS_ERROR';

export const CONTACT_SEND_SUCCESS = 'CONTACT_SEND_SUCCESS';
export const CONTACT_SEND_FAILURE = 'CONTACT_SEND_FAILURE';
export const CONTACT_SEND_BEGIN = 'CONTACT_SEND_BEGIN';
export const CONTACT_SEND_DISMISS_ERROR = 'CONTACT_SEND_DISMISS_ERROR';

export const VERIFY_EMAIL_SUCCESS = 'VERIFY_EMAIL_SUCCESS';
export const VERIFY_EMAIL_FAILURE = 'VERIFY_EMAIL_FAILURE';
export const VERIFY_EMAIL_BEGIN = 'VERIFY_EMAIL_BEGIN';
export const VERIFY_EMAIL_DISMISS_ERROR = 'VERIFY_EMAIL_DISMISS_ERROR';

export const SEARCH_SUCCESS = 'SEARCH_SUCCESS';
export const SEARCH_FAILURE = 'SEARCH_FAILURE';
export const SEARCH_BEGIN = 'SEARCH_BEGIN';
export const SEARCH_DISMISS_ERROR = 'SEARCH_DISMISS_ERROR';

export const FORGOT_PASSWORD_SUCCESS = 'FORGOT_PASSWORD_SUCCESS';
export const FORGOT_PASSWORD_FAILURE = 'FORGOT_PASSWORD_FAILURE';
export const FORGOT_PASSWORD_BEGIN = 'FORGOT_PASSWORD_BEGIN';
export const FORGOT_PASSWORD_DISMISS_ERROR = 'FORGOT_PASSWORD_DISMISS_ERROR';
