import { Project } from 'redux/projects/models/Project';

export const DEV_ENV = process.env.REACT_APP_ENV === 'dev' || process.env.REACT_APP_ENV === 'development' || process.env.NODE_ENV === 'development';

export const getRandom = (arr: Partial<Project>[], n: number) => {
    if (!arr || arr.length === 0) {
        return [];
    }
    let origN = n;
    const result = new Array(n);
    let len = arr.length;
    const taken = new Array(len);
    if (n > len) {
        return [];
    }
    // eslint-disable-next-line no-plusplus
    while (origN--) {
        const x = Math.floor(Math.random() * len);
        result[origN] = arr[x in taken ? taken[x] : x];
        // eslint-disable-next-line no-plusplus
        taken[x] = --len in taken ? taken[len] : len;
    }
    return result;
};
