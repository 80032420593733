import React, { CSSProperties } from 'react';
import { useMediaQuery } from '@material-ui/core';
import { Corner, CORNER_POSITION } from 'components/ExamplesCarousel/constants';
import styles from './styles/cornerInfo.module.css';

interface Props {
    corner: Corner;
    blurb: string | JSX.Element;
}

const CornerInfo = ({ corner, blurb }: Props) => {
    const isMobile = useMediaQuery('(max-width: 760px)');
    const isWideScreen = useMediaQuery('(min-width: 1800px)');
    const blurbFontSize = isMobile ? '2.2vw' : isWideScreen ? '25px' : '1.6vw';

    const cornerPositionStyles = {
        [CORNER_POSITION.TOP_LEFT]: { top: 0, left: 0, borderBottomRightRadius: 10 },
        [CORNER_POSITION.TOP_RIGHT]: { top: 0, right: 0, borderBottomLeftRadius: 10 },
        [CORNER_POSITION.BOTTOM_RIGHT]: { bottom: 0, right: 0, borderTopLeftRadius: 10 },
        [CORNER_POSITION.BOTTOM_LEFT]: { bottom: 0, left: 0, borderTopRightRadius: 10 },
    };

    const cornerStyle: CSSProperties = {
        width: isMobile ? '33%' : '25%',
        height: isMobile ? '15%' : '10%',
        fontSize: blurbFontSize,
        padding: isMobile ? 0 : '1rem',
        ...cornerPositionStyles[corner || CORNER_POSITION.TOP_LEFT],
    };

    return (
        <div className={styles.container} style={cornerStyle}>
            {blurb}
        </div>
    );
};

export default CornerInfo;
