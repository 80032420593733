import axios, { AxiosRequestConfig } from 'axios';
import {
    USER_TOKEN_REFRESH_BEGIN,
    USER_TOKEN_REFRESH_SUCCESS,
    USER_TOKEN_REFRESH_FAILURE,
    USER_TOKEN_REFRESH_DISMISS_ERROR,
} from 'redux/users/constants';
import { AnyAction } from 'redux';
import { AppAction } from 'common/configStore';

export const userTokenRefresh = (): AppAction => async (dispatch) => {
    dispatch({
        type: USER_TOKEN_REFRESH_BEGIN,
    });

    const query = `
            mutation tokenAuthen {
              tokenAuthen {
                _id
                email
                user_name
                project_ids
                liked_projects
                user_image
                date_created
              }
            }
        `;

    const axiosOptions: AxiosRequestConfig = {
        withCredentials: true,
        method: 'POST',
        headers: {
            'Content-Type': 'application/json',
        },
        timeout: 2000, // Timeout of 2 seconds
    };

    try {
        const response = await axios.post(`${process.env.REACT_APP_GRAPHQL}`, JSON.stringify({ query }), axiosOptions);
        const createdJson = response?.data;
        if (createdJson?.data?.tokenAuthen && !createdJson.errors) {
            dispatch({
                type: USER_TOKEN_REFRESH_SUCCESS,
                userData: createdJson.data.tokenAuthen,
            });
        } else {
            dispatch({
                type: USER_TOKEN_REFRESH_FAILURE,
                errors: createdJson.errors || 'Failed to login.',
            });
        }
    } catch (errors) {
        dispatch({
            type: USER_TOKEN_REFRESH_FAILURE,
            errors,
        });
    }

};

// Async action saves request error by default, this method is used to dismiss the error info.
// If you don't want errors to be saved in Redux store, just ignore this method.
export const dismissUserTokenRefreshError = () => ({
    type: USER_TOKEN_REFRESH_DISMISS_ERROR,
});

export const reducer = (state: any, action: AnyAction) => {
    switch (action.type) {
    case USER_TOKEN_REFRESH_BEGIN:
        // Just after a request is sent
        return {
            ...state,
            userTokenRefreshPending: true,
            userTokenRefreshError: null,
        };

    case USER_TOKEN_REFRESH_SUCCESS:
        // The request is success
        return {
            ...state,
            userTokenRefreshPending: false,
            userTokenRefreshError: null,
            authenticated: true,
            userAuthenData: action.userData,
        };

    case USER_TOKEN_REFRESH_FAILURE:
        // The request is failed
        return {
            ...state,
            userTokenRefreshPending: false,
            userTokenRefreshError: action.errors,
            authenticated: false,
        };

    case USER_TOKEN_REFRESH_DISMISS_ERROR:
        // Dismiss the request failure error
        return {
            ...state,
            userTokenRefreshError: null,
        };

    default:
        return state;
    }
};
