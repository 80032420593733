export const GET_PROJECT_BEGIN = 'GET_PROJECT_BEGIN';
export const GET_PROJECT_SUCCESS = 'GET_PROJECT_SUCCESS';
export const GET_PROJECT_FAILURE = 'GET_PROJECT_FAILURE';
export const GET_PROJECT_DISMISS_ERROR = 'GET_PROJECT_DISMISS_ERROR';
//
export const GET_PROJECTS_BEGIN = 'GET_PROJECTS_BEGIN';
export const GET_PROJECTS_SUCCESS = 'GET_PROJECTS_SUCCESS';
export const GET_PROJECTS_FAILURE = 'GET_PROJECTS_FAILURE';
export const GET_PROJECTS_DISMISS_ERROR = 'GET_PROJECTS_DISMISS_ERROR';
//
export const GET_SHOWCASE_BEGIN = 'GET_SHOWCASE_BEGIN';
export const GET_SHOWCASE_SUCCESS = 'GET_SHOWCASE_SUCCESS';
export const GET_SHOWCASE_FAILURE = 'GET_SHOWCASE_FAILURE';
export const GET_SHOWCASE_DISMISS_ERROR = 'GET_SHOWCASE_DISMISS_ERROR';
//
export const UPDATE_PROJECT_BEGIN = 'UPDATE_PROJECT_BEGIN';
export const UPDATE_PROJECT_SUCCESS = 'UPDATE_PROJECT_SUCCESS';
export const UPDATE_PROJECT_FAILURE = 'UPDATE_PROJECT_FAILURE';
export const UPDATE_PROJECT_DISMISS_ERROR = 'UPDATE_PROJECT_DISMISS_ERROR';
export const UPDATE_PROJECT_DISMISS_SUCCESS = 'UPDATE_PROJECT_DISMISS_SUCCESS';
//
export const CREATE_PROJECT_BEGIN = 'CREATE_PROJECT_BEGIN';
export const CREATE_PROJECT_SUCCESS = 'CREATE_PROJECT_SUCCESS';
export const CREATE_PROJECT_FAILURE = 'CREATE_PROJECT_FAILURE';
export const CREATE_PROJECT_DISMISS_ERROR = 'CREATE_PROJECT_DISMISS_ERROR';
export const SET_CREATE_PROJECT_TO_FALSE = 'SET_CREATE_PROJECT_TO_FALSE';
//
export const UPDATE_PROJECT_VISIBILITY_BEGIN = 'UPDATE_PROJECT_VISIBILITY_BEGIN';
export const UPDATE_PROJECT_VISIBILITY_SUCCESS = 'UPDATE_PROJECT_VISIBILITY_SUCCESS';
export const UPDATE_PROJECT_VISIBILITY_FAILURE = 'UPDATE_PROJECT_VISIBILITY_FAILURE';
export const UPDATE_PROJECT_VISIBILITY_DISMISS_ERROR = 'UPDATE_PROJECT_VISIBILITY_DISMISS_ERROR';
export const UPDATE_PROJECT_VISIBILITY_DISMISS_SUCCESS = 'UPDATE_PROJECT_VISIBILITY_DISMISS_SUCCESS';
//
export const UPDATE_COLLABORATORS_BEGIN = 'UPDATE_COLLABORATORS_BEGIN';
export const UPDATE_COLLABORATORS_SUCCESS = 'UPDATE_COLLABORATORS_SUCCESS';
export const UPDATE_COLLABORATORS_FAILURE = 'UPDATE_COLLABORATORS_FAILURE';
export const UPDATE_COLLABORATORS_DISMISS_ERROR = 'UPDATE_COLLABORATORS_DISMISS_ERROR';
export const UPDATE_COLLABORATORS_DISMISS_SUCCESS = 'UPDATE_COLLABORATORS_DISMISS_SUCCESS';
//
export const DELETE_PROJECT_BEGIN = 'DELETE_PROJECT_BEGIN';
export const DELETE_PROJECT_SUCCESS = 'DELETE_PROJECT_SUCCESS';
export const DELETE_PROJECT_FAILURE = 'DELETE_PROJECT_FAILURE';
export const DELETE_PROJECT_DISMISS_ERROR = 'DELETE_PROJECT_DISMISS_ERROR';
export const DELETE_PROJECT_DISMISS_SUCCESS = 'DELETE_PROJECT_DISMISS_SUCCESS';
